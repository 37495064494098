import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { FilterList } from 'core/icons'
import React from 'react'

export const ManageClosetsHistoryFilterComponent = ({ search, point, status, setSearch, setPoint, setStatus }: any) => {
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
	}

	const handlePointChange = (event: SelectChangeEvent) => {
		setPoint(event.target.value)
	}

	const handleStatusChange = (event: SelectChangeEvent) => {
		setStatus(event.target.value)
	}

	return (
		<Box display='flex' justifyContent='space-between' alignItems='center' py={1} px={2}>
			<Box display='flex' justifyContent='space-between' alignItems='center' gap={3}>
				<FilterList />
				<Box display='flex' alignItems='center'>
					<Typography variant='caption_1_regular' color='primary.gray'>
						Точка выдачи:
					</Typography>
					<FormControl sx={{ mt: 1.5 }}>
						<Select
							value={point}
							onChange={handlePointChange}
							displayEmpty
							id='my-point-select'
							sx={{
								'& #my-point-select': {
									fontSize: '12px',
									fontWeight: 600
								},
								height: 24,
								'.MuiOutlinedInput-notchedOutline': {
									border: 'none'
								},
								'.MuiSelect-iconOutlined': {
									marginTop: -0.5
								}
							}}
						>
							<MenuItem value='1'>№1</MenuItem>
							<MenuItem value='2'>№2</MenuItem>
							<MenuItem value='3'>№3</MenuItem>
						</Select>
					</FormControl>
				</Box>
				<Box display='flex' alignItems='center'>
					<Typography variant='caption_1_regular' color='primary.gray'>
						Статусы:
					</Typography>
					<FormControl sx={{ mt: 1.5 }}>
						<Select
							value={status}
							onChange={handleStatusChange}
							displayEmpty
							id='my-status-select'
							sx={{
								'& #my-status-select': {
									fontSize: '13px',
									fontWeight: 600
								},
								height: 24,
								'.MuiOutlinedInput-notchedOutline': {
									border: 'none'
								},
								'.MuiSelect-iconOutlined': {
									marginTop: -0.5
								}
							}}
						>
							<MenuItem value='all'>Все</MenuItem>
							<MenuItem value='done'>Готово</MenuItem>
							<MenuItem value='in_progress'>В процессе</MenuItem>
							<MenuItem value='queue'>В очереди</MenuItem>
						</Select>
					</FormControl>
				</Box>
			</Box>
			<SearchInput search={search} onChangeSearch={onChangeSearch} />
		</Box>
	)
}
