import { MenuList, Sidebar } from 'components'
import { useApp } from 'core/contexts/profile.context'
import {
	AccessTime,
	AccountCircle,
	Cash,
	CheckCircle,
	ConfirmationNumber,
	Dashboard,
	ErrorOutline,
	FastFood,
	HouseSiding,
	ListAlt,
	Login,
	PeopleOutline,
	Printer,
	QrCode,
	Return,
	RoomService,
	SensorWindow,
	Settings,
	StackedLineChart,
	StoreFront,
	TableChart,
	TableRows,
	ViewColumn
} from 'core/icons'
import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { mainColors } from 'theme/colors'

const Root = styled.div`
	min-height: 100vh;
	display: flex;
	background: ${mainColors.Background['background-1']};
`

const Main = styled.main`
	flex: 1;
	margin: 16px 16px 8px 0;
	background: #ffffff;
	border: 1px solid rgba(214, 214, 214, 0.48);
	border-radius: 12px;
`

export const MainLayout = () => {
	const { profile } = useApp()
	const getCheckShow = (perm: any) => {
		return profile?.user?.role?.permissions?.filter((p: any) => p === perm).length > 0
	}
	const primaryMenuList: MenuList[] = [
		{
			id: 1,
			subheader: 'Справочники',
			list: [
				{
					id: 100,
					text: 'Клиенты',
					url: '/clients',
					icon: <AccountCircle />,
					show: getCheckShow('view_customers')
				},
				{
					id: 101,
					text: 'Сотрудники',
					url: '/stuffs',
					icon: <PeopleOutline />,
					show: getCheckShow('view_employees')
				},
				{
					id: 102,
					text: 'Шкафы',
					url: '/lockers',
					icon: <SensorWindow />,
					show: getCheckShow('view_closets')
				},
				{
					id: 103,
					text: 'Топчаны',
					url: '/tapchans',
					icon: <HouseSiding />,
					show: getCheckShow('view_tapchans')
				},
				{
					id: 104,
					text: 'Точки для выдачи заказа',
					url: '/order_issuing',
					icon: <StoreFront />,
					show: getCheckShow('view_issue_points')
				},
				{
					id: 105,
					text: 'Полотенце',
					url: '/towel',
					icon: <TableRows />,
					show: getCheckShow('view_towels')
				},
				{
					id: 106,
					text: 'Прочие услуги',
					url: '/other_services',
					icon: <ListAlt />,
					show: getCheckShow('view_services')
				},
				{
					id: 107,
					text: 'Турникеты',
					url: '/tourniquets',
					icon: <Settings />,
					show: getCheckShow('view_tourniquets')
				},
				{
					id: 108,
					text: 'Еда и напитки',
					url: '/food_and_drinks',
					icon: <FastFood />,
					show: getCheckShow('view_product_categories')
				},
				{
					id: 109,
					text: 'Билеты',
					url: '/tickets',
					icon: <ConfirmationNumber />,
					show: getCheckShow('view_tickets')
				},
				{
					id: 110,
					text: 'Доступы и роли',
					url: '/accesses_roles',
					icon: <CheckCircle />,
					show: getCheckShow('view_roles')
				},
				{
					id: 111,
					text: 'Инструктаж правил',
					url: '/rules',
					icon: <ListAlt />,
					show: getCheckShow('view_basic_rules')
				},
				{
					id: 112,
					text: 'Печать чеков',
					url: '/printers',
					icon: <Printer />,
					show: getCheckShow('view_printers')
				}
			]
		},
		{
			id: 2,
			subheader: 'Управление',
			list: [
				{
					id: 200,
					text: 'Заказы',
					url: '/orders',
					icon: <RoomService />,
					show: getCheckShow('view_orders')
				},
				{
					id: 201,
					text: 'Продажа по QR',
					url: '/qr',
					icon: <QrCode />,
					show: getCheckShow('view_manage_buy_qr')
				},
				{
					id: 202,
					text: 'История продаж услуг',
					url: '/sales_history',
					icon: <AccessTime />,
					show: getCheckShow('view_manage_service')
				},
				{
					id: 203,
					text: 'Управление топчанами',
					url: '/management_tapchan',
					icon: <ViewColumn />,
					show: getCheckShow('view_manage_tapchan')
				},
				{
					id: 204,
					text: 'Управление шкафами',
					url: '/management_closet_select',
					icon: <Dashboard />,
					show: getCheckShow('view_manage_closet')
				},
				{
					id: 205,
					text: 'Управление билетами',
					url: '/tickets_manage',
					icon: <StackedLineChart />,
					show: getCheckShow('view_manage_ticket')
				},
				{
					id: 206,
					text: 'История платежей',
					url: '/payment-history',
					icon: <Cash />,
					show: getCheckShow('wallet_operation_view')
				},
				{
					id: 207,
					text: 'Возврат денег',
					url: '/return-cash',
					icon: <Return />,
					show: getCheckShow('wallet_operation_view')
				},
				{
					id: 208,
					text: 'Управление входа/выхода',
					url: '/management_enter_exit',
					icon: <Login />,
					show: getCheckShow('view_crossings')
				},
				{
					id: 209,
					text: 'Управление полотенцами',
					url: '/management_towel',
					icon: <TableChart />,
					show: getCheckShow('view_towel_uses')
				},
				{
					id: 210,
					text: 'SOS',
					url: '/sos',
					icon: <ErrorOutline />,
					show: getCheckShow('view_sos')
				}
			]
		}
	]
	return (
		<Root>
			<Sidebar primaryMenuList={primaryMenuList} />
			<Main>
				<Outlet />
			</Main>
		</Root>
	)
}
