import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography
} from '@mui/material'
import UploadIcon from 'components/uploadIcon/upload-icon'
import { dateFormatterTime } from 'core/helpers/date-formatter'
import { ArrowBack } from 'core/icons'
import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import {
	addTicketsCategory,
	deleteTicketCategory,
	getTicketCategoryId,
	getTicketsCategories,
	updateTicketsCategory
} from 'services/ticketsCategory/tickets-category.service'
import { useBoolean } from 'usehooks-ts'

const label = { inputProps: { 'aria-label': 'Switch demo' } }

export const TicketsCategoryDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const {
		register,
		handleSubmit,
		control,
		reset,
		setValue,
		watch,
		formState: { errors }
	} = useForm<any>()

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'visit_times'
	})
	const watchVisit = watch('visit_times')
	const [image, setImage] = useState<any>()
	const [timeArrive, setTimeArrive] = useState<any>()
	const [childCategory, setChildCategory] = useState<any>()
	const [needCloset, setNeedCloset] = useState(false)
	const [fileImage, setFileImage] = useState<any>()
	const modalOpen = useBoolean(false)

	const ticketsCategoryMutation = useMutation({
		mutationFn: payload => addTicketsCategory(payload),
		onSuccess: () => {
			reset()
			navigate('/tickets?tab=2')
			toast.success('Successfully added')
		}
	})

	const { data: category } = useQuery(['getTicketCategoryId', id, location], {
		queryFn: () => getTicketCategoryId(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})

	const { data: categories } = useQuery(['getTicketsCategories'], {
		queryFn: () => getTicketsCategories(true)
	})

	const deleteCategoryMutate = useMutation({
		mutationFn: (category_id: any) => deleteTicketCategory(category_id),
		onSuccess: () => {
			toast.success('Билет успешно удален!')
			navigate('/tickets?tab=2')
		}
	})

	const categoryUpdateMutation = useMutation({
		mutationFn: ({ category_id, body }: any) => updateTicketsCategory({ category_id, body }),
		onSuccess: () => {
			toast.success('Изменения успешно сохранены!')
			navigate('/tickets?tab=2')
		}
	})

	const onSubmit = (formValues: any) => {
		console.log(formValues)
		const fd: any = new FormData()
		if (fileImage) fd.append('icon', fileImage)
		formValues.visit_times.forEach((visitTime: any, index: any) => {
			fd.append(`visit_times[${index}][hours_from]`, dateFormatterTime(new Date(visitTime.hours_from)))
			fd.append(`visit_times[${index}][hours_to]`, dateFormatterTime(new Date(visitTime.hours_to)))
		})

		if (!childCategory) {
			fd.append('parent_category_id', '')
		} else {
			fd.append('parent_category_id', formValues.parent_category_id)
		}
		fd.append('name', formValues.name)
		fd.append('need_closet', `${needCloset}`)
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		location.search.indexOf('edit') > 0
			? categoryUpdateMutation.mutate({ category_id: id, body: fd })
			: ticketsCategoryMutation.mutate(fd)
	}

	const onIconUpload = (file: any) => {
		setFileImage(file)
		setImage(URL.createObjectURL(file))
	}

	const handleChangeTime = (e: any) => {
		setTimeArrive(e.target.checked)
	}
	const handleChangeChildCategory = (e: any) => {
		setChildCategory(e.target.checked)
	}
	const handleChangeNeedCloset = (event: any) => {
		const value = event.target.checked
		setNeedCloset(value)
	}

	const onDeleteIcon = () => {
		setFileImage(null)
		setImage(null)
	}

	const onAddTimes = () => {
		append({ hours_from: '', hours_to: '' })
	}

	const onDeleteInput = (index: number) => () => {
		remove(index)
	}
	const handleCategoryDelete = () => {
		deleteCategoryMutate.mutate(id)
	}
	const onChangePhone = (e: any, index_number: any, name: any) => {
		setValue(`visit_times.${index_number}.${name}`, e.target.value)
	}
	useEffect(() => {
		if (category?.data) {
			setTimeArrive(category.data.visit_times.length > 0)
			setChildCategory(!!category.data?.parent_category_id)
			setImage(category.data.icon_path)
			setNeedCloset(category.data.need_closet)
			setValue('name', category.data.name)
			setValue('visit_times', category.data.visit_times)
			setValue('parent_category_id', category.data?.parent_category_id)
		}
	}, [setValue, category])
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={modalOpen.setTrue}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={424}>
					<Typography variant='headline_1'>Данные категории билета</Typography>
					<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
						<Box mb={3} mt={4}>
							<TextField
								label='Наименование'
								placeholder=''
								required
								disabled={location.search.indexOf('view') > 0}
								fullWidth
								error={!!errors.name}
								{...register('name')}
							/>
						</Box>
						<Box mb={1} display='flex' justifyContent='space-between' alignItems='center'>
							<Typography variant='body_2'>Является подкатегорией</Typography>
							<Switch
								{...label}
								disabled={location.search.indexOf('view') > 0}
								checked={childCategory}
								onChange={handleChangeChildCategory}
							/>
						</Box>
						{childCategory && (
							<Controller
								control={control}
								name='parent_category_id'
								render={({ field: { ...field } }) => (
									<FormControl required fullWidth>
										<InputLabel id='demo-simple-select-label'>Категория</InputLabel>
										<Select
											disabled={location.search.indexOf('view') > 0}
											labelId='demo-simple-select-label'
											id='demo-simple-select'
											{...field}
										>
											{categories?.data.map((cat: any) => (
												<MenuItem value={cat.id}>{cat.name}</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							/>
						)}
						<Divider />
						<Box mb={1} display='flex' justifyContent='space-between' alignItems='center'>
							<Typography variant='body_2'>Присваивается шкаф</Typography>
							<Switch
								{...label}
								name='need_closet'
								disabled={location.search.indexOf('view') > 0}
								checked={needCloset}
								onChange={handleChangeNeedCloset}
							/>
						</Box>
						<Divider />
						<Box my={1} display='flex' justifyContent='space-between' alignItems='center'>
							<Typography variant='body_2'>Имеются время посещения</Typography>
							<Switch
								{...label}
								disabled={location.search.indexOf('view') > 0}
								checked={timeArrive}
								onChange={handleChangeTime}
							/>
						</Box>
						{timeArrive &&
							fields.map((inp: any, index) => (
								<Box mb={2} position='relative' key={inp.id}>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<InputMask
												mask='99:99'
												maskChar=' '
												disabled={location.search.indexOf('view') > 0}
												value={watchVisit[index].hours_from}
												onChange={event => onChangePhone(event, index, 'hours_from')}
												alwaysShowMask={false}
											>
												<TextField fullWidth label='Время с' type='text' />
											</InputMask>
										</Grid>
										<Grid item xs={6}>
											<InputMask
												mask='99:99'
												maskChar=' '
												disabled={location.search.indexOf('view') > 0}
												value={watchVisit[index].hours_to}
												onChange={event => onChangePhone(event, index, 'hours_to')}
												alwaysShowMask={false}
											>
												<TextField fullWidth label='Время по' type='text' />
											</InputMask>
										</Grid>
									</Grid>
									{location.search.indexOf('view') < 0 && (
										<Button
											variant='text'
											size='small'
											color='error'
											sx={{
												position: 'absolute',
												right: '-100px',
												top: '38px'
											}}
											onClick={onDeleteInput(index)}
										>
											Удалить
										</Button>
									)}
								</Box>
							))}

						{timeArrive && (
							<Button
								variant='text'
								size='small'
								onClick={onAddTimes}
								disabled={location.search.indexOf('view') > 0}
							>
								Добавить время
							</Button>
						)}
						<UploadIcon
							src={image}
							disabled={location.search.indexOf('view') > 0}
							onUpload={onIconUpload}
							onDeleteIcon={onDeleteIcon}
						/>
						{location.search.indexOf('view') < 0 && (
							<Button variant='contained' type='submit' color='success' sx={{ marginTop: '32px' }}>
								{ticketsCategoryMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
							</Button>
						)}
					</form>
				</Box>
			</Box>

			<Dialog
				open={modalOpen.value}
				onClose={modalOpen.setFalse}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={modalOpen.setFalse}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleCategoryDelete} autoFocus variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
