import { axiosBaseInstance } from 'core/axios.config'

export const getCrossings = async (params: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/crossings/today`, {
		params
	})
	return data
}

export const getCrossingsList = async (params: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/crossings`, {
		params
	})
	return data
}
