import { Box, Button, Divider, TextField, Typography } from '@mui/material'
import { ArrowBack } from 'core/icons'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { activateTickets, deActivateTickets, getTicketsById } from 'services/tickets/tickets.service'
import { useDebounce } from 'usehooks-ts'

export const ActivatePage = () => {
	const navigate = useNavigate()
	const [customerTickets, setCustomerTickets] = useState('')
	const debouncedSearch = useDebounce(customerTickets, 1000)
	const { data: ticket, isLoading } = useQuery(['getTicketsById', debouncedSearch], {
		queryFn: () => getTicketsById(debouncedSearch),
		enabled: !!debouncedSearch
	})
	const activateMutation = useMutation({
		mutationFn: (customerTicket: any) => activateTickets(customerTicket),
		onSuccess: () => {
			navigate('/management_enter_exit')
			toast.success('Активация прошла успешно!')
		}
	})

	const deactivateMutate = useMutation({
		mutationFn: (customerTicket: any) => deActivateTickets(customerTicket),
		onSuccess: () => {
			navigate('/management_enter_exit')
			toast.success('Деактивация прошла успешно!')
		}
	})
	const onActivate = () => {
		activateMutation.mutate(ticket?.data?.id)
	}
	const onDeactivate = () => {
		deactivateMutate.mutate(ticket?.data?.id)
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={520}>
					<Typography variant='headline_1' mb={1}>
						Активация / Деактивация билета
					</Typography>
					<Box style={{ width: '100%' }}>
						<Box mb={2} mt={4} display='flex' justifyContent='space-between'>
							<TextField
								label='Номер билета'
								placeholder=''
								required
								fullWidth
								disabled={isLoading}
								type='text'
								value={customerTickets}
								onChange={event => setCustomerTickets(event.target.value)}
							/>
						</Box>
						{ticket?.data?.can_be_deactivated && (
							<Button
								variant='outlined'
								onClick={onDeactivate}
								color='primary'
								style={{ width: '252px', marginRight: '16px' }}
							>
								<span style={{ color: '#349EFF' }}>деактивировать</span>
							</Button>
						)}
						{ticket?.data?.can_be_activated && (
							<Button variant='contained' onClick={onActivate} color='info' style={{ width: '252px' }}>
								<span style={{ color: '#FFFFFF' }}>активировать</span>
							</Button>
						)}
					</Box>
				</Box>
			</Box>
		</div>
	)
}
