import { Box, Button, Divider, styled, TextField, Typography } from '@mui/material'
import { ArrowBack } from 'core/icons'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { customerWalletDeposit, getCustomerNumber } from 'services/payment/payment.service'
import { useDebounce } from 'usehooks-ts'

const ImgBlock = styled('div')(() => ({
	img: {
		height: '40px',
		width: '40px',
		borderRadius: '40px',
		objectFit: 'cover',
		marginRight: '12px'
	}
}))

export const PaymentPage = () => {
	const navigate = useNavigate()
	const [customerNumber, setCustomerNumber] = useState('')
	const [customerAmount, setCustomerAmount] = useState('')
	const debouncedSearch = useDebounce(customerNumber, 3000)
	const { handleSubmit, reset } = useForm<any>()
	const { data: customer } = useQuery(['getCustomerNumber', debouncedSearch], {
		queryFn: () => getCustomerNumber(debouncedSearch),
		enabled: !!debouncedSearch
	})
	const paymentMutation = useMutation({
		mutationFn: ({ customer_id, amount }: any) => customerWalletDeposit({ customer_id, amount }),
		onSuccess: () => {
			reset()
			navigate('/payment-history')
			toast.success('Сумма успешно зачислена!')
		}
	})
	const onSubmit = () => {
		paymentMutation.mutate({
			customer_id: customer?.data?.id,
			amount: customerAmount
		})
	}
	return (
		<div>
			<Box display='flex' p={2}>
				<Box display='flex' alignItems='center'>
					<Button
						color='info'
						variant='text'
						size='small'
						onClick={() => navigate(-1)}
						sx={{ border: 0, borderRadius: '50%', marginRight: 2 }}
					>
						<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
					</Button>
					<Typography variant='title_2'>История платежей</Typography>
				</Box>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={520}>
					<Typography variant='headline_1' mb={1}>
						Данные платежа
					</Typography>
					<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
						<Box style={{ width: '100%' }}>
							<Box mb={2} mt={4} display='column'>
								<InputMask
									mask='99999999999'
									maskChar=''
									value={customerNumber}
									onChange={event => setCustomerNumber(event.target.value)}
								>
									<TextField
										label='Телефон платежника'
										placeholder=''
										required
										fullWidth
										type='text'
									/>
								</InputMask>
								{customer?.data?.has_phone && (
									<Box display='flex' mt={3} mb={3}>
										<ImgBlock>
											<img src={customer?.data?.avatar_url} alt='frame' />
										</ImgBlock>
										<Box display='column'>
											<Typography variant='footnote_1' color='gray'>
												Клиент
											</Typography>
											<Typography>
												{customer?.data?.first_name} {customer?.data?.last_name}
											</Typography>
										</Box>
									</Box>
								)}
								<TextField
									label='Сумма зачисления'
									placeholder=''
									required
									fullWidth
									type='text'
									value={customerAmount}
									onChange={event => setCustomerAmount(event.target.value)}
								/>
							</Box>
							<Button
								variant='contained'
								type='submit'
								disabled={!customer?.data?.has_phone}
								color='success'
								style={{ width: '252px' }}
							>
								<span style={{ color: '#FFFFFF' }}>зачислить</span>
							</Button>
						</Box>
					</form>
				</Box>
			</Box>
		</div>
	)
}
