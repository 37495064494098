import { Box, Button, Divider, Link, styled, Typography } from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import { parseLockers } from 'pages/Lockers/parse'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import {
	cancelTowelUseReturn,
	confirmTowelUseReturn,
	getTowelReturnRequest
} from 'services/towelManagement/towelManage.service'

const ImgBlock = styled('div')(() => ({
	img: {
		height: '136px',
		width: '136px'
	}
}))
export const TowelsMainComponent = () => {
	const { profile } = useApp()
	const { data: towelRequest, refetch } = useQuery(['getTowelReturnRequest'], {
		queryFn: () => getTowelReturnRequest()
	})
	const acceptMutation = useMutation({
		mutationFn: (towel_id: any) => confirmTowelUseReturn(towel_id),
		onSuccess: () => {
			toast.success('Полотенце успешно принят!')
			refetch()
		}
	})

	const cancelMutate = useMutation({
		mutationFn: (towel_id: any) => cancelTowelUseReturn(towel_id),
		onSuccess: () => {
			toast.success('Successfully canceled')
			refetch()
		}
	})

	const onAccept = (towel_id: any) => () => {
		acceptMutation.mutate(towel_id)
	}

	const onCancel = (id: any) => () => {
		cancelMutate.mutate(id)
	}

	return (
		<div>
			<Box p={3} display='flex' justifyContent='center' alignItems='center'>
				<Typography variant='title_2'>Подтверждение СДАЧИ</Typography>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={500}>
					<Box display='flex' flexDirection='column' mb={2}>
						{towelRequest?.data &&
							parseLockers(towelRequest.data).map((row: any) => (
								<Box display='flex' flexDirection='column'>
									<Box display='flex' justifyContent='center' alignItems='center' mb={2}>
										<Box mr={3}>
											<ImgBlock>
												<img src={row.customer.avatar_url} alt='frame' />
											</ImgBlock>
										</Box>
										<Box
											display='flex'
											flexDirection='column'
											justifyContent='center'
											alignContent='center'
										>
											<Link
												href={`/management_towel/${row.id}?detail`}
												underline='none'
												color='inherit'
											>
												<Typography variant='body_2'>
													{row.customer.first_name} {row.customer.last_name}
												</Typography>
											</Link>
											<Box display='flex' mb={1}>
												<Typography variant='footnote_1' color='primary.gray' mr={1}>
													Сдает количество:
												</Typography>
												<Typography variant='footnote_1'>{row.quantity}х</Typography>
											</Box>
										</Box>
									</Box>
									{profile?.user?.role.permissions.includes('confirm_towel_use') && (
										<Box
											display='flex'
											justifyContent='center'
											alignContent='center'
											gap={2}
											mb={3}
										>
											<Button
												color='error'
												variant='outlined'
												onClick={onCancel(row.id)}
												sx={{ width: '212px' }}
											>
												Отменить
											</Button>
											<Button
												color='success'
												onClick={onAccept(row.id)}
												variant='outlined'
												sx={{ width: '212px' }}
											>
												<span style={{ color: '#24D321' }}>Принять</span>
											</Button>
										</Box>
									)}
								</Box>
							))}
					</Box>
				</Box>
			</Box>
		</div>
	)
}
