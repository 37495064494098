import { toast } from 'react-toastify'

export const downloadFile = async (url: string, name: string) => {
	toast.info('Начинается скачивание', {
		position: toast.POSITION.TOP_RIGHT,
		theme: 'dark'
	})
	await fetch(url)
		.then(async response => response.blob())
		.then(blob => {
			const blobURL = URL.createObjectURL(blob)
			const a = document.createElement('a')
			a.href = blobURL

			if (name && name.length > 0) a.download = name
			document.body.append(a)
			a.click()
			toast.success('Файл успешно скачан', {
				position: toast.POSITION.TOP_RIGHT,
				theme: 'dark'
			})
		})
		.catch(() => {
			toast.error('Ошибка скачивания', {
				position: toast.POSITION.TOP_RIGHT,
				theme: 'dark'
			})
		})
}
