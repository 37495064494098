import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	Menu,
	MenuItem,
	Pagination,
	Typography
} from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { useApp } from 'core/contexts/profile.context'
import { AccessTime, ArrowBack, Cancel, Dots, Edit, View } from 'core/icons'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteProducts, getProducts } from 'services/product/product.service'
import { useDebounce } from 'usehooks-ts'

export const FoodsPage = () => {
	const navigate = useNavigate()
	const { profile } = useApp()
	const { id } = useParams()
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const [currentId, setCurrentId] = useState<any>()
	const [page, setPage] = React.useState(1)
	const [search, setSearch] = React.useState('')
	const [anchorDotsEl, setAnchorDotsEl] = React.useState<null | HTMLElement>(null)
	const debouncedSearch = useDebounce(search, 500)
	const openDots = Boolean(anchorDotsEl)
	const { data: products, refetch } = useQuery(['getProducts', page, debouncedSearch, id], {
		queryFn: () =>
			getProducts({
				page,
				id,
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})

	const deleteFoodMutate = useMutation({
		mutationFn: (service_id: any) => deleteProducts(service_id),
		onSuccess: () => {
			toast.success('Successfully deleted')
			setOpenDeleteModal(false)
			setAnchorDotsEl(null)
			refetch()
		}
	})
	const handleDeleteOpenModal = () => setOpenDeleteModal(true)
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}

	const handleHistoryStuff = () => {
		navigate(`/food_and_drinks/product/${currentId}/history`)
	}
	const handleDeleteFood = () => {
		deleteFoodMutate.mutate(currentId)
	}

	const handleEditFood = () => {
		navigate(`/food_and_drinks/product/${currentId}?edit`)
	}

	const handleViewFood = () => {
		navigate(`/food_and_drinks/product/${currentId}?view`)
	}
	const handleClickDots = (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentId(event.currentTarget.id)
		setAnchorDotsEl(event.currentTarget)
	}
	const handleCloseDots = () => {
		setAnchorDotsEl(null)
	}
	const handleDeleteCloset = () => {
		navigate('/food_and_drinks/product/add')
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Box display='flex' alignItems='center'>
					<Button
						color='info'
						variant='text'
						size='small'
						onClick={() => navigate(-1)}
						sx={{ border: 0, borderRadius: '50%', marginRight: '16px' }}
					>
						<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
					</Button>
					<Typography variant='title_2'>Еда и напитки</Typography>
				</Box>
				{profile?.user?.role.permissions.includes('update_product') && (
					<Button onClick={handleClick} variant='contained'>
						Добавить
					</Button>
				)}
				<Menu
					id='basic-menu'
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button'
					}}
				>
					<MenuItem onClick={handleDeleteCloset}>Новый товар</MenuItem>
				</Menu>
			</Box>
			<Divider />
			<Box p={2} display='flex' justifyContent='end'>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<Box sx={{ padding: '48px 96px' }}>
				<Grid container spacing={2}>
					{products?.data.map((product: any) => (
						<Grid item xs={3}>
							<Card
								sx={{
									maxWidth: 280,
									background: '#F7F7F7',
									borderRadius: '12px',
									position: 'relative'
								}}
							>
								<CardMedia
									sx={{
										height: 160,
										width: 280,
										border: '1px solid #F7F7F7',
										borderRadius: '12px'
									}}
									image={product.img_path}
									title={product.name}
								/>
								<Box
									position='absolute'
									right={8}
									top={8}
									display='flex'
									justifyContent='center'
									alignContent='center'
									sx={{
										width: 32,
										height: 32,
										border: '1px solid #D6D6D6',
										background: 'white',
										borderRadius: '12px'
									}}
								>
									<Button
										id={product.id}
										aria-controls={openDots ? `basic-menu-${product.id}` : undefined}
										aria-haspopup='true'
										aria-expanded={openDots ? 'true' : undefined}
										onClick={handleClickDots}
									>
										<Dots />
									</Button>
								</Box>
								<Menu
									id={`basic-menu-${product.id}`}
									anchorEl={anchorDotsEl}
									open={openDots}
									onClose={handleCloseDots}
									MenuListProps={{
										'aria-labelledby': product.id
									}}
								>
									<MenuItem key={`menu-item-${product.id}`} onClick={handleViewFood}>
										<View
											style={{
												color: '#349EFF',
												marginRight: '8px'
											}}
										/>
										Просмотр товара
									</MenuItem>
									{profile?.user?.role.permissions.includes('update_product') && (
										<MenuItem key={`menu-item-${product.id}`} onClick={handleEditFood}>
											<Edit
												style={{
													marginRight: '8px'
												}}
											/>
											Редактировать
										</MenuItem>
									)}
									<MenuItem onClick={handleHistoryStuff}>
										<AccessTime
											style={{
												fill: '#349EFF',
												marginRight: '8px'
											}}
										/>
										История изменения
									</MenuItem>
									{profile?.user?.role.permissions.includes('update_product') && (
										<MenuItem onClick={handleDeleteOpenModal}>
											<Cancel
												style={{
													marginRight: '8px'
												}}
											/>
											Удалить
										</MenuItem>
									)}
								</Menu>
								<CardContent>
									<Typography gutterBottom variant='caption_2' component='div'>
										Рейтинг: <span style={{ color: '#24D321' }}>{product.rating}</span>
									</Typography>
									<Typography
										gutterBottom
										variant='footnote_2'
										component='div'
										color='text.secondary'
									>
										{product.name}
									</Typography>
									<Typography variant='body_2'>{product.price} ₸</Typography>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			</Box>
			{products?.last_page && (
				<Box display='flex' justifyContent='center'>
					<Pagination
						sx={{ marginTop: '16px' }}
						count={products.last_page}
						page={page}
						onChange={handleChange}
					/>
				</Box>
			)}
			<Dialog
				open={openDeleteModal}
				onClose={handleDeleteCloseModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={handleDeleteCloseModal}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button autoFocus variant='text' color='error' onClick={handleDeleteFood}>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
