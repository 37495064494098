import './style.css'

import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AddPhotoAlternate } from 'core/icons'
import React, { useRef } from 'react'

const imageFileExtensions = ['image/jpeg', 'image/png', '.jpg', '.jpeg', '.png']

const ImgBox = styled('div')(() => ({
	width: '264px',
	height: '264px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	background: '#F7F7F7',
	border: '1px solid #D6D6D6',
	borderRadius: '12px'
}))

const ImgButtonBox = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	position: 'relative'
}))

function EditAvatar(props: any) {
	const { src, onUpload, disabled } = props

	const fileInputRef: any = useRef(null)

	const onEditClick = () => {
		fileInputRef.current.click()
	}

	const onChangeFile = (event: any) => {
		const file = event.target.files[0]
		if (file) {
			onUpload(file)
		}
	}

	return (
		<div style={{ width: '264px', height: '264px' }}>
			{src ? (
				<Box display='flex' flexDirection='column' alignItems='center'>
					<img src={src} alt='avatar' className='edit-avatar__img' />

					{!disabled && (
						<Button onClick={onEditClick} variant='text' size='small'>
							Изменить фото
						</Button>
					)}
				</Box>
			) : (
				<ImgBox>
					<ImgButtonBox
						onClick={!disabled ? onEditClick : undefined}
						onKeyDown={!disabled ? onEditClick : undefined}
						role='button'
						tabIndex={0}
					>
						<AddPhotoAlternate />
						{!disabled && (
							<Button variant='text' size='small'>
								Добавить фото
							</Button>
						)}
					</ImgButtonBox>
				</ImgBox>
			)}

			<input
				type='file'
				className='edit-avatar__input'
				ref={fileInputRef}
				accept={imageFileExtensions.join(', ')}
				onChange={disabled ? undefined : onChangeFile}
			/>
		</div>
	)
}

export default EditAvatar
