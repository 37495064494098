import { Box, Button, Divider, Menu, MenuItem, Tab, Tabs, Typography } from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import { TicketsComponent } from 'pages/Tickets/tickets.component'
import { TicketsCategoryTableComponent } from 'pages/Tickets/tickets-category-table.component'
import { TicketsTariffTableComponent } from 'pages/Tickets/tickets-tariff-table.component'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { TicketsTypeTableComponent } from './tickets-type-table.component'

export const TicketsPage = () => {
	const { profile } = useApp()
	const navigate = useNavigate()
	const [search, setSearch] = useSearchParams({ tab: '1' })
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setSearch({ tab: newValue })
	}
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleAdd = (path: string) => () => {
		navigate(path)
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Билеты</Typography>
				{profile?.user?.role.permissions.includes('update_ticket') && (
					<Button onClick={handleClick} variant='contained'>
						Добавить
					</Button>
				)}
				<Menu
					id='basic-menu'
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button'
					}}
				>
					<MenuItem onClick={handleAdd('/tickets/add')}>Новый билет</MenuItem>
					<MenuItem onClick={handleAdd('/tickets/type/add')}>Тип билета</MenuItem>
					<MenuItem onClick={handleAdd('/tickets/tariff/add')}>Тариф</MenuItem>
					<MenuItem onClick={handleAdd('/tickets/category/add')}>Категория билета</MenuItem>
				</Menu>
			</Box>
			<Divider />
			<Box sx={{ width: '100%' }} mb={2} py={4} display='flex' justifyContent='center'>
				<Tabs value={search.get('tab')} onChange={handleChangeTab} aria-label='wrapped label tabs example'>
					<Tab value='1' label='Текущие билеты' wrapped />
					<Tab value='2' label='Категория билета' />
					<Tab value='3' label='Тарифы' />
					<Tab value='4' label='Тип билета' />
				</Tabs>
			</Box>
			<Divider />
			{search.get('tab') === '1' && <TicketsComponent />}
			{search.get('tab') === '2' && <TicketsCategoryTableComponent />}
			{search.get('tab') === '3' && <TicketsTariffTableComponent />}
			{search.get('tab') === '4' && <TicketsTypeTableComponent />}
		</div>
	)
}
