import { styled, Typography } from '@mui/material'
import { CheckLoginComponent } from 'pages/Authorization/check-login.component'
import { CheckRestoreComponent } from 'pages/Authorization/check-restore.component'
import { CreatePasswordComponent } from 'pages/Authorization/create-password.component'
import { LoginComponent } from 'pages/Authorization/login.component'
import { OtpComponent } from 'pages/Authorization/otp.component'
import { RestorePasswordComponent } from 'pages/Authorization/restore-password.component'
import React, { useState } from 'react'

const Root = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.primary.black,
	display: 'flex',
	height: '100vh',
	justifyContent: 'space-between',
	background: 'linear-gradient(90deg, #396AFC 0%, #2948FF 100%)'
}))

const ContentBlock = styled('div')(({ theme }) => ({
	padding: theme.spacing(1),
	borderRadius: '16px',
	marginLeft: '48px',
	display: 'flex',
	marginTop: '220px',
	width: '350px',
	alignItems: 'center',
	flexDirection: 'column'
}))
const FormBlock = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.primary.white,
	padding: '210px 124px 0',
	borderRadius: '16px',
	margin: '16px',
	display: 'flex',
	width: '662px',
	alignItems: 'center',
	flexDirection: 'column'
}))

const ImgBlock = styled('div')(() => ({
	position: 'absolute',
	left: 0,
	bottom: 0,
	img: {
		height: '490px'
	}
}))

export const AuthorizationPage = () => {
	const [phone, setPhone] = useState<any>()
	const [otp, setOtp] = useState<any>()
	const [step, setStep] = useState('login')
	const [path, setPath] = useState('login')

	return (
		<Root>
			<div className='d-flex'>
				<ContentBlock>
					<Typography
						variant='headline_1'
						mb={1}
						sx={{
							color: 'white',
							width: '100%'
						}}
					>
						Добро пожаловать!
					</Typography>
					<Typography
						variant='title_3'
						sx={{
							color: 'white'
						}}
					>
						Цифровая панель администрации для систематизации внутренних процессов
					</Typography>
				</ContentBlock>
				<ImgBlock>
					<img src='/assets/img/Frame_55.png' alt='frame' />
				</ImgBlock>
			</div>
			<FormBlock>
				{step === 'check' && <CheckLoginComponent setStep={setStep} setPhone={setPhone} />}
				{step === 'password' && <CreatePasswordComponent phone={phone} setStep={setStep} otp={otp} />}
				{step === 'otp' && <OtpComponent phone={phone} setStep={setStep} setOtp={setOtp} path={path} />}
				{step === 'restore' && (
					<CheckRestoreComponent setStep={setStep} setPhone={setPhone} setPath={setPath} />
				)}
				{step === 'update' && <RestorePasswordComponent phone={phone} setStep={setStep} otp={otp} />}
				{step === 'login' && <LoginComponent phone={phone} setPhone={setPhone} setStep={setStep} />}
			</FormBlock>
		</Root>
	)
}
