import { axiosBaseInstance } from 'core/axios.config'

export const customerWalletDeposit = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/wallet/deposit`, data)
	return v.data
}

export const refundCacheFromWallet = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/wallet/refund_in_cache`, data)
	return v.data
}

export const getWalletOperations = async (params?: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/wallet/operations/`, {
		params
	})
	return data
}

export const getBckOperations = async (params?: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/processing/transactions`, {
		params
	})
	return data
}

export const customerWalletOperationsId = async (operation_id?: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/wallet/operations/${operation_id}`)
	return data
}

export const getCustomerNumber = async (phone?: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/customers/find-by-phone/${phone}`)
	return data
}

export const closeSession = async (data: any) => {
	const v = await axiosBaseInstance.put(`back_office/webkassa/close_session`, data)
	return v.data
}
