import {
	Box,
	Divider,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { dateFormatter } from 'core/helpers/date-formatter'
import { parseLockers } from 'pages/Lockers/parse'
import React from 'react'
import { useQuery } from 'react-query'
import { getTowelTenants } from 'services/towelManagement/towelManage.service'

export const TowelsTenantListComponent = () => {
	const [page, setPage] = React.useState(1)
	const [search, setSearch] = React.useState('')
	const { data: tenants } = useQuery(['getTowelTenants'], {
		queryFn: () => getTowelTenants()
	})

	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
		setPage(1)
	}

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}

	return (
		<div>
			<Box p={2} display='flex' justifyContent='end'>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell align='left'>Имя и фамилия</TableCell>
							<TableCell align='left'>Количество</TableCell>
							<TableCell align='left'>Дата и время аренды</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tenants?.data &&
							parseLockers(tenants.data).map((row: any) => (
								<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component='th' scope='row'>
										{row.id}
									</TableCell>
									<TableCell align='left'>
										{row.customer.first_name} {row.customer.last_name}
									</TableCell>
									<TableCell align='left'>{row.quantity}х</TableCell>
									<TableCell align='left'>{dateFormatter(new Date(row.receive_time))}</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{tenants?.last_page && (
				<Box display='flex' justifyContent='center'>
					<Pagination
						sx={{ marginTop: '16px' }}
						count={tenants.last_page}
						page={page}
						onChange={handleChange}
					/>
				</Box>
			)}
		</div>
	)
}
