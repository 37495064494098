import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Menu,
	MenuItem,
	Pagination,
	Paper,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	TextField,
	Typography
} from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { useApp } from 'core/contexts/profile.context'
import { dateFormatter } from 'core/helpers/date-formatter'
import { Cancel, Dots, Edit, Settings, View } from 'core/icons'
import moment from 'moment'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDebounce } from 'usehooks-ts'

import { deleteCustomerGuest, getCustomerGuests } from '../../services/guests/guests.service'
import { parseLockers } from '../Lockers/parse'

export const ClientsPage = () => {
	const { profile } = useApp()
	const navigate = useNavigate()
	const [page, setPage] = React.useState(1)
	const [search, setSearch] = React.useState('')
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
	const [tab, setTab] = React.useState('all')
	const [currentId, setCurrentId] = useState<any>()
	const [reasonText, setReasonText] = React.useState('')

	const debouncedSearch = useDebounce(search, 500)

	const { data: clients, refetch } = useQuery(['getCustomerGuests', tab, page, debouncedSearch], {
		queryFn: () =>
			getCustomerGuests({
				page,
				gender: tab === 'all' || tab === 'child' ? null : tab,
				max_age: tab === 'child' ? '13' : null,
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})
	const deleteClientMutate = useMutation({
		mutationFn: ({ customer_id, reason }: any) => deleteCustomerGuest({ customer_id, reason }),
		onSuccess: () => {
			toast.success('Successfully deleted')
			setOpenDeleteModal(false)
			setAnchorEl(null)
			setReasonText('')
			refetch()
		}
	})

	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setTab(newValue)
		setPage(1)
	}

	const onChangeReason = (e: any) => {
		setReasonText(e.target.value)
	}
	const handleDeleteOpenModal = () => setOpenDeleteModal(true)
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentId(event.currentTarget.id)
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleEditClient = () => {
		navigate(`/clients/${currentId}?edit`)
	}
	const handleViewClient = () => {
		navigate(`/clients/${currentId}?view`)
	}
	const handleClientDelete = () => {
		if (reasonText) {
			deleteClientMutate.mutate({ customer_id: currentId, reason: reasonText })
		} else {
			toast.error('Нужна причина!')
		}
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
		setPage(1)
		setTab('all')
	}

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}

	const onUserAdd = () => {
		navigate(`/clients/-1?edit`)
	}

	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Клиенты</Typography>
				<Button onClick={onUserAdd} variant='text' size='small'>
					Добавить
				</Button>
			</Box>
			<Divider />
			<Box sx={{ width: '100%' }} mb={2} py={4} display='flex' justifyContent='center'>
				<Tabs value={tab} onChange={handleChangeTab} aria-label='wrapped label tabs example'>
					<Tab value='all' label='Все' wrapped />
					<Tab value='male' label='Мужчины' />
					<Tab value='female' label='Женщины' />
					<Tab value='child' label='Дети' />
				</Tabs>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='end' p={1}>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell align='left'>Имя</TableCell>
							<TableCell align='left'>Фамилия</TableCell>
							<TableCell align='left'>Дата рождения</TableCell>
							<TableCell align='left'>Возраст</TableCell>
							<TableCell align='left'>Последнее посещение</TableCell>
							<TableCell align='left'>Пол</TableCell>
							<TableCell align='right'>
								<Settings />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{clients?.data &&
							parseLockers(clients.data).map((row: any) => (
								<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component='th' scope='row'>
										{row.id}
									</TableCell>
									<TableCell align='left'>{row.first_name}</TableCell>
									<TableCell align='left'>{row.last_name}</TableCell>
									<TableCell align='left'>{moment(row.birth_date).format('DD.MM.YYYY')}</TableCell>
									<TableCell align='left'>{row.age}</TableCell>
									<TableCell align='left'>{dateFormatter(new Date(row.updated_at))}</TableCell>
									<TableCell align='left'>
										{row.gender === 'female' ? 'Женский' : 'Мужской'}
									</TableCell>
									<TableCell align='right'>
										<Button
											id={row.id}
											aria-controls={open ? 'basic-menu' : undefined}
											aria-haspopup='true'
											aria-expanded={open ? 'true' : undefined}
											onClick={handleClick}
										>
											<Dots />
										</Button>
										<Menu
											id={`basic-menu-${row.id}`}
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button'
											}}
										>
											<MenuItem onClick={handleViewClient} key={`menu-item-${row.id}`}>
												<View
													style={{
														color: '#349EFF',
														marginRight: '8px'
													}}
												/>
												Просмотр карточки клиента
											</MenuItem>
											{profile?.user?.role.permissions.includes('update_customer') && (
												<MenuItem onClick={handleEditClient} key={`menu-item-${row.id}`}>
													<Edit
														style={{
															marginRight: '8px'
														}}
													/>
													Редактировать
												</MenuItem>
											)}
											{profile?.user?.role.permissions.includes('update_customer') && (
												<MenuItem onClick={handleDeleteOpenModal}>
													<Cancel
														style={{
															marginRight: '8px'
														}}
													/>
													Удалить
												</MenuItem>
											)}
										</Menu>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{clients?.last_page && (
				<Box display='flex' justifyContent='center'>
					<Pagination
						sx={{ marginTop: '16px' }}
						count={clients.last_page}
						page={page}
						onChange={handleChange}
					/>
				</Box>
			)}
			<Dialog
				open={openDeleteModal}
				onClose={handleDeleteCloseModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
					<Box mt={2}>
						<TextField
							label='Причина удаления'
							placeholder=''
							fullWidth
							multiline
							rows={3}
							type='text'
							value={reasonText}
							onChange={onChangeReason}
							name='reason'
						/>
					</Box>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={handleDeleteCloseModal}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleClientDelete} autoFocus disabled={!reasonText} variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
