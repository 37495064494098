import { Box, Typography } from '@mui/material'
import React from 'react'

export const ManageClosetsHistoryCardComponent = ({ date, time, number, owner }: any) => {
	return (
		<Box
			display='flex'
			flexDirection='column'
			mt={2}
			py={1.75}
			gap={0.25}
			sx={{ borderBottom: '1px solid #D7D7D7', cursor: 'pointer' }}
		>
			<Box display='flex' flexDirection='column' gap={0.25}>
				<Box display='flex'>
					<Typography variant='caption_1' mr={0.5} sx={{ color: '#24D321' }}>
						Открытие охраником
					</Typography>
					<Typography variant='caption_1_regular' color='primary.gray'>
						- {date} {time}
					</Typography>
				</Box>
				<Typography variant='caption_1'>Шкаф №: {number}</Typography>
			</Box>
			<Box display='flex' gap={0.5}>
				<Typography variant='footnote_2' mr={0.5} sx={{ color: 'primary.gray' }}>
					Владелец:
				</Typography>
				<Typography variant='footnote_2'>{owner}</Typography>
			</Box>
		</Box>
	)
}
