import { createTheme } from '@mui/material/styles'
// @ts-ignore
import GilroyTtf from 'assets/fonts/Gilroy-Light.ttf'

declare module '@mui/material/Button' {
	interface ButtonPropsSizeOverrides {
		extraSmall: true
	}
}
declare module '@mui/material/IconButton' {
	interface IconButtonPropsSizeOverrides {
		extraSmall: true
	}
}

declare module '@mui/material/styles' {
	interface Palette {
		link: Palette['primary']
		white: string
		black: string
	}

	interface PaletteOptions {
		link?: PaletteOptions['primary']
		white?: string
		black?: string
	}

	interface PaletteColor {
		brand?: string
		white?: string
		black?: string
		gray?: string
		red?: string
		neutral?: string
		success?: string
		orange?: string
		pink?: string
	}

	interface SimplePaletteColorOptions {
		brand?: string
		white?: string
		black?: string
		neutral?: string
		gray?: string
		red?: string
		success?: string
		orange?: string
		pink?: string
	}
}

declare module '@mui/material/styles/createPalette' {
	interface PaletteColor {
		50?: React.CSSProperties['color']
		100?: React.CSSProperties['color']
		200?: React.CSSProperties['color']
		300?: React.CSSProperties['color']
		400?: React.CSSProperties['color']
		500?: React.CSSProperties['color']
		600?: React.CSSProperties['color']
		700?: React.CSSProperties['color']
		800?: React.CSSProperties['color']
		brand?: React.CSSProperties['color']
		white?: React.CSSProperties['color']
		black?: React.CSSProperties['color']
		gray?: React.CSSProperties['color']
		red?: React.CSSProperties['color']
		success?: React.CSSProperties['color']
		orange?: React.CSSProperties['color']
		pink?: React.CSSProperties['color']
	}
}

declare module '@mui/material/styles' {
	interface TypographyVariants {
		headline_1: React.CSSProperties
		headline_2: React.CSSProperties
		headline_3: React.CSSProperties
		title_1: React.CSSProperties
		title_2: React.CSSProperties
		title_3: React.CSSProperties
		body_1: React.CSSProperties
		body_2: React.CSSProperties
		footnote_1: React.CSSProperties
		footnote_2: React.CSSProperties
		caption_1: React.CSSProperties
		caption_1_regular: React.CSSProperties
		caption_2: React.CSSProperties
		button_1: React.CSSProperties
		button_2: React.CSSProperties
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		headline_1: React.CSSProperties
		headline_2: React.CSSProperties
		headline_3: React.CSSProperties
		title_1: React.CSSProperties
		title_2: React.CSSProperties
		title_3: React.CSSProperties
		body_1: React.CSSProperties
		body_2: React.CSSProperties
		footnote_1: React.CSSProperties
		footnote_2: React.CSSProperties
		caption_1: React.CSSProperties
		caption_1_regular: React.CSSProperties
		caption_2: React.CSSProperties
		button_1: React.CSSProperties
		button_2: React.CSSProperties
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		headline_1: true
		headline_2: true
		headline_3: true
		title_1: true
		title_2: true
		title_3: true
		body_1: true
		body_2: true
		footnote_1: true
		footnote_2: true
		caption_1: true
		caption_1_regular: true
		caption_2: true
		button_1: true
		button_2: true
		h4: false
		h5: false
		h6: false
	}
}

declare module '@mui/material/styles/createTypography' {
	interface FontStyle {
		fontWeightSemiBold: React.CSSProperties['fontWeight']
	}
}

const fontFamilyInter = [
	'Girloy',
	'-apple-system',
	'BlinkMacSystemFont',
	'"Segoe UI"',
	'Roboto',
	'"Helvetica Neue"',
	'Arial',
	'sans-serif',
	'"Apple Color Emoji"',
	'"Segoe UI Emoji"',
	'"Segoe UI Symbol"'
].join(',')

const theme = createTheme({
	palette: {
		background: {
			default: '#F7F7F7'
		},
		primary: {
			main: '#349EFF',
			brand: '#349EFF',
			white: '#FFFFFF',
			black: '#181818',
			gray: '#7D7D7D',
			red: '#ED4141',
			success: '#24D321',
			orange: '#FF9A3D',
			pink: '#FC6BFF'
		},
		secondary: {
			main: '#BFE0FF',
			brand: '#BFE0FF',
			neutral: '#F6F6F6',
			black: '#7C7C7C',
			gray: '#D6D6D6',
			red: '#FFAFAF',
			success: '#24D321',
			orange: '#F4BC87',
			pink: '#FEDBFF'
		},
		success: {
			main: '#24D321'
		},
		error: {
			main: '#ED4141'
		},
		white: '#fff',
		black: '#121212'
	},
	spacing: 8,
	components: {
		MuiCssBaseline: {
			styleOverrides: `
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Girloy'), local('Girloy-Regular'), url(${GilroyTtf}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
		}
	},
	typography: {
		fontFamily: fontFamilyInter,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightSemiBold: 600,
		fontWeightBold: 700,
		headline_1: {
			fontWeight: 700,
			fontSize: '32px',
			lineHeight: '40px'
		},
		headline_2: {
			fontWeight: 600,
			fontSize: '28px',
			lineHeight: '36px'
		},
		headline_3: {
			fontWeight: 600,
			fontSize: '24px',
			lineHeight: '32px',
			letterSpacing: '0.4px'
		},
		title_1: {
			fontWeight: 700,
			fontSize: '22px',
			lineHeight: '28px',
			letterSpacing: '1.2px'
		},
		title_2: {
			fontWeight: 700,
			fontSize: '18px',
			lineHeight: '24px',
			letterSpacing: '1.2px'
		},
		title_3: {
			fontWeight: 700,
			fontSize: '16px',
			lineHeight: '20px',
			letterSpacing: '0.6px'
		},
		body_1: {
			fontWeight: 600,
			fontSize: '16px',
			lineHeight: '24px',
			letterSpacing: '0.4px'
		},
		body_2: {
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '24px'
		},
		footnote_1: {
			fontWeight: 600,
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.4px'
		},
		footnote_2: {
			fontWeight: 500,
			fontSize: '14px',
			lineHeight: '20px'
		},
		caption_1: {
			fontWeight: 600,
			fontSize: '12px',
			lineHeight: '16px',
			letterSpacing: '0.4px'
		},
		caption_1_regular: {
			fontWeight: 500,
			fontSize: '12px',
			lineHeight: '16px',
			letterSpacing: '0.4px'
		},
		caption_2: {
			fontWeight: 500,
			fontSize: '10px',
			lineHeight: '16px'
		},
		button_1: {
			fontWeight: 700,
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '1.25px',
			textTransform: 'uppercase'
		},
		button_2: {
			fontWeight: 700,
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '1px'
		}
	}
})
theme.components = {
	MuiCssBaseline: {
		styleOverrides: ({ palette }) => ({
			body: {
				color: palette.black
			},
			svg: {
				fill: 'currentColor'
			}
		})
	},
	MuiTypography: {
		defaultProps: {
			variantMapping: {
				headline_1: 'h1',
				headline_2: 'h2',
				headline_3: 'h3'
			}
		}
	},
	MuiButton: {
		defaultProps: {
			disableRipple: true
		},
		styleOverrides: {
			root: {
				textTransform: 'none',
				borderRadius: '12px',
				color: 'purple',
				boxShadow: 'none',
				'&:hover': {
					boxShadow: 'none'
				},
				'&.Mui-disabled': {
					backgroundColor: theme.palette.secondary[500],
					border: 'none',
					color: theme.palette.secondary.gray
				}
			},
			text: {
				color: theme.palette.primary.brand,
				padding: '0px !important',
				'&:hover': {
					background: 'none'
				}
			},
			sizeMedium: {
				padding: theme.spacing(1.25, 2),
				...theme.typography.button_1
			},
			sizeSmall: {
				height: '32px',
				padding: theme.spacing(0.75, 1.5),
				...theme.typography.button_2
			},
			containedPrimary: {
				color: theme.palette.white,
				'&:hover': {
					background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.primary.main}`
				}
			},
			containedSecondary: {
				backgroundColor: theme.palette.white,
				border: `1px solid ${theme.palette.secondary[500]}`,
				color: theme.palette.primary.white,
				'&:hover': {
					backgroundColor: theme.palette.secondary[800]
				},
				'&:active': {
					backgroundColor: theme.palette.secondary[800]
				},
				'& svg path': {
					fill: theme.palette.secondary[400]
				},
				'&.Mui-disabled svg path': {
					fill: theme.palette.white
				}
			},
			containedSuccess: {
				color: theme.palette.primary.white
			},
			containedError: {
				'&:hover': {
					background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.error.main}`
				}
			},
			outlinedError: {
				color: theme.palette.error.main,
				border: '1px solid #D6D6D6',
				height: 40
			},
			textInfo: {
				fontStyle: 'normal',
				fontWeight: 500,
				fontSize: '14px',
				lineHeight: '20px',
				color: '#000000'
			},
			textSuccess: {
				fontStyle: 'normal',
				fontWeight: 500,
				fontSize: '14px',
				lineHeight: '20px',
				color: '#24D321'
			},
			textError: {
				fontStyle: 'normal',
				fontWeight: 500,
				fontSize: '14px',
				lineHeight: '20px',
				color: '#ED4141'
			},
			textSecondary: {
				'&:hover': {
					background: 'none'
				},
				'&:active': {
					backgroundColor: 'red'
				},
				'& svg path': {
					fill: 'green'
				},
				'&.Mui-disabled': {
					backgroundColor: theme.palette.white,
					border: 'none',
					color: 'inherit'
				}
			},
			iconSizeMedium: {
				width: '20px',
				height: '20px',
				svg: {
					width: '20px',
					height: '20px'
				}
			},
			iconSizeSmall: {
				width: '20px',
				height: '20px',
				svg: {
					width: '20px',
					height: '20px'
				}
			},
			startIcon: {}
		},
		variants: [
			{
				props: { size: 'extraSmall' },
				style: {
					fontSize: '14px',
					height: '24px',
					padding: theme.spacing(0.5, 1),
					'.MuiButton-iconSizeExtraSmall': {
						width: '16px',
						height: '16px',
						svg: {
							width: '16px',
							height: '16px'
						}
					},
					'.MuiButton-startIcon': {
						marginLeft: 0,
						marginRight: theme.spacing(0.5)
					},
					'.MuiButton-endIcon': {
						marginLeft: theme.spacing(0.5),
						marginRight: 0
					}
				}
			}
		]
	},
	MuiPaper: {
		styleOverrides: {
			root: {
				border: '1px solid rgba(214, 214, 214, 0.48)',
				boxShadow: 'none',
				borderRadius: '12px'
			}
		}
	},
	MuiIconButton: {
		defaultProps: {
			disableRipple: true
		},
		styleOverrides: {
			root: {
				borderRadius: '4px',
				'&.Mui-disabled': {
					backgroundColor: theme.palette.secondary[500],
					border: 'none',
					color: theme.palette.white
				}
			},
			colorPrimary: {
				backgroundColor: theme.palette.primary.main,
				'&:hover': {
					background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.primary.main}`
				}
			},
			colorSecondary: {
				backgroundColor: theme.palette.white,
				border: `1px solid ${theme.palette.secondary[500]}`,
				color: theme.palette.black,
				'&:hover': {
					backgroundColor: theme.palette.secondary[800]
				},
				'&:active': {
					backgroundColor: theme.palette.secondary[800]
				},
				'& svg path': {
					fill: theme.palette.secondary[400]
				},
				'&.Mui-disabled svg path': {
					fill: theme.palette.white
				}
			},
			sizeMedium: {
				width: '40px',
				height: '40px',
				padding: theme.spacing(1),

				svg: {
					width: '24px',
					height: '24px'
				}
			},
			sizeSmall: {
				width: '32px',
				height: '32px',
				padding: theme.spacing(0.5),

				svg: {
					width: '24px',
					height: '24px'
				}
			}
		},
		variants: [
			{
				props: { size: 'extraSmall' },
				style: {
					width: '24px',
					height: '24px',
					padding: theme.spacing(0.5),

					svg: {
						width: '16px',
						height: '16px'
					}
				}
			}
		]
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				'svg path': {
					fill: '#6D7C8B'
				},
				'&.Mui-disabled': {
					backgroundColor: theme.palette.secondary[800]
				},
				margin: '8px 0',
				borderRadius: '12px!important'
			},
			input: {
				display: 'flex',
				alignItems: 'center',
				fontSize: '16px',
				lineHeight: '20px',
				minHeight: '50px!important',
				boxSizing: 'border-box',
				padding: 0,
				'&::placeholder': {
					color: theme.palette.secondary[400],
					opacity: 1
				}
			},
			inputSizeSmall: {
				height: '32px'
			}
		}
	},
	MuiOutlinedInput: {
		styleOverrides: {
			root: {
				// backgroundColor: 'purple',
				'&:hover': {
					'&:not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.palette.primary.main
					}
				},
				// removes empty space
				'& legend': {
					width: 0
				}
			},
			input: {
				padding: theme.spacing(1.25, 1)
			},
			multiline: {
				padding: 0
			},
			notchedOutline: {
				borderColor: theme.palette.secondary[500],
				top: 0,
				'& legend': {
					display: 'none'
				}
			},
			adornedStart: {
				paddingLeft: theme.spacing(1)
			},
			adornedEnd: {
				paddingRight: theme.spacing(1)
			},
			inputSizeSmall: {
				padding: theme.spacing(0.75, 1)
			},
			error: {
				borderColor: theme.palette.error.main
			}
		}
	},
	MuiInputAdornment: {
		styleOverrides: {
			positionStart: {
				marginRight: 0
			},
			positionEnd: {
				marginLeft: 0
			}
		}
	},
	MuiInputLabel: {
		defaultProps: {
			// shrink: false,
		},
		styleOverrides: {
			root: {
				position: 'static',
				transform: 'none',
				color: theme.palette.secondary[300],
				fontSize: '12px',
				lineHeight: '16px',
				fontWeight: 500,
				marginBottom: theme.spacing(0.5)
			},
			asterisk: {
				color: theme.palette.error.main
			}
		}
	},
	MuiFormHelperText: {
		styleOverrides: {
			root: {
				marginLeft: 0,
				marginTop: theme.spacing(0.5),
				color: theme.palette.secondary[500]
			}
		}
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				padding: theme.spacing(1.25, 2),
				'&&&': {
					minHeight: '40px'
				}
			}
		}
	},
	MuiAutocomplete: {
		styleOverrides: {
			root: {
				'& .MuiInputBase-root': {
					padding: 0
				},
				'& .MuiIconButton-root': {
					width: 'auto',
					height: 'auto',

					'& svg': {
						width: '1em',
						height: '1em'
					}
				}
			},
			endAdornment: {}
		}
	},
	MuiCheckbox: {
		styleOverrides: {
			root: {
				color: theme.palette.secondary[300],
				'&.Mui-disabled': {
					color: theme.palette.secondary[500]
				}
			}
		}
	},
	MuiRadio: {
		styleOverrides: {
			root: {
				color: theme.palette.secondary[300],
				'&.Mui-disabled': {
					color: theme.palette.secondary[500]
				}
			}
		}
	},
	MuiSwitch: {
		styleOverrides: {
			root: {
				width: 44,
				height: 24,
				padding: 0,
				'&:hover .Mui-checked:not(.Mui-disabled) + .MuiSwitch-track': {
					background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.secondary.main}`
				},
				'&&': {
					margin: theme.spacing(1)
				}
			},
			switchBase: {
				padding: 0,
				margin: '4px 0px 4px 8px',
				transitionDuration: '300ms',
				'&.Mui-checked': {
					transform: 'translateX(14px)',
					color: theme.palette.white,
					'& + .MuiSwitch-track': {
						opacity: 1,
						border: 0
					},
					'&.Mui-disabled + .MuiSwitch-track': {
						opacity: 0.5
					}
				},
				'&.Mui-focusVisible .MuiSwitch-thumb': {
					color: theme.palette.primary.main,
					border: `6px solid ${theme.palette.white}`
				},
				'&.Mui-disabled .MuiSwitch-thumb': {
					color: theme.palette.white
				},
				'&.Mui-disabled + .MuiSwitch-track': {
					opacity: 0.5
				}
			},
			thumb: {
				boxSizing: 'border-box',
				width: 16,
				height: 16
			},
			track: {
				borderRadius: 25 / 2,
				backgroundColor: theme.palette.secondary.black,
				opacity: 1,
				transition: theme.transitions.create(['background-color'], {
					duration: 500
				})
			},
			sizeSmall: {
				height: 20,

				'& .MuiSwitch-switchBase': {
					padding: 0,
					'&.Mui-checked': {
						transform: 'translateX(19px)'
					}
				}
			}
		}
	},
	MuiTabs: {
		styleOverrides: {
			root: {
				borderBottom: `1px solid ${theme.palette.secondary[500]}`,
				minHeight: 'auto'
			}
		}
	},
	MuiTab: {
		styleOverrides: {
			root: {
				color: theme.palette.secondary[300],
				fontSize: '14px',
				lineHeight: '18px',
				padding: theme.spacing(1.25, 2),
				textTransform: 'initial',
				minHeight: 'auto',
				'&.Mui-selected': {
					color: 'inherit'
				}
			}
		}
	},
	MuiAvatar: {
		styleOverrides: {
			root: {
				backgroundColor: 'blue'
			}
		}
	},
	MuiChip: {
		styleOverrides: {
			root: {
				borderRadius: '16px',
				fontWeight: 500,
				fontStyle: 'normal',
				fontSize: '14px',
				lineHeight: '20px'
			},
			filled: {
				background: '#181818',
				color: '#FFFFFF'
			},
			outlined: {
				background: '#F7F7F7'
			},
			iconMedium: {
				width: 16,
				height: 16,
				color: 'currentColor'
			},
			iconSmall: {
				color: 'currentColor',
				width: 16,
				height: 16
			},
			deleteIcon: {
				color: 'inherit'
			},
			deleteIconMedium: {
				width: 16,
				height: 16
			},
			deleteIconSmall: {
				width: 16,
				height: 16
			},
			colorSuccess: {
				backgroundColor: theme.palette.success[800],
				color: theme.palette.success.main
			},
			colorInfo: {
				backgroundColor: theme.palette.secondary[800],
				color: theme.palette.secondary[300]
			},
			colorWarning: {
				backgroundColor: theme.palette.warning[800],
				color: theme.palette.warning.main
			},
			colorError: {
				backgroundColor: theme.palette.error[800],
				color: theme.palette.error.main
			}
		}
	},
	MuiTableCell: {
		styleOverrides: {
			head: {
				fontSize: '16px',
				fontWeight: 600,
				lineHeight: '24px'
			}
		}
	},
	MuiAlert: {
		styleOverrides: {
			root: {
				height: '64px',
				alignItems: 'center',
				borderRadius: '6px'
			},
			message: {
				fontSize: '16px',
				lineHeight: '20px',
				fontWeight: 400,
				display: 'flex',
				alignItems: 'center'
			},

			action: {
				padding: 0
			},
			standardError: {
				backgroundColor: theme.palette.error[800],
				border: `1px solid ${theme.palette.error.main}`
			},
			standardWarning: {
				backgroundColor: theme.palette.warning[800],
				border: `1px solid ${theme.palette.warning.main}`
			},
			standardInfo: {
				backgroundColor: theme.palette.info[800],
				border: `1px solid ${theme.palette.info.main}`
			},
			standardSuccess: {
				backgroundColor: theme.palette.success[800],
				border: `1px solid ${theme.palette.success.main}`
			}
		}
	},
	MuiAlertTitle: {
		styleOverrides: {
			root: {
				margin: 0,
				fontWeight: 600,
				fontSize: '16px',
				lineHeight: '20px',
				marginRight: theme.spacing(2)
			}
		}
	},
	MuiStep: {
		styleOverrides: {
			root: {
				marginBottom: theme.spacing(3)
			}
		}
	},
	MuiStepContent: {
		styleOverrides: {
			root: {
				border: 'none',
				marginLeft: '62px',
				marginTop: theme.spacing(1.25),
				paddingLeft: 0,
				paddingRight: 0
			}
		}
	},
	MuiStepLabel: {
		styleOverrides: {
			root: {
				padding: 0
			},
			label: {
				fontWeight: 500,
				fontSize: '16px',
				lineHeight: '20px'
			},
			labelContainer: {
				color: theme.palette.secondary[400],
				display: 'flex',
				flexDirection: 'column-reverse'
			},
			iconContainer: {
				paddingRight: 0,
				marginRight: theme.spacing(1.25),
				paddingLeft: theme.spacing(3),
				borderLeft: '8px solid transparent',
				height: '50px',
				alignItems: 'center',

				'&.Mui-active': {
					borderColor: theme.palette.primary.main
				}
			},
			active: {
				background: 'red'
			}
		}
	},
	MuiStepConnector: {
		styleOverrides: {
			root: {
				display: 'none'
			},
			line: {
				border: 'none'
			}
		}
	},
	MuiDialog: {
		styleOverrides: {
			paper: {
				maxWidth: '480px'
			}
		}
	},
	MuiDialogTitle: {
		styleOverrides: {
			root: {
				borderBottom: `1px solid ${theme.palette.secondary[800]}`,
				'&&': {
					padding: theme.spacing(2, 3)
				}
			}
		}
	},
	MuiDialogContent: {
		styleOverrides: {
			root: {
				'&&': {
					padding: theme.spacing(2, 3)
				}
			}
		}
	},
	MuiDialogActions: {
		styleOverrides: {
			root: {
				'&&': {
					padding: theme.spacing(2, 3)
				}
			}
		}
	}
}

// theme = responsiveFontSizes(theme);

export default theme
