export enum ManageClosestCategory {
	male = 'Мужской',
	female = 'Женский',
	kids = 'Дети'
}

export enum ManageClosestState {
	locked = 'Закрытый',
	open = 'Открытый'
}

export enum ManageClosestStatus {
	free = 'Свободный',
	busy = 'Занятый',
	reserved = 'Забронированный'
}
