import { axiosBaseInstance } from 'core/axios.config'

export const addNewSos = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/sos/`, data)
	return v.data
}

export const getSosHistory = async (params?: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/sos/history/`, {
		params
	})
	return data
}

export const cancelSos = async (sos_id: any) => {
	const v = await axiosBaseInstance.post(`/back_office/sos/${sos_id}/cancel`)
	return v.data
}
