import './index.css'

import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { ArrowBack } from 'core/icons'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import VerificationInput from 'react-verification-input'
import { backOfficeOtp, backOfficeOtpCheck } from 'services/authorization/authorization.service'

export const OtpComponent = ({ phone, path, setStep, setOtp: setOtpProps }: any) => {
	const [minutes, setMinutes] = useState(1)
	const [seconds, setSeconds] = useState(60)
	const [otp, setOtp] = useState('')
	const registrationMutation = useMutation({
		mutationFn: payload => backOfficeOtpCheck(payload),
		onSuccess: () => {
			if (path) {
				setStep('update')
			} else {
				setStep('password')
			}
		}
	})

	const otpMutation = useMutation({
		mutationFn: payload => backOfficeOtp(payload),
		onSuccess: () => {
			setMinutes(1)
			setSeconds(60)
		}
	})

	const onChangeOtp = (value: any) => {
		setOtpProps(value)
		setOtp(value)
	}

	const onSubmit = () => {
		setSeconds(60)
		setMinutes(1)

		const PhoneMask = phone.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')
		// @ts-ignore
		registrationMutation.mutate({
			otp,
			phone: PhoneMask
		})
	}

	const onResendSubmit = () => {
		const PhoneMask = phone?.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')
		// @ts-ignore
		otpMutation.mutate({
			phone: PhoneMask
		})
	}
	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1)
			}

			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(interval)
				} else {
					setSeconds(59)
					setMinutes(minutes - 1)
				}
			}
		}, 1000)

		return () => {
			clearInterval(interval)
		}
	}, [seconds])
	return (
		<Box width='100%'>
			<Button
				color='info'
				variant='text'
				size='small'
				onClick={() => setStep('login')}
				sx={{ border: 0, borderRadius: '50%' }}
			>
				<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
			</Button>
			<Typography
				variant='headline_1'
				mb={1}
				sx={{
					width: '100%'
				}}
			>
				Введите код
			</Typography>
			<Typography
				variant='footnote_1'
				mb={4}
				sx={{
					width: '100%'
				}}
			>
				На номер +{phone} придет код, введите его ниже
			</Typography>
			<Box my={2}>
				<VerificationInput
					length={4}
					validChars='0-9'
					onComplete={onChangeOtp}
					placeholder=''
					classNames={{
						container: 'otp-container',
						character: 'otp-character',
						characterInactive: 'otp-character--inactive',
						characterSelected: 'otp-character--selected'
					}}
					inputProps={{ inputMode: 'numeric' }}
				/>
			</Box>
			<Box mb={2}>
				<Button variant='text' size='small' onClick={onResendSubmit}>
					<Typography
						variant='button_1'
						color={minutes === 0 && seconds === 0 ? 'primary.main' : 'secondary.gray'}
					>
						Отправить код через {seconds ? `0${minutes}:${seconds < 10 ? `0${seconds}` : seconds}` : ''}
					</Typography>
				</Button>
			</Box>
			<Button
				onClick={onSubmit}
				disabled={registrationMutation.isLoading || otp?.length !== 4}
				variant='contained'
				type='submit'
				fullWidth
			>
				{registrationMutation.isLoading ? <CircularProgress /> : 'Продолжить'}
			</Button>
		</Box>
	)
}
