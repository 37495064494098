import { Box, Typography } from '@mui/material'
import * as React from 'react'

export const OrdersDetailCardComponent = ({ name, count, price }: any) => {
	return (
		<Box display='flex' alignItems='center' py={2} sx={{ borderBottom: '1px solid #D7D7D7' }}>
			<img
				style={{
					background: '#D9D9D9',
					width: 64,
					borderRadius: 8
				}}
				src='/assets/img/avatar.png'
				alt='avatar'
			/>
			<Box display='flex' flexDirection='column' flexGrow={1} ml={1.5} gap={0.25}>
				<Typography variant='caption_1' color='primary.gray'>
					{name}
				</Typography>
				<Typography variant='body_2'>{count}х</Typography>
			</Box>
			<Typography variant='title_3'>{price} ₸</Typography>
		</Box>
	)
}
