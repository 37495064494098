import './style.css'

import { Box, Button } from '@mui/material'
import { Cancel } from 'core/icons'
import React, { useRef } from 'react'

const imageFileExtensions = ['image/png', '.svg', '.png']

function UploadIcon(props: any) {
	const { onUpload, src, onDeleteIcon } = props

	const fileInputRef: any = useRef(null)

	const onEditClick = () => {
		fileInputRef.current.click()
	}

	const onChangeFile = (event: any) => {
		const file = event.target.files[0]
		if (file) {
			onUpload(file)
		}
	}

	return (
		<Box display='flex' flexDirection='column' alignItems='start' sx={{ width: '264px' }}>
			<Button variant='text' disabled={location.search.indexOf('view') > 0} size='small' onClick={onEditClick}>
				Загрузить иконку
			</Button>

			{src && (
				<div className='edit-icon__wrapper'>
					<Button
						sx={{
							position: 'absolute',
							top: -10,
							right: -10,
							minWidth: 24,
							background: 'white'
						}}
						disabled={location.search.indexOf('view') > 0}
						onClick={onDeleteIcon}
					>
						<Cancel />
					</Button>
					<img src={src} alt='icon' className='edit-icon__img' />
				</div>
			)}

			<input
				type='file'
				disabled={location.search.indexOf('view') > 0}
				className='edit-icon__input'
				ref={fileInputRef}
				accept={imageFileExtensions.join(', ')}
				onChange={onChangeFile}
			/>
		</Box>
	)
}

export default UploadIcon
