import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

export const OrdersDeleteComponent = ({ showModal, setShowModal, onDeleteOrder }: any) => {
	const handleCloseModal = () => {
		setShowModal(false)
	}
	const handleDeleteClick = () => {
		onDeleteOrder()
	}

	return (
		<Dialog
			open={showModal}
			onClose={handleCloseModal}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>
					Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
				</DialogContentText>
			</DialogContent>
			<DialogActions
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'end'
				}}
			>
				<Button
					variant='text'
					size='small'
					color='success'
					style={{ marginBottom: '8px' }}
					onClick={handleCloseModal}
				>
					Нет, оставить
				</Button>
				<Button autoFocus variant='text' size='small' color='error' onClick={handleDeleteClick}>
					Да, удалить
				</Button>
			</DialogActions>
		</Dialog>
	)
}
