import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from '@mui/material'
import EditAvatar from 'components/avatar/EditAvatar'
import { ArrowBack, PhoneIphone } from 'core/icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import {
	createCustomer,
	createCustomerGuests,
	deleteCustomerGuest,
	getCustomerGuestInfo,
	updateCustomer
} from 'services/guests/guests.service'
import { useBoolean } from 'usehooks-ts'

export const ClientsDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const modalOpen = useBoolean(false)
	const [image, setImage] = useState<any>()
	const [fileImage, setFileImage] = useState<any>()
	const [phone, setPhone] = useState<any>()
	const [reasonText, setReasonText] = React.useState('')
	const IS_EDIT = id !== '-1'
	const {
		register,
		handleSubmit,
		reset,
		control,
		setValue,
		formState: { errors }
	} = useForm<any>()

	const {
		fields: guests,
		append: onAppendGuests,
		remove: onRemoveGuests
	} = useFieldArray({
		control,
		name: 'guests'
	})

	const upDateClientMutation = useMutation({
		mutationFn: ({ guest_id, body }: any) => updateCustomer({ guest_id, body }),
		onSuccess: () => {
			reset()
			navigate('/clients')
			toast.success('Клиент успешно изменен!')
		}
	})

	const createClientMutation = useMutation({
		mutationFn: ({ body }: any) => createCustomer({ body }),
		onSuccess: data => {
			reset()
			navigate(`/clients/${data.data.id}?view`)
			toast.success('Клиент успешно создан!')
		}
	})

	const createClientGuestsMutation = useMutation({
		mutationFn: (body: any) => createCustomerGuests(id, body),
		onSuccess: () => {
			reset()
			navigate('/clients')
			toast.success('Ваши гости добавлены')
		}
	})

	const { data: client } = useQuery(['getCustomerGuestInfo', id, location], {
		queryFn: () => getCustomerGuestInfo(id),
		enabled: IS_EDIT && !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})

	const deleteClientMutate = useMutation({
		mutationFn: ({ customer_id, reason }: any) => deleteCustomerGuest({ customer_id, reason }),
		onSuccess: () => {
			toast.success('Клиент успешно удален!')
			setReasonText('')
			navigate('/clients')
		}
	})

	const onChangeReason = (e: any) => {
		setReasonText(e.target.value)
	}
	const handleClientDelete = () => {
		if (reasonText) {
			deleteClientMutate.mutate({ customer_id: id, reason: reasonText })
		} else {
			toast.error('Нужнa причина!')
		}
	}
	const onSubmit = (body: any) => {
		const fd: any = new FormData()
		const PhoneMask = phone?.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')
		if (fileImage) fd.append('avatar', fileImage)
		fd.append('first_name', body.first_name)
		fd.append('last_name', body.last_name)
		fd.append('birth_date', body.birth_date)
		fd.append('gender', body.gender)
		fd.append('phone', PhoneMask)
		if (IS_EDIT) {
			upDateClientMutation.mutate({ guest_id: id, body: fd })
		} else {
			createClientMutation.mutate({ body: fd })
		}
	}
	useEffect(() => {
		if (client) {
			setImage(client.data.avatar_url)
			setValue('avatar', client.data.fileImage)
			setValue('first_name', client.data.first_name)
			setValue('last_name', client.data.last_name)
			setValue('birth_date', moment(client?.data?.birth_date).format('YYYY-MM-DD'))
			setPhone(client.data.phone)
			setValue('gender', client.data.gender)
		}
	}, [setValue, client])

	const onAvatarUpload = (file: any) => {
		setFileImage(file)
		setImage(URL.createObjectURL(file))
	}

	const onChangePhone = (e: any) => {
		setPhone(e.target.value)
	}

	const addGuest = () => {
		onAppendGuests({
			first_name: '',
			last_name: '',
			birth_date: '',
			gender: '',
			guest_relation: ''
		})
	}
	const removeGuest = (guestId: any) => {
		onRemoveGuests(guestId)
	}
	const saveGuests = (data: any) => {
		const guestsData = data?.guests?.map((guest: any) => {
			return {
				...guest,
				phone: guest.phone?.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')
			}
		})
		createClientGuestsMutation.mutate(guestsData)
	}

	const handlePhoneChange = (event: any) => {
		const input = event.target
		const value = input.value.replace(/\D/g, '') // Remove non-numeric characters
		const maskedValue = value.replace(
			/(\d{1})?(\d{0,3})?(\d{0,3})?(\d{0,2})?(\d{0,2})?/,
			(match: any, p1: any, p2: any, p3: any, p4: any, p5: any) => {
				let newValue = ''
				if (p1) newValue += p1
				if (p2) newValue += ` (${p2})`
				if (p3) newValue += ` ${p3}`
				if (p4) newValue += `-${p4}`
				if (p5) newValue += `-${p5}`
				return newValue
			}
		)
		input.value = maskedValue
	}

	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && IS_EDIT && (
					<Button variant='outlined' color='error' onClick={modalOpen.setTrue}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={824}>
					<Typography variant='headline_1'>Данные клиента</Typography>
					<Box display='flex' mt={6}>
						<EditAvatar src={image} onUpload={onAvatarUpload} />
						<Box>
							<form
								onSubmit={handleSubmit(onSubmit)}
								noValidate
								style={{ width: '100%', marginLeft: '40px' }}
							>
								<Box mb={2}>
									<Typography variant='title_1'>Персональные данные</Typography>
									<TextField
										label='Имя'
										placeholder=''
										required
										fullWidth
										type='text'
										disabled={location.search.indexOf('view') > 0}
										error={!!errors.first_name}
										{...register('first_name')}
									/>
									<TextField
										label='Фамилия'
										placeholder=''
										required
										fullWidth
										type='text'
										disabled={location.search.indexOf('view') > 0}
										error={!!errors.last_name}
										{...register('last_name')}
									/>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField
												label='Дата рождения'
												placeholder=''
												required
												fullWidth
												type='date'
												disabled={location.search.indexOf('view') > 0}
												error={!!errors.birth_date}
												{...register('birth_date')}
											/>
										</Grid>
										<Grid item xs={6}>
											<Controller
												control={control}
												name='gender'
												defaultValue=''
												render={({ field: { ref, onBlur, name, ...field } }) => (
													<FormControl required fullWidth>
														<InputLabel id='demo-simple-select-label'>Пол</InputLabel>
														<Select
															disabled={location.search.indexOf('view') > 0}
															labelId='demo-simple-select-label'
															id='demo-simple-select'
															{...field}
														>
															<MenuItem value='male'>Мужской</MenuItem>
															<MenuItem value='female'>Женский</MenuItem>
														</Select>
													</FormControl>
												)}
											/>
										</Grid>
									</Grid>
									<Typography variant='title_1'>Контакты</Typography>
									<InputMask
										mask='9 (999) 999-99-99'
										maskChar=''
										maskPlaceholder=''
										onChange={onChangePhone}
										value={phone}
										alwaysShowMask={false}
										disabled={location.search.indexOf('view') > 0}
									>
										<TextField
											fullWidth
											label='Введите номер'
											type='text'
											disabled={location.search.indexOf('view') > 0}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<PhoneIphone />
													</InputAdornment>
												)
											}}
										/>
									</InputMask>
								</Box>
								{location.search.indexOf('edit') > 0 && (
									<Button variant='contained' type='submit' color='success' fullWidth>
										{upDateClientMutation.isLoading || createClientMutation.isLoading ? (
											<CircularProgress size={24} />
										) : (
											'Сохранить'
										)}
									</Button>
								)}
							</form>
							<form
								onSubmit={handleSubmit(saveGuests)}
								noValidate
								style={{ width: '100%', marginLeft: '40px' }}
							>
								{guests.map((guestField, index) => (
									<Box mb={2} mt={6} key={guestField.id}>
										<Box display='flex' justifyContent='space-between'>
											<Typography variant='title_1'>Персональные данные гостя</Typography>
											<Button
												color='error'
												variant='outlined'
												size='small'
												onClick={(): void => removeGuest(index)}
											>
												Удалить
											</Button>
										</Box>
										<TextField
											label='Имя'
											placeholder=''
											required
											fullWidth
											type='text'
											error={!!errors.first_name}
											{...register(`guests.${index}.first_name`)}
										/>
										<TextField
											label='Фамилия'
											placeholder=''
											required
											fullWidth
											type='text'
											error={!!errors.last_name}
											{...register(`guests.${index}.last_name`)}
										/>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<TextField
													label='Дата рождения'
													placeholder=''
													required
													fullWidth
													type='date'
													error={!!errors.birth_date}
													{...register(`guests.${index}.birth_date`)}
												/>
											</Grid>
											<Grid item xs={6}>
												<Controller
													control={control}
													name={`guests.${index}.gender`}
													defaultValue=''
													render={({ field: { ref, onBlur, name, ...fields } }) => (
														<FormControl required fullWidth>
															<InputLabel id='simple-select-label'>Пол</InputLabel>
															<Select
																labelId='simple-select-label'
																id='simple-select'
																{...fields}
															>
																<MenuItem value='male'>Мужской</MenuItem>
																<MenuItem value='female'>Женский</MenuItem>
															</Select>
														</FormControl>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Controller
													control={control}
													name={`guests.${index}.guest_relation`}
													defaultValue=''
													render={({ field: { ref, onBlur, name, ...fields } }) => (
														<FormControl required fullWidth>
															<InputLabel id='simple-select-label'>Статус</InputLabel>
															<Select
																labelId='simple-select-label'
																id='simple-select'
																{...fields}
															>
																<MenuItem value='husband'>Муж</MenuItem>
																<MenuItem value='wife'>Жена</MenuItem>
																<MenuItem value='child'>Ребенок</MenuItem>
																<MenuItem value='parent'>Родитель</MenuItem>
																<MenuItem value='other'>Другое</MenuItem>
															</Select>
														</FormControl>
													)}
												/>
											</Grid>
										</Grid>
										<Typography variant='title_1'>Контакты</Typography>
										<Controller
											control={control}
											name={`guests.${index}.phone`}
											render={({ field }) => (
												<TextField
													onBeforeInput={handlePhoneChange}
													fullWidth
													label='Введите номер'
													type='text'
													InputProps={{
														...field,
														startAdornment: (
															<InputAdornment position='start'>
																<PhoneIphone />
															</InputAdornment>
														)
													}}
													// eslint-disable-next-line react/jsx-no-duplicate-props
													inputProps={{ maxLength: 17 }}
												/>
											)}
										/>
									</Box>
								))}
								{location.search.indexOf('view') > 0 && (
									<Button
										variant='outlined'
										color='primary'
										sx={{ mb: 2, color: '#349EFF' }}
										fullWidth
										onClick={addGuest}
									>
										Добавить гостя
									</Button>
								)}
								{location.search.indexOf('view') > 0 && guests.length > 0 && (
									<Button type='submit' variant='contained' color='success' sx={{ mb: 2 }} fullWidth>
										Сохранить
									</Button>
								)}
							</form>
						</Box>
					</Box>
				</Box>
			</Box>
			<Dialog
				open={modalOpen.value}
				onClose={modalOpen.setFalse}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
					<Box mt={2}>
						<TextField
							label='Причина удаления'
							placeholder=''
							fullWidth
							multiline
							rows={3}
							type='text'
							value={reasonText}
							onChange={onChangeReason}
							name='reason'
						/>
					</Box>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={modalOpen.setFalse}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleClientDelete} autoFocus disabled={!reasonText} variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
