import { axiosBaseInstance } from 'core/axios.config'

export const getClosetsList = async (params: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/closets/`, {
		params
	})
	return data
}
export const getClosetsStateChangeList = async (params: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/closets/state-change-logs/open`, {
		params
	})
	return data
}

export const createClosets = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/closets/`, data)
	return v.data
}

export const deleteClosets = async ({ closet_id, reason }: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/closets/${closet_id}/`, {
		data: {
			reason
		}
	})
	return v.data
}

export const getCustomerActiveClosets = async (closet_id: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/closets/${closet_id}/`)
	return data
}

export const updateClosets = async ({ closet_id, body }: any) => {
	const { data } = await axiosBaseInstance.put(`back_office/closets/${closet_id}/`, body)
	return data
}
// closets management API's
export const openCustomerActiveClosets = async (closetId: string) => {
	const { data } = await axiosBaseInstance.post(`back_office/closets/${closetId}/open/`, {
		closetId
	})
	return data
}
export const openAllClosets = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/closets/open`, data)
	return v.data
}
export const getClosetsOpenLogs = async () => {
	const { data } = await axiosBaseInstance.get(`/back_office/closets/state-change-logs/open`)
	return data
}

export const addClosetsOwner = async ({ closet_id, body }: any) => {
	const { data } = await axiosBaseInstance.post(`back_office/closets/${closet_id}/ownership`, body)
	return data
}

export const deleteClosetsOwner = async (closet_id: any) => {
	const { data } = await axiosBaseInstance.delete(`back_office/closets/${closet_id}/ownership`)
	return data
}
