import { Box, Button, Typography } from '@mui/material'
import { DoubleArrowDown, DoubleArrowUp, ErrorOutline } from 'core/icons'
import moment from 'moment'
import React, { useState } from 'react'

import { VisitorsCardComponent } from './visitors-card'

export const EntryDetailCardComponent = ({ cardItem, isActive }: any) => {
	const [showType, setShowType] = useState('all')
	const onClickShow = (type: any) => () => {
		setShowType(type)
	}
	return (
		<Box
			display='flex'
			flexDirection='column'
			p={3}
			sx={{
				width: isActive ? 732 : 656,
				background: '#FFFFFF',
				border: '1px solid rgba(214, 214, 214, 0.48)',
				borderRadius: 1.5,
				cursor: 'pointer'
			}}
		>
			<Box display='flex' justifyContent='space-between' alignItems='center' mb={3}>
				<Box display='flex' gap={4}>
					<Box display='flex' gap={1}>
						<Typography
							variant={isActive ? 'title_2' : 'body_2'}
							sx={{ color: 'primary.gray', fontWeight: 500 }}
						>
							Билет№:
						</Typography>
						<Typography variant={isActive ? 'title_2' : 'body_1'}>{cardItem.ticket_number}</Typography>
					</Box>
					<Box display='flex' gap={1}>
						<Typography
							variant={isActive ? 'title_2' : 'body_2'}
							sx={{ color: 'primary.gray', fontWeight: 500 }}
						>
							Время:
						</Typography>
						<Typography variant={isActive ? 'title_2' : 'body_1'}>{cardItem.time}</Typography>
					</Box>
				</Box>
				<Box display='flex' gap={1}>
					<Typography variant={isActive ? 'title_2' : 'body_1'}>
						{cardItem.type === 'in' ? 'Вход' : 'Выход'}
					</Typography>
					{cardItem.type === 'in' ? <DoubleArrowUp /> : <DoubleArrowDown />}
				</Box>
			</Box>
			<Box display='flex' alignItems='center' gap={4}>
				<img
					style={{
						background: '#D9D9D9',
						borderRadius: '50%',
						width: isActive ? 200 : 128,
						height: isActive ? 200 : 128,
						objectFit: 'cover'
					}}
					src={cardItem.customer.avatar_url}
					alt='avatar'
				/>
				<Box display='flex' flexDirection='column' flexGrow={1} ml={2}>
					<Typography variant={isActive ? 'title_1' : 'title_2'}>
						{cardItem.customer.first_name} {cardItem.customer.last_name}
					</Typography>
					<Box display='flex' gap={0.5}>
						{isActive && (
							<Typography variant='body_2' sx={{ color: 'primary.gray' }}>
								Тип билета:
							</Typography>
						)}
						<Typography variant='body_2' sx={{ color: isActive ? 'primary.black' : 'primary.gray' }}>
							{cardItem.ticket_type}
						</Typography>
					</Box>
					{isActive && (
						<Box display='flex' mt={1} py={1.75} gap={1}>
							<Box display='flex' flexDirection='column' sx={{ width: '50%' }}>
								<Typography variant='caption_1_regular' sx={{ color: 'primary.gray' }}>
									Дата рождения
								</Typography>
								<Typography variant='body_2' mt={0.25}>
									{moment(cardItem.customer.birth_date).format('DD.MM.YYYY')}
								</Typography>
							</Box>
							<Box display='flex' flexDirection='column'>
								<Typography variant='caption_1_regular' sx={{ color: 'primary.gray' }}>
									Пол
								</Typography>
								<Typography variant='body_2' mt={0.25}>
									{cardItem.customer.gender === 'male' ? 'Мужчина' : 'Женщина'}
								</Typography>
							</Box>
						</Box>
					)}
					{!isActive && cardItem?.guests.length > 0 && (
						<Box display='flex' alignItems='center' mt={1.5}>
							<Typography variant='body_2' sx={{ color: 'primary.gray' }}>
								Посетители:
							</Typography>
							<Box display='flex' ml={2} gap={0.5}>
								{cardItem?.guests.slice(0, 3).map((childrenItem: { avatar_url: string }) => (
									<img
										style={{
											background: '#D9D9D9',
											borderRadius: '50%',
											objectFit: 'cover',
											width: 40,
											height: 40
										}}
										src={childrenItem.avatar_url}
										alt='avatar'
									/>
								))}
								{cardItem?.guests.length > 3 && (
									<Box
										display='flex'
										alignItems='center'
										justifyContent='center'
										sx={{
											width: 40,
											height: 40,
											borderRadius: 5,
											background:
												'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #D9D9D9'
										}}
									>
										<Typography variant='body_2' sx={{ color: 'primary.white' }}>
											+{cardItem.guests.length - 3}
										</Typography>
									</Box>
								)}
							</Box>
						</Box>
					)}
				</Box>
			</Box>
			{isActive && (
				<Box display='flex' mt={1} py={1.25} gap={1}>
					{cardItem.show_has_towel_warning && (
						<Box
							display='flex'
							alignItems='center'
							p={2}
							gap={1.5}
							sx={{
								width: 'calc(50% - 4px)',
								background: '#F7F7F7',
								borderRadius: 1.5
							}}
						>
							<ErrorOutline style={{ fill: '#ED4141' }} />
							<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
								Имеется полотенце в аренде
							</Typography>
						</Box>
					)}
					{cardItem.show_has_children_warning && (
						<Box
							display='flex'
							alignItems='center'
							p={2}
							gap={1.5}
							sx={{
								width: 'calc(50% - 4px)',
								background: '#F7F7F7',
								borderRadius: 1.5
							}}
						>
							<ErrorOutline style={{ fill: '#ED4141' }} />
							<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
								Данный клиент, должен уйти с детьми
							</Typography>
						</Box>
					)}
				</Box>
			)}
			{isActive && cardItem?.guests?.length > 0 && (
				<Box display='flex' flexDirection='column' mt={1} gap={1}>
					<Box display='flex' justifyContent='space-between' alignItems='center' py={1.25}>
						<Typography variant='title_3'>Другие посетители</Typography>
						<Box display='flex' gap={1}>
							<Button
								variant='outlined'
								sx={{
									border: '1px solid #181818',
									borderRadius: 4,
									height: 28,
									px: 1.5,
									textTransform: 'unset',
									letterSpacing: '-0.25px'
								}}
								onClick={onClickShow('all')}
							>
								<Typography variant='footnote_2' sx={{ color: 'primary.black' }}>
									Все
								</Typography>
							</Button>
							<Button
								variant='outlined'
								sx={{
									border: '1px solid #D6D6D6',
									borderRadius: 4,
									height: 28,
									px: 1.5,
									textTransform: 'unset',
									letterSpacing: '-0.5px'
								}}
								onClick={onClickShow('old')}
							>
								<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
									Взрослые - {cardItem?.guests.filter((quest: any) => !quest.is_child).length}
								</Typography>
							</Button>
							<Button
								variant='outlined'
								sx={{
									border: '1px solid #D6D6D6',
									borderRadius: 4,
									height: 28,
									px: 1.5,
									textTransform: 'unset',
									letterSpacing: '-0.5px'
								}}
								onClick={onClickShow('child')}
							>
								<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
									Дети - {cardItem?.guests.filter((quest: any) => quest.is_child).length}
								</Typography>
							</Button>
						</Box>
					</Box>
					{showType === 'all' && (
						<Box display='flex' flexWrap='wrap' gap={3}>
							{cardItem?.guests.map((visitor: any) => (
								<VisitorsCardComponent visitor={visitor} />
							))}
						</Box>
					)}
					{showType === 'old' && (
						<Box display='flex' flexWrap='wrap' gap={3}>
							{cardItem?.guests
								.filter((quest: any) => !quest.is_child)
								.map((visitor: any) => (
									<VisitorsCardComponent visitor={visitor} />
								))}
						</Box>
					)}
					{showType === 'child' && (
						<Box display='flex' flexWrap='wrap' gap={3}>
							{cardItem?.guests
								.filter((quest: any) => quest.is_child)
								.map((visitor: any) => (
									<VisitorsCardComponent visitor={visitor} />
								))}
						</Box>
					)}
				</Box>
			)}
		</Box>
	)
}
