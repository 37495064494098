import { Box, Button, Divider, Pagination, Typography } from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { dateFormatter } from 'core/helpers/date-formatter'
import { ArrowBack } from 'core/icons'
import { parseLockers } from 'pages/Lockers/parse'
import { HistoryTopchanStatusColor, HistoryTopchanStatusText } from 'pages/TapchansManage/enum'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getTapchanHistory } from 'services/tapchan/tapchan.service'
import { useDebounce } from 'usehooks-ts'

export const HistoryTopchanPage = () => {
	const [page, setPage] = React.useState(1)
	const [search, setSearch] = React.useState('')

	const navigate = useNavigate()
	const debouncedSearch = useDebounce(search, 500)
	const { data: history } = useQuery(['getTapchanHistory', page, debouncedSearch], {
		queryFn: () =>
			getTapchanHistory({
				page,
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
		setPage(1)
	}
	return (
		<div>
			<Box p={2} display='flex' alignItems='center'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%', marginRight: '16px' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				<Typography variant='title_2'>История</Typography>
			</Box>
			<Divider />
			<Divider />
			<Box p={2} display='flex' justifyContent='end'>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
				{history?.data &&
					Object.keys(history?.data).map(key => (
						<Box mt={6} width={500} key={`manage-topchan-history-${key}`}>
							<Typography variant='headline_1' color='primary.black' mb={4}>
								{key}
							</Typography>
							{history?.data[key] &&
								parseLockers(history?.data[key]).map((data: any) => (
									<Box display='flex' flexDirection='column' mb={2}>
										<Box display='flex' justifyContent='space-between' mb={2}>
											<Box display='flex' flexDirection='column'>
												<Box display='flex' mb={1}>
													<Typography
														variant='caption_1_regular'
														sx={{
															color: HistoryTopchanStatusColor[
																data.status as keyof typeof HistoryTopchanStatusColor
															]
														}}
														mr={1}
													>
														{
															HistoryTopchanStatusText[
																data.status as keyof typeof HistoryTopchanStatusText
															]
														}
													</Typography>
													<Typography variant='caption_1_regular' color='primary.gray'>
														- {dateFormatter(new Date(data.created_at))}
													</Typography>
												</Box>
												<Typography variant='body_2' color='primary.black' mb={1}>
													{data.tapchan?.type}
												</Typography>
												<Box key={data.customer.id} display='flex'>
													<Typography variant='footnote_1' color='primary.gray' mr={1}>
														Клиент:
													</Typography>
													<Typography variant='footnote_1'>
														{data.customer.first_name} {data.customer.last_name}
													</Typography>
												</Box>
											</Box>
											<Box
												display='flex'
												flexDirection='column'
												justifyContent='center'
												alignContent='center'
											>
												<Typography variant='body_1'>{data.tapchan?.price}</Typography>
											</Box>
										</Box>
										<Divider />
									</Box>
								))}
						</Box>
					))}
				{history?.last_page && (
					<Box display='flex' justifyContent='center'>
						<Pagination
							sx={{ marginTop: '16px' }}
							count={history.last_page}
							page={page}
							onChange={handleChange}
						/>
					</Box>
				)}
			</Box>
		</div>
	)
}
