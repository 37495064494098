import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from '@mui/material'
import { ArrowBack } from 'core/icons'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import { addTickets, deleteTicket, getTicketsId, updateTickets } from 'services/tickets/tickets.service'
import { getTicketsCategories } from 'services/ticketsCategory/tickets-category.service'
import { getTicketsTariffs } from 'services/ticketTariff/tickets-tariff.service'
import { getTicketsTypes } from 'services/ticketTypes/tickets-types.service'
import { useBoolean } from 'usehooks-ts'

export const TicketsDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const {
		register,
		handleSubmit,
		reset,
		control,
		setValue,
		watch,
		formState: { errors }
	} = useForm<any>()
	const modalOpen = useBoolean(false)
	const colorWatch = watch('color')
	const ticketsMutation = useMutation({
		mutationFn: payload => addTickets(payload),
		onSuccess: () => {
			reset()
			navigate('/tickets')
			toast.success('Successfully added')
		}
	})
	const { data: ticket } = useQuery(['getTapchanId', id, location], {
		queryFn: () => getTicketsId(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})

	const { data: categories } = useQuery(['getTicketsCategories'], {
		queryFn: () => getTicketsCategories()
	})

	const { data: tarrifs } = useQuery(['getTicketsTariffs'], {
		queryFn: () => getTicketsTariffs()
	})

	const { data: types } = useQuery(['getTicketsTypes'], {
		queryFn: () => getTicketsTypes()
	})
	const deleteTicketMutate = useMutation({
		mutationFn: (ticket_id: any) => deleteTicket(ticket_id),
		onSuccess: () => {
			toast.success('Билет успешно удален!')
			navigate('/tickets?tab=1')
		}
	})

	const ticketUpdateMutation = useMutation({
		mutationFn: ({ ticket_id, body }: any) => updateTickets({ ticket_id, body }),
		onSuccess: () => {
			toast.success('Изменения успешно сохранены!')
			navigate('/tickets?tab=1')
		}
	})

	const onSubmit = (data: any) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		location.search.indexOf('edit') > 0
			? ticketUpdateMutation.mutate({ ticket_id: id, body: data })
			: ticketsMutation.mutate(data)
	}

	const handleTicketDelete = () => {
		deleteTicketMutate.mutate(id)
	}

	useEffect(() => {
		if (ticket?.data) {
			setValue('category_id', ticket.data.category?.id)
			setValue('price', ticket.data.price)
			setValue('type_id', ticket.data.type?.id)
			setValue('color', ticket.data.color)
			setValue('tariff_id', ticket.data.tariff?.id)
		}
	}, [setValue, ticket])
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={modalOpen.setTrue}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={520}>
					<Typography variant='headline_1'>Данные билета</Typography>
					<Box
						mt={3}
						sx={{
							background: colorWatch || '#E2F1FF',
							borderRadius: '12px',
							padding: '58px 8px 8px 8px'
						}}
					>
						<Box
							sx={{
								background: '#FFFFFF',
								borderRadius: '0px 0px 10px 10px',
								padding: '40px'
							}}
						>
							<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
								<Box mb={2} mt={4}>
									<Box mb={2}>
										<Controller
											control={control}
											defaultValue=''
											name='category_id'
											render={({ field: { ...field } }) => (
												<FormControl required fullWidth>
													<InputLabel id='demo-simple-select-label'>Категория</InputLabel>
													<Select
														disabled={location.search.indexOf('view') > 0}
														labelId='demo-simple-select-label'
														id='demo-simple-select'
														{...field}
													>
														{categories?.data.map((category: any) => (
															<MenuItem value={category.id}>{category.name}</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										/>
									</Box>
									<Box mb={2}>
										<Controller
											control={control}
											defaultValue=''
											name='type_id'
											render={({ field: { ...field } }) => (
												<FormControl required fullWidth>
													<InputLabel id='demo-simple-select-label'>Тип билета</InputLabel>
													<Select
														disabled={location.search.indexOf('view') > 0}
														labelId='demo-simple-select-label'
														id='demo-simple-select'
														{...field}
													>
														{types?.data.map((category: any) => (
															<MenuItem value={category.id}>{category.name}</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										/>
									</Box>
									<Box mb={2}>
										<Controller
											control={control}
											defaultValue=''
											name='tariff_id'
											render={({ field: { ...field } }) => (
												<FormControl required fullWidth>
													<InputLabel id='demo-simple-select-label'>Тариф</InputLabel>
													<Select
														disabled={location.search.indexOf('view') > 0}
														labelId='demo-simple-select-label'
														id='demo-simple-select'
														{...field}
													>
														{tarrifs?.data.map((category: any) => (
															<MenuItem value={category.id}>{category.name}</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										/>
									</Box>
									<Box mb={2}>
										<TextField
											label='Цвет'
											placeholder=''
											disabled={location.search.indexOf('view') > 0}
											required
											sx={{
												width: '100px',
												height: '100px',
												borderRadius: '50%'
											}}
											defaultValue={colorWatch || '#E2F1FF'}
											type='color'
											error={!!errors.color}
											{...register('color')}
										/>
									</Box>
									<Box mb={2}>
										<TextField
											label='Цена'
											placeholder=''
											required
											disabled={location.search.indexOf('view') > 0}
											fullWidth
											type='price'
											InputProps={{
												startAdornment: <InputAdornment position='start'>₸</InputAdornment>
											}}
											error={!!errors.price}
											{...register('price')}
										/>
									</Box>
								</Box>
								{location.search.indexOf('view') < 0 && (
									<Button variant='contained' type='submit' fullWidth color='success'>
										{ticketsMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
									</Button>
								)}
							</form>
						</Box>
					</Box>
				</Box>
			</Box>

			<Dialog
				open={modalOpen.value}
				onClose={modalOpen.setFalse}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={modalOpen.setFalse}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleTicketDelete} autoFocus variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
