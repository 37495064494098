import { Box, Button, CircularProgress, Divider, Grid, ListSubheader, TextField } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'
import { getStatsByDates } from 'services/tickets/tickets.service'
import { getTicketsCategories } from 'services/ticketsCategory/tickets-category.service'

import { parseStats } from './parse'

export const TicketsManageStats = () => {
	const [categoryId, setCategoryId] = React.useState()
	const [startDate, setStartDate] = React.useState(new Date())
	const [endDate, setEndDate] = React.useState(new Date())
	const [openGraph, setOpenGraph] = React.useState(false)
	const chartWidth = (window.innerWidth - 384) * 0.75

	const { data: categories } = useQuery(['getTicketsCategories'], {
		queryFn: () => getTicketsCategories()
	})

	const { data: statsTickets, isLoading } = useQuery(['getStatsByDates', startDate, endDate, categoryId], {
		queryFn: () => getStatsByDates(categoryId, startDate, endDate),
		enabled: !!categoryId && !!startDate && !!endDate && openGraph
	})

	const onClickCategory = (category_id: any) => () => {
		setCategoryId(category_id)
	}
	const onChangeStartDate = (e: any) => {
		setStartDate(e.target.value)
	}
	const onChangeEndDate = (e: any) => {
		setEndDate(e.target.value)
	}
	const onClickPeriodApprove = () => {
		setOpenGraph(true)
	}

	const onClickPeriodReset = () => {
		setOpenGraph(false)
		setStartDate(new Date())
		setEndDate(new Date())
	}

	useEffect(() => {
		if (categories?.data?.length > 0) {
			setCategoryId(categories?.data[0].id)
		}
	}, [categories])
	return (
		<Grid container spacing={2}>
			<Grid display='flex' item xs={3}>
				<Box display='flex' flexDirection='column' flex='1'>
					<Box flex='1' p={3}>
						<nav>
							<Box mb={1}>
								<List
									sx={{
										padding: '0'
									}}
									subheader={
										<ListSubheader
											sx={{
												padding: '0',
												marginBottom: '16px',
												fontWeight: '700',
												fontsize: '16px',
												lineHeight: '20px',
												letterSpacing: '0.6px',
												color: '#181818'
											}}
										>
											Категории
										</ListSubheader>
									}
								>
									{categories?.data.map(({ id: listId, name }: any) => (
										<ListItem
											key={listId}
											sx={{
												padding: '0'
											}}
										>
											<ListItemButton
												sx={{
													height: '52px',
													'&.Mui-selected': {
														background: '#E2F1FF',
														svg: {
															fill: '#349EFF'
														}
													}
												}}
												selected={categoryId === listId}
												onClick={onClickCategory(listId)}
											>
												<ListItemText
													primary={name}
													primaryTypographyProps={{
														variant: 'body_2',
														color: categoryId === listId ? '#000000' : '#575757'
													}}
												/>
											</ListItemButton>
										</ListItem>
									))}
								</List>
							</Box>
						</nav>
					</Box>
				</Box>
				<Divider orientation='vertical' flexItem />
			</Grid>
			<Grid
				item
				xs={9}
				mt={5}
				pr={2}
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
			>
				<Box display='flex' gap={4} alignItems='center' mb={10}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<TextField
								placeholder='Начало периода'
								required
								fullWidth
								value={startDate}
								onChange={onChangeStartDate}
								type='date'
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								placeholder='Конец периода'
								required
								value={endDate}
								onChange={onChangeEndDate}
								fullWidth
								type='date'
							/>
						</Grid>
					</Grid>
					<Button onClick={onClickPeriodApprove}>Готово</Button>
					<Button color='error' onClick={onClickPeriodReset}>
						Отменить
					</Button>
				</Box>
				{isLoading && <CircularProgress />}
				{statsTickets?.data && (
					<LineChart
						width={chartWidth}
						height={450}
						data={parseStats(statsTickets?.data)}
						margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
					>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='date' />
						<YAxis />
						<Tooltip />
						<Legend />
						<Line type='monotone' name='Количество проданных билетов' dataKey='bought' stroke='#349EFF' />
						<Line type='monotone' name='Использованный' dataKey='used' stroke='#696969' />
						<Line type='monotone' name='Просроченный' dataKey='overdue' stroke='#ED4141' />
					</LineChart>
				)}
			</Grid>
		</Grid>
	)
}
