import { axiosBaseInstance } from 'core/axios.config'

export const getCustomerGuests = async (params?: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/customers`, {
		params
	})
	return data
}

export const createCustomerGuests = async (customer_id: any, data: any) => {
	const response = await axiosBaseInstance.post(`/back_office/customers/${customer_id}/guest`, {
		guests: data
	})
	return response.data
}

export const getCustomerGuestInfo = async (customer_id: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/customers/${customer_id}`)
	return data
}

export const createCustomer = async ({ body }: any) => {
	const response = await axiosBaseInstance.post(`/back_office/customers`, body, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return response.data
}

export const updateCustomer = async ({ guest_id, body }: any) => {
	const { data } = await axiosBaseInstance.post(`back_office/customers/${guest_id}/`, body, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return data
}

export const deleteCustomerGuest = async ({ customer_id, reason }: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/customers/${customer_id}`, {
		data: {
			reason
		}
	})
	return v.data
}
