export const tickets = [
	{
		id: '10',
		cards: [
			{
				user: 'Диас Ибрашин',
				type: 'Взрослый',
				children: [
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					}
				],
				number: '21321443',
				time: '12:45',
				isEntering: true
			},
			{
				user: 'Малик Абдиев',
				type: 'Детский',
				children: [
					{
						avatar: '/assets/img/avatar.png'
					}
				],
				number: '21321444',
				time: '12:45',
				isEntering: false
			}
		]
	},
	{
		id: '1',
		cards: [
			{
				user: 'Малик Абдиев',
				type: 'Детский',
				children: [
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					}
				],
				number: '21321447',
				time: '12:45',
				isEntering: true
			},
			{
				user: 'Диас Ибрашин',
				type: 'Взрослый',
				children: [
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					}
				],
				number: '21321445',
				time: '12:45',
				isEntering: true
			},
			{
				user: 'Азамат Даулетов',
				type: 'Взрослый',
				number: '21321446',
				time: '12:45',
				isEntering: false
			}
		]
	},
	{
		id: '2',
		cards: [
			{
				user: 'Малик Абдиев',
				type: 'Детский',
				children: [
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					}
				],
				number: '21321447',
				time: '12:45',
				isEntering: true
			},
			{
				user: 'Диас Ибрашин',
				type: 'Взрослый',
				children: [
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					}
				],
				number: '21321445',
				time: '12:45',
				isEntering: true
			},
			{
				user: 'Азамат Даулетов',
				type: 'Взрослый',
				number: '21321446',
				time: '12:45',
				isEntering: false
			}
		]
	},
	{
		id: '3',
		cards: [
			{
				user: 'Малик Абдиев',
				type: 'Детский',
				children: [
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					}
				],
				number: '21321447',
				time: '12:45',
				isEntering: true
			},
			{
				user: 'Диас Ибрашин',
				type: 'Взрослый',
				children: [
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					}
				],
				number: '21321445',
				time: '12:45',
				isEntering: true
			},
			{
				user: 'Азамат Даулетов',
				type: 'Взрослый',
				number: '21321446',
				time: '12:45',
				isEntering: false
			}
		]
	},
	{
		id: '4',
		cards: [
			{
				user: 'Малик Абдиев',
				type: 'Детский',
				children: [
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					}
				],
				number: '21321447',
				time: '12:45',
				isEntering: true
			},
			{
				user: 'Диас Ибрашин',
				type: 'Взрослый',
				children: [
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					},
					{
						avatar: '/assets/img/avatar.png'
					}
				],
				number: '21321445',
				time: '12:45',
				isEntering: true
			},
			{
				user: 'Азамат Даулетов',
				type: 'Взрослый',
				number: '21321446',
				time: '12:45',
				isEntering: false
			}
		]
	}
]
