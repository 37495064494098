import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from '@mui/material'
import EditAvatar from 'components/avatar/EditAvatar'
import { ArrowBack } from 'core/icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import { createCategory, deleteCategory, getCategoryById, updateCategory } from 'services/food/food.service'

export const FoodCategoryDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const [reasonText, setReasonText] = React.useState('')
	const { id } = useParams()
	const {
		register,
		handleSubmit,
		reset,
		control,
		setValue,
		formState: { errors }
	} = useForm<any>()
	const [image, setImage] = useState<any>()
	const [fileImage, setFileImage] = useState<any>()
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)

	const foodCategoryMutation = useMutation<any>({
		mutationFn: payload => createCategory(payload),
		onSuccess: () => {
			reset()
			navigate('/food_and_drinks')
			toast.success('Successfully added')
		}
	})

	const deleteCategoryMutate = useMutation(['deleteFoodCategory'], {
		mutationFn: ({ category_id, reason }: any) => deleteCategory({ category_id, reason }),
		onSuccess: () => {
			navigate(`/food_and_drinks`)
			toast.success('Successfully deleted')
			setOpenDeleteModal(false)
		}
	})

	const foodCategoryUpdateMutation = useMutation({
		mutationFn: ({ category_id, body }: any) => updateCategory({ category_id, body }),
		onSuccess: () => {
			toast.success('Изменения успешно сохранены!')
			navigate('/food_and_drinks')
		}
	})

	const { data: category } = useQuery(['getCategoryById', id, location], {
		queryFn: () => getCategoryById(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})

	const onSubmit = (body: any) => {
		const fd: any = new FormData()

		if (fileImage) fd.append('avatar', fileImage)
		fd.append('name', body.name)
		fd.append('is_visible', body.is_visible ? 1 : 0)
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		location.search.indexOf('edit') > 0
			? foodCategoryUpdateMutation.mutate({ category_id: id, body: fd })
			: foodCategoryMutation.mutate(fd)
	}

	const onChangeReason = (e: any) => {
		setReasonText(e.target.value)
	}

	const handleDeleteCategory = () => {
		if (reasonText) {
			deleteCategoryMutate.mutate({ category_id: id, reason: reasonText })
		} else {
			toast.error('Нужнa причина!')
		}
	}
	const onAvatarUpload = (file: any) => {
		setFileImage(file)
		setImage(URL.createObjectURL(file))
	}

	const handleDeleteOpenModal = () => setOpenDeleteModal(true)
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)
	useEffect(() => {
		if (category) {
			setImage(category.data.img_path)
			setValue('name', category.data.name)
			setValue('is_visible', category.data.is_visible)
		}
	}, [setValue, category])
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={handleDeleteOpenModal}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={824}>
					<Typography variant='headline_1'>Данные категории</Typography>
					{category?.data && (
						<Box display='flex' mt={2}>
							<Typography variant='body1' color='grey' mr={2}>
								ID: <strong style={{ color: 'black' }}>{category.data.id}</strong>
							</Typography>
							<Typography variant='body1' color='grey' mr={2}>
								Дата добавления:{' '}
								<strong style={{ color: 'black' }}>
									{moment(category.data.created_at).format('DD MMM YYYY')}
								</strong>
							</Typography>
							<Typography variant='body1' color='grey'>
								Дата обновления:{' '}
								<strong style={{ color: 'black' }}>
									{moment(category.data.updated_at).format('DD MMM YYYY')}
								</strong>
							</Typography>
						</Box>
					)}
					<Box display='flex' mt={6}>
						<EditAvatar
							src={image}
							onUpload={onAvatarUpload}
							disabled={location.search.indexOf('view') > 0}
						/>
						<form
							onSubmit={handleSubmit(onSubmit)}
							noValidate
							style={{ width: '100%', marginLeft: '40px' }}
						>
							<Box mb={2}>
								<TextField
									label='Название категории'
									placeholder='Название категории'
									required
									disabled={location.search.indexOf('view') > 0}
									fullWidth
									type='text'
									error={!!errors.name}
									{...register('name')}
								/>
								<Controller
									control={control}
									name='is_visible'
									defaultValue=''
									render={({ field: { ref, onBlur, name, ...field } }) => (
										<FormControl required fullWidth>
											<InputLabel id='demo-simple-select-label'>
												Доступность показа клиентам
											</InputLabel>
											<Select
												disabled={location.search.indexOf('view') > 0}
												labelId='demo-simple-select-label'
												id='demo-simple-select'
												{...field}
											>
												<MenuItem value='true'>Доступно</MenuItem>
												<MenuItem value='false'>Не доступно</MenuItem>
											</Select>
										</FormControl>
									)}
								/>
							</Box>
							{location.search.indexOf('view') < 0 && (
								<Button variant='contained' type='submit' color='success'>
									{foodCategoryMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
								</Button>
							)}
						</form>
					</Box>
				</Box>
			</Box>
			<Dialog
				open={openDeleteModal}
				onClose={handleDeleteCloseModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
					<Box mt={2}>
						<TextField
							label='Причина удаления'
							placeholder=''
							fullWidth
							multiline
							rows={3}
							type='text'
							value={reasonText}
							onChange={onChangeReason}
							name='reason'
						/>
					</Box>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={handleDeleteCloseModal}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button
						autoFocus
						variant='text'
						disabled={!reasonText}
						color='error'
						onClick={handleDeleteCategory}
					>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
