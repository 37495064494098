import { Box, Button, Typography } from '@mui/material'
import { Add, Remove } from 'core/icons'
import * as React from 'react'

export const OrdersEditProductComponent = ({ name, count, price }: any) => {
	const [amount, setAmount] = React.useState(count)

	const handleDecrement = () => {
		if (amount > 0) setAmount(amount - 1)
	}

	const handleIncrement = () => {
		setAmount(amount + 1)
	}

	return (
		<Box display='flex' alignItems='center' py={2} sx={{ borderBottom: '1px solid #D7D7D7' }}>
			<img
				style={{
					background: '#D9D9D9',
					width: 64,
					borderRadius: 8
				}}
				src='/assets/img/avatar.png'
				alt='avatar'
			/>
			<Box display='flex' flexDirection='column' flexGrow={1} ml={1.5} gap={0.25}>
				<Typography variant='caption_1' color='primary.gray'>
					{name}
				</Typography>
				<Typography variant='body_2'>{price} ₸</Typography>
			</Box>
			<Box display='flex' alignItems='center' gap={1.5}>
				<Button
					variant='contained'
					size='small'
					sx={{ minWidth: 32, width: 32, background: '#F7F7F7' }}
					onClick={handleDecrement}
				>
					<Remove style={{ flexShrink: 0, color: '#575757' }} />
				</Button>
				<Typography variant='title_2'>{amount}</Typography>
				<Button
					variant='contained'
					size='small'
					sx={{ minWidth: 32, width: 32, background: '#E2F1FF' }}
					onClick={handleIncrement}
				>
					<Add style={{ flexShrink: 0, color: '#349EFF' }} />
				</Button>
			</Box>
		</Box>
	)
}
