import './index.css'

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Menu,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteRoles, getRolesList } from 'services/roles/roles.service'

import { Cancel, Dots, Edit, Settings, View } from '../../core/icons'

export const RolesPage = () => {
	const { profile } = useApp()
	const [currentId, setCurrentId] = useState<any>()
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const [openDialog, setOpenDialog] = React.useState(false)
	const navigate = useNavigate()
	const open = Boolean(anchorEl)

	const { data: roles, refetch } = useQuery(['getRolesList'], {
		queryFn: () => getRolesList()
	})
	const deletePointerMutate = useMutation({
		mutationFn: (rolesId: any) => deleteRoles(rolesId),
		onSuccess: () => {
			toast.success('Successfully deleted')
			setOpenDialog(false)
			setAnchorEl(null)
			refetch()
		}
	})
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentId(event.currentTarget.id)
		setAnchorEl(event.currentTarget)
	}

	const handleEditRole = () => {
		navigate(`/accesses_roles/${currentId}?edit`)
	}
	const handleViewRole = () => {
		navigate(`/accesses_roles/${currentId}?view`)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleCloseDialog = () => {
		setOpenDialog(false)
	}
	const handleDeleteRole = () => {
		deletePointerMutate.mutate(currentId)
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Доступы и роли</Typography>
				{profile?.user?.role.permissions.includes('update_role') && (
					<Button onClick={() => navigate('/accesses_roles/add')} variant='contained'>
						Добавить
					</Button>
				)}
			</Box>
			<Divider />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell align='left'>Должность</TableCell>
							<TableCell align='left'>Описание</TableCell>
							<TableCell align='right'>Кол. доcтупов</TableCell>
							<TableCell align='right'>
								<Settings />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{roles?.data.map((row: any) => (
							<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component='th' scope='row'>
									{row.id}
								</TableCell>
								<TableCell align='left'>{row.name}</TableCell>
								<TableCell align='left'>{row.description}</TableCell>
								<TableCell align='right'>{row.permissions.length}</TableCell>
								<TableCell align='right'>
									<Button
										id={row.id}
										aria-controls={open ? 'basic-menu' : undefined}
										aria-haspopup='true'
										aria-expanded={open ? 'true' : undefined}
										onClick={handleClick}
									>
										<Dots />
									</Button>
									<Menu
										id={`basic-menu-${row.id}`}
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
										MenuListProps={{
											'aria-labelledby': 'basic-button'
										}}
									>
										<MenuItem onClick={handleViewRole}>
											<View
												style={{
													color: '#349EFF',
													marginRight: '8px'
												}}
											/>
											Просмотр роли и доступов
										</MenuItem>
										{profile?.user?.role.permissions.includes('update_role') && (
											<MenuItem onClick={handleEditRole}>
												<Edit
													style={{
														marginRight: '8px'
													}}
												/>
												Редактировать
											</MenuItem>
										)}
										{profile?.user?.role.permissions.includes('update_role') && (
											<MenuItem onClick={handleDeleteRole}>
												<Cancel
													style={{
														marginRight: '8px'
													}}
												/>
												Удалить
											</MenuItem>
										)}
									</Menu>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Dialog
				open={openDialog}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button onClick={handleCloseDialog} variant='text' color='success' style={{ marginBottom: '16px' }}>
						Нет, оставить
					</Button>
					<Button onClick={handleCloseDialog} autoFocus variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
