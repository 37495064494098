import { Box, Button, Divider, Typography } from '@mui/material'
import { ArrowBack } from 'core/icons'
import { FoodAddComponent } from 'pages/QrFoods/food-add.component'
import { QrFoodsComponent } from 'pages/QrFoods/qr-foods.component'
import { QrPayComponent } from 'pages/QrFoods/qr-pay.component'
import React from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getCategoryById } from 'services/food/food.service'

export const QrPayPage = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const location = useLocation()
	const isFalse = false
	const [addedProducts, setAddedProducts] = React.useState()

	const { data: category } = useQuery(['getCategoryById', id, location], {
		queryFn: () => getCategoryById(id),
		enabled: !!id
	})

	const handleClick = () => {
		navigate('/qr/history')
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Box display='flex' alignItems='center'>
					<Button
						color='info'
						variant='text'
						size='small'
						onClick={() => navigate(-1)}
						sx={{ border: 0, borderRadius: '50%', marginRight: '16px' }}
					>
						<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
					</Button>
					<Typography variant='title_2'>{category?.data?.name}</Typography>
				</Box>
				<Button onClick={handleClick} variant='outlined'>
					<span style={{ color: '#349EFF' }}>История</span>
				</Button>
			</Box>
			<Divider />
			<Box display='flex' mt={1} gap={2}>
				<Box width='50%'>
					<QrFoodsComponent addedProducts={addedProducts} setAddedProducts={setAddedProducts} />
				</Box>
				<Box width='50%'>
					<FoodAddComponent addedProducts={addedProducts} setAddedProducts={setAddedProducts} />
				</Box>
				{isFalse && <QrPayComponent />}
			</Box>
		</div>
	)
}
