import { Autocomplete, Box, Button, Divider, TextField, Typography } from '@mui/material'
import { Close } from 'core/icons'
import { parseClients } from 'pages/ManageClosestDetail/utils'
import { ManageTapchanStatus, ManageTapchanType } from 'pages/TapchansManageDetail/enum'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCustomerGuests } from 'services/guests/guests.service'
import { addOwnerTapchan, getTapchanId } from 'services/tapchan/tapchan.service'

export const TapchansManageAddPage = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const { control, handleSubmit } = useForm<any>()
	const { data: tapchan } = useQuery(['getTapchanId', id], {
		queryFn: () => getTapchanId(id),
		enabled: !!id
	})

	const addOwnerMutation = useMutation({
		mutationFn: ({ tapchan_id, body }: any) => addOwnerTapchan({ tapchan_id, body }),
		onSuccess: () => {
			toast.success('Владелец выбран успешно!')
			navigate('/management_tapchan')
		}
	})
	const { data: clients } = useQuery(['getCustomerGuests'], {
		queryFn: () =>
			getCustomerGuests({
				per_page: 1000000,
				having_tickets: 1
			})
	})

	const onSubmit = (data: any) => {
		const userId = data?.customer_id.id
		addOwnerMutation.mutate({
			tapchan_id: id,
			body: {
				customer_id: userId
			}
		})
	}
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<Close style={{ marginRight: '12px' }} /> Отмена
				</Button>
			</Box>
			<Divider />
			<Box display='flex' mt={1} gap={2}>
				<Box
					sx={{
						width: '374px',
						minHeight: '100vh',
						border: '1px solid rgba(214, 214, 214, 0.48)',
						borderRadius: 1.5
					}}
				>
					<Box
						sx={{
							padding: '24px 16px'
						}}
					>
						<Typography variant='title_2'>Сведения о тапчане</Typography>
					</Box>
					<Divider />
					<Box display='flex' flexDirection='column' alignItems='center' px={2} py={6}>
						<Box
							display='flex'
							alignItems='center'
							justifyContent='center'
							mb={2}
							sx={{
								width: 130,
								height: 130,
								background: '#349EFF',
								borderRadius: 2.5
							}}
						>
							<Typography variant='headline_1' color='primary.white'>
								{tapchan?.data.number}
							</Typography>
						</Box>
						<Box display='flex' flexDirection='column' alignItems='center' p={2}>
							<Typography variant='caption_1_regular' color='primary.gray'>
								Номер тапчана
							</Typography>
							<Typography variant='body_1'>{tapchan?.data.number}</Typography>
						</Box>
						<Box display='flex' flexDirection='column' alignItems='center' p={2}>
							<Typography variant='caption_1_regular' color='primary.gray'>
								Статус
							</Typography>
							{tapchan?.data.status && (
								<Typography variant='body_1'>
									{ManageTapchanStatus[tapchan?.data.status as keyof typeof ManageTapchanStatus]}
								</Typography>
							)}
						</Box>
						<Box display='flex' flexDirection='column' alignItems='center' p={2}>
							<Typography variant='caption_1_regular' color='primary.gray'>
								Вид тапчана
							</Typography>
							{tapchan?.data.type && (
								<Typography variant='body_1'>
									{ManageTapchanType[tapchan?.data.type as keyof typeof ManageTapchanType]}
								</Typography>
							)}
						</Box>
					</Box>
				</Box>
				<Box display='flex' p={3}>
					<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
						{clients?.data && (
							<Box mb={2}>
								<Controller
									control={control}
									defaultValue=''
									name='customer_id'
									render={({ field: { ...field } }) => (
										<Autocomplete
											id='clients-select'
											options={parseClients(clients?.data)}
											sx={{ width: 300 }}
											getOptionLabel={option => (option ? option.label : '')}
											renderInput={params => <TextField {...params} label='Клиенты' />}
											{...field}
											onChange={(event, item) => {
												field.onChange(item)
											}}
										/>
									)}
								/>
							</Box>
						)}
						<Button variant='contained' type='submit' size='medium' sx={{ width: '252px' }} color='success'>
							Сделать владельцем
						</Button>
					</form>
				</Box>
			</Box>
		</Box>
	)
}
