import { Box, Button, Divider, Tab, Tabs, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const ClosetsManageSelect = () => {
	const navigate = useNavigate()
	const [tab, setTab] = React.useState('male')
	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setTab(newValue)
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Управление шкафами</Typography>
			</Box>
			<Divider />
			<Box sx={{ width: '100%' }} mb={2} py={4} display='flex' justifyContent='center'>
				<Tabs value={tab} onChange={handleChangeTab} aria-label='wrapped label tabs example'>
					<Tab value='male' label='Мужская раздевалка' wrapped />
					<Tab value='female' label='Женская раздевалка ' />
				</Tabs>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center' mt={7}>
				<Box display='flex' flexWrap='wrap' sx={{ width: 496 }} gap={2}>
					<Box>
						<Button>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								sx={{
									backgroundColor: '#F6F6F6',
									width: 240,
									height: 120,
									borderRadius: '12px'
								}}
								onClick={() => navigate(`/management_closet?gender=${tab}&page=1`)}
							>
								<Typography variant='body_1' color='primary.black'>
									1-250
								</Typography>
							</Box>
						</Button>
					</Box>
					<Box>
						<Button>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								sx={{
									backgroundColor: '#F6F6F6',
									width: 240,
									height: 120,
									borderRadius: '12px'
								}}
								onClick={() => navigate(`/management_closet?gender=${tab}&page=2`)}
							>
								<Typography variant='body_1' color='primary.black'>
									250-500
								</Typography>
							</Box>
						</Button>
					</Box>
					<Box>
						<Button>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								sx={{
									backgroundColor: '#F6F6F6',
									width: 240,
									height: 120,
									borderRadius: '12px'
								}}
								onClick={() => navigate(`/management_closet?gender=${tab}&page=3`)}
							>
								<Typography variant='body_1' color='primary.black'>
									500-750
								</Typography>
							</Box>
						</Button>
					</Box>
					<Box>
						<Button>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								sx={{
									backgroundColor: '#F6F6F6',
									width: 240,
									height: 120,
									borderRadius: '12px'
								}}
								onClick={() => navigate(`/management_closet?gender=${tab}&page=4`)}
							>
								<Typography variant='body_1' color='primary.black'>
									750-1 000
								</Typography>
							</Box>
						</Button>
					</Box>
					<Box>
						<Button>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								sx={{
									backgroundColor: '#F6F6F6',
									width: 240,
									height: 120,
									borderRadius: '12px'
								}}
								onClick={() => navigate(`/management_closet?gender=${tab}&page=5`)}
							>
								<Typography variant='body_1' color='primary.black'>
									1 000-1 250
								</Typography>
							</Box>
						</Button>
					</Box>
					<Box>
						<Button>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								sx={{
									backgroundColor: '#F6F6F6',
									width: 240,
									height: 120,
									borderRadius: '12px'
								}}
								onClick={() => navigate(`/management_closet?gender=${tab}&page=6`)}
							>
								<Typography variant='body_1' color='primary.black'>
									1 250-1 500
								</Typography>
							</Box>
						</Button>
					</Box>
					<Box>
						<Button>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								sx={{
									backgroundColor: '#F6F6F6',
									width: 240,
									height: 120,
									borderRadius: '12px'
								}}
								onClick={() => navigate(`/management_closet?gender=${tab}&page=7`)}
							>
								<Typography variant='body_1' color='primary.black'>
									1 500-1 750
								</Typography>
							</Box>
						</Button>
					</Box>
					<Box>
						<Button>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								sx={{
									backgroundColor: '#F6F6F6',
									width: 240,
									height: 120,
									borderRadius: '12px'
								}}
								onClick={() => navigate(`/management_closet?gender=${tab}&page=8`)}
							>
								<Typography variant='body_1' color='primary.black'>
									1 750-2 000
								</Typography>
							</Box>
						</Button>
					</Box>
				</Box>
			</Box>
		</div>
	)
}
