export const clientsPermissions = [
	{
		id: '1',
		name: 'Клиенты',
		edit: 'update_customer',
		view: 'view_customers'
	},
	{
		id: '2',
		name: 'Сотрудники',
		edit: 'update_employee',
		view: 'view_employees'
	},
	{
		id: '3',
		name: 'Шкафы',
		edit: 'update_closets',
		view: 'view_closets'
	},
	{
		id: '4',
		name: 'Топчаны и шезлонги',
		edit: 'update_tapchan',
		view: 'view_tapchans'
	},
	{
		id: '5',
		name: 'Точки для выдачи заказа',
		edit: 'update_issue_point',
		view: 'view_issue_points'
	},
	{
		id: '6',
		name: 'Полотенце ',
		edit: 'update_towel',
		view: 'view_towels'
	},
	{
		id: '7',
		name: 'Прочие услуги',
		edit: 'update_service',
		view: 'view_services'
	},
	{
		id: '8',
		name: 'Турникеты',
		edit: 'update_tourniquet',
		view: 'view_tourniquets'
	},
	{
		id: '9',
		name: 'Доступы и роли',
		edit: 'update_role',
		view: 'view_roles'
	},
	{
		id: '10',
		name: 'Категорий продукта',
		edit: 'update_product_category',
		view: 'view_product_categories'
	},
	{
		id: '11',
		name: 'Продукт',
		edit: 'update_product',
		view: 'view_products'
	},
	{
		id: '12',
		name: 'Тип билеты',
		edit: 'update_ticket_type',
		view: 'view_ticket_types'
	},
	{
		id: '13',
		name: 'Категорий билеты',
		edit: 'update_ticket_category',
		view: 'view_ticket_categories'
	},
	{
		id: '14',
		name: 'Тариф билеты',
		edit: 'update_ticket_tariff',
		view: 'view_ticket_tariffs'
	},
	{
		id: '15',
		name: 'Билеты ',
		edit: 'update_ticket',
		view: 'view_tickets'
	},
	{
		id: '16',
		name: 'Инструктаж правил',
		edit: 'update_basic_rule',
		view: 'view_basic_rules'
	}
]

export const managePermissions = [
	{
		id: '20',
		name: 'Заказы',
		edit: 'update_order',
		view: 'view_orders'
	},
	{
		id: '21',
		name: 'Покупка по QR',
		edit: 'update_manage_buy_qr',
		view: 'view_manage_buy_qr'
	},
	{
		id: '22',
		name: 'История продаж услуг',
		edit: 'update_manage_service',
		view: 'view_manage_service'
	},
	{
		id: '23',
		name: 'Управление топчанами',
		edit: 'update_manage_tapchan',
		view: 'view_manage_tapchan'
	},
	{
		id: '24',
		name: 'Управление шкафами',
		edit: 'update_manage_closet',
		view: 'view_manage_closet'
	},
	{
		id: '25',
		name: 'Управление билетами',
		edit: 'update_manage_ticket',
		view: 'view_manage_ticket'
	},
	{
		id: '26',
		name: 'История платежей',
		edit: 'wallet_deposit',
		view: 'wallet_operation_view'
	},
	{
		id: '27',
		name: 'Возврат денег',
		edit: 'wallet_deposit',
		view: 'wallet_operation_view'
	},
	{
		id: '28',
		name: 'Управление входа/выхода',
		edit: 'update_crossings',
		view: 'view_crossings'
	},
	{
		id: '29',
		name: 'Управление полотенцами',
		edit: 'confirm_towel_use',
		view: 'view_towel_uses'
	},
	{
		id: '30',
		name: 'SOS',
		edit: 'update_sos',
		view: 'view_sos'
	}
]
