/* eslint-disable react/no-unescaped-entities */
import { Box, Typography } from '@mui/material'
import React from 'react'

export const TermsOfUse = () => {
	return (
		<Box py={6} px={8}>
			<section>
				<Typography variant='headline_1' mb={2}>
					Пользовательское соглашение
				</Typography>
				<Box display='flex' flexDirection='column'>
					<Typography variant='body_2' mb={2}>
						Пользовательское соглашение для мобильного приложения "ZER" аквапарка &quot;ZERBULAQ&quot;
					</Typography>
					<Typography variant='body_2' mb={2}>
						Пожалуйста, ознакомьтесь с нижеприведенным пользовательским соглашением перед использованием
						мобильного приложения "ZER" аквапарка &quot;ZERBULAQ&quot;. Это соглашение содержит важные
						положения, регулирующие ваше использование приложения. При использовании приложения, вы
						подтверждаете свое согласие с этими условиями и обязуетесь соблюдать их.
					</Typography>
					<Typography variant='body_2' mb={2}>
						1. Введение
					</Typography>
					<Typography variant='body_2' mb={2}>
						1.1 Мобильное приложение "ZER" аквапарка &quot;ZERBULAQ&quot; (далее - &quot;Приложение&quot;)
						является продуктом и собственностью ТОО «South holiday», юридического лица зарегистрированного
						по следующему адресу: Жамбылская область, Байзакский район, Жалгызтобинский С.О., С.Аккия, улица
						А.Молдагулова, дом 8.
					</Typography>
					<Typography variant='body_2' mb={2}>
						1.2 Пользовательское соглашение устанавливает условия и правила использования Приложения,
						включая доступ к информации, функционалу и услугам, предоставляемым через Приложение.
					</Typography>
					<Typography variant='body_2' mb={2}>
						1.3 При использовании Приложения вы признаете, что ознакомлены с этим Пользовательским
						соглашением и принимаете его условия. Если вы не согласны с этим соглашением, пожалуйста, не
						используйте Приложение.
					</Typography>
					<Typography variant='body_2' mb={2}>
						2. Права и обязанности пользователей
					</Typography>
					<Typography variant='body_2' mb={2}>
						2.1 Вы обязаны предоставить достоверную и точную информацию при регистрации в Приложении.
					</Typography>
					<Typography variant='body_2' mb={2}>
						2.2 Пользователь обязуется не нарушать законы Республики Казахстан при использовании Приложения,
						а также не совершать действия, которые могут нанести вред Приложению или правам других
						пользователей.
					</Typography>
					<Typography variant='body_2' mb={2}>
						2.3 Вы несете ответственность за сохранение конфиденциальности вашего логина, пароля и иной
						информации, используемой для доступа к Приложению. Вы соглашаетесь не передавать эти данные
						третьим лицам и не использовать учетную запись другого пользователя без его разрешения.
					</Typography>
					<Typography variant='body_2' mb={2}>
						2.4 Пользователь обязан уведомить администрацию Приложения в случае несанкционированного доступа
						к его учетной записи или других нарушений безопасности.
					</Typography>
					<Typography variant='body_2' mb={2}>
						3. Интеллектуальная собственность
					</Typography>
					<Typography variant='body_2' mb={2}>
						3.1 Все авторские права и другие права интеллектуальной собственности в отношении Приложения,
						его контента и материалов, включая графику, дизайн, логотипы, тексты, изображения и другие
						компоненты, принадлежат ТОО «South holiday» или его лицензиару.
					</Typography>
					<Typography variant='body_2' mb={2}>
						3.2 Вы не получаете права на использование, копирование или распространение материалов, за
						исключением случаев, прямо предусмотренных этим соглашением или дополнительными соглашениями с
						ТОО «South holiday».
					</Typography>
					<Typography variant='body_2' mb={2}>
						4. Ограничение ответственности
					</Typography>
					<Typography variant='body_2' mb={2}>
						4.1 При использовании Приложения вы соглашаетесь, что ТОО «South holiday» не несет
						ответственности за любые прямые или косвенные убытки, возникшие в результате использования или
						невозможности использования Приложения.
					</Typography>
					<Typography variant='body_2' mb={2}>
						4.2 ТОО «South holiday» не несет ответственности за любой вред, причиненный вашему мобильному
						устройству или программному обеспечению в результате использования Приложения.
					</Typography>
					<Typography variant='body_2' mb={2}>
						5. Изменения и прекращение предоставления услуг
					</Typography>
					<Typography variant='body_2' mb={2}>
						5.1 ТОО «South holiday» имеет право вносить изменения в функционал Приложения и условия его
						использования в любое время без предварительного уведомления.
					</Typography>
					<Typography variant='body_2' mb={2}>
						5.2 ТОО «South holiday» имеет право в любой момент прекратить предоставление Приложения или его
						части без объяснения причин.
					</Typography>
					<Typography variant='body_2' mb={2}>
						6. Применимое право и разрешение споров
					</Typography>
					<Typography variant='body_2' mb={2}>
						6.1 Все взаимоотношения между пользователями и ТОО «South holiday», связанные с использованием
						Приложения, регулируются законами Республики Казахстан.
					</Typography>
					<Typography variant='body_2' mb={2}>
						6.2 Любые споры или разногласия, возникающие из этого Пользовательского соглашения или в связи с
						его исполнением, подлежат разрешению в судебном порядке в соответствии с действующим
						законодательством Республики Казахстан.
					</Typography>
					<Typography variant='body_2' mb={2}>
						Пожалуйста, обратите внимание, что данное Пользовательское соглашение может быть изменено или
						обновлено ТОО «South holiday». Регулярно проверяйте наличие изменений, чтобы быть в курсе
						актуальных условий использования Приложения.
					</Typography>
				</Box>
			</section>
			<section>
				<Typography variant='headline_1' mb={2} mt={8}>
					Приложение к пользовательскому соглашению
				</Typography>
				<Typography variant='headline_3' textAlign='right' mb={2} mt={8}>
					Утверждаю
					<br />
					Директор Аквапарка «Зербулак»
					<br />
					Каримбаев Б.А.
				</Typography>
				<Typography variant='headline_3' mb={2} mt={8}>
					Согласие на соблюдение Правил посещения Аквапарка «Зербулак»
				</Typography>
				<Box display='flex' flexDirection='column'>
					<Typography variant='body_2' mb={2}>
						Подписывая данный документ, Посетитель, дает свое согласие на соблюдение Правил посещения
						Аквапарка и несет полную ответственность за себя и за имеющихся с ним несовершеннолетних лиц, в
						соответствии, с настоящими Правилами и действующим законодательством Республики Казахстан.
						Данный документ, является «Приложение № 1» к основным Правилам посещения Аквапарка «Зербулак».
					</Typography>
					<Typography variant='body_2' style={{ fontWeight: 400 }}>
						1. Право посещения аквапарка «Зербулак» (далее - «Аквапарк») предоставляется только лицам,
						которые прошли регистрацию по средствам мобильного приложения. Исключение составляют лица,
						которые по техническим причинам не имеют возможность пройти регистрацию с согласия администрации
						Аквапарка.
						<br />
						2. В случае возникновения непредвиденных технических неполадок аттракционов либо отсутствия
						средств для спуска с горок, и как следствие, невозможности их использования, снижение цен на
						время пребывания в Аквапарке не осуществляется.
						<br />
						3. Посетители имеют право находиться на территории Аквапарка в установленные часы работы
						Аквапарка (с 09:00 до 21:00).
						<br />
						4. В целях предотвращения травматизма на скользких поверхностях и соблюдений личной гигиены,
						посетитель обязан использовать для передвижения по Аквапарку обувь на резиновой подошве.
						<br />
						5. В целях обеспечения личной безопасности, не разрешается пользоваться аттракционами и
						бассейнами Аквапарка в купальных костюмах с поясами, заклепками, пряжками, металлическими
						украшениями, Посетителям с ювелирными украшениями и др.
						<br />
						6. В целях обеспечения общественной безопасности и предотвращения террористических актов
						посетители обязаны предъявлять службе безопасности аквапарка содержимое личных вещей для
						проверки.
						<br />
						7. Для расчетов в заведениях на территории Аквапарка посетители использует приложение, где будет
						возможность проводить оплату с любых банковских карточных счетов.
						<br />
						8. Посетители должны выполнять требования сотрудников Аквапарка в отношении обеспечения
						безопасности, поддержания порядка и чистоты на территории Аквапарка.
						<br />
						9. Посетитель, приобретая билет в Аквапарк, имеет право на один вход и один выход через турникет
						Аквапарка. Для повторного входа Посетителю необходимо произвести оплату нового билета.
						<br />
						10. Вход Посетителей в Аквапарк прекращается за час до окончания его работы в связи с закрытием
						аттракционов (20:00).
						<br />
						11.Водно - развлекательные зоны и зоны оказания услуг общественного питания прекращают свое
						функционирование за 30 минут до закрытия Аквапарка.
						<br />
						12. В уличной обуви на территорию развлекательной зоны проходить запрещается. Так же купаться в
						бассейнах и спуск с горок разрешен только в специальной купальной одежде (купальники,
						плавательные шорты из синтетики).
						<br />
						13. Ценные вещи необходимо оставлять в личных шкафчиках, закрепленных за Посетителем.
						<br />
						14. Пройдя через турникет, Посетитель должен пройти в общую раздевалку с кабинками для
						переодевания, переодеться, сложить вещи в персональный шкафчик и закрыть его в соответствии с
						инструкцией, которая размещена на шкафчиках. Открывание ящика также осуществляется в
						соответствии с инструкцией.
						<br />
						15. Перед посещением аквазоны Аквапарка Посетитель должен принять душ. Не допускается
						использовать в душевых любые изделия из стекла (посуду, моющиеся и косметические средства в
						стеклянной таре и т.п.) во избежание порезов.
						<br />
						16. Запрещается пользоваться аттракционами Аквапарка Посетителям с сердечно сосудистыми и
						психоневрологическими заболеваниями, заболеваниями позвоночника и опорно-двигательного аппарата,
						а также другими заболеваниями. Беременным женщинам запрещается спускаться с водных горок.
						<br />
						17. Запрещается прыгать и нырять в воду с бортиков, удерживать друг друга под водой, кричать,
						использовать акробатические прыжки, задерживать дыхание под водой.
						<br />
						18. Во избежание падений и травм запрещается быстрая ходьба и бег на территории Аквапарка.
						<br />
						19. Запрещается спускаться с горок и пользоваться бассейнами Посетителям, не владеющих навыками
						плавания. Администрация не несет ответственности за безопасность таких Посетителей в случае
						нарушения ими данного Правила.
						<br />
						20. Запрещается спускаться с водных горок, лежа на животе, головой вперед или стоя. Посетитель
						обязан начинать спуск с горок ногами вперёд, сидя или лежа на спине.
						<br />
						21. Запрещается спускаться с водных горок по двое или более человек одновременно без специальных
						лодок. Спуск с горки можно начинать только после того, как горка освободится от людей, и
						предыдущий посетитель полностью завершил свой спуск и освободил зону финиша.
						<br />
						22. Запрещается спускаться с горок без подачи воды - то есть во всех случаях отключения
						автоматической подачи воды на горки.
						<br />
						23. Запрещается спускаться с горок лицам, находящимся в состоянии алкогольного или
						наркотического опьянения, а также находящимся под воздействием медицинских препаратов, которые
						понижают реакцию.
						<br />
						24. Порядок прохода на все аттракционы регулируют представители службы спасателей Аквапарка.
						Посетители должны выполнять любые просьбы или требования спасателей и других служащих Аквапарка
						в отношении обеспечения безопасности, поддержания порядка и чистоты на территории Аквапарка
						<br />
						25. Нельзя кататься парами, на тех горках, где предусмотрено размещение только одного человека.
						Ваши общие габариты могут превысить ширину туннеля или желоба, и вы можете застрять или создать
						на горке затор.
						<br />
						26.При спуске с горки нельзя держаться за желоба, спускаться стоя или на коленях, а также с
						растопыренными ногами и руками.
						<br />
						27. Спуск с горок в состояние алкогольного и наркотического опьянения запрещен.
						<br />
						28. Запрещена фото и видео съемка на профессиональное оборудование.
						<br />
						29. Запрещено подходить к бассейнам со стеклянной посудой ближе, чем на 10 метров.
						<br />
						30. С собой в аквазону нельзя брать косметику (уходовую и декоративную). Все необходимо оставить
						в шкафчике.
						<br />
						31. Дети до 18 лет имеют право посещать Аквапарк только в сопровождении взрослых, которые несут
						за них полную ответственность Один взрослый может сопровождать не более 2-х детей.
						<br />
						32. Запрещается вход Посетителей на территорию аквазоны Аквапарка (за пределы раздевалки) с
						пакетами, сумками и Т.Д.
						<br />
						33. Запрещается посещать Аквапарк лицам с открытыми ранами, инфекционными, кожными и другими
						видами заболеваний, а также при наличии медицинских повязок и лейкопластырей. Посетители
						Аквапарка, нарушившие данное правило, несут полную ответственность за ущерб, причиненный
						здоровью других Посетителей, и сопутствующие материальные расходы.
						<br />
						34. Курение запрещено на всей территории Аквапарка, кроме специально отведенных для этого мест.
						За курение в местах, не отведенных на этого, посетитель должен выплатить штраф, согласно
						Прейскуранта (10 000 тенге).
						<br />
						35. Не разрешается приносить в Аквапарк продукты питания и напитки.
						<br />
						36. Запрещается проносить в Аквапарк огнестрельное, газовое, пневматическое и холодное оружие,
						колюще-режущие предметы, а также взрывчатые и легковоспламеняющиеся, токсичные и сильно пахнущие
						вещества.
						<br />
						37. Посетители, находящиеся в состоянии алкогольного, наркотического или иного опьянения, в
						Аквапарк не допускаются.
						<br />
						38. Для предотвращения травматизма запрещается плавать с распушенными волосами.
						<br />
						39.3апрещается игнорировать указания о глубине бассейнов, инструкции и правила пользования
						горками и другими аттракционами (в случае непонимания - за разъяснениями просьба обращаться к
						спасателям).
						<br />
						40. В случае утраты или повреждения имущества Аквапарка по вине Посетителя, он обязан возместить
						причиненный ущерб, а также нести ответственность за иные допущенные им нарушения в соответствии
						с действующим законодательством РК.
						<br />
						41. Выданное Посетителю полотенце в аренду на платной основе, необходимо вернуть при выходе из
						Аквапарка, в пункт выдачи полотенец. За порчу или не сдачу полотенца штраф - 3000 (три тысячи)
						тенге за одно полотенце.
						<br />
						42. Администрация не несет ответственности за сохранность личных вещей и ювелирных украшений,
						оставленных в гардеробе и индивидуальных шкафчиках, а также вещей, оставленных без присмотра.
						Персонал Аквапарка уполномочен предпринимать меры против Посетителей находящихся в состоянии
						алкогольного или наркотического опьянения, в психически неуравновешенном состоянии, а также
						против Посетителей, не реагирующих на замечания персонала и не соблюдающих настоящие правила
						<br />
						43. Несоблюдение правил посещения Аквапарка или попытка помешать отдыху других Посетителей,
						является основанием для удаления Посетителя из Аквапарка, без какого либо денежного возмещения.
						<br />
						44. Посетитель дает согласие на соблюдение правил и несет полную ответственность за их нарушение
						в соответствии с настоящими Правилами и действующим законодательством РК.
						<br />
						45. Администрация не несет ответственности за полученные травмы, повреждения и ущерб, которые
						были получены Посетителями вследствие невыполнения или ненадлежащего выполнения данных Правил
						или при несоблюдении правил техники безопасности.
						<br />
						46. Штраф за справленные нужды, в не специально отведенных для этого местах - 15 000 тенге.
						<br />
						При нарушении посетителем Правил посещения Аквапарка и принудительном его удалении из Аквапарка,
						неиспользованная часть аванса НЕ ВОЗВРАЩАЕТСЯ.
						<br />С Правилами ознакомлен (а)__________________________«_____»_______2023 год.
					</Typography>
				</Box>
			</section>
			<section>
				<Typography variant='headline_3' textAlign='right' mb={2} mt={8}>
					Бекітемін
					<br />
					«Зербұлақ» Аквапаркінің басқарушысы
					<br />
					Каримбаев Б.А.
				</Typography>
				<Typography variant='headline_3' mb={2} mt={8}>
					«Зербұлақ» Аквапаркіне бару Ережелерін сактауға келісім.
				</Typography>
				<Box display='flex' flexDirection='column'>
					<Typography variant='body_2' mb={2}>
						Осы кұжатқа қол қоя отырып, Келуші аквапаркқа келу Ережелерін сақтауға өз келісімін береді және
						осы Ережеге және Қазақстан Республикасының қолданыстағы заңнамасына сәйкес өзі үшін және онымен
						бірге кәмелетке толмаған тұлғалар үшін толық жауапты болады. Бұл құжат «Зербұлақ» Аквапаркіне
						барудың негізгі Ережелеріне «№ 1 қосымша» болып табылады.
					</Typography>
					<Typography variant='body_2' style={{ fontWeight: 400 }}>
						1. «Зербұлақ» аквапаркіне (бұдан әрі - «Аквапарк») кіру құкығы тек мобилдік қосымша арқылы
						тіркелуден өткен тұлғаларға ғана беріледі. Техникалық себептерге байланысты Аквапарк
						әкімшілігінің келісімімен тіркелуге мүмкіндігі жоқ адамдар ерекшелік болып табылады.
						<br />
						2. Аттракциондарда күтпеген техникалық ақаулар пайда болған кезде немесе төбешіктерден түсуге
						арналған құралдар болмаған, соның салдарынан оларды пайдалану мүмкін болмаған жағдайда
						Аквапаркте болған уақытка бағаның төмендеуіне жүзеге асырылмайды.
						<br />
						3. Аквапаркқа келушілер Аквапарк жумысының белгіленген уақытында (сагат 09:00-ден 21:00-ге
						дейін) Аквапарк аумағында болуға құқылы.
						<br />
						4. Тайгак беттерде жаракаттануды болдырмау жоне жеке гигиенаны сактау максатында аквапарка
						келуші Аквапаркте козгалу ушін резенке табаны бар аяк кимді пайдалануга міндетті.
						<br />
						5. Жеке кауіпсіздікті камтамасыз ету максатында аквапарк атракциондары мен бассейндерінде
						белдіктері, тойтармалары, тогалары, металл ошекейлері бар суга тусу костюмдерінде, зергерлік
						эшекейлері бар келушілерге жоне т. б. пайдалануга руксат етілмейді.
						<br />
						6. Когамдык кауіпсіздікті камтамасыз ету жоне терористік актілерді болдырмау максатында
						келушілер аквапарк кауіпсіздік кызметіне тексеру ушін жеке заттарынын ішіндегісін керсетуге
						міндетті.
						<br />
						7. Аквапарк аумагындагы мекемелерде есеп айырысу ушін келушілер косымша акылар кассасындары
						электронды білезікке аванс енгізуі тиіс. Тапсыруды кайтару аванс сагат 20:00ге дейін енгізілген
						кассада тек чек усынылган кезде гана жургізіледі.
						<br />
						8. КР Азаматтык кодексінін 401-бабына сойкес корсетілген кызметтер ушін «Зербұлақ» аквапаркі
						акшаны кайтармайды.
						<br />
						9. Келушілер аквапарк кызметкерлерінін Аквапарк аумагында кауіпсіздікті камтамасыз етуге, тертіп
						пен тазалькты сактауга катысты талаптарын орындауга тиіс.
						<br />
						10. Аквапаркка билет сатып алган келуші Аквапарк турникеті аркылы бір кіруге жене бір шыгуга
						кукылы. Кайта кіру ушін Келуші жана билетті телеуі керек.
						<br />
						11. Аквапаркка Келушілердін кіруі онын жумысы аякталганга дейін бір сагат бурын токтатылады
						себебі Су-ойын-сауык аймактары жабылады(19:00).
						<br />
						12. Су-ойын-сауык аймактары жене когамдык тамактандыру кызметтерін керсету аймактары аквапарк
						жабылганга дейін 30 минут бурын оз кызметін токтатады.
						<br />
						13. Ойын-сауык аймарынын аумагына кеше аяк киімдерінен отуге тыйым сальнады. Сондай - ак
						бассейндерде жузуге жане сырганакпен тусуге тек арнайы шомылу киімімен руксат етіледі (шомылу
						киімі, синтетикалык шорт).
						<br />
						14. Кунды заттарды Келушіге бекітілген жеке шкафтарда калдыру керек.
						<br />
						15. Келуші турникет аркылы отіп, ким ауыстыру ушін кабинкалары бар жалпы киім ауыстыратын жерге
						кіру керек, киім ауыстырып, дербес шкафтарга жеке заттарын буктеп, оны шкафтарда орналастырылан
						нускаулыктарга сойкес жабуы тиіс. Жэшікті ашу нускаулыкка сэйкес жузеге асырылады.
						<br />
						16. Аквапарктін аквааймагына барар алдында Келуші душ кабылдауы керек. Себезгі белмелерінде
						кесілуді болдырмау ушін шыныдан жасалган кез келген буйымдарды (ыдыс, шыны дыстагы жулатын жоне
						косметикалык куралдар жене т.б.) пайдалануга жол берілмейді.
						<br />
						17. Аквапарк аттракциондарын журек кан тамырлары жане психоневрологиялык аурулары, омыртка жэне
						тірек-кимыл аппараты аурулары, сондай-ак баска да аурулары бар Келушілерге пайдалануга тыйым
						салынады. Жукті эйелдерге су тебешіктерінен тусуге тыйым салынады.
						<br />
						18. Буйірден суга секіруге жэне сунгуге, бір-бірін су астында устауга, айкайлауга, акробатикалык
						секіруге, су астында дем алуга тыйым салынады.
						<br />
						19. Кулау мен жаракаттануды болдырмау ушін Аквапарк аумагында жылдам журуге жэне жугіруге тыйым
						сальнады.
						<br />
						20. Жузу дагдылары жок Келушілерге тобешіктерден тусуге жоне бассейндерді пайдалануга тыйым
						салынады. Экімшілік мундай Келушілер осы Ережені бузган жагдайда олардын кауіпсіздігі ушін жауап
						бермейді.
						<br />
						21. Су тобешіктерінен ішпен жатып, баспен алга немесе турып тусуге тыйым салынады. Келуші
						тебешіктерден аянымен алга, отырып немесе аркада жатып тусуге міндетті.
						<br />
						22. Су тебешіктерінен бір мезгілде арнайы кайыктарсыз екі немесе одан да кеп адамнан тусуге
						тыйым сальнады. Тебешіктен тусуді тебешік адамдардан босатылганнан кейін гана бастауга болады
						жоне алдынны Келуші тусуді толырымен аяктап, мэре аймарын босятканнан кеишн гана.
						<br />
						23. Тобешіктерден су берместен тусуге тыйым салынады, ягни тобешіктерден автоматты турде су беру
						ошкен барлык жагдайларында.
						<br />
						24. Тобешіктерден алкогольдік немесе ссірткілік масан куйдегі, сондай-ак реакцияны томендететін
						медициналык препараттардын эсеріндегі адамдарга тусуге тыйым салынады.
						<br />
						25. Барлык атракциондарга оту тортібін Аквапарк куткару кызметінін окілдері реттейді. Келушілер
						Аквапарк зуматында кауіпсіздікті камтамасыз етуге, тартіп пен тазалыкты сактауга катысты
						аквапарк куткарушылары мен баска да кызметшлерінін кез келген отініштерін немесе талаптарын
						орындауы тиіс.
						<br />
						26. Сіз тек бір адамды орналастыру карастырылган тебекшіктерде жуппен журе алмайсыз. Сіздін
						жалпы елшемдерініз тунельдін немесе науанын енінен асып кетуі мумкін жене сіз тобеде кептеліс
						жасай аласьз.
						<br />
						27. Тебешіктен тускен кезде науаны устауга, турып немесе тізерлеп, сондай-ак аяктары мен колдары
						жайылган кезде тусуге болмайды.
						<br />
						28. Тебешіктерден алкогольдік жене есірткілік масан куйге тусуге тыйым салынады.
						<br />
						29. Кэсіби жабдыкка фото жэне видео тусіруге тыйым салынады.
						<br />
						30. Бассейндерге шыны ыдыстармен 10 метрден жакын жакындауга тыйым салынады.
						<br />
						31. Аквазонга озінізбен бірге косметика (кутім жене сондік) алуга болмайды. Барлыгын шкафта
						калдыру керек.
						<br />
						32. 18 жаска дейінгі балалар аквапаркка тек ересек адамдармен бірге баруга кукылы, балалар ушін
						олар толык жауап береді. Бір ересек адам 2 баладан артык баланы еріп журе аламайды.
						<br />
						33. Келушілердін аквапарк аквазоны аумагына (киім шешетін белмеден тыс) пакеттермен, семкелермен
						жене т. б. кіруіне тыйым салынады.
						<br />
						34. Аквапаркка ашык жаралы, жукпалы, тері жене баска да ауру турлері бар, сондай-ак медициналык
						тангыштар мен лейкопластырь болган жагдайда баруга тыйым сальнады. Осы Ережені бузган аквапаркка
						Келушілер баска Келушілердін денсаулыгына келтірілген зиян ушін жоне онымен байланысты
						материалдык шыгындар ушін толык жауап береді.
						<br />
						35. Аквапарк аумагила томекі шегуте тйтим сальнады, тек арнайы белінген орындардан баска. Бутан
						белінбеген орышдарда темекі шеккені ушін Келуші прейскурантка сойкес айыппул телеуі тиіс (10 000
						тенге).
						<br />
						36. Аквапаркка азык-тулік пен сусындар экелуге руксат етілмейді.
						<br />
						37. Аквапаркка атыс каруын, газды, пневматикалык жоне суык каруды, тесіп-кесетін заттарды,
						сондай-ак жарылыш жэне тез тутанатын, уытты жэне катты иісі бар заттарды экелуге тыйым салынады.
						<br />
						38. Алкогольдік, есірткілік немесе езге де масан куйдегі аквапаркка Келушілер жіберілмейді.
						<br />
						39. Жаракаттанудын алдын алу ушін шешілген шашпен жузуге тыйым салынады.
						<br />
						40. Бассейндердін терендігі туралы нускауларды, тобешіктер мен атракциондарды пайдалану
						нускаулыктары мен ережелерін елемеуге тыйым сальнады (тусінбеген жагдайда - тусініктеме алу ушін
						куткарушыларга жугіну кажет).
						<br />
						41. Аквапарк мулкін Келушінін кіносінен жогалткан немесе булдірген жадайда, ол келтірілген
						залалды отеуге, сондай-ак Казакстан Республикасынын колданыстаны заннамасына сойкес оган
						жіберілген озге де бузушылыктар ушін жауанты болуга міндетті.
						<br />
						42. Аквапаркка Келушіге берілген сулгіні аквапарктан шыккан кезде сулгі беру пунктіне кайтару
						кажет. Сулгіні булдіргені немесе тапсырмаганы ушін бір сулгі ушін 3000 (уш мын) тенге айыпул
						салынады.
						<br />
						43. Экімшілік гардеробта жоне жеке шкафтарда калдырылан жеке заттар мен зергерлік эшекейлердін,
						сондай-ак караусыз калдырылган заттардын сакталуына жауапты болмайды. Аквапарк персоналы
						алкогольдік немесе есірткілік масан куйлегі, психикалык тенгерімеіз куйдегі келушілерте карсы,
						сондай-ак персоналдын ескертулеріне жауап коймайтын жэне осы Ерержені сактамайтын келушілерге
						карсы шаралар кабылдауга укілетті.
						<br />
						44. Аквапаркка бару ережелерін сатама немесе баска келушілердін демалуына кедергі жасау рекеті
						Аквапарктан келушіні акшалай отемсіз шыгарып салуга негіз болы табылады.
						<br />
						45. Келуші ережелердін сакталуына келісім береді жене осы Ережелер мен Казакстан Республикасынын
						колданыстаны заннамасына сэйкес олардын бузылганы ушін толык жауап береді.
						<br />
						46. Экімшілік келушілердін осы Ережелерді орындамауы немесе тисінше орындамауы салдарынан немесе
						кауіпсіздік техникасы ережелерін сактамауы салдарынан алан жаракаттары, закымдары жэне залалдары
						ушін жауапты болмайды.
						<br />
						47. Арнайы белгіленбеген жерлерде женілдетілген кажеттіліктер ушін айыппул - 15 000 тенге.
						<br />
						Келуші аквапаркқа келу Ережелерін бұзған және оны Аквапарктан мәжбүрлеп алып тастаган жағдайда
						аванстың пайдаланылмаган бөлігі қайтарылмайды.
						<br />
						<br />
					</Typography>
					<Typography variant='body_2' mb={2}>
						ТОО «South holiday»
					</Typography>
					<Typography variant='body_2' mb={2}>
						Телефон: 8 707 139 3909, 8 701 037 9999
					</Typography>
					<Typography variant='body_2' mb={2}>
						Адрес: Казахстан, Аккия, улица А.Молдагулова, дом 8
					</Typography>
					<Typography variant='body_2' mb={2}>
						Электронная почта: <a href='mailto:toozerbulaq@mail.ru'> toozerbulaq@mail.ru</a>.
					</Typography>
					<Typography variant='body_2' mb={2}>
						Дата вступления в силу политики конфиденциальности: 01.06.2023 г.
					</Typography>
					<Typography variant='body_2' mb={2}>
						Спасибо за доверие и использование мобильного приложения "ZER" аквапарка &quot;ZERBULAQ&quot;!
					</Typography>
				</Box>
			</section>
		</Box>
	)
}
