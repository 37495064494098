import { Box, Button, Typography } from '@mui/material'
import { dateFormatter } from 'core/helpers/date-formatter'
import { ArrowBack } from 'core/icons'
import Cookies from 'js-cookie'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { HistoryVisitorsCardComponent } from './history-visitors-card'

export const EnterExitClientPage = () => {
	const navigate = useNavigate()
	const cardItem = JSON.parse(Cookies.get('enter-exit-history-client') ?? '')

	const [showType, setShowType] = useState('all')
	const onClickShow = (type: any) => () => {
		setShowType(type)
	}

	return (
		<div>
			<Box p={2} display='flex' alignItems='center'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%', marginRight: '16px' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
			</Box>
			<Box
				display='flex'
				flexDirection='column'
				p={3}
				mx='auto'
				sx={{
					width: 732,
					background: '#FFFFFF',
					border: '1px solid rgba(214, 214, 214, 0.48)',
					borderRadius: 1.5,
					cursor: 'pointer'
				}}
			>
				<Typography variant='headline_1' mb={1}>
					Данные клиента
				</Typography>
				<Box display='flex' justifyContent='space-between' alignItems='center' mb={4}>
					<Box display='flex' gap={4}>
						<Box display='flex' gap={1}>
							<Typography variant='body_2' sx={{ color: 'primary.gray', fontWeight: 500 }}>
								Дата входа:
							</Typography>
							<Typography variant='body_1'>{dateFormatter(new Date(cardItem.created_at))}</Typography>
						</Box>
					</Box>
				</Box>
				<Box display='flex' alignItems='center' gap={4}>
					<img
						style={{
							background: '#D9D9D9',
							borderRadius: '50%',
							width: 140,
							height: 140,
							objectFit: 'cover'
						}}
						src={cardItem.customer.avatar_url}
						alt='avatar'
					/>
					<Box display='flex' flexDirection='column' flexGrow={1} ml={2}>
						<Typography variant='title_1'>
							{cardItem.customer.first_name} {cardItem.customer.last_name}
						</Typography>
						<Box display='flex' gap={0.5}>
							<Typography variant='body_2' sx={{ color: 'primary.gray' }}>
								Тип билета:
							</Typography>
							<Typography variant='body_2' sx={{ color: 'primary.black' }}>
								{cardItem.ticket_type}
							</Typography>
						</Box>
						<Box display='flex' mt={1} py={1.75} gap={1}>
							<Box display='flex' flexDirection='column' sx={{ width: '50%' }}>
								<Typography variant='caption_1_regular' sx={{ color: 'primary.gray' }}>
									Дата рождения
								</Typography>
								<Typography variant='body_2' mt={0.25}>
									{moment(cardItem.customer.birth_date).format('DD.MM.YYYY')}
								</Typography>
							</Box>
							<Box display='flex' flexDirection='column'>
								<Typography variant='caption_1_regular' sx={{ color: 'primary.gray' }}>
									Пол
								</Typography>
								<Typography variant='body_2' mt={0.25}>
									{cardItem.customer.gender === 'male' ? 'Мужчина' : 'Женщина'}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
				{cardItem?.guests?.length > 0 && (
					<Box display='flex' flexDirection='column' mt={1} gap={1}>
						<Box display='flex' justifyContent='space-between' alignItems='center' py={1.25}>
							<Typography variant='title_3'>Другие посетители</Typography>
							<Box display='flex' gap={1}>
								<Button
									variant='outlined'
									sx={{
										border: '1px solid #181818',
										borderRadius: 4,
										height: 28,
										px: 1.5,
										textTransform: 'unset',
										letterSpacing: '-0.25px'
									}}
									onClick={onClickShow('all')}
								>
									<Typography variant='footnote_2' sx={{ color: 'primary.black' }}>
										Все
									</Typography>
								</Button>
								<Button
									variant='outlined'
									sx={{
										border: '1px solid #D6D6D6',
										borderRadius: 4,
										height: 28,
										px: 1.5,
										textTransform: 'unset',
										letterSpacing: '-0.5px'
									}}
									onClick={onClickShow('old')}
								>
									<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
										Взрослые - {cardItem?.guests.filter((quest: any) => !quest.is_child).length}
									</Typography>
								</Button>
								<Button
									variant='outlined'
									sx={{
										border: '1px solid #D6D6D6',
										borderRadius: 4,
										height: 28,
										px: 1.5,
										textTransform: 'unset',
										letterSpacing: '-0.5px'
									}}
									onClick={onClickShow('child')}
								>
									<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
										Дети - {cardItem?.guests.filter((quest: any) => quest.is_child).length}
									</Typography>
								</Button>
							</Box>
						</Box>
						{showType === 'all' && (
							<Box display='flex' flexWrap='wrap' gap={3}>
								{cardItem?.guests.map((visitor: any) => (
									<HistoryVisitorsCardComponent visitor={visitor} />
								))}
							</Box>
						)}
						{showType === 'old' && (
							<Box display='flex' flexWrap='wrap' gap={3}>
								{cardItem?.guests
									.filter((quest: any) => !quest.is_child)
									.map((visitor: any) => (
										<HistoryVisitorsCardComponent visitor={visitor} />
									))}
							</Box>
						)}
						{showType === 'child' && (
							<Box display='flex' flexWrap='wrap' gap={3}>
								{cardItem?.guests
									.filter((quest: any) => quest.is_child)
									.map((visitor: any) => (
										<HistoryVisitorsCardComponent visitor={visitor} />
									))}
							</Box>
						)}
					</Box>
				)}
			</Box>
		</div>
	)
}
