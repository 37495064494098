/* eslint-disable react/react-in-jsx-scope */
import {
	Box,
	Button,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography
} from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import { ArrowBack, Cancel } from 'core/icons'
import { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getPoints } from 'services/pointer/pointer.service'
import { createPrinter, getPrinterById, removePrinter, updatePrinter } from 'services/printer/printer.service'
import { getAllProducts } from 'services/product/product.service'

export const CheckPrintDetails = () => {
	const navigate = useNavigate()
	const { profile } = useApp()
	const { id } = useParams()

	const [allIssuePoints, setAllIssuePoints] = useState(false)
	const [allProducts, setAllProducts] = useState(false)
	const [point, setPoint] = useState('')

	const {
		control,
		register,
		formState: { errors },
		watch,
		handleSubmit,
		setValue
	} = useForm<any>({
		defaultValues: {
			issuePoints: [
				{
					issuePoint: point
				}
			],
			products: [
				{
					category: '',
					product: ''
				}
			]
		}
	})

	const {
		fields: issuePoints,
		append: onAppendIssuePoints,
		update: onUpdateIssuePoints,
		remove: onRemoveIssuePoints
	} = useFieldArray({
		control,
		name: 'issuePoints'
	})

	const {
		fields: products,
		append: onAppendProducts,
		update: onUpdateProducts,
		remove: onRemoveProducts
	} = useFieldArray({
		control,
		name: 'products'
	})

	const { data: points } = useQuery(['getPoints'], {
		queryFn: () => getPoints(),
		onSuccess: response => {
			const [first] = response.data
			setPoint(first.id)
		}
	})

	const { data: productsWithCategories } = useQuery(['getAllProducts', 1], {
		queryFn: () => getAllProducts({ page: 1 })
	})

	const { data: printer, status } = useQuery(['getPrinterById', id], {
		queryFn: () => getPrinterById(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})

	const { mutate: createPrinterMutation, isLoading: createLoading } = useMutation({
		mutationFn: ({ body }: any) => createPrinter({ body }),
		onSuccess: () => {
			navigate(`/printers`)
			toast.success('Принтер успешно создан!')
		}
	})

	const { mutate: updatePrinterMutation, isLoading: updateLoading } = useMutation({
		mutationFn: ({ printerId, body }: any) => updatePrinter({ printerId, body }),
		onSuccess: () => {
			navigate(`/printers`)
			toast.success('Принтер успешно обновлен!')
		}
	})

	const removePrinterMutation = useMutation({
		mutationFn: (printerId: any) => removePrinter(printerId),
		onSuccess: () => {
			navigate(`/printers`)
			toast.success('Принтер успешно удален!')
		}
	})

	const handleAllIssuePoints = () => {
		setAllIssuePoints(!allIssuePoints)
	}

	const handleAddIssuePoint = () => {
		onAppendIssuePoints({ issuePoint: point })
	}

	const handleRemoveIssuePoint = (issuePointId: number) => {
		onRemoveIssuePoints(issuePointId)
	}

	const handleAllProducts = () => {
		setAllProducts(!allProducts)
	}

	const handleAddProduct = () => {
		onAppendProducts({ issuePoint: point })
	}

	const handleRemoveProduct = (productId: number) => {
		onRemoveProducts(productId)
	}

	useEffect(() => {
		if (printer && status === 'success') {
			setValue('name', printer.data.name)
			setValue('host', printer.data.host)
			setValue('port', printer.data.port)
			printer.data.issue_points.forEach((value: any, index: number) => {
				onUpdateIssuePoints(index, { issuePoint: value.id })
			})
			printer.data.products.forEach((value: any, index: number) => {
				onUpdateProducts(index, {
					category: value.category.id,
					product: value.id
				})
			})

			if (printer.data.issue_points.length === 0) {
				setAllIssuePoints(true)
			}
			if (printer.data.products.length === 0) {
				setAllProducts(true)
			}
		}
	}, [printer, status, onUpdateIssuePoints])

	const onSubmit = (body: any) => {
		let allPointIds = []
		if (!allIssuePoints) {
			allPointIds = body.issuePoints.map((item: any) => item.issuePoint)
		}
		let allProductIds = []
		if (!allProducts) {
			allProductIds = body.products.map((item: any) => item.product)
		}

		if (location.search.indexOf('edit') > 0 && id !== '-1') {
			updatePrinterMutation({
				printerId: id,
				body: {
					name: body.name,
					host: body.host,
					port: body.port,
					issue_points: allPointIds ?? [],
					products: allProductIds ?? []
				}
			})
		}
		if (id === '-1') {
			createPrinterMutation({
				body: {
					name: body.name,
					host: body.host,
					port: body.port,
					issue_points: allPointIds ?? [],
					products: allProductIds ?? []
				}
			})
		}
	}

	const onDelete = () => {
		removePrinterMutation.mutate(id)
	}

	return (
		<>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Box display='flex' alignItems='center'>
					<Button
						color='info'
						variant='text'
						size='small'
						onClick={() => navigate(-1)}
						sx={{ border: 0, borderRadius: '50%', marginRight: '16px' }}
					>
						<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
					</Button>
				</Box>
				{profile?.user?.role.permissions.includes('update_printer') && (
					<Button
						variant='outlined'
						color='error'
						onClick={onDelete}
						disabled={location.search.indexOf('view') > 0}
					>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%', marginLeft: '40px' }}>
				<Box display='flex' justifyContent='center'>
					<Box sx={{ width: 2 / 4 }}>
						<Box my={4}>
							<Typography variant='headline_1'>Данные печати</Typography>
							<Typography variant='subtitle1'>Дата добавления:</Typography>
						</Box>
						<Box mb={2}>
							<TextField
								label='Наименование'
								placeholder='Введите наименование'
								required
								fullWidth
								type='text'
								disabled={location.search.indexOf('view') > 0}
								error={!!errors.name}
								{...register('name')}
							/>
						</Box>
						<Box>
							<Box mb={2}>
								<Typography variant='title_1'>Выберите точку выдачи</Typography>
							</Box>
							<Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
								<Typography variant='body1'>Указать все точки выдачи</Typography>
								<Switch
									checked={allIssuePoints}
									disabled={location.search.indexOf('view') > 0}
									onChange={handleAllIssuePoints}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							</Box>
							{issuePoints.map((issuePoint, index) => (
								<Box key={issuePoint.id} display='flex'>
									{points?.data && (
										<Box sx={{ position: 'relative', width: '100%' }} mb={1}>
											<Controller
												control={control}
												name={`issuePoints.${index}.issuePoint`}
												defaultValue={point}
												render={({ field: { ref, onBlur, name, ...fields } }) => (
													<FormControl required fullWidth>
														<InputLabel id='demo-simple-select-label'>
															Куда печатать?
														</InputLabel>
														<Select
															labelId='demo-simple-select-label'
															id='demo-simple-select'
															displayEmpty
															disabled={location.search.indexOf('view') > 0}
															{...fields}
														>
															{points?.data?.map((pointProps: any) => {
																return (
																	<MenuItem
																		value={Number(pointProps.id)}
																		key={pointProps.id}
																	>
																		{pointProps.address}
																	</MenuItem>
																)
															})}
														</Select>
													</FormControl>
												)}
											/>
											{index !== 0 && (
												<Button
													sx={{
														position: 'absolute',
														right: 0,
														minWidth: 24,
														zIndex: 1,
														alignItems: 'start'
													}}
													disabled={location.search.indexOf('view') > 0}
													onClick={() => handleRemoveIssuePoint(index)}
												>
													<Cancel />
												</Button>
											)}
										</Box>
									)}
								</Box>
							))}
							<Button
								variant='text'
								size='small'
								onClick={handleAddIssuePoint}
								disabled={location.search.indexOf('view') > 0}
							>
								Добавить еще
							</Button>
						</Box>
						<Box>
							<Box mb={2}>
								<Typography variant='title_1'>Выберите товары для печати</Typography>
							</Box>
							<Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
								<Typography variant='body1'>Указать все товары</Typography>
								<Switch
									checked={allProducts}
									onChange={handleAllProducts}
									disabled={location.search.indexOf('view') > 0}
								/>
							</Box>
							{products.map((product, index) => (
								<Box key={product.id} display='flex' justifyContent='space-between'>
									{productsWithCategories?.data && (
										<Box sx={{ position: 'relative', width: '100%' }} mb={1}>
											<Grid container spacing={2}>
												<Grid item xs={6}>
													<Controller
														control={control}
														name={`products.${index}.category`}
														render={({ field: { ref, onBlur, name, ...fields } }) => (
															<FormControl required fullWidth>
																<InputLabel id='demo-simple-select-label'>
																	Категория
																</InputLabel>
																<Select
																	labelId='demo-simple-select-label'
																	id='demo-simple-select'
																	displayEmpty
																	disabled={location.search.indexOf('view') > 0}
																	{...fields}
																>
																	{productsWithCategories?.data?.map(
																		(productCategory: any) => {
																			return (
																				<MenuItem
																					value={Number(productCategory.id)}
																					key={productCategory.id}
																				>
																					{productCategory.name}
																				</MenuItem>
																			)
																		}
																	)}
																</Select>
															</FormControl>
														)}
													/>
												</Grid>
												<Grid item xs={6}>
													<Controller
														control={control}
														name={`products.${index}.product`}
														defaultValue=''
														render={({ field: { ref, onBlur, name, ...fields } }) => {
															const categoryId = watch('products')[index].category
															const category = productsWithCategories?.data?.find(
																(item: any) => item.id === categoryId
															)

															return (
																<FormControl required fullWidth>
																	<InputLabel id='demo-simple-select-label'>
																		Товары
																	</InputLabel>
																	<Select
																		labelId='demo-simple-select-label'
																		id='demo-simple-select'
																		displayEmpty
																		disabled={location.search.indexOf('view') > 0}
																		{...fields}
																	>
																		{category?.products?.map((pointProps: any) => {
																			return (
																				<MenuItem
																					value={Number(pointProps.id)}
																					key={pointProps.id}
																				>
																					{pointProps.name}
																				</MenuItem>
																			)
																		})}
																	</Select>
																</FormControl>
															)
														}}
													/>
												</Grid>
											</Grid>
											{index !== 0 && (
												<Button
													sx={{
														position: 'absolute',
														right: 0,
														top: 0,
														minWidth: 24,
														zIndex: 1,
														alignItems: 'start'
													}}
													disabled={location.search.indexOf('view') > 0}
													onClick={() => handleRemoveProduct(index)}
												>
													<Cancel />
												</Button>
											)}
										</Box>
									)}
								</Box>
							))}
							<Button
								variant='text'
								size='small'
								onClick={handleAddProduct}
								disabled={location.search.indexOf('view') > 0}
							>
								Добавить еще
							</Button>
						</Box>
						<Box mb={2} mt={8}>
							<TextField
								label='IP адрес принтера'
								placeholder='Введите IP адрес'
								required
								fullWidth
								type='text'
								disabled={location.search.indexOf('view') > 0}
								error={!!errors.host}
								{...register('host')}
							/>
						</Box>
						<Box mb={2}>
							<TextField
								label='Порт'
								placeholder='Введите Порт'
								required
								fullWidth
								type='text'
								disabled={location.search.indexOf('view') > 0}
								error={!!errors.port}
								{...register('port')}
							/>
						</Box>
						{location.search.indexOf('view') <= 0 &&
							profile?.user?.role.permissions.includes('update_printer') && (
								<Button
									variant='contained'
									color='success'
									type='submit'
									disabled={createLoading || updateLoading}
								>
									Сохранить
								</Button>
							)}
					</Box>
				</Box>
			</form>
		</>
	)
}
