import { axiosBaseInstance } from 'core/axios.config'

export const getPrinters = async (params?: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/printers`, {
		params
	})
	return data
}

export const getPrinterById = async (id: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/printers/${id}`)
	return data
}

export const createPrinter = async ({ body }: any) => {
	const response = await axiosBaseInstance.post(`/back_office/printers`, body)
	return response.data
}

export const updatePrinter = async ({ printerId, body }: any) => {
	const response = await axiosBaseInstance.put(`/back_office/printers/${printerId}`, body)
	return response.data
}

export const removePrinter = async (id: any) => {
	const response = await axiosBaseInstance.delete(`/back_office/printers/${id}`)
	return response.data
}
