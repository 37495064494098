import { axiosBaseInstance } from 'core/axios.config'

export const getOrders = async ({ id, params }: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/orders/active/${id}`, {
		params
	})
	return data
}

export const getOrderById = async ({ id }: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/orders/${id}`)
	return data
}

export const getOrdersHistory = async (params: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/orders/`, {
		params
	})
	return data
}

export const createQrOrder = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/orders/generate/qr`, data)
	return v.data
}

export const makeQrPayed = async (uuid: any) => {
	const v = await axiosBaseInstance.post(`/back_office/orders/qr/${uuid}/make-payed`)
	return v.data
}

export const cancelOrder = async (id: any) => {
	const v = await axiosBaseInstance.post(`/back_office/orders/cancel/${id}`)
	return v.data
}

export const updateOrders = async (orderId: any) => {
	const { data } = await axiosBaseInstance.put(`/back_office/orders/${orderId}/`)
	return data
}
