import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	InputAdornment,
	TextField,
	Typography
} from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import { Lock, PhoneIphone } from 'core/icons'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { backOfficeLogin } from 'services/authorization/authorization.service'

export const LoginComponent = ({ phone: phoneProps, setStep }: any) => {
	const navigate = useNavigate()
	const { refetch } = useApp()
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<any>()
	const [phone, setPhone] = useState<any>(phoneProps)

	const loginMutation = useMutation({
		mutationFn: payload => backOfficeLogin(payload),
		onSuccess: response => {
			if (response?.data?.jwt?.access_token) localStorage.setItem('ACCESS_TOKEN', response.data.jwt.access_token)
			refetch()
			navigate('/clients')
		}
	})
	const onRestorePassword = () => {
		setStep('restore')
	}
	const onChangePhone = (e: any) => {
		setPhone(e.target.value)
	}

	const onSubmit = (data: any) => {
		const PhoneMask = phone.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')
		// @ts-ignore
		loginMutation.mutate({
			phone: PhoneMask,
			password: data.password
		})
	}
	return (
		<>
			<Typography
				variant='headline_1'
				mb={1}
				sx={{
					width: '100%'
				}}
			>
				Вход в систему
			</Typography>
			<Typography
				variant='footnote_1'
				mb={4}
				sx={{
					width: '100%'
				}}
			>
				Введите данные ниже для авторизации
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
				<Box mb={2}>
					<InputMask mask='9 (999) 999-99-99' maskChar=' ' onChange={onChangePhone} alwaysShowMask={false}>
						<TextField
							fullWidth
							label='Введите номер'
							type='text'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<PhoneIphone />
									</InputAdornment>
								)
							}}
						/>
					</InputMask>
				</Box>
				<Box mb={2}>
					<TextField
						label='Пароль'
						placeholder=''
						required
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<Lock />
								</InputAdornment>
							)
						}}
						type='password'
						error={!!errors.password}
						{...register('password')}
					/>
				</Box>

				<Box mb={2} display='flex' justifyContent='space-between'>
					<Button variant='text' size='small' onClick={onRestorePassword}>
						Забыли пароль?
					</Button>
					<Button variant='text' size='small' onClick={() => setStep('check')}>
						Регистрация
					</Button>
				</Box>

				<Box mb={2}>
					<FormControlLabel control={<Checkbox defaultChecked />} label='Запомнить меня' />
				</Box>
				<Button variant='contained' type='submit' fullWidth disabled={loginMutation.isLoading}>
					{loginMutation.isLoading ? <CircularProgress /> : 'Войти'}
				</Button>
			</form>
		</>
	)
}
