import { Box, Button, Card, CardContent, CardMedia, Divider, Grid, Link, Typography } from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getCategoryList } from 'services/food/food.service'
import { useDebounce } from 'usehooks-ts'

export const QrPage = () => {
	const navigate = useNavigate()
	const [search, setSearch] = React.useState('')

	const debouncedSearch = useDebounce(search, 500)
	const { data: categories } = useQuery(['getCategoryList', debouncedSearch], {
		queryFn: () =>
			getCategoryList({
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})
	const handleClick = () => {
		navigate('/qr/history')
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
	}

	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Продажа по QR</Typography>
				<Button onClick={handleClick} variant='outlined'>
					<span style={{ color: '#349EFF' }}>История</span>
				</Button>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='end' p={1}>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<Box sx={{ padding: '48px 96px' }}>
				<Grid container spacing={2}>
					{categories?.data.map((category: any) => (
						<Grid item xs={6} sm={6} md={3}>
							<Card
								sx={{
									maxWidth: 280,
									background: '#F7F7F7',
									borderRadius: '12px',
									position: 'relative',
									zIndex: '5'
								}}
							>
								<Link href={`qr/${category.id}/pay`} underline='none'>
									<CardMedia
										sx={{
											height: 160,
											width: 280,
											border: '1px solid #F7F7F7',
											borderRadius: '12px'
										}}
										image={category.img_path}
										title={category.name}
									/>
								</Link>
								<Link href={`qr/${category.id}/pay`} underline='none' color='inherit'>
									<CardContent>
										<Typography gutterBottom variant='body_1' component='div'>
											{category.name}
										</Typography>
										<Typography variant='footnote_2' color='text.secondary'>
											{category.products_count} товаров
										</Typography>
									</CardContent>
								</Link>
							</Card>
						</Grid>
					))}
				</Grid>
			</Box>
		</div>
	)
}
