import { axiosBaseInstance } from 'core/axios.config'

export const getPoints = async () => {
	const { data } = await axiosBaseInstance.get(`/back_office/issue_points/`)
	return data
}

export const addPoint = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/issue_points/`, data)
	return v.data
}

export const deletePoint = async (issue_point_id: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/issue_points/${issue_point_id}/`)
	return v.data
}

export const getPointId = async (issue_point_id: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/issue_points/${issue_point_id}`)
	return data
}

export const updatePoint = async ({ issue_point_id, body }: any) => {
	const { data } = await axiosBaseInstance.post(`back_office/issue_points/${issue_point_id}/`, body)
	return data
}

export const setPresets = async (issue_point_id: any) => {
	const { data } = await axiosBaseInstance.post(`back_office/employee_filter_presets/`, {
		issue_point_id
	})
	return data
}

export const getPresets = async () => {
	const { data } = await axiosBaseInstance.get(`back_office/employee_filter_presets/`)
	return data
}
