import { axiosBaseInstance } from 'core/axios.config'

export const getTickets = async () => {
	const { data } = await axiosBaseInstance.get(`/back_office/tickets/`)
	return data
}

export const addTickets = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/tickets/`, data)
	return v.data
}

export const getTicketsId = async (ticket_id: any) => {
	const v = await axiosBaseInstance.get(`/back_office/tickets/${ticket_id}/`)
	return v.data
}

export const getTicketsByDates = async (categoryId: any, date: any) => {
	const v = await axiosBaseInstance.get(`/back_office/ticket/category/dates/${categoryId}?date=${date}`)
	return v.data
}
export const getStatsByDates = async (categoryId: any, date: any, end_date: any) => {
	const v = await axiosBaseInstance.get(
		`/back_office/ticket/category/statistics/${categoryId}?start_date=${date}&end_date=${end_date}`
	)
	return v.data
}

export const postTicketsByDates = async (data: any) => {
	const v = await axiosBaseInstance.put(`/back_office/ticket/category/dates`, data)
	return v.data
}

export const putTicketsByPeriod = async (data: any) => {
	const v = await axiosBaseInstance.put(`/back_office/ticket/category/period`, data)
	return v.data
}

export const deleteTicket = async (ticket_id: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/tickets/${ticket_id}/`)
	return v.data
}

export const updateTickets = async ({ ticket_id, body }: any) => {
	const { data } = await axiosBaseInstance.put(`/back_office/tickets/${ticket_id}/`, body)
	return data
}
export const getTicketsById = async (ticket_id: any) => {
	const v = await axiosBaseInstance.get(`/back_office/customers/tickets/findByQrText/${ticket_id}/`)
	return v.data
}

export const activateTickets = async (customerTicket: any) => {
	const { data } = await axiosBaseInstance.put(`/back_office/customers/tickets/${customerTicket}/activate/`)
	return data
}

export const deActivateTickets = async (customerTicket: any) => {
	const { data } = await axiosBaseInstance.put(`/back_office/customers/tickets/${customerTicket}/deactivate/`)
	return data
}
