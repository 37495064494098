export enum OperationStatusText {
	outcome = 'Списание',
	income = 'Пополнение'
}

export enum OperationDetailStatusText {
	outcome = 'Списание',
	income = 'Пополнение счета'
}

export enum OperationStatusColor {
	income = '#24D321',
	outcome = '#FF9A3D'
}

export enum StatusText {
	success = 'Успешно',
	income = 'Отменена',
	failure = 'Ошибка'
}

export enum StatusColor {
	success = '#24D321',
	outcome = '#FF9A3D',
	failure = '#e33e0b'
}
export enum StatusBackgroundColor {
	success = '#98FB98',
	outcome = '#FF9A3D',
	fail = '#FF9A3D'
}
