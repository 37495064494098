export enum HistoryTopchanStatusText {
	success = 'Оплачено',
	returned = 'Возвращен',
	received = 'Получен',
	canceled = 'Отменен'
}

export enum HistoryTopchanStatusColor {
	success = '#24D321',
	returned = '#ED4141',
	received = '#24D321',
	canceled = '#ED4141'
}

export enum TopchansManageStatusColor {
	busy = '#FF9A3D',
	free = '#349EFF'
}

export enum TopchansManageStatusText {
	busy = 'Занятый',
	free = 'Свободно'
}

export enum TopchansManageTypeText {
	ordinary = 'Обычный',
	vip = 'VIP'
}
