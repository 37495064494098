export const editProducts = [
	{
		id: 1,
		rating: 4.7,
		name: 'Coca Cola (0,5 л) без сахара',
		price: 450,
		img_path: 'https://api.zerbulakfamily.kz/storage/products/QZ8CjtQzaRsq6wSO4uIylxHCvMBd7hRaoDuCuPsL.jpg'
	},
	{
		id: 2,
		rating: 4.7,
		name: 'Coca Cola (0,5 л) без сахара',
		price: 450,
		img_path: 'https://api.zerbulakfamily.kz/storage/products/QZ8CjtQzaRsq6wSO4uIylxHCvMBd7hRaoDuCuPsL.jpg'
	},
	{
		id: 3,
		rating: 4.7,
		name: 'Coca Cola (0,5 л) без сахара',
		price: 450,
		img_path: 'https://api.zerbulakfamily.kz/storage/products/QZ8CjtQzaRsq6wSO4uIylxHCvMBd7hRaoDuCuPsL.jpg'
	},
	{
		id: 4,
		rating: 4.7,
		name: 'Coca Cola (0,5 л) без сахара',
		price: 450,
		img_path: 'https://api.zerbulakfamily.kz/storage/products/QZ8CjtQzaRsq6wSO4uIylxHCvMBd7hRaoDuCuPsL.jpg'
	}
]

export const addedProducts = [
	{
		id: 1,
		name: 'Coca Cola (0,5 л) без сахара',
		count: 1,
		price: 450
	},
	{
		id: 2,
		name: 'Шашлыки 2 палки баранина',
		count: 2,
		price: 600
	},
	{
		id: 3,
		name: 'Хлеб корзина',
		count: 1,
		price: 300
	},
	{
		id: 4,
		name: 'Fanta',
		count: 3,
		price: 900
	}
]
