import { Box, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

export const HistoryVisitorsCardComponent = ({ visitor }: any) => {
	return (
		<Box
			display='flex'
			flexDirection='column'
			py={1.5}
			sx={{
				width: 596
			}}
		>
			<Box display='flex' alignItems='center' gap={1}>
				<img
					style={{
						background: '#D9D9D9',
						width: 140,
						height: 140,
						objectFit: 'cover',
						borderRadius: '50%'
					}}
					src={visitor.avatar_url}
					alt='avatar'
				/>
				<Box display='flex' flexDirection='column' ml={2} gap={0.25}>
					<Typography variant='title_1'>
						{visitor.first_name} {visitor.last_name}
					</Typography>
					<Box display='flex' gap={0.5}>
						<Typography variant='body_2' sx={{ color: 'primary.gray' }}>
							Тип билета:
						</Typography>
						<Typography variant='body_2' sx={{ color: 'primary.black' }}>
							{visitor.ticket_type ?? '-'}
						</Typography>
					</Box>
					<Box display='flex' mt={1} py={1.75} gap={1}>
						<Box display='flex' flexDirection='column' sx={{ width: '50%' }}>
							<Typography variant='caption_1_regular' sx={{ color: 'primary.gray' }}>
								Дата рождения
							</Typography>
							<Typography variant='body_2' mt={0.25}>
								{moment(visitor.birth_date).format('DD.MM.YYYY')}
							</Typography>
						</Box>
						<Box display='flex' flexDirection='column'>
							<Typography variant='caption_1_regular' sx={{ color: 'primary.gray' }}>
								Пол
							</Typography>
							<Typography variant='body_2' mt={0.25}>
								{visitor.gender === 'male' ? 'Мужчина' : 'Женщина'}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
