import { Box, Button, Divider, Tab, Tabs, Typography } from '@mui/material'
import { BASE_API } from 'core/axios.config'
import { Document } from 'core/icons'
import moment from 'moment'
import { AnalyticsDialog } from 'pages/HistoryPayment/analytics-dialog'
import React from 'react'
import { downloadFile } from 'services/report/report.service'

import { TicketsManageDates } from './tickets-manage-dates.components'
import { TicketsManageStats } from './tickets-manage-stats.components'

export const TicketsManagePage = () => {
	const [tab, setTab] = React.useState('1')
	const [radioValue, setRadioValue] = React.useState('all')
	const [openModal, setOpenModal] = React.useState(false)

	const handleOpenModal = () => setOpenModal(true)
	const handleCloseModal = () => setOpenModal(false)

	const [startDate, setStartDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'))
	const [endDate, setEndDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'))

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRadioValue((event.target as HTMLInputElement).value)
	}

	const onChangeStartDate = (e: any) => {
		setStartDate(e.target.value)
	}
	const onChangeEndDate = (e: any) => {
		setEndDate(e.target.value)
	}

	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setTab(newValue)
	}

	const handleSubmitReport = () => {
		let dates = ''
		if (radioValue === 'range') {
			dates = `?start_date=${startDate}&end_date=${endDate}`
		}
		const url = `${BASE_API}/back_office/reports/ticket${dates}`
		downloadFile(url, 'ticketsReport')
		handleCloseModal()
	}

	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Управление билетами</Typography>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={handleOpenModal}
					sx={{ border: 0, borderRadius: '50%', marginRight: 5 }}
				>
					<Document style={{ marginRight: '12px' }} />
					Скачать отчет
				</Button>
			</Box>
			<Divider />
			<Box sx={{ width: '100%' }} mb={2} py={2} display='flex' justifyContent='center'>
				<Tabs value={tab} onChange={handleChangeTab} aria-label='wrapped label tabs example'>
					<Tab value='1' label='Управление' wrapped />
					<Tab value='2' label='Статистика' />
				</Tabs>
			</Box>
			<Divider />
			{tab === '1' && <TicketsManageDates />}
			{tab === '2' && <TicketsManageStats />}
			<AnalyticsDialog
				openModal={openModal}
				handleCloseModal={handleCloseModal}
				value={radioValue}
				handleChange={handleChange}
				title='Скачивание отчета по продажам билетов'
				startDate={startDate}
				onChangeStartDate={onChangeStartDate}
				endDate={endDate}
				onChangeEndDate={onChangeEndDate}
				handleSubmit={handleSubmitReport}
			/>
		</div>
	)
}
