import { axiosBaseInstance } from 'core/axios.config'

export const getTowelUseHistory = async (params?: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/towel-use/history/`, {
		params
	})
	return data
}

export const getTowelReturnRequest = async () => {
	const { data } = await axiosBaseInstance.get(`/back_office/towel-use/requests/`)
	return data
}

// TODO replace with detail query when backend is ready
export const getTowelDetailRequest = async (id?: string) => {
	const { data } = await axiosBaseInstance.get(`/back_office/towel-use/requests/`)
	return data.data.find((n: any) => n.id.toString() === id) ?? null
}

export const cancelTowelUseReturn = async (id: number) => {
	const v = await axiosBaseInstance.post(`/back_office/towel-use/return/${id}/cancel/`)
	return v.data
}

export const confirmTowelUseReturn = async (id: number) => {
	const v = await axiosBaseInstance.post(`/back_office/towel-use/return/${id}/confirm/`)
	return v.data
}

export const getTowelTenants = async () => {
	const { data } = await axiosBaseInstance.get(`/back_office/towel-use/tenants/`)
	return data
}
