import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	InputAdornment,
	Modal,
	TextField,
	Typography
} from '@mui/material'
import EditAvatar from 'components/avatar/EditAvatar'
import { useApp } from 'core/contexts/profile.context'
import { PhoneIphone } from 'core/icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import VerificationInput from 'react-verification-input'
import { backOfficeOtp, backOfficeOtpCheck } from 'services/authorization/authorization.service'
// eslint-disable-next-line import/no-extraneous-dependencies
import { updateProfile } from 'services/profile/profile.service'

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	borderRadius: '12px',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #D6D6D6',
	boxShadow: 24,
	p: 4
}
export const ProfilePage = () => {
	const navigate = useNavigate()
	const { profile, refetch } = useApp()
	const [image, setImage] = useState<any>()
	const [fileImage, setFileImage] = useState<any>()
	const [phone, setPhone] = useState<any>()
	const [otp, setOtp] = useState('')
	const [dirty, setDirty] = useState<any>()
	const [minutes, setMinutes] = useState(1)
	const [seconds, setSeconds] = useState(60)
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm<any>({
		mode: 'onChange',
		reValidateMode: 'onChange'
	})
	const [open, setOpen] = React.useState(false)
	const otpMutation = useMutation({
		mutationFn: (payload: any) => backOfficeOtp(payload),
		onSuccess: () => {
			setMinutes(1)
			setSeconds(60)
		}
	})
	const profileUpdateMutation = useMutation({
		mutationFn: (body: any) => updateProfile(body),
		onSuccess: () => {
			refetch()
			toast.success('Изменения успешно сохранены!')
		}
	})
	const checkOtpMutation = useMutation({
		mutationFn: payload => backOfficeOtpCheck(payload),
		onSuccess: () => {
			setOpen(false)
			setDirty(false)
		}
	})
	const onSubmitCheckOtp = () => {
		setSeconds(60)
		setMinutes(1)

		const PhoneMask = phone.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')
		// @ts-ignore
		checkOtpMutation.mutate({
			otp,
			phone: PhoneMask
		})
	}
	const onSubmit = (formValues: any) => {
		const fd: any = new FormData()
		const PhoneMask = phone?.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')
		const numberPhone = Number(PhoneMask)
		if (fileImage) fd.append('avatar', fileImage)
		fd.append('email', formValues.email)
		fd.append('phone', numberPhone)
		if (otp) fd.append('otp', otp)
		if (dirty) {
			setOpen(true)
			otpMutation.mutate({
				phone: PhoneMask
			})
		} else {
			profileUpdateMutation.mutate(fd)
		}
	}

	const handleClose = () => {
		setOpen(false)
	}

	useEffect(() => {
		if (profile) {
			setImage(profile?.user.avatar_url)
			setValue('avatar', profile?.user.fileImage)
			setValue('first_name', profile?.user.first_name)
			setValue('last_name', profile?.user.last_name)
			setValue('birth_date', profile?.user.birth_date)
			setPhone(profile?.user.phone)
			setValue('iin', profile?.user.iin)
			setValue('email', profile?.user.email)
		}
	}, [setValue, profile])

	const onAvatarUpload = (file: any) => {
		setFileImage(file)
		setImage(URL.createObjectURL(file))
	}
	const onLogout = () => {
		localStorage.removeItem('ACCESS_TOKEN')
		navigate('/login')
	}
	const onChangePhone = (e: any) => {
		setPhone(e.target.value)
	}

	const onChangeOtp = (value: any) => {
		setOtp(value)
	}
	const onResendSubmit = () => {
		const PhoneMask = phone?.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')
		// @ts-ignore
		otpMutation.mutate({
			phone: PhoneMask
		})
	}

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1)
			}

			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(interval)
				} else {
					setSeconds(59)
					setMinutes(minutes - 1)
				}
			}
		}, 1000)

		return () => {
			clearInterval(interval)
		}
	}, [seconds])

	useEffect(() => {
		if (profile?.user.phone && phone) {
			const PhoneMask = phone?.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')
			const numberPhone = Number(PhoneMask)
			if (numberPhone !== Number(profile?.user.phone)) {
				setDirty(true)
			}
		}
	}, [phone, profile])

	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Typography variant='title_2'>Мой профиль</Typography>
				<Button variant='outlined' color='error' onClick={onLogout}>
					Выйти
				</Button>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={824}>
					<Typography variant='headline_1'>Мои данные</Typography>
					{profile && (
						<Box display='flex'>
							<Typography variant='body1' color='grey' mr={2}>
								ID: <strong>{profile.id}</strong>
							</Typography>
							<Typography variant='body1' color='grey'>
								Дата добавления: <strong>{moment(profile.created_at).format('DD MMM YYYY')}</strong>
							</Typography>
						</Box>
					)}
					<Box display='flex' mt={6}>
						<EditAvatar src={image} onUpload={onAvatarUpload} />
						<form
							noValidate
							style={{ width: '100%', marginLeft: '40px' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Box mb={2} mt={4}>
								<Typography variant='title_1'>Персональные данные</Typography>
								{profile && (
									<Grid container rowSpacing={1} my={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item xs={6}>
											<Box display='flex' flexDirection='column'>
												<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
													Имя
												</Typography>
												<Typography>{profile?.user?.first_name}</Typography>
											</Box>
										</Grid>
										<Grid item xs={6}>
											<Box display='flex' flexDirection='column'>
												<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
													Фамилия
												</Typography>
												<Typography>{profile?.user?.last_name}</Typography>
											</Box>
										</Grid>
										<Grid item xs={6}>
											<Box display='flex' flexDirection='column'>
												<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
													ИИН
												</Typography>
												<Typography>{profile?.user?.iin}</Typography>
											</Box>
										</Grid>
										<Grid item xs={6}>
											<Box display='flex' flexDirection='column'>
												<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
													Роль
												</Typography>
												<Typography>{profile?.user?.role.name}</Typography>
											</Box>
										</Grid>
									</Grid>
								)}
								<Typography variant='title_1'>Контакты</Typography>
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<InputMask
											mask='9 (999) 999-99-99'
											maskChar=''
											maskPlaceholder=''
											onChange={onChangePhone}
											value={phone}
											alwaysShowMask={false}
										>
											<TextField
												fullWidth
												label='Введите номер'
												type='text'
												InputProps={{
													startAdornment: (
														<InputAdornment position='start'>
															<PhoneIphone />
														</InputAdornment>
													)
												}}
											/>
										</InputMask>
									</Grid>
									<Grid item xs={6}>
										<TextField
											label='Почта'
											placeholder=''
											required
											fullWidth
											type='email'
											{...register('email', {
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message: 'Неправильная почта'
												}
											})}
											helperText={!!errors.email && 'Неправильная почта'}
											error={!!errors.email}
											{...register('email')}
										/>
									</Grid>
								</Grid>
							</Box>
							{/* <Box display="flex" justifyContent="start" my={6}> */}
							{/*  <Button>Изменить пароль</Button> */}
							{/* </Box> */}

							<Button
								variant='contained'
								type='submit'
								color='success'
								style={{
									width: '252px'
								}}
							>
								Сохранить
							</Button>
						</form>
					</Box>
				</Box>
			</Box>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<Typography
						variant='headline_1'
						mb={1}
						sx={{
							width: '100%'
						}}
					>
						Введите код
					</Typography>
					<Typography
						variant='footnote_1'
						mb={4}
						sx={{
							width: '100%'
						}}
					>
						На номер +{phone} придет код, введите его ниже
					</Typography>
					<Box my={2}>
						<VerificationInput
							length={4}
							validChars='0-9'
							onComplete={onChangeOtp}
							placeholder=''
							classNames={{
								container: 'otp-container',
								character: 'otp-character',
								characterInactive: 'otp-character--inactive',
								characterSelected: 'otp-character--selected'
							}}
							inputProps={{ inputMode: 'numeric' }}
						/>
					</Box>
					<Box mb={2}>
						<Button variant='text' size='small' onClick={onResendSubmit}>
							<Typography
								variant='button_1'
								color={minutes === 0 && seconds === 0 ? 'primary.main' : 'secondary.gray'}
							>
								Отправить код через{' '}
								{seconds ? `0${minutes}:${seconds < 10 ? `0${seconds}` : seconds}` : ''}
							</Typography>
						</Button>
					</Box>
					<Button
						onClick={onSubmitCheckOtp}
						disabled={checkOtpMutation.isLoading || otp?.length !== 4}
						variant='contained'
						type='submit'
						fullWidth
					>
						{checkOtpMutation.isLoading ? <CircularProgress /> : 'Продолжить'}
					</Button>
				</Box>
			</Modal>
		</Box>
	)
}
