export enum TowelHistoryStatusText {
	received = 'Получен',
	not_passed_in_time = 'Не сдано за день',
	returned = 'Сдано'
}

export enum TowelHistoryStatusColor {
	received = '#007aff',
	returned = '#24D321',
	not_passed_in_time = '#ED4141'
}

export enum TowelDetailStatusText {
	received = 'Получен',
	waiting_for_return = 'Не сдано',
	waiting_for_receive = 'Ожидание получения',
	returned = 'Сдано'
}
