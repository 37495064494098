import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography
} from '@mui/material'
import { Add, Foods, Remove } from 'core/icons'
import { splitAmount } from 'core/utils'
import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { createQrOrder, makeQrPayed } from 'services/orders/orders.service'
import { getPoints, getPresets, setPresets } from 'services/pointer/pointer.service'

export const FoodAddComponent = ({ addedProducts, setAddedProducts }: any) => {
	const [qrImg, setQrImg] = useState<any>()
	const [point, setPoint] = useState<any>()
	const [serviceFee, setServiceFee] = useState<boolean>(false)
	const onClickClearProducts = () => {
		setAddedProducts([])
		localStorage.removeItem('products')

		setPoint(null)
		localStorage.removeItem('orderPoint')
	}
	const createQrMutation = useMutation({
		mutationFn: (payload: any) => createQrOrder(payload),
		onSuccess: res => {
			setQrImg(JSON.stringify(res.data))
			onClickClearProducts()
			localStorage.removeItem('products')
			toast.success('Заказ успешно добавлен!')
		}
	})

	const makePayedMutation = useMutation({
		mutationFn: (payload: any) => makeQrPayed(payload),
		onSuccess: () => {
			onClickClearProducts()
			localStorage.removeItem('products')
			toast.success('Заказ успешно добавлен!')
		}
	})

	const { mutate: createQrMutationAndMakePayed, isLoading: makePayedLoading } = useMutation({
		mutationFn: (payload: any) => createQrOrder(payload),
		onSuccess: res => {
			const { uuid } = res.data
			makePayedMutation.mutate(uuid)
		}
	})

	const { data: points } = useQuery(['getPoints'], {
		queryFn: () => getPoints()
	})

	const { data: presets } = useQuery(['getPresets'], {
		queryFn: () => getPresets()
	})

	const setPresetsMutate = useMutation({
		mutationFn: (point_id: any) => setPresets(point_id)
	})

	useEffect(() => {
		const orderPoint = localStorage.getItem('orderPoint') ?? null
		if (orderPoint) {
			setPoint(parseInt(orderPoint, 10))
		}
	}, [])

	useEffect(() => {
		if (point) {
			const orderPoint = String(point)
			localStorage.setItem('orderPoint', orderPoint)
		}
	}, [point, setPoint])

	useEffect(() => {
		const productsJSON = localStorage.getItem('products') ?? '[]'
		setAddedProducts(JSON.parse(productsJSON) || [])
	}, [])

	useEffect(() => {
		if (addedProducts) {
			const productsJSON = JSON.stringify([...addedProducts])
			localStorage.setItem('products', productsJSON)
		}
	}, [addedProducts, setAddedProducts])

	const handleDecrement = (product: any) => () => {
		const productIndex = addedProducts.findIndex((obj: any) => obj.id === product.id)
		if (product.count > 1) {
			const updatedObj = {
				...addedProducts[productIndex],
				count: product.count - 1
			}
			setAddedProducts([
				...addedProducts.slice(0, productIndex),
				updatedObj,
				...addedProducts.slice(productIndex + 1)
			])
		} else {
			setAddedProducts([...addedProducts.slice(0, productIndex), ...addedProducts.slice(productIndex + 1)])
		}
	}

	const handleIncrement = (product: any) => () => {
		const productIndex = addedProducts.findIndex((obj: any) => obj.id === product.id)
		const updatedObj = {
			...addedProducts[productIndex],
			count: product.count + 1
		}
		setAddedProducts([
			...addedProducts.slice(0, productIndex),
			updatedObj,
			...addedProducts.slice(productIndex + 1)
		])
	}

	const handlePointChange = (event: SelectChangeEvent) => {
		setPoint(event.target.value)
		setPresetsMutate.mutate(event.target.value)
	}

	const onClickAddQr = () => {
		if (point === null) {
			toast.error('Выберите точку продажи!', {
				position: toast.POSITION.TOP_RIGHT,
				theme: 'dark'
			})
			return
		}

		if (addedProducts?.length > 0) {
			const productsArr = addedProducts.map((n: any) => {
				return {
					product_id: n.id,
					quantity: n.count
				}
			})
			const data = {
				products: productsArr,
				issue_point_id: point,
				service: serviceFee
			}
			createQrMutation.mutate(data)
		}
	}

	const onClickPayed = () => {
		if (point === null) {
			toast.error('Выберите точку продажи!', {
				position: toast.POSITION.TOP_RIGHT,
				theme: 'dark'
			})
			return
		}

		if (addedProducts?.length > 0) {
			const productsArr = addedProducts.map((n: any) => {
				return {
					product_id: n.id,
					quantity: n.count
				}
			})
			const data = {
				products: productsArr,
				issue_point_id: point,
				service: serviceFee
			}
			createQrMutationAndMakePayed(data)
		}
	}

	useEffect(() => {
		if (points?.data) {
			const presetPointId = presets?.data?.issue_point_id
			if (presetPointId) {
				setPoint(presetPointId)
			} else {
				setPoint(points?.data[0].id)
			}
		}
	}, [points, presets?.data?.issue_point_id, setPoint])

	const sum = addedProducts
		? addedProducts.reduce((accumulator: any, object: any) => {
				return accumulator + (object?.price || 0) * (object?.count || 0)
		  }, 0)
		: 0

	return (
		<Box
			sx={{
				width: '100%',
				minHeight: '100vh',
				border: '1px solid rgba(214, 214, 214, 0.48)',
				borderRadius: 1.5
			}}
		>
			<Box p={2} display='flex' alignItems='center'>
				<Typography variant='title_2'>Добавленные товары</Typography>
			</Box>
			<Divider />
			{/* eslint-disable-next-line no-nested-ternary */}
			{qrImg ? (
				<Box p={3} display='flex' alignItems='center' justifyContent='center'>
					<QRCode
						size={128}
						style={{ height: 'auto', maxWidth: '50%', width: '50%' }}
						value={qrImg}
						viewBox='0 0 128 128'
					/>
				</Box>
			) : !(addedProducts?.length > 0) ? (
				<Box
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					sx={{ padding: '196px 96px', textAlign: 'center' }}
				>
					<Foods />
					<Typography variant='title_2' my={1}>
						Нет добавленных товаров
					</Typography>
					<Typography variant='footnote_1' color='primary.gray'>
						Чтобы создать заказ, добавьте товары что отображены слева
					</Typography>
				</Box>
			) : (
				<Box display='flex' flexDirection='column' px={4} py={2}>
					{points?.data && (
						<FormControl required fullWidth>
							<InputLabel id='demo-simple-select-label'>Точка выдачи</InputLabel>
							<Select
								labelId='demo-simple-select-label'
								id='demo-simple-select'
								value={point}
								onChange={handlePointChange}
								displayEmpty
							>
								{points?.data?.map((pointProps: any) => {
									return (
										<MenuItem value={Number(pointProps.id)} key={pointProps.id}>
											{pointProps.address}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					)}
					{addedProducts?.map((addedProduct: any) => (
						<Box
							key={`added-product-${addedProduct?.id}`}
							display='flex'
							alignItems='center'
							py={2}
							sx={{ borderBottom: '1px solid #D7D7D7' }}
						>
							<img
								style={{
									background: '#D9D9D9',
									width: 60,
									height: 60,
									borderRadius: 8
								}}
								src={addedProduct.img_path}
								alt='avatar'
							/>
							<Box display='flex' flexDirection='column' flexGrow={1} ml={1.5} gap={0.25}>
								<Typography variant='caption_1' color='primary.gray'>
									{addedProduct.name}
								</Typography>
								<Typography variant='body_2'>{addedProduct.price} ₸</Typography>
							</Box>
							<Box display='flex' alignItems='center' gap={1.5}>
								<Button
									variant='contained'
									size='small'
									sx={{ minWidth: 32, width: 32, background: '#F7F7F7' }}
									onClick={handleDecrement(addedProduct)}
								>
									<Remove style={{ flexShrink: 0, color: '#575757' }} />
								</Button>
								<Typography variant='title_2'>{addedProduct.count}</Typography>
								<Button
									variant='contained'
									size='small'
									sx={{ minWidth: 32, width: 32, background: '#E2F1FF' }}
									onClick={handleIncrement(addedProduct)}
								>
									<Add style={{ flexShrink: 0, color: '#349EFF' }} />
								</Button>
							</Box>
						</Box>
					))}
				</Box>
			)}
			{!qrImg && addedProducts?.length > 0 && (
				<Box sx={{ padding: '32px 48px' }} mt={4}>
					<Divider />
					<Box display='flex' justifyContent='space-between' alignItems='center' mt={2}>
						<FormControlLabel
							control={<Checkbox checked={serviceFee} onClick={() => setServiceFee(!serviceFee)} />}
							label={
								<Typography variant='body2' color='textSecondary'>
									10% за обслуживание
								</Typography>
							}
						/>
						<Typography variant='body_2'>{serviceFee ? splitAmount(sum * 0.1) : 0} ₸</Typography>
					</Box>
					<Box display='flex' justifyContent='space-between' mt={2}>
						<Typography variant='title_2'>Итого</Typography>
						<Typography variant='title_2'>
							{serviceFee ? splitAmount(sum + sum * 0.1) : splitAmount(sum)} ₸
						</Typography>
					</Box>
					<Box display='flex' justifyContent='space-between' alignContent='center' mt={2}>
						<Button variant='contained' onClick={onClickAddQr}>
							Получить qr
						</Button>
						<Button variant='contained' onClick={onClickPayed} disabled={makePayedLoading}>
							Оплачено
						</Button>
					</Box>
					<br></br>
					<Divider></Divider>
					<br></br>
					<Box display='flex' justifyContent='center' alignContent='center'>
						<Button variant='text' onClick={onClickClearProducts}>
							Очистить
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	)
}
