import { axiosBaseInstance } from 'core/axios.config'

export const getCategoryList = async (params: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/product/categories`, {
		params
	})
	return data
}
export const getCategoryById = async (id: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/product/categories/${id}`)
	return data
}

export const createCategory = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/product/categories`, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return v.data
}

export const deleteCategory = async ({ category_id, reason }: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/product/categories/${category_id}/`, {
		data: {
			reason
		}
	})
	return v.data
}

export const updateCategory = async ({ category_id, body }: any) => {
	const { data } = await axiosBaseInstance.post(`/back_office/product/categories/${category_id}/`, body, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return data
}
