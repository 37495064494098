import { Box, Button, Card, CardContent, CardMedia, Grid, Pagination, Typography } from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { useApp } from 'core/contexts/profile.context'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getProducts } from 'services/product/product.service'
import { useDebounce } from 'usehooks-ts'

export const QrFoodsComponent = ({ addedProducts, setAddedProducts }: any) => {
	const { id } = useParams()
	const [page, setPage] = React.useState(1)
	const [search, setSearch] = React.useState('')
	const { profile } = useApp()

	const debouncedSearch = useDebounce(search, 500)
	const { data: products } = useQuery(['getProducts', page, debouncedSearch, id], {
		queryFn: () =>
			getProducts({
				page,
				search: debouncedSearch.length > 0 ? debouncedSearch : null,
				id
			})
	})
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
		setPage(1)
	}

	const onAddClick = (product: any) => () => {
		const productParse = {
			id: product.id,
			img_path: product.img_path,
			name: product.name,
			price: product.price,
			count: 1
		}
		if (addedProducts && addedProducts?.length > 0) {
			const exitsProduct = addedProducts?.find((n: any) => n.id === product.id)
			if (!exitsProduct) {
				setAddedProducts([...addedProducts, productParse])
				const productsJSON = JSON.stringify([...addedProducts, productParse])
				localStorage.setItem('products', productsJSON)
			}
		} else {
			const productsJSON = JSON.stringify(productParse)
			localStorage.setItem('products', productsJSON)
			setAddedProducts([productParse])
		}
	}

	return (
		<Box
			sx={{
				padding: '24px 48px',
				width: '100%',
				border: '1px solid rgba(214, 214, 214, 0.48)',
				borderRadius: 1.5,
				minHeight: '100vh'
			}}
		>
			<Box display='flex' justifyContent='center' alignContent='center'>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Box display='flex' justifyContent='center' alignContent='center'>
				<Grid container spacing={1}>
					{products?.data.map((product: any) => (
						<Grid item xs={9} sm={9} md={6}>
							<Card
								sx={{
									maxWidth: 280,
									background: '#F7F7F7',
									borderRadius: '12px',
									position: 'relative'
								}}
							>
								<CardMedia
									sx={{
										height: 160,
										width: 280,
										border: '1px solid #F7F7F7',
										borderRadius: '12px'
									}}
									image={product.img_path}
									title={product.name}
								/>
								<CardContent>
									<Typography gutterBottom variant='caption_2' component='div'>
										Рейтинг: <span style={{ color: '#24D321' }}>{product.rating}</span>
									</Typography>
									<Typography
										gutterBottom
										variant='footnote_2'
										component='div'
										color='text.secondary'
									>
										{product.name}
									</Typography>
									<Typography variant='body_2'>{product.price} ₸</Typography>
								</CardContent>
								{profile?.user?.role.permissions.includes('update_manage_buy_qr') && (
									<Box
										display='flex'
										justifyContent='center'
										alignContent='center'
										mb={2}
										onClick={onAddClick(product)}
									>
										<Button>Добавить</Button>
									</Box>
								)}
							</Card>
						</Grid>
					))}
				</Grid>
			</Box>
			{products?.last_page && (
				<Box display='flex' justifyContent='center'>
					<Pagination
						sx={{ marginTop: '16px' }}
						count={products.last_page}
						page={page}
						onChange={handleChange}
					/>
				</Box>
			)}
		</Box>
	)
}
