import { axiosBaseInstance } from 'core/axios.config'

export const getTicketsTypes = async () => {
	const { data } = await axiosBaseInstance.get(`/back_office/ticket/types`)
	return data
}

export const addTicketsTypes = async (body: any) => {
	const { data } = await axiosBaseInstance.post(`/back_office/ticket/types`, body)
	return data
}

export const getTicketTypesId = async (type_id: any) => {
	const v = await axiosBaseInstance.get(`/back_office/ticket/types/${type_id}/`)
	return v.data
}

export const deleteTicketTypes = async (type_id: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/ticket/types/${type_id}/`)
	return v.data
}

export const updateTicketsTypes = async ({ type_id, body }: any) => {
	const { data } = await axiosBaseInstance.put(`/back_office/ticket/types/${type_id}/`, body)
	return data
}
