import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField
} from '@mui/material'
import React from 'react'

export const AnalyticsDialog = (props: any) => {
	const {
		openModal,
		handleCloseModal,
		value,
		handleChange,
		title,
		startDate,
		onChangeStartDate,
		endDate,
		onChangeEndDate,
		handleSubmit
	} = props

	return (
		<Dialog
			open={openModal}
			onClose={handleCloseModal}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>Укажите период отчета</DialogContentText>
				<Box mt={2}>
					<RadioGroup
						aria-labelledby='demo-controlled-radio-buttons-group'
						name='controlled-radio-buttons-group'
						value={value}
						onChange={handleChange}
					>
						<FormControlLabel value='all' control={<Radio />} label='За все время' />
						<FormControlLabel value='range' control={<Radio />} label='Диапазон дат' />
					</RadioGroup>
					{value === 'range' && (
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									label='Начало периода'
									placeholder=''
									required
									fullWidth
									value={startDate}
									onChange={onChangeStartDate}
									type='date'
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label='Конец периода'
									placeholder=''
									required
									value={endDate}
									onChange={onChangeEndDate}
									fullWidth
									type='date'
								/>
							</Grid>
						</Grid>
					)}
				</Box>
			</DialogContent>
			<DialogActions
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'end'
				}}
			>
				<Button onClick={handleSubmit} variant='text' color='success' style={{ marginBottom: '16px' }}>
					Подтвердить
				</Button>
				<Button onClick={handleCloseModal} autoFocus variant='text' color='error'>
					Отменить
				</Button>
			</DialogActions>
		</Dialog>
	)
}
