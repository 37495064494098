import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputAdornment,
	Radio,
	RadioGroup,
	TextField,
	Typography
} from '@mui/material'
import { ArrowBack } from 'core/icons'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import { createClosets, deleteClosets, getCustomerActiveClosets, updateClosets } from 'services/closets/closets.service'
import { useBoolean } from 'usehooks-ts'

export const LockersDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const [searchParams] = useSearchParams()
	const defaultCategory = searchParams.get('category')
	const {
		register,
		handleSubmit,
		control,
		reset,
		setValue,
		getValues,
		watch,
		formState: { errors }
	} = useForm<any>({
		defaultValues: {
			category: defaultCategory ?? 'male'
		}
	})
	const watchGender = watch('category')
	const watchNumber = watch('number')
	const modalOpen = useBoolean(false)
	const [reasonText, setReasonText] = React.useState('')

	const closetMutation = useMutation({
		mutationFn: payload => createClosets(payload),
		onSuccess: () => {
			toast.success('Шкаф успешно добавлен!')
			navigate(`/lockers?category=${getValues('category')}`)
			reset()
		}
	})
	const closetUpdateMutation = useMutation({
		mutationFn: ({ closet_id, body }: any) => updateClosets({ closet_id, body }),
		onSuccess: () => {
			toast.success('Изменения успешно сохранены!')
			navigate('/lockers')
		}
	})

	const { data: closet } = useQuery(['getCustomerActiveClosetsId', id], {
		queryFn: () => getCustomerActiveClosets(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})
	const deleteClosetMutate = useMutation({
		mutationFn: ({ closet_id, reason }: any) => deleteClosets({ closet_id, reason }),
		onSuccess: () => {
			toast.success('Шкаф успешно удален!')
			navigate('/lockers')
		}
	})
	const onSubmit = (data: any) => {
		if (watchGender === 'male' && watchNumber >= 1000 && watchNumber <= 1999) {
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions
			location.search.indexOf('edit') > 0
				? closetUpdateMutation.mutate({ closet_id: id, body: data })
				: closetMutation.mutate(data)
		} else if (watchGender === 'female' && watchNumber >= 2000 && watchNumber <= 2999) {
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions
			location.search.indexOf('edit') > 0
				? closetUpdateMutation.mutate({ closet_id: id, body: data })
				: closetMutation.mutate(data)
		} else {
			toast.error('Номер не правильный!')
		}
	}

	const handleTapchanDelete = () => {
		if (reasonText) {
			deleteClosetMutate.mutate({ closet_id: id, reason: reasonText })
		} else {
			toast.error('Нужнa причина!')
		}
	}

	const onChangeReason = (e: any) => {
		setReasonText(e.target.value)
	}

	useEffect(() => {
		if (closet) {
			setValue('category', closet.data.category)
			setValue('number', closet.data.number)
			setValue('board_id', closet.data.board_id)
			setValue('branch_id', closet.data.branch_id)
			setValue('locker_id', closet.data.locker_id)
		}
	}, [setValue, closet])

	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={modalOpen.setTrue}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={520}>
					<Typography variant='headline_1'>
						{location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0
							? 'Данные шкафа'
							: 'Добавление шкафа'}
					</Typography>
					{closet?.data && (
						<Box display='flex'>
							<Typography variant='body1' color='grey' mr={2}>
								ID: <strong>{closet.data.id}</strong>
							</Typography>
							<Typography variant='body1' color='grey'>
								Дата добавления: <strong>{moment(closet.data.created_at).format('DD MMM YYYY')}</strong>
							</Typography>
						</Box>
					)}
					<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
						<Controller
							control={control}
							name='category'
							render={({ field: { ref, onBlur, name, ...field } }) => (
								<FormControl fullWidth disabled={location.search.indexOf('view') > 0}>
									<FormLabel id='demo-radio-buttons-group-label' style={{ margin: '16px 0' }}>
										<Typography variant='title_1'>Тип шкафа</Typography>
									</FormLabel>
									<RadioGroup
										aria-labelledby='demo-radio-buttons-group-label'
										defaultValue=''
										{...field}
									>
										<FormControlLabel value='male' control={<Radio />} label='Мужской' />
										<Divider />
										<FormControlLabel value='female' control={<Radio />} label='Женский' />
									</RadioGroup>
								</FormControl>
							)}
						/>
						{closet?.data && (
							<Box display='flex' flexDirection='column' mb={2} mt={4}>
								<Typography variant='caption_1_regular'>Статус</Typography>
								<Typography variant='body_1'>{closet.data.status}</Typography>
							</Box>
						)}
						<Box mb={2} mt={4}>
							<TextField
								label='Номер'
								placeholder=''
								required
								fullWidth
								type='number'
								helperText={`Введите номер от ${watchGender === 'male' ? '1000' : '2000'} до ${
									watchGender === 'male' ? '1999' : '2999'
								} включительно`}
								disabled={location.search.indexOf('view') > 0}
								error={!!errors.number}
								{...register('number')}
							/>
						</Box>
						<Box mb={2} mt={4}>
							<TextField
								label='Номер борда'
								placeholder=''
								required
								disabled={location.search.indexOf('view') > 0}
								fullWidth
								type='number'
								error={!!errors.board_id}
								{...register('board_id')}
							/>
						</Box>
						<Box mb={2} mt={4}>
							<TextField
								label='Идентификатор планшета'
								placeholder=''
								required
								disabled={location.search.indexOf('view') > 0}
								fullWidth
								type='number'
								error={!!errors.branch_id}
								{...register('branch_id')}
							/>
						</Box>
						<Box mb={2}>
							<TextField
								label='ID замка'
								placeholder=''
								required
								fullWidth
								type='number'
								disabled={location.search.indexOf('view') > 0}
								InputProps={{
									startAdornment: <InputAdornment position='start'>id</InputAdornment>
								}}
								error={!!errors.locker_id}
								{...register('locker_id')}
							/>
						</Box>
						{location.search.indexOf('view') < 0 && (
							<Button variant='contained' type='submit' sx={{ width: '252px' }} color='success'>
								{closetMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
							</Button>
						)}
					</form>
				</Box>
				<Dialog
					open={modalOpen.value}
					onClose={modalOpen.setFalse}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
						</DialogContentText>
						<Box mt={2}>
							<TextField
								label='Причина удаления'
								placeholder=''
								fullWidth
								multiline
								rows={3}
								type='text'
								value={reasonText}
								onChange={onChangeReason}
								name='reason'
							/>
						</Box>
					</DialogContent>
					<DialogActions
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'end'
						}}
					>
						<Button
							onClick={modalOpen.setFalse}
							variant='text'
							color='success'
							style={{ marginBottom: '16px' }}
						>
							Нет, оставить
						</Button>
						<Button
							onClick={handleTapchanDelete}
							autoFocus
							variant='text'
							disabled={!reasonText}
							color='error'
						>
							Да, удалить
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
		</Box>
	)
}
