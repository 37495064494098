import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Switch,
	TextField,
	Typography
} from '@mui/material'
import { ArrowBack } from 'core/icons'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import {
	addTicketsTypes,
	deleteTicketTypes,
	getTicketTypesId,
	updateTicketsTypes
} from 'services/ticketTypes/tickets-types.service'
import { useBoolean } from 'usehooks-ts'

export const TicketsTypeDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const {
		register,
		handleSubmit,
		reset,
		control,
		setValue,
		formState: { errors }
	} = useForm<any>()
	const modalOpen = useBoolean(false)
	const [isKids, setIsKids] = useState(false)

	const ticketsMutation = useMutation({
		mutationFn: payload => addTicketsTypes(payload),
		onSuccess: () => {
			reset()
			navigate('/tickets')
			toast.success('Successfully added')
		}
	})
	const typeUpdateMutation = useMutation({
		mutationFn: ({ type_id, body }: any) => updateTicketsTypes({ type_id, body }),
		onSuccess: () => {
			toast.success('Изменения успешно сохранены!')
			navigate('/tickets?tab=4')
		}
	})
	const deleteTypeMutate = useMutation({
		mutationFn: (type_id: any) => deleteTicketTypes(type_id),
		onSuccess: () => {
			toast.success('Тип успешно удален!')
			navigate('/tickets?tab=4')
		}
	})
	const { data: type } = useQuery(['getTapchanId', id, location], {
		queryFn: () => getTicketTypesId(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})

	const onSubmit = (data: any) => {
		const formData = {
			...data,
			vip: data.type === 'vip',
			is_kids: isKids
		}
		console.log(formData)
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		location.search.indexOf('edit') > 0
			? typeUpdateMutation.mutate({ type_id: id, body: formData })
			: ticketsMutation.mutate(formData)
	}
	const handleTypeDelete = () => {
		deleteTypeMutate.mutate(id)
	}

	const handleChangeIsKid = (event: any) => {
		setIsKids(event.target.checked)
	}

	useEffect(() => {
		if (type?.data) {
			setValue('name', type.data.name)
			setValue('type', type.data.vip ? 'vip' : 'ordinary')
			setValue('age_from', type.data.age_from)
			setValue('age_to', type.data.age_to)
			setValue('gender', type.data.gender)
			setValue('is_kids', type.data.is_kids)

			setIsKids(type.data.is_kids)
		}
	}, [setValue, type])
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={modalOpen.setTrue}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={520}>
					<Typography variant='headline_1'>Данные типа билета</Typography>
					<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
						<Box mb={2} mt={4}>
							<TextField
								label='Наименование'
								placeholder=''
								required
								disabled={location.search.indexOf('view') > 0}
								fullWidth
								error={!!errors.name}
								{...register('name')}
							/>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField
										label='Возраст от'
										placeholder=''
										required
										disabled={location.search.indexOf('view') > 0}
										fullWidth
										type='number'
										error={!!errors.age_from}
										{...register('age_from')}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										label='Возраст до'
										placeholder=''
										required
										fullWidth
										disabled={location.search.indexOf('view') > 0}
										type='number'
										error={!!errors.age_to}
										{...register('age_to')}
									/>
								</Grid>
							</Grid>

							<Controller
								control={control}
								name='gender'
								defaultValue=''
								render={({ field: { ref, onBlur, name, ...field } }) => (
									<FormControl required fullWidth>
										<InputLabel id='demo-simple-select-label'>Пол</InputLabel>
										<Select
											disabled={location.search.indexOf('view') > 0}
											labelId='demo-simple-select-label'
											id='demo-simple-select'
											{...field}
										>
											<MenuItem value='male'>Мужской</MenuItem>
											<MenuItem value='female'>Женский</MenuItem>
										</Select>
									</FormControl>
								)}
							/>

							<FormControlLabel
								value='is_kids'
								control={
									<Switch
										color='primary'
										disabled={location.search.indexOf('view') > 0}
										name='is_kids'
										required
										checked={isKids}
										onChange={handleChangeIsKid}
									/>
								}
								sx={{ marginLeft: 0 }}
								label={
									<Typography variant='footnote_2' color='secondary.black'>
										Детский
									</Typography>
								}
								labelPlacement='start'
							/>
						</Box>
						<Box mb={3}>
							<Controller
								control={control}
								name='type'
								defaultValue=''
								render={({ field: { ref, onBlur, name, ...field } }) => (
									<FormControl fullWidth disabled={location.search.indexOf('view') > 0}>
										<FormLabel id='demo-radio-buttons-group-label' style={{ margin: '16px 0' }}>
											<Typography variant='title_1' color='black'>
												Признак билета
											</Typography>
										</FormLabel>
										<RadioGroup
											aria-labelledby='demo-radio-buttons-group-label'
											defaultValue='ordinary'
											{...field}
										>
											<FormControlLabel value='ordinary' control={<Radio />} label='Обычный' />
											<Divider />
											<FormControlLabel value='vip' control={<Radio />} label='VIP' />
										</RadioGroup>
									</FormControl>
								)}
							/>
						</Box>
						{location.search.indexOf('view') < 0 && (
							<Button variant='contained' type='submit' fullWidth color='success'>
								{ticketsMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
							</Button>
						)}
					</form>
				</Box>
			</Box>

			<Dialog
				open={modalOpen.value}
				onClose={modalOpen.setFalse}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={modalOpen.setFalse}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleTypeDelete} autoFocus variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
