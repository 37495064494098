import { Box, Button, Typography } from '@mui/material'
import { CheckCircleFilled, UncheckedRadioBtn } from 'core/icons'
import React, { useState } from 'react'

export const ProductsListComponent = ({ products, orderType }: any) => {
	const [countToShow, setCountToShow] = useState(3)

	const onClickShowMore = () => {
		setCountToShow(products.length)
	}

	return (
		<Box display='flex' flexDirection='column' gap={0.75}>
			{products.slice(0, countToShow).map((product: any) => (
				<Box display='flex' alignItems='center'>
					{orderType === 'in_process' && product.product.status === 'ready_product' && (
						<CheckCircleFilled style={{ marginRight: '4px' }} />
					)}
					{orderType === 'in_process' && product.product.status === 'cook_product' && (
						<UncheckedRadioBtn style={{ marginRight: '4px' }} />
					)}
					<Typography key={`${product.product.name}-${product.quantity}`} variant='caption_1'>
						{product.quantity}х - {product.product.name}
					</Typography>
				</Box>
			))}
			{products.length > countToShow && (
				<Button size='small' sx={{ justifyContent: 'start' }} onClick={onClickShowMore}>
					Показать еще
				</Button>
			)}
		</Box>
	)
}
