import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	TextField,
	Typography
} from '@mui/material'
import { ArrowBack } from 'core/icons'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import QRCode from 'react-qr-code'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addPoint, deletePoint, getPointId, updatePoint } from 'services/pointer/pointer.service'
import { useBoolean } from 'usehooks-ts'

export const PointerDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm<any>()

	const modalOpen = useBoolean(false)
	const [qrUrl, setQrUrl] = React.useState('')

	const pointMutation = useMutation({
		mutationFn: payload => addPoint(payload),
		onSuccess: () => {
			reset()
			navigate('/order_issuing')
			toast.success('Точка успешно добавлен!')
		}
	})

	const pointUpdateMutation = useMutation({
		mutationFn: ({ issue_point_id, body }: any) => updatePoint({ issue_point_id, body }),
		onSuccess: () => {
			navigate('/order_issuing')
			toast.success('Изменения успешно сохранены!')
		}
	})

	const { data: point } = useQuery(['getPointId', id, location], {
		queryFn: () => getPointId(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})
	const deletePointMutate = useMutation({
		mutationFn: (issue_point_id: any) => deletePoint(issue_point_id),
		onSuccess: () => {
			navigate('/order_issuing')
			toast.success('Тапчан успешно удален!')
		}
	})
	const onSubmit = (data: any) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		location.search.indexOf('edit') > 0
			? pointUpdateMutation.mutate({ issue_point_id: id, body: data })
			: pointMutation.mutate(data)
	}

	const handlePointDelete = () => {
		deletePointMutate.mutate(id)
	}

	const onClickDownload = () => {
		const svg = document.getElementById('QRCode-pointer-detail')
		// @ts-ignore
		const svgData = new XMLSerializer().serializeToString(svg)
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		const img = new Image()
		img.onload = () => {
			canvas.width = img.width * 2.5
			canvas.height = img.height * 2.5
			// @ts-ignore
			ctx.fillStyle = '#FFFFFF'
			// @ts-ignore
			ctx.fillRect(0, 0, 640, 640)
			// @ts-ignore
			ctx.drawImage(img, 70, 70, 500, 500)
			const pngFile = canvas.toDataURL('image/png')
			const downloadLink = document.createElement('a')
			downloadLink.download = 'QRCode-pointer-detail'
			downloadLink.href = `${pngFile}`
			downloadLink.click()
		}
		img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
	}

	useEffect(() => {
		if (point) {
			setQrUrl(JSON.stringify({ type: 'issue_point', id: point.data.id }))
			setValue('number', point.data.number)
			setValue('address', point.data.address)
			setValue('qr_url', point.data.qr_url)
		}
	}, [setValue, point])
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={modalOpen.setTrue}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box
					mt={6}
					width={location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0 ? 728 : 424}
				>
					<Typography variant='headline_1'>
						{location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0
							? 'Данные точки для выдачи'
							: 'Добавление точки для выдачи'}
					</Typography>
					{point?.data && (
						<Box display='flex'>
							<Typography variant='body1' color='grey' mr={2}>
								ID: <strong>{point.data.id}</strong>
							</Typography>
							<Typography variant='body1' color='grey'>
								Дата добавления: <strong>{moment(point.data.created_at).format('DD MMM YYYY')}</strong>
							</Typography>
						</Box>
					)}
					<Box display='flex' mt={6}>
						{(location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0) && (
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								mt={3}
								sx={{
									width: 264,
									height: 264,
									borderColor: 'red',
									borderRadius: '12px'
								}}
							>
								{qrUrl && (
									<Box>
										<QRCode
											id='QRCode-pointer-detail'
											size={254}
											style={{
												height: 'auto',
												maxWidth: '100%',
												width: '100%',
												marginBottom: 32
											}}
											value={qrUrl}
											viewBox='0 0 254 254'
										/>
									</Box>
								)}
								<Button onClick={onClickDownload}>Печать</Button>
							</Box>
						)}
						<form
							onSubmit={handleSubmit(onSubmit)}
							noValidate
							style={{ width: '100%', marginLeft: '40px' }}
						>
							<Box mt={4}>
								<TextField
									label='Номер'
									placeholder=''
									required
									disabled={location.search.indexOf('view') > 0}
									fullWidth
									type='number'
									error={!!errors.number}
									{...register('number')}
								/>
							</Box>
							<Box mb={6}>
								<TextField
									label='Адрес'
									placeholder=''
									required
									disabled={location.search.indexOf('view') > 0}
									fullWidth
									type='price'
									error={!!errors.address}
									{...register('address')}
								/>
							</Box>
							<Box mb={2}>
								<TextField
									label='Цвет'
									placeholder=''
									disabled={location.search.indexOf('view') > 0}
									required
									sx={{
										width: '100px',
										height: '100px',
										borderRadius: '50%'
									}}
									defaultValue='#E2F1FF'
									type='color'
									error={!!errors.color}
									{...register('color')}
								/>
							</Box>

							{location.search.indexOf('view') < 0 && (
								<Button
									variant='contained'
									type='submit'
									fullWidth
									color='success'
									sx={{ width: '252px' }}
								>
									{pointMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
								</Button>
							)}
						</form>
					</Box>
				</Box>
			</Box>

			<Dialog
				open={modalOpen.value}
				onClose={modalOpen.setFalse}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={modalOpen.setFalse}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handlePointDelete} autoFocus variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
