import { Box, Button, Divider, Typography } from '@mui/material'
import { ChevronRight } from 'core/icons'
import { EntryExitCards } from 'pages/EnterExit/enter-exit-cars.component'
import { parseLockers } from 'pages/Lockers/parse'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getTourniquets } from 'services/tourniquet/tourniquet.service'

import { tickets } from './mock'

export const EnterExitPage = () => {
	const navigate = useNavigate()

	const { data: tourniquets } = useQuery(['getTourniquets'], {
		queryFn: () =>
			getTourniquets({
				per_page: 9999
			})
	})
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Управление входа/выхода</Typography>
				<Box display='flex'>
					<Button
						onClick={() => navigate('/management_enter_exit/history')}
						variant='outlined'
						sx={{ marginRight: '8px' }}
					>
						<span style={{ color: '#349EFF' }}>История посещения</span>
					</Button>
					<Button onClick={() => navigate('/management_enter_exit/activate&deactivate')} variant='contained'>
						<span>(де-) Активировать</span>
					</Button>
				</Box>
			</Box>
			<Divider />
			<Box display='flex' sx={{ borderBottom: '0.5px solid #D7D7D7' }}>
				{tourniquets?.data &&
					parseLockers(tourniquets.data).map((ticket: any, index: number) => (
						<Box
							display='flex'
							flexDirection='column'
							key={ticket?.id}
							sx={{
								width: '34%',
								borderRight: '0.5px solid #D7D7D7'
							}}
						>
							<Box
								display='flex'
								justifyContent='center'
								gap={2}
								sx={{
									paddingY: 2.75,
									marginBottom: 1,
									borderBottom: '0.5px solid #D7D7D7',
									cursor: 'pointer'
								}}
								onClick={() => navigate(`/management_enter_exit/${ticket.id}`)}
							>
								<Typography variant='body_1'>Турникет №{ticket?.id}</Typography>
								<ChevronRight style={{ fill: '#7D7D7D' }} />
							</Box>
							<EntryExitCards tourniquetId={ticket.id} tickets={tickets[index].cards} />
						</Box>
					))}
			</Box>
		</div>
	)
}
