export const detailOrders = [
	{
		id: 1,
		name: 'Coca Cola (0,5 л) без сахара',
		count: 1,
		price: 450
	},
	{
		id: 2,
		name: 'Шашлыки 2 палки баранина',
		count: 2,
		price: 600
	},
	{
		id: 3,
		name: 'Хлеб корзина',
		count: 1,
		price: 300
	},
	{
		id: 4,
		name: 'Fanta',
		count: 3,
		price: 900
	}
]
