import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	InputAdornment,
	Modal,
	TextField,
	Typography
} from '@mui/material'
import EditAvatar from 'components/avatar/EditAvatar'
import { ArrowBack } from 'core/icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import QRCode from 'react-qr-code'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import {
	addOtherService,
	deleteOtherService,
	getOtherServiceId,
	updateOtherService
} from 'services/otherServices/other.service'
import { useBoolean } from 'usehooks-ts'

export const ServicesDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const modalOpen = useBoolean(false)
	const [image, setImage] = useState<any>()
	const [openModal, setOpenModal] = React.useState(false)
	const [fileImage, setFileImage] = useState<any>()
	const [qrUrl, setQrUrl] = React.useState('')
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm<any>()
	const handleCloseModal = () => setOpenModal(false)
	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 556,
		height: 556,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4
	}
	const addServiceMutation = useMutation({
		mutationFn: payload => addOtherService(payload),
		onSuccess: () => {
			reset()
			navigate('/other_services')
			toast.success('Сервис успешно добавлен!')
		}
	})
	const serviceUpdateMutation = useMutation({
		mutationFn: ({ service_id, body }: any) => updateOtherService({ service_id, body }),
		onSuccess: () => {
			toast.success('Изменения успешно сохранены!')
			navigate('/other_services')
		}
	})
	const { data: services } = useQuery(['getOtherServiceId', id], {
		queryFn: () => getOtherServiceId(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})
	const handleOpenModal = () => {
		setOpenModal(true)
	}
	const deleteServiceMutate = useMutation({
		mutationFn: (service_id: any) => deleteOtherService(service_id),
		onSuccess: () => {
			toast.success('Successfully deleted')
			navigate('/other_services')
		}
	})
	const onClickDownload = () => {
		const svg = document.getElementById('QRCode-services-detail')
		// @ts-ignore
		const svgData = new XMLSerializer().serializeToString(svg)
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		const img = new Image()
		img.onload = () => {
			canvas.width = img.width * 2.5
			canvas.height = img.height * 2.5
			// @ts-ignore
			ctx.fillStyle = '#FFFFFF'
			// @ts-ignore
			ctx.fillRect(0, 0, 640, 640)
			// @ts-ignore
			ctx.drawImage(img, 70, 70, 500, 500)
			const pngFile = canvas.toDataURL('image/png')
			const downloadLink = document.createElement('a')
			downloadLink.download = 'QRCode-services-detail'
			downloadLink.href = `${pngFile}`
			downloadLink.click()
		}
		img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
	}

	const handleServiceDelete = () => {
		deleteServiceMutate.mutate(id)
	}

	const onSubmit = (data: any) => {
		const fd: any = new FormData()
		if (fileImage) fd.append('image', fileImage)
		fd.append('name', data.name)
		fd.append('description', data.description)
		fd.append('rating', data.rating)
		fd.append('price', data.price)
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		location.search.indexOf('edit') > 0
			? serviceUpdateMutation.mutate({ service_id: id, body: fd })
			: addServiceMutation.mutate(fd)
	}

	const onAvatarUpload = (file: any) => {
		setFileImage(file)
		setImage(URL.createObjectURL(file))
	}
	useEffect(() => {
		if (services) {
			setQrUrl(JSON.stringify(services.data))
			setImage(services.data.img_url)
			setValue('image', services.data.img_url)
			setValue('name', services.data.name)
			setValue('description', services.data.description)
			setValue('rating', services.data.rating)
			setValue('price', services.data.price)
		}
	}, [setValue, services])
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={modalOpen.setTrue}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={824}>
					<Typography variant='headline_1'>
						{location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0
							? 'Данные услуги'
							: 'Добавление услуги'}
					</Typography>
					{services?.data && (
						<Box display='flex'>
							<Typography variant='body1' color='grey' mr={2}>
								ID: <strong>{services.data.id}</strong>
							</Typography>
							<Typography variant='body1' color='grey'>
								Дата добавления:{' '}
								<strong>{moment(services.data.created_at).format('DD MMM YYYY')}</strong>
							</Typography>
						</Box>
					)}
					<Box display='flex' mt={6}>
						<Box display='flex' flexDirection='column' justifyContent='center' alignContent='center'>
							<EditAvatar
								src={image}
								onUpload={onAvatarUpload}
								disabled={location.search.indexOf('view') > 0}
							/>
							{services?.data && (
								<Box display='flex' justifyContent='center'>
									{(location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0) && (
										<Button
											onClick={handleOpenModal}
											style={{
												marginTop: 48
											}}
										>
											Открыть статичный QR
										</Button>
									)}
								</Box>
							)}
						</Box>
						<form
							onSubmit={handleSubmit(onSubmit)}
							noValidate
							style={{ width: '100%', marginLeft: '40px' }}
						>
							<Box mb={2} mt={4}>
								<TextField
									label='Название услуги'
									placeholder=''
									required
									fullWidth
									type='text'
									disabled={location.search.indexOf('view') > 0}
									error={!!errors.name}
									{...register('name')}
								/>
								<TextField
									label='Описание (Необязательно)'
									placeholder=''
									required
									fullWidth
									type='text'
									disabled={location.search.indexOf('view') > 0}
									error={!!errors.description}
									{...register('description')}
								/>
								<TextField
									label='Рейтинг'
									placeholder=''
									required
									fullWidth
									disabled={location.search.indexOf('view') > 0}
									type='number'
									error={!!errors.rating}
									{...register('rating')}
								/>
								<TextField
									label='Цена'
									placeholder=''
									required
									fullWidth
									type='price'
									disabled={location.search.indexOf('view') > 0}
									InputProps={{
										startAdornment: <InputAdornment position='start'>₸</InputAdornment>
									}}
									error={!!errors.price}
									{...register('price')}
								/>
							</Box>

							{location.search.indexOf('view') < 0 && (
								<Button variant='contained' type='submit' color='success' style={{ width: '252px' }}>
									{addServiceMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
								</Button>
							)}
						</form>
					</Box>
				</Box>
			</Box>
			<Dialog
				open={modalOpen.value}
				onClose={modalOpen.setFalse}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={modalOpen.setFalse}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleServiceDelete} autoFocus variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<Box display='flex' justifyContent='center' mb={1}>
						{qrUrl && (
							<Box>
								<QRCode
									id='QRCode-services-detail'
									size={254}
									style={{
										height: 'auto',
										maxWidth: '440px',
										width: '440px',
										marginBottom: 32
									}}
									value={qrUrl}
									viewBox='0 0 254 254'
								/>
							</Box>
						)}
					</Box>
					<Divider />
					<Button onClick={onClickDownload}>Печать</Button>
				</Box>
			</Modal>
		</Box>
	)
}
