import axios from 'axios'
import { toast } from 'react-toastify'

export const BASE_API = 'https://api.zerbulakfamily.kz/api'
export const axiosBaseInstance = axios.create({
	baseURL: BASE_API
})
export const axiosBaseInstanceToken = axios.create({
	baseURL: BASE_API
})
axiosBaseInstance.interceptors.request.use(request => {
	const ACCESS_TOKEN = localStorage.getItem('ACCESS_TOKEN')
	// @ts-ignore
	request.headers = {
		...request.headers,
		Authorization: `Bearer ${ACCESS_TOKEN}`
	}
	return request
})
axiosBaseInstanceToken.interceptors.request.use(request => {
	// @ts-ignore
	request.headers = {
		...request.headers
	}
	return request
})
axiosBaseInstanceToken.interceptors.response.use(
	response => response,
	async error => {
		const { config } = error
		if (error.response.status === 401 && !config._retry) {
			config._retry = true
			window.location.href = '/login'
		}
		if (error.response?.data?.messages?.length) {
			error.response.data.messages.forEach((response: any) => {
				toast.error(response, {
					position: toast.POSITION.TOP_RIGHT,
					theme: 'dark'
				})
			})
		} else {
			toast.error(error?.message || 'Something went wrong!', {
				position: toast.POSITION.TOP_RIGHT,
				theme: 'dark'
			})
		}

		return Promise.reject(error)
	}
)

axiosBaseInstance.interceptors.response.use(
	response => response,
	async error => {
		const { config } = error
		if (error.response.status === 401 && !config._retry) {
			config._retry = true
			window.location.href = '/login'
		}
		if (error.response?.data?.messages?.length) {
			error.response.data.messages.forEach((response: any) => {
				toast.error(response, {
					position: toast.POSITION.TOP_RIGHT,
					theme: 'dark'
				})
			})
		} else if (error.response?.data.message) {
			toast.error(error.response?.data.message, {
				position: toast.POSITION.TOP_RIGHT,
				theme: 'dark'
			})
		} else {
			toast.error(error?.message || 'Something went wrong!', {
				position: toast.POSITION.TOP_RIGHT,
				theme: 'dark'
			})
		}

		return Promise.reject(error)
	}
)
