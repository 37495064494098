import { styled } from '@mui/material/styles'

const Root = styled('div')(({ theme }) => ({
	width: '100%',
	backgroundColor: theme.palette.primary.white,
	padding: theme.spacing(2),
	borderRadius: '16px',
	display: 'flex',
	flexDirection: 'column'
}))

const Role = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.primary.white,
	color: theme.palette.primary.main,
	padding: theme.spacing(0.5, 1),
	fontSize: '11px',
	fontWeight: 500,
	borderRadius: '8px 4px 4px 0px'
}))

export const Styled = {
	Root,
	Role
}
