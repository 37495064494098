import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Menu,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteTicketCategory, getTicketsCategories } from 'services/ticketsCategory/tickets-category.service'

import { Cancel, Dots, Edit, Settings, View } from '../../core/icons'

export const TicketsCategoryTableComponent = () => {
	const navigate = useNavigate()
	const { profile } = useApp()
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const [openModal, setOpenModal] = React.useState(false)
	const [currentId, setCurrentId] = useState<any>()

	const { data: category, refetch } = useQuery(['getTicketsCategories'], {
		queryFn: () => getTicketsCategories()
	})
	const deleteCategoryMutate = useMutation({
		mutationFn: (employee_id: any) => deleteTicketCategory(employee_id),
		onSuccess: () => {
			toast.success('Successfully deleted')
			setOpenModal(false)
			setAnchorEl(null)
			refetch()
		}
	})
	const handleOpenModal = () => setOpenModal(true)
	const handleCloseModal = () => setOpenModal(false)
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentId(event.currentTarget.id)
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleStaffsDelete = () => {
		deleteCategoryMutate.mutate(currentId)
	}
	const handleEditStuff = () => {
		navigate(`/tickets/category/${currentId}?edit`)
	}
	const handleViewTicketCategory = () => {
		navigate(`/tickets/category/${currentId}?view`)
	}
	return (
		<div>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell align='left'>Наименование</TableCell>
							<TableCell align='left'>Время посещения</TableCell>
							<TableCell align='right'>
								<Settings />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{category?.data &&
							category.data.map((row: any) => (
								<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component='th' scope='row'>
										{row.id}
									</TableCell>
									<TableCell align='left'>{row.name}</TableCell>
									<TableCell align='left'>{row.visit_times.length > 0 ? 'Да' : 'Нет'}</TableCell>
									<TableCell align='right'>
										<Button
											id={row.id}
											aria-controls={open ? 'basic-menu' : undefined}
											aria-haspopup='true'
											aria-expanded={open ? 'true' : undefined}
											onClick={handleClick}
										>
											<Dots />
										</Button>
										<Menu
											id={`basic-menu-${row.id}`}
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button'
											}}
										>
											<MenuItem onClick={handleViewTicketCategory} key={`menu-item-${row.id}`}>
												<View
													style={{
														color: '#349EFF',
														marginRight: '8px'
													}}
												/>
												Просмотр категории билета
											</MenuItem>
											<Divider />
											{profile?.user?.role.permissions.includes('update_ticket_category') && (
												<MenuItem onClick={handleEditStuff} key={`menu-item-${row.id}`}>
													<Edit
														style={{
															marginRight: '8px'
														}}
													/>
													Редактировать
												</MenuItem>
											)}
											<Divider />
											{profile?.user?.role.permissions.includes('update_ticket_category') && (
												<MenuItem onClick={handleOpenModal}>
													<Cancel
														style={{
															marginRight: '8px'
														}}
													/>
													Удалить
												</MenuItem>
											)}
										</Menu>
									</TableCell>
									<Dialog
										open={openModal}
										onClose={handleCloseModal}
										aria-labelledby='alert-dialog-title'
										aria-describedby='alert-dialog-description'
									>
										<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
										<DialogContent>
											<DialogContentText id='alert-dialog-description'>
												Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и
												без сохранении
											</DialogContentText>
										</DialogContent>
										<DialogActions
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'end'
											}}
										>
											<Button
												onClick={handleCloseModal}
												variant='text'
												color='success'
												style={{ marginBottom: '16px' }}
											>
												Нет, оставить
											</Button>
											<Button onClick={handleStaffsDelete} autoFocus variant='text' color='error'>
												Да, удалить
											</Button>
										</DialogActions>
									</Dialog>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
