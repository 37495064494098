import './App.css'

import { CssBaseline, ThemeProvider } from '@mui/material'
import { AppContext } from 'core/contexts/profile.context'
import { AuthorizationLayout } from 'layouts/authorization-layout.component'
import { MainLayout } from 'layouts/main-layout.component'
import { AboutApp } from 'pages/AboutApp/about_app'
import { AuthorizationPage } from 'pages/Authorization/authorization.page'
import { CheckPrint } from 'pages/CheckPrint/CheckPrint'
import { CheckPrintDetails } from 'pages/CheckPrinterDetails/CheckPrinterDetails'
import { ClientsPage } from 'pages/Clients/clients.page'
import { ClosetsManageSelect } from 'pages/ClosetsManageSelect/closets-manage-select.page'
import { ActivatePage } from 'pages/EnterExit/activate.page'
import { EnterExitHistoryPage } from 'pages/EnterExit/enter-exit-history.page'
import { FoodsCategoryPage } from 'pages/FoodCategory/foods-category.page'
import { FoodCategoryDetailPage } from 'pages/FoodCategoryDetail/food-category-detail.page'
import { FoodChangeHistoryComponent } from 'pages/FoodChangeHistory/food-change-history.component'
import { FoodDetailPage } from 'pages/FoodDetail/food-detail.page'
import { HistoryPaymentPage } from 'pages/HistoryPayment/history-payment.page'
import { HistoryPaymentDetailPage } from 'pages/HistoryPayment/history-payment-detail.page'
import { PaymentPage } from 'pages/HistoryPayment/payment.page'
import { ClosetsPage } from 'pages/Lockers/closets.page'
import { LockersDetailPage } from 'pages/LockersDetail/lockers-detail.page'
import { ManageClosestDetailPage } from 'pages/ManageClosestDetail/manage-closest-detail.page'
import { OrdersPage } from 'pages/Orders/orders.page'
import { OrdersTestPage } from 'pages/OrdersTest/orders.page'
import { ProfilePage } from 'pages/Profile/profile.page'
import { QrDetailPage } from 'pages/QrDetail/qr-detail.page'
import { QrHistoryComponent } from 'pages/QrHistory/qr-history.component'
import { QrPayPage } from 'pages/QrPay/qr-pay.page'
import { ReturnPage } from 'pages/ReturnCash/return.page'
import { ReturnCashPage } from 'pages/ReturnCash/return-cash.page'
import { ReturnCashDetailPage } from 'pages/ReturnCash/return-cash-detail.page'
import { HistorySosPage } from 'pages/Sos/history-sos.page'
import { StuffsPage } from 'pages/Stuff/stuff.page'
import { StuffChangeHistoryComponent } from 'pages/StuffChangeHistory/stuff-change-history.component'
import { HistoryTopchanPage } from 'pages/TapchansManage/history-topchan.page'
import { TapchansManageDetailPage } from 'pages/TapchansManage/tapchans-detail.page'
import { TapchansManageAddPage } from 'pages/TapchansManageDetail/tapchans-manage-detail.page'
import { TermsOfUse } from 'pages/TermsOfUse/terms-of-use.page'
import { TicketsPage } from 'pages/Tickets/tickets.page'
import { TicketsCategoryDetailPage } from 'pages/TicketsCategoryDetail/tickets-category-detail.page'
import { TicketsManagePage } from 'pages/TicketsManage/tickets-manage.page'
import { TicketsTariffDetailPage } from 'pages/TicketsTariffDetail/tickets-tariff-detail.page'
import { TicketsTypeDetailPage } from 'pages/TicketsTypeDetail/tickets-type-detail.page'
import { TourniquetsPage } from 'pages/Tourniquets/tourniquets.page'
import { TowelsDetailPage } from 'pages/TowelDetail/towels-detail.page'
import { TowelsClientDetailPage } from 'pages/TowelsManage/towels-client-detail.page'
import { TowelsHistoryComponent } from 'pages/TowelsManageHistory/towels-manage-history.component'
import Pusher from 'pusher-js'
import React, { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { getProfile } from 'services/profile/profile.service'

import { ClientsDetailPage } from './pages/ClientsDetail/clients-detail.page'
import { ClosetsManagePage } from './pages/ClosetsManage/closets-manage.page'
import { EnterExitPage } from './pages/EnterExit/enter-exit.page'
import { EnterExitClientPage } from './pages/EnterExit/enter-exit-client.page'
import { EnterExitDetailPage } from './pages/EnterExitDetail/enter-exit-detail.page'
import { FoodsPage } from './pages/Foods/foods.page'
import { HistorySalesPage } from './pages/HistorySales/history-sales.page'
import { ManageClosetsHistoryPage } from './pages/ManageClosetsHistory/manage-closets-history.page'
import { OrdersDetailPage } from './pages/OrdersDetail/orders-detail.page'
import { OrdersEditPage } from './pages/OrdersEdit/orders-edit.page'
import { OrdersHistoryPage } from './pages/OrdersHistory/orders-history.page'
import { PointersPage } from './pages/Pointer/pointer.page'
import { PointerDetailPage } from './pages/PointerDetail/pointer-detail.page'
import { PrivacyPolicyPage } from './pages/PrivacyPolicy/privacy-policy.page'
import { QrPage } from './pages/Qr/qr.page'
import { RolesPage } from './pages/Roles/roles.page'
import { RolesDetailPage } from './pages/RolesDetail/roles-detail.page'
import { RulesPage } from './pages/Rules/rules.page'
import { RulesDetailPage } from './pages/RulesDetail/rules-detail.page'
import { ServicesPage } from './pages/Services/services.page'
import { ServicesDetailPage } from './pages/ServicesDetail/services-detail.page'
import { SosPage } from './pages/Sos/sos.page'
import { StuffsDetailPage } from './pages/StuffsDetail/stuffs-detail.page'
import { TapchansPage } from './pages/Tapchans/tapchans.page'
import { TapchansDetailPage } from './pages/TapchansDetail/tapchans-detail.page'
import { TapchansManagePage } from './pages/TapchansManage/tapchan-manage.page'
import { TicketsDetailPage } from './pages/TicketsDetail/tickets-detail.page'
import { TourniquetsDetailPage } from './pages/TourniquetDetail/tourniquets-detail.page'
import { TowelsPage } from './pages/Towels/towels.page'
import { TowelsManagePage } from './pages/TowelsManage/towels-manage.page'
import theme from './theme'

function App() {
	const ACCESS_TOKEN = localStorage.getItem('ACCESS_TOKEN')
	// @ts-ignore
	window.Pusher = Pusher
	// @ts-ignore
	const location = useLocation()
	const navigate = useNavigate()

	const { data, refetch } = useQuery(['getProfile', ACCESS_TOKEN], {
		queryFn: () => getProfile(),
		enabled: !!ACCESS_TOKEN
	})
	const contextProviderValue = useMemo(
		() => ({
			profile: data?.data || null,
			refetch
		}),
		[data?.data, refetch]
	)
	useEffect(() => {
		if (location.pathname === '/') {
			navigate('/clients')
		}
	}, [location, navigate])

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />

			<AppContext.Provider value={contextProviderValue}>
				<Routes>
					<Route path='/' element={<MainLayout />}>
						<Route path='/tickets' element={<TicketsPage />} />
						<Route path='/profile' element={<ProfilePage />} />
						<Route path='/tickets/:id' element={<TicketsDetailPage />} />
						<Route path='/tickets/category/:id' element={<TicketsCategoryDetailPage />} />
						<Route path='/tickets/tariff/:id' element={<TicketsTariffDetailPage />} />
						<Route path='/tickets/type/:id' element={<TicketsTypeDetailPage />} />
						<Route path='/tickets/:id' element={<TicketsDetailPage />} />
						<Route path='/tickets_manage' element={<TicketsManagePage />} />
						<Route path='/orders' element={<OrdersPage />} />
						<Route path='/orders-test' element={<OrdersTestPage />} />
						<Route path='/orders/:id' element={<OrdersDetailPage />} />
						<Route path='/orders/history' element={<OrdersHistoryPage />} />
						<Route path='/orders/:id/edit' element={<OrdersEditPage />} />
						<Route path='/clients' element={<ClientsPage />} />
						<Route path='/clients/:id' element={<ClientsDetailPage />} />
						<Route path='/stuffs' element={<StuffsPage />} />
						<Route path='/stuffs/:id' element={<StuffsDetailPage />} />
						<Route path='/stuffs/:id/history' element={<StuffChangeHistoryComponent />} />
						<Route path='/lockers' element={<ClosetsPage />} />
						<Route path='/lockers/:id' element={<LockersDetailPage />} />
						<Route path='/tapchans' element={<TapchansPage />} />
						<Route path='/tapchans/:id' element={<TapchansDetailPage />} />
						<Route path='/tourniquets' element={<TourniquetsPage />} />
						<Route path='/tourniquets/:id' element={<TourniquetsDetailPage />} />
						<Route path='/order_issuing' element={<PointersPage />} />
						<Route path='/order_issuing/:id' element={<PointerDetailPage />} />
						<Route path='/towel' element={<TowelsPage />} />
						<Route path='/towel/:id' element={<TowelsDetailPage />} />
						<Route path='/accesses_roles' element={<RolesPage />} />
						<Route path='/accesses_roles/:id' element={<RolesDetailPage />} />
						<Route path='/rules' element={<RulesPage />} />
						<Route path='/rules/:id' element={<RulesDetailPage />} />
						<Route path='/food_and_drinks' element={<FoodsCategoryPage />} />
						<Route path='/food_and_drinks/category/:id' element={<FoodCategoryDetailPage />} />
						<Route path='/food_and_drinks/:id/product' element={<FoodsPage />} />
						<Route path='/food_and_drinks/product/:id' element={<FoodDetailPage />} />
						<Route path='/food_and_drinks/product/:id/history' element={<FoodChangeHistoryComponent />} />
						<Route path='/other_services' element={<ServicesPage />} />
						<Route path='/other_services/:id' element={<ServicesDetailPage />} />
						<Route path='/qr' element={<QrPage />} />
						<Route path='/qr/detail' element={<QrDetailPage />} />
						<Route path='/qr/history' element={<QrHistoryComponent />} />
						<Route path='/qr/:id/pay' element={<QrPayPage />} />
						<Route path='/sales_history' element={<HistorySalesPage />} />
						<Route path='/management_tapchan' element={<TapchansManagePage />} />
						<Route path='/management_tapchan/:id' element={<TapchansManageDetailPage />} />
						<Route path='/management_tapchan/:id/add' element={<TapchansManageAddPage />} />
						<Route path='/management_tapchan/history' element={<HistoryTopchanPage />} />
						<Route path='/management_closet' element={<ClosetsManagePage />} />
						<Route path='/management_closet_select' element={<ClosetsManageSelect />} />
						<Route path='/management_closet/:id' element={<ManageClosestDetailPage />} />
						<Route path='/management_closet/history' element={<ManageClosetsHistoryPage />} />
						<Route path='/management_enter_exit' element={<EnterExitPage />} />
						<Route path='/management_enter_exit/:id' element={<EnterExitDetailPage />} />
						<Route path='/payment-history' element={<HistoryPaymentPage />} />
						<Route path='/payment-history/:id' element={<HistoryPaymentDetailPage />} />
						<Route path='/payment-history/payment-page' element={<PaymentPage />} />
						<Route path='/return-cash' element={<ReturnCashPage />} />
						<Route path='/return-cash/:id' element={<ReturnCashDetailPage />} />
						<Route path='/return-cash/return' element={<ReturnPage />} />
						<Route path='/management_enter_exit/history' element={<EnterExitHistoryPage />} />
						<Route path='/management_enter_exit/history/client' element={<EnterExitClientPage />} />
						<Route path='/management_enter_exit/activate&deactivate' element={<ActivatePage />} />
						<Route path='/management_towel' element={<TowelsManagePage />} />
						<Route path='/management_towel/:id' element={<TowelsClientDetailPage />} />
						<Route path='/management_towel/history' element={<TowelsHistoryComponent />} />
						<Route path='/sos' element={<SosPage />} />
						<Route path='/sos/history' element={<HistorySosPage />} />

						<Route path='/printers' element={<CheckPrint />} />
						<Route path='/printer/:id' element={<CheckPrintDetails />} />
					</Route>
					<Route path='/login' element={<AuthorizationLayout />}>
						<Route index element={<AuthorizationPage />} />
					</Route>
					<Route path='/privacy_policy' element={<PrivacyPolicyPage />} />
					<Route path='/terms_of_use' element={<TermsOfUse />} />
					<Route path='/about_app' element={<AboutApp />} />
					{/* <Route path="*" element={<NotFoundPage />} /> */}
				</Routes>
			</AppContext.Provider>
		</ThemeProvider>
	)
}

export default App
