import { createContext, useContext } from 'react'

const defaultProps = {
	profile: null,
	contractor: null
}

export const AppContext = createContext<any>(defaultProps)

export const useApp = () => useContext(AppContext)
