import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Menu,
	MenuItem,
	Pagination,
	Paper,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	TextField,
	Typography
} from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { useApp } from 'core/contexts/profile.context'
import { Cancel, Dots, Edit, Settings, View } from 'core/icons'
import { LockersStatusColor, LockersStatusText } from 'pages/Lockers/enum'
import { parseLockers } from 'pages/Lockers/parse'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteClosets, getClosetsList } from 'services/closets/closets.service'
import { useDebounce } from 'usehooks-ts'

export const ClosetsPage = () => {
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()
	const defaultTab = searchParams.get('category')
	const { profile } = useApp()
	const [page, setPage] = React.useState(1)
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const [search, setSearch] = React.useState('')
	const [tab, setTab] = React.useState(defaultTab ?? 'male')
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
	const [currentId, setCurrentId] = useState<any>()
	const [reasonText, setReasonText] = React.useState('')

	const debouncedSearch = useDebounce(search, 500)
	const { data: lockers, refetch } = useQuery(['getClosetsList', tab, page, debouncedSearch], {
		queryFn: () =>
			getClosetsList({
				page,
				category: tab,
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})

	const deleteClosetMutate = useMutation({
		mutationFn: ({ closet_id, reason }: any) => deleteClosets({ closet_id, reason }),
		onSuccess: () => {
			toast.success('Successfully deleted')
			setOpenDeleteModal(false)
			setAnchorEl(null)
			setReasonText('')
			refetch()
		}
	})

	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setTab(newValue)
		searchParams.set('category', newValue)
		setSearchParams(searchParams)
		setPage(1)
	}

	const handleDeleteOpenModal = () => setOpenDeleteModal(true)
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentId(event.currentTarget.id)
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleEditCloset = () => {
		navigate(`/lockers/${currentId}?edit`)
	}
	const handleViewCloset = () => {
		navigate(`/lockers/${currentId}?view`)
	}
	const handleClosetDelete = () => {
		if (reasonText) {
			deleteClosetMutate.mutate({ closet_id: currentId, reason: reasonText })
		} else {
			toast.error('Нужно причина!')
		}
	}

	const onChangeReason = (e: any) => {
		setReasonText(e.target.value)
	}
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
		setPage(1)
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Шкафы</Typography>
				{profile?.user?.role.permissions.includes('update_closets') && (
					<Button onClick={() => navigate(`/lockers/add?category=${tab}`)} variant='contained'>
						Добавить
					</Button>
				)}
			</Box>
			<Divider />
			<Box sx={{ width: '100%' }} mb={2} py={4} display='flex' justifyContent='center'>
				<Tabs value={tab} onChange={handleChangeTab} aria-label='wrapped label tabs example'>
					<Tab value='male' label='Мужские' wrapped />
					<Tab value='female' label='Женские' />
				</Tabs>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='end' p={1}>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell align='left'>ID</TableCell>
							<TableCell align='left'>Статус</TableCell>
							<TableCell align='left'>Планшет</TableCell>
							<TableCell align='left'>Номер</TableCell>
							<TableCell align='left'>ID замка</TableCell>
							<TableCell align='left'>Board ID</TableCell>
							<TableCell align='right'>
								<Settings />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lockers?.data &&
							parseLockers(lockers.data).map((row: any) => (
								<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component='th' scope='row'>
										{row.id}
									</TableCell>
									<TableCell align='left'>{row.id}</TableCell>
									<TableCell
										align='left'
										sx={{
											color: LockersStatusColor[row.status as keyof typeof LockersStatusColor]
										}}
									>
										{LockersStatusText[row.status as keyof typeof LockersStatusText]}
									</TableCell>
									<TableCell align='left'>{row.branch_id}</TableCell>
									<TableCell align='left'>{row.number}</TableCell>
									<TableCell align='left'>{row.locker_id}</TableCell>
									<TableCell align='left'>{row.board_id}</TableCell>
									<TableCell align='right'>
										<Button
											id={row.id}
											aria-controls={open ? 'basic-menu' : undefined}
											aria-haspopup='true'
											aria-expanded={open ? 'true' : undefined}
											onClick={handleClick}
										>
											<Dots />
										</Button>
										<Menu
											id={`basic-menu-${row.id}`}
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button'
											}}
										>
											<MenuItem onClick={handleViewCloset} key={`menu-item-${row.id}`}>
												<View
													style={{
														color: '#349EFF',
														marginRight: '8px'
													}}
												/>
												Просмотр карточки шкафа
											</MenuItem>
											{profile?.user?.role.permissions.includes('update_closets') && (
												<MenuItem onClick={handleEditCloset} key={`menu-item-${row.id}`}>
													<Edit
														style={{
															marginRight: '8px'
														}}
													/>
													Редактировать
												</MenuItem>
											)}
											{profile?.user?.role.permissions.includes('update_closets') && (
												<MenuItem onClick={handleDeleteOpenModal}>
													<Cancel
														style={{
															marginRight: '8px'
														}}
													/>
													Удалить
												</MenuItem>
											)}
										</Menu>
									</TableCell>
									<Dialog
										open={openDeleteModal}
										onClose={handleClose}
										aria-labelledby='alert-dialog-title'
										aria-describedby='alert-dialog-description'
									>
										<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
										<DialogContent>
											<DialogContentText id='alert-dialog-description'>
												Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и
												без сохранении
											</DialogContentText>
											<Box mt={2}>
												<TextField
													label='Причина удаления'
													placeholder=''
													fullWidth
													multiline
													rows={3}
													type='text'
													value={reasonText}
													onChange={onChangeReason}
													name='reason'
												/>
											</Box>
										</DialogContent>
										<DialogActions
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'end'
											}}
										>
											<Button
												onClick={handleDeleteCloseModal}
												variant='text'
												color='success'
												style={{ marginBottom: '16px' }}
											>
												Нет, оставить
											</Button>
											<Button
												onClick={handleClosetDelete}
												autoFocus
												disabled={!reasonText}
												variant='text'
												color='error'
											>
												Да, удалить
											</Button>
										</DialogActions>
									</Dialog>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{lockers?.last_page && (
				<Box display='flex' justifyContent='center'>
					<Pagination
						sx={{ marginTop: '16px' }}
						count={lockers.last_page}
						page={page}
						onChange={handleChange}
					/>
				</Box>
			)}
		</div>
	)
}
