import { Box, Button, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material'
import { ArrowBack, PhoneIphone } from 'core/icons'
import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import { useMutation } from 'react-query'
import { backOfficeOtp } from 'services/authorization/authorization.service'

export const CheckLoginComponent = ({ setPhone: setPhoneProps, setStep }: any) => {
	const [phone, setPhone] = useState<any>()

	const otpMutation = useMutation({
		mutationFn: payload => backOfficeOtp(payload),
		onSuccess: () => {
			setPhoneProps(phone)
			setStep('otp')
		}
	})
	const onChangePhone = (e: any) => {
		setPhone(e.target.value)
	}

	const onSubmit = () => {
		const PhoneMask = phone?.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')
		// @ts-ignore
		otpMutation.mutate({
			phone: PhoneMask
		})
	}

	return (
		<Box width='100%'>
			<Button
				color='info'
				variant='text'
				size='small'
				onClick={() => setStep('login')}
				sx={{ border: 0, borderRadius: '50%' }}
			>
				<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
			</Button>
			<Typography
				variant='headline_1'
				mb={1}
				sx={{
					width: '100%'
				}}
			>
				Вход в систему
			</Typography>
			<Typography
				variant='footnote_1'
				mb={4}
				sx={{
					width: '100%'
				}}
			>
				Введите данные ниже для авторизации
			</Typography>
			<Box my={2}>
				<InputMask mask='9 (999) 999-99-99' maskChar=' ' onChange={onChangePhone} alwaysShowMask={false}>
					<TextField
						fullWidth
						label='Введите номер'
						type='text'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<PhoneIphone />
								</InputAdornment>
							)
						}}
					/>
				</InputMask>
			</Box>

			<Button variant='contained' type='submit' fullWidth onClick={onSubmit} disabled={otpMutation.isLoading}>
				{otpMutation.isLoading ? <CircularProgress /> : 'Войти'}
			</Button>
		</Box>
	)
}
