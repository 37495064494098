import { axiosBaseInstance } from 'core/axios.config'

export const getOtherServices = async (params: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/services/`, {
		params
	})
	return data
}

export const getCustomerServicesHistory = async (params: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/services-history/`, {
		params
	})
	return data
}

export const addOtherService = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/services/`, data)
	return v.data
}

export const deleteOtherService = async (service_id: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/services/${service_id}/`)
	return v.data
}

export const getOtherServiceId = async (service_id: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/services/${service_id}`)
	return data
}

export const updateOtherService = async ({ service_id, body }: any) => {
	const { data } = await axiosBaseInstance.post(`back_office/services/${service_id}/`, body, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return data
}
