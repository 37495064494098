import { Box, Button, Divider, Pagination, Typography } from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { ArrowBack } from 'core/icons'
import moment from 'moment'
import { parseLockers } from 'pages/Lockers/parse'
import { OrderStatusColor, OrderStatusText } from 'pages/Orders/enum'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getOrdersHistory } from 'services/orders/orders.service'
import { useDebounce } from 'usehooks-ts'

export const OrdersHistoryPage = () => {
	const navigate = useNavigate()
	const [search, setSearch] = React.useState('')
	const debouncedSearch = useDebounce(search, 500)
	const [page, setPage] = React.useState(1)
	const { data: history } = useQuery(['getOrdersHistory', page, debouncedSearch], {
		queryFn: () =>
			getOrdersHistory({
				page,
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
	}

	return (
		<div>
			<Box p={2} display='flex' alignItems='center'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%', marginRight: '16px' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				<Typography variant='title_2'>История</Typography>
			</Box>
			<Divider />
			<Box p={2} display='flex' justifyContent='end'>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
				{history?.data &&
					Object.keys(history?.data).map(key => (
						<Box mt={6} width={500}>
							<Box display='flex' flexDirection='column' mb={2}>
								<Typography variant='headline_1' color='primary.black' mb={1}>
									{key}
								</Typography>
								{history?.data[key] &&
									parseLockers(history?.data[key]).map((data: any) => (
										<Box display='flex' justifyContent='space-between' mb={4}>
											<Box display='flex' flexDirection='column'>
												<Box display='flex' key={`orders-history-${data.id}`}>
													<Typography
														mr={1}
														variant='caption_1'
														sx={{
															color: OrderStatusColor[
																data.status as keyof typeof OrderStatusColor
															]
														}}
													>
														{OrderStatusText[data.status as keyof typeof OrderStatusText]}
													</Typography>
													<Typography variant='caption_1'>
														{' - '} {moment(data.updated_at).format('DD MMM YYYY')}
													</Typography>
												</Box>
												<Typography variant='body_1' color='primary.black'>
													Заказ №: {data.id}
												</Typography>
												<Box display='flex' flexDirection='column' gap={0.75}>
													{parseLockers(data?.products).map((product: any) => (
														<Typography
															key={`${product.product?.name}-${product.quantity}`}
															variant='footnote_1'
															color='primary.gray'
														>
															{product.quantity}х - {product.product?.name}
														</Typography>
													))}
												</Box>
											</Box>
											<Box>
												<Typography variant='body_1'>{data.price}</Typography>
											</Box>
										</Box>
									))}
								<Divider />
							</Box>
						</Box>
					))}
				{history?.last_page && (
					<Box display='flex' justifyContent='center'>
						<Pagination
							sx={{ marginTop: '16px' }}
							count={history.last_page}
							page={page}
							onChange={handleChange}
						/>
					</Box>
				)}
			</Box>
		</div>
	)
}
