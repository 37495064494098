import { Box, Button, CircularProgress, Divider, TextField, Typography } from '@mui/material'
import { ArrowBack } from 'core/icons'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import QRCode from 'react-qr-code'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import { getTowelsListId, updateTowels } from 'services/towels/towel.service'

export const TowelsDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors }
	} = useForm<any>()
	const [qrReceiveUrl, setQrReceiveUrl] = React.useState('')
	const [qrReturnUrl, setQrReturnUrl] = React.useState('')

	const towelUpdateMutation = useMutation({
		mutationFn: ({ towel_id, body }: any) => updateTowels({ towel_id, body }),
		onSuccess: () => {
			toast.success('Successfully updated')
			navigate('/towel')
		}
	})

	const { data: towel } = useQuery(['getTowelsId', id, location], {
		queryFn: () => getTowelsListId(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})
	const onSubmit = (data: any) => {
		towelUpdateMutation.mutate({ towel_id: id, body: data })
	}

	const onClickDownload = (qrId: string) => () => {
		const svg = document.getElementById(qrId)
		// @ts-ignore
		const svgData = new XMLSerializer().serializeToString(svg)
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		const img = new Image()
		img.onload = () => {
			canvas.width = img.width * 2.5
			canvas.height = img.height * 2.5
			// @ts-ignore
			ctx.fillStyle = '#FFFFFF'
			// @ts-ignore
			ctx.fillRect(0, 0, 640, 640)
			// @ts-ignore
			ctx.drawImage(img, 70, 70, 500, 500)
			const pngFile = canvas.toDataURL('image/png')
			const downloadLink = document.createElement('a')
			downloadLink.download = qrId
			downloadLink.href = `${pngFile}`
			downloadLink.click()
		}
		img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
	}

	useEffect(() => {
		if (towel) {
			setValue('name', towel.data.name)
			setValue('price', towel.data.price)
			setQrReceiveUrl(JSON.stringify({ type: 'receive_towel' }))
			setQrReturnUrl(JSON.stringify({ type: 'return_towel' }))
		}
	}, [setValue, towel])
	return (
		<Box>
			<Box p={2}>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={520}>
					<Typography variant='headline_1'>Данные полотенца</Typography>
					{towel?.data && (
						<Box display='flex' mb={5}>
							<Typography variant='body1' color='grey' mr={2}>
								ID: <strong>{towel.data.id}</strong>
							</Typography>
							<Typography variant='body1' color='grey'>
								Дата добавления: <strong>{moment(towel.data.created_at).format('DD MMM YYYY')}</strong>
							</Typography>
						</Box>
					)}
					{towel?.data && (
						<Box display='flex' justifyContent='space-between' p={2}>
							<Box mr={5}>
								<Typography variant='body1' color='black' mb={4} display='flex' justifyContent='center'>
									<strong> 1 - QR для выдачи</strong>
								</Typography>
								{qrReceiveUrl && (
									<Box>
										<QRCode
											id='QRCode-towels-receive'
											size={254}
											style={{
												height: 'auto',
												maxWidth: '100%',
												width: '100%',
												marginBottom: 32
											}}
											value={qrReceiveUrl}
											viewBox='0 0 254 254'
										/>
									</Box>
								)}
								<Box display='flex' justifyContent='center' mb={3}>
									<Button onClick={onClickDownload('QRCode-towels-receive')}>Печать</Button>
								</Box>
							</Box>
							<Box>
								<Typography display='flex' justifyContent='center' variant='body1' color='black' mb={4}>
									<strong>2 - QR для сдачи</strong>
								</Typography>
								{qrReturnUrl && (
									<Box>
										<QRCode
											id='QRCode-towels-return'
											size={254}
											style={{
												height: 'auto',
												maxWidth: '100%',
												width: '100%',
												marginBottom: 32
											}}
											value={qrReturnUrl}
											viewBox='0 0 254 254'
										/>
									</Box>
								)}
								<Box display='flex' justifyContent='center' mb={3}>
									<Button onClick={onClickDownload('QRCode-towels-return')}>Печать</Button>
								</Box>
							</Box>
						</Box>
					)}
					<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
						<Box mb={2} mt={4}>
							<TextField
								label='Наименование'
								placeholder=''
								required
								fullWidth
								type='text'
								disabled={location.search.indexOf('view') > 0}
								error={!!errors.name}
								{...register('name')}
							/>
							<TextField
								label='Цена'
								placeholder=''
								required
								fullWidth
								disabled={location.search.indexOf('view') > 0}
								type='number'
								error={!!errors.price}
								{...register('price')}
							/>
						</Box>
						{location.search.indexOf('view') < 0 && (
							<Button variant='contained' type='submit' sx={{ width: '252px' }} color='success'>
								{towelUpdateMutation.isLoading ? <CircularProgress size='small' /> : 'Сохранить'}
							</Button>
						)}
					</form>
				</Box>
			</Box>
		</Box>
	)
}
