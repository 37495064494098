import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Menu,
	MenuProps,
	Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useApp } from 'core/contexts/profile.context'
import { ArrowBack, Lock, LockOpen } from 'core/icons'
import Echo from 'laravel-echo'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
	deleteClosetsOwner,
	getClosetsList,
	openAllClosets,
	openCustomerActiveClosets
} from 'services/closets/closets.service'

import { ManageClosestState, ManageClosestStatus } from '../ManageClosestDetail/enum'

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right'
		}}
		transformOrigin={{
			vertical: 'center',
			horizontal: 'left'
		}}
		{...props}
	/>
))(() => ({
	'& .MuiPaper-root': {
		borderRadius: 12,
		boxShadow: 'none',
		border: 'none',
		'& .MuiMenu-list': {
			padding: '0px'
		}
	}
}))

export const ClosetsManagePage = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const gender = searchParams.get('gender')
	const { profile } = useApp()
	const page = searchParams.get('page')
	const [currentLocker, setCurrentLocker] = useState<any>()
	const [lockers, setLockers] = useState<any>([])
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const [currentId, setCurrentId] = useState<any>()
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
	const [openModal, setOpenModal] = React.useState(false)
	const [openAllModal, setOpenAllModal] = React.useState(false)
	const { refetch } = useQuery(['getClosetsList'], {
		queryFn: () =>
			getClosetsList({
				page,
				category: gender,
				per_page: 250
			}),
		onSuccess: res => {
			setLockers(res.data)
		}
	})

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentId(event.currentTarget.id)
		setCurrentLocker(lockers?.find((n: { id: number }) => n.id.toString() === event.currentTarget.id.toString()))
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleDeleteOpenModal = () => setOpenDeleteModal(true)
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)

	const handleOpenModal = () => setOpenModal(true)
	const handleCloseModal = () => setOpenModal(false)

	const handleOpenAllModal = () => setOpenAllModal(true)
	const handleCloseAllModal = () => setOpenAllModal(false)

	// PUSHER_APP_ID=app_id
	// PUSHER_APP_KEY=app_key
	// PUSHER_APP_SECRET=app_secret
	// PUSHER_HOST=195.49.210.120
	// PUSHER_PORT=6001
	// PUSHER_HOST_AUTH=https://api.zerbulakfamily.kz/broadcasting/auth
	// PUSHER_SCHEME=https
	// PUSHER_APP_CLUSTER=mt1

	useEffect(() => {
		const ACCESS_TOKEN = localStorage.getItem('ACCESS_TOKEN')
		// @ts-ignore
		const echo = new Echo({
			broadcaster: 'pusher',
			cluster: 'mt1',
			key: 'app_key',
			wsHost: 'zerbulakfamily.kz',
			wsPort: '6001',
			forceTLS: false, // Change to true if using HTTPS
			disableStats: false, // Optional: Disable client stat tracking
			authEndpoint: 'https://api.zerbulakfamily.kz/back_office/broadcasting/auth', // Laravel broadcasting authentication endpoint
			auth: {
				headers: {
					Authorization: `Bearer ${ACCESS_TOKEN}`
				}
			}
		})
		echo.private('closets').notification((notification: any) => {
			setLockers((current: any) =>
				current.map((obj: any) => {
					if (obj.id === notification?.closet?.id) {
						return { ...obj, state: notification?.state }
					}

					return obj
				})
			)
		})
	}, [])

	const onClickAddManage = (id: any) => () => {
		navigate(`/management_closet/${id}?gender=${gender}&page=${page}`)
	}

	const deleteClosetsOwnerMutate = useMutation({
		mutationFn: (closet_id: any) => deleteClosetsOwner(closet_id),
		onSuccess: () => {
			toast.success('Successfully deleted')
			setOpenDeleteModal(false)
			setAnchorEl(null)
			refetch()
		}
	})

	const openClosetMutate = useMutation({
		mutationFn: (closet_id: any) => openCustomerActiveClosets(closet_id),
		onSuccess: () => {
			toast.success('Шкаф успешно открыт!')
			setOpenModal(false)
			setAnchorEl(null)
			refetch()
		}
	})

	const openAllClosetsMutate = useMutation({
		mutationFn: (data: any) => openAllClosets(data),
		onSuccess: () => {
			toast.success('Шкафы успешно открыты!')
			setOpenAllModal(false)
			setAnchorEl(null)
			refetch()
		}
	})

	const onClickRemoveManage = () => {
		deleteClosetsOwnerMutate.mutate(currentId)
	}

	const onClickOpenManage = () => {
		openClosetMutate.mutate(currentId)
	}

	const onClickOpenAllManage = () => {
		openAllClosetsMutate.mutate({})
	}

	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%', marginRight: '16px' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				<Typography variant='title_2'>
					{gender === 'male' ? 'Мужской' : 'Женская'} ({Number(page) * 250 - 250}-{Number(page) * 250})
				</Typography>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='space-between' p={3}>
				<Box display='flex'>
					<Box display='flex' alignItems='center' mr={3}>
						<span
							style={{
								display: 'block',
								width: '20px',
								height: '20px',
								background: '#349EFF',
								borderRadius: '4px',
								marginRight: 4
							}}
						/>
						<Typography>– Свободный</Typography>
					</Box>
					<Box display='flex' alignItems='center' mr={3}>
						<span
							style={{
								display: 'block',
								width: '20px',
								height: '20px',
								background: '#D6D6D6',
								borderRadius: '4px',
								marginRight: 4
							}}
						/>
						<Typography>– Занятый</Typography>
					</Box>
					<Box display='flex' alignItems='center'>
						<span
							style={{
								display: 'block',
								width: '20px',
								height: '20px',
								background: '#FFDEC0',
								borderRadius: '4px',
								marginRight: 4
							}}
						/>
						<Typography>– Забронированный</Typography>
					</Box>
				</Box>
				{profile?.user?.role.permissions.includes('update_manage_closet') && (
					<Button variant='outlined' sx={{ borderColor: '#D6D6D6' }} onClick={handleOpenAllModal}>
						<span style={{ color: '#181818' }}>Открыть все шкафы</span>
					</Button>
				)}
			</Box>
			<Box display='flex' flexWrap='wrap' p={3}>
				{lockers?.map((locker: any) => (
					<Box position='relative' width={80} mr={3} mb={3}>
						<span
							style={{
								position: 'absolute',
								left: '25%',
								top: '-20%',
								width: '40px',
								height: '40px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								background: '#FFFFFF',
								border: '1px solid #D6D6D6',
								borderRadius: '40px',
								zIndex: 99
							}}
						>
							{locker.state === 'locked' ? <Lock style={{ fill: 'red' }} /> : <LockOpen />}
						</span>
						<Button
							id={locker.id}
							aria-controls={open ? 'basic-menu' : undefined}
							aria-haspopup='true'
							aria-expanded={open ? 'true' : undefined}
							onClick={handleClick}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: '80px',
								height: '90px',
								background:
									// eslint-disable-next-line no-nested-ternary
									locker.status === 'reserved'
										? '#FFDEC0'
										: locker.status === 'free'
										? '#349EFF'
										: '#D6D6D6',
								borderRadius: '12px',
								wordBreak: 'break-word'
							}}
						>
							<Typography color='black'>{locker.number}</Typography>
						</Button>
						<StyledMenu
							id='basic-menu'
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button'
							}}
						>
							<Box
								display='flex'
								flexDirection='column'
								sx={{
									width: '350px',
									height: '184px',
									background: '#FFFFFF',
									border: '1px solid #D6D6D6',
									boxShadow: '0px 2px 12px rgba(51, 51, 51, 0.12)',
									borderRadius: '12px',
									padding: '12px'
								}}
							>
								<Box display='flex' flexDirection='column' alignItems='start' mb={2}>
									<Typography variant='footnote_2' color='primary.gray'>
										Владелец:
									</Typography>
									<Typography variant='footnote_1'>
										{currentLocker?.owner
											? `${currentLocker?.owner?.first_name} ${currentLocker?.owner?.last_name}`
											: '-'}
									</Typography>
									{currentLocker?.owner ? (
										<Button
											onClick={handleDeleteOpenModal}
											color='error'
											disabled={!profile?.user?.role.permissions.includes('update_manage_closet')}
											size='small'
										>
											Убрать владельца
										</Button>
									) : (
										<Button
											onClick={onClickAddManage(currentLocker?.id)}
											size='small'
											disabled={!profile?.user?.role.permissions.includes('update_manage_closet')}
										>
											Добавить владельца
										</Button>
									)}
								</Box>
								<Box display='flex' gap={1}>
									<Typography variant='footnote_2' color='primary.gray'>
										Статус:
									</Typography>
									{currentLocker?.status && (
										<Typography variant='footnote_1'>
											{
												ManageClosestStatus[
													currentLocker?.status as keyof typeof ManageClosestStatus
												]
											}
										</Typography>
									)}
								</Box>
								<Box display='flex' gap={1}>
									<Typography variant='footnote_2' color='primary.gray'>
										Состояние:
									</Typography>
									{currentLocker?.state && (
										<Typography variant='footnote_1'>
											{
												ManageClosestState[
													currentLocker?.state as keyof typeof ManageClosestState
												]
											}
										</Typography>
									)}
								</Box>
								<Box display='flex' flexDirection='column' alignItems='start' mb={2}>
									<Button
										onClick={handleOpenModal}
										disabled={!profile?.user?.role.permissions.includes('update_manage_closet')}
										size='small'
									>
										Открыть шкаф
									</Button>
								</Box>
							</Box>
						</StyledMenu>
					</Box>
				))}
			</Box>
			<Dialog
				open={openDeleteModal}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем убрать владельца, убедитесь в том что это необходимо
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={handleDeleteCloseModal}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={onClickRemoveManage} autoFocus variant='text' color='error'>
						Да, убрать
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Шкаф является занятым клиентом. Сообщение об открытии шкафа вами, придет владельцу данного шкафа
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button onClick={handleCloseModal} variant='text' color='success' style={{ marginBottom: '16px' }}>
						Нет, оставить
					</Button>
					<Button onClick={onClickOpenManage} autoFocus variant='text' color='error'>
						Да, открыть
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openAllModal}
				onClose={handleCloseAllModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Сообщение об открытии шкафов вами, придет владельцам этих шкафов
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={handleCloseAllModal}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={onClickOpenAllManage} autoFocus variant='text' color='error'>
						Да, открыть
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
