import { Box, Button, Chip, Divider, Grid, ListSubheader, Modal, Stack, TextField, Typography } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { useApp } from 'core/contexts/profile.context'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { getTicketsByDates, postTicketsByDates, putTicketsByPeriod } from 'services/tickets/tickets.service'
import { getTicketsCategories } from 'services/ticketsCategory/tickets-category.service'

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 492,
	height: 320,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4
}

const stylePeriod = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 492,
	height: 360,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4
}

export const TicketsManageDates = () => {
	const [count, setCount] = React.useState()
	const { profile } = useApp()
	const [categoryId, setCategoryId] = React.useState()
	const [openModal, setOpenModal] = React.useState(false)
	const [openModalDates, setOpenModalDates] = React.useState(false)
	const [selectDate, setSelectDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'))
	const [startDate, setStartDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'))
	const [endDate, setEndDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'))

	const { data: categories } = useQuery(['getTicketsCategories'], {
		queryFn: () => getTicketsCategories()
	})

	const { data: dateTickets, refetch } = useQuery(['getTicketsByDates', selectDate, categoryId], {
		queryFn: () => getTicketsByDates(categoryId, selectDate),
		enabled: !!categoryId && !!selectDate
	})

	const ticketsDateMutation = useMutation({
		mutationFn: (payload: any) => postTicketsByDates(payload),
		onSuccess: () => {
			setOpenModal(false)
			setCount(undefined)
			refetch()
			toast.success('Successfully added')
		}
	})

	const ticketsPeriodMutation = useMutation({
		mutationFn: (payload: any) => putTicketsByPeriod(payload),
		onSuccess: () => {
			setOpenModalDates(false)
			setCount(undefined)
			setStartDate(moment(new Date()).format('YYYY-MM-DD'))
			setEndDate(moment(new Date()).format('YYYY-MM-DD'))
			refetch()
			toast.success('Successfully added')
		}
	})
	const handleClick = (date: any) => () => {
		setSelectDate(date)
	}
	const onClickCategory = (category_id: any) => () => {
		setCategoryId(category_id)
	}
	const weekDays = (date: any) => {
		return Array(7)
			.fill(new Date(date))
			.map((el, idx) => new Date(el.setDate(el.getDate() - el.getDay() + idx + 1)))
	}

	const onChangeCount = (e: any) => {
		setCount(e.target.value)
	}
	const onChangeStartDate = (e: any) => {
		setStartDate(e.target.value)
	}
	const onChangeEndDate = (e: any) => {
		setEndDate(e.target.value)
	}

	const handleCloseModal = () => setOpenModal(false)
	const handleCloseModalDates = () => setOpenModalDates(false)
	const onClickApprove = () => {
		const data = {
			quantity: count,
			category_id: categoryId,
			date: selectDate
		}
		ticketsDateMutation.mutate(data)
	}
	const onClickPeriodApprove = () => {
		const data = {
			quantity: count,
			category_id: categoryId,
			start_date: startDate,
			end_date: endDate
		}
		ticketsPeriodMutation.mutate(data)
	}

	useEffect(() => {
		if (categories?.data?.length > 0) {
			setCategoryId(categories?.data[0].id)
		}
	}, [categories])

	return (
		<>
			<Grid container spacing={2}>
				<Grid display='flex' item xs={3}>
					<Box display='flex' flexDirection='column' flex='1'>
						<Box flex='1' p={3}>
							<nav>
								<Box mb={1}>
									<List
										sx={{
											padding: '0'
										}}
										subheader={
											<ListSubheader
												sx={{
													padding: '0',
													marginBottom: '16px',
													fontWeight: '700',
													fontsize: '16px',
													lineHeight: '20px',
													letterSpacing: '0.6px',
													color: '#181818'
												}}
											>
												Категории
											</ListSubheader>
										}
									>
										{categories?.data.map(({ id: listId, name }: any) => (
											<ListItem
												key={listId}
												sx={{
													padding: '0'
												}}
											>
												<ListItemButton
													sx={{
														height: '52px',
														'&.Mui-selected': {
															background: '#E2F1FF',
															svg: {
																fill: '#349EFF'
															}
														}
													}}
													selected={categoryId === listId}
													onClick={onClickCategory(listId)}
												>
													<ListItemText
														primary={name}
														primaryTypographyProps={{
															variant: 'body_2',
															color: categoryId === listId ? '#000000' : '#575757'
														}}
													/>
												</ListItemButton>
											</ListItem>
										))}
									</List>
								</Box>
							</nav>
						</Box>
					</Box>
					<Divider orientation='vertical' flexItem />
				</Grid>
				<Grid
					item
					xs={9}
					mt={5}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
				>
					<Stack direction='row' spacing={1}>
						{weekDays(new Date()).map(day => (
							<Chip
								label={moment(day).format('ddd - DD.MM')}
								variant={selectDate === moment(day).format('YYYY-MM-DD') ? 'filled' : 'outlined'}
								onClick={handleClick(moment(day).format('YYYY-MM-DD'))}
							/>
						))}
					</Stack>
					{profile?.user?.role.permissions.includes('update_manage_ticket') && (
						<Box mt={3}>
							<Button size='small' variant='outlined' onClick={() => setOpenModalDates(true)}>
								<span style={{ color: '#349EFF' }}> Указать другой период</span>
							</Button>
						</Box>
					)}
					<Box mt={19}>
						<Box display='flex' alignItems='center'>
							<Box display='flex' flexDirection='column' mr={4}>
								<span
									style={{
										fontStyle: 'normal',
										fontWeight: 700,
										fontSize: '88px',
										lineHeight: '120px',
										textAlign: 'center',
										color: '#24D321'
									}}
								>
									{dateTickets?.data?.counts?.total}
								</span>
								<Typography>Количество проданных билетов</Typography>
							</Box>
							<span
								style={{
									fontStyle: 'normal',
									fontWeight: 700,
									fontSize: '32px',
									lineHeight: '40px',
									textAlign: 'center',
									color: '#7D7D7D'
								}}
							>
								из
							</span>
							<Box display='flex' flexDirection='column' ml={4}>
								<span
									style={{
										fontStyle: 'normal',
										fontWeight: 700,
										fontSize: '88px',
										lineHeight: '120px',
										textAlign: 'center',
										color: '#000000'
									}}
								>
									{dateTickets?.data?.quantity}
								</span>
								{profile?.user?.role.permissions.includes('update_manage_ticket') && (
									<Button onClick={() => setOpenModal(true)}>Изменить количество</Button>
								)}
							</Box>
						</Box>
						<Box display='flex' mt={7} justifyContent='center'>
							<Typography mr={4}>Активный: {dateTickets?.data?.counts?.active}</Typography>
							<Typography mr={4}>Использованный: {dateTickets?.data?.counts?.used}</Typography>
							<Typography>Просроченный: {dateTickets?.data?.counts?.overdue}</Typography>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style} display='flex' flexDirection='column'>
					<Typography variant='headline_1'>Задать количество</Typography>
					<Typography variant='footnote_1'>Укажите лимит продажи билетов</Typography>
					<Box my={2}>
						<TextField
							label='Введите количество'
							placeholder='Введите количество'
							required
							fullWidth
							name='number'
							value={count}
							onChange={onChangeCount}
							type='number'
						/>
					</Box>
					<Box display='flex' justifyContent='space-between'>
						<Button onClick={() => setOpenModal(false)} variant='outlined' color='error'>
							Отменить
						</Button>
						<Button variant='contained' onClick={onClickApprove}>
							Подтвердить
						</Button>
					</Box>
				</Box>
			</Modal>
			<Modal
				open={openModalDates}
				onClose={handleCloseModalDates}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={stylePeriod} display='flex' flexDirection='column'>
					<Typography variant='headline_1'>Задать количество</Typography>
					<Typography variant='footnote_1'>Укажите лимит продажи билетов</Typography>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<TextField
								label='Начало периода'
								placeholder=''
								required
								fullWidth
								value={startDate}
								onChange={onChangeStartDate}
								type='date'
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label='Конец периода'
								placeholder=''
								required
								value={endDate}
								onChange={onChangeEndDate}
								fullWidth
								type='date'
							/>
						</Grid>
					</Grid>
					<Box my={2}>
						<TextField
							label='Введите количество'
							placeholder='Введите количество'
							required
							fullWidth
							name='number'
							value={count}
							onChange={onChangeCount}
							type='number'
						/>
					</Box>
					<Box display='flex' justifyContent='space-between'>
						<Button onClick={() => setOpenModalDates(false)} variant='outlined' color='error'>
							Отменить
						</Button>
						<Button variant='contained' onClick={onClickPeriodApprove}>
							Подтвердить
						</Button>
					</Box>
				</Box>
			</Modal>
		</>
	)
}
