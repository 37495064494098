import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from '@mui/material'
import EditAvatar from 'components/avatar/EditAvatar'
import { ArrowBack, PhoneIphone } from 'core/icons'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import {
	createEmployee,
	deleteEmployee,
	getEmployeeInfo,
	restoreEmployee,
	updateEmployee
} from 'services/employees/employees.service'
import { getRolesList } from 'services/roles/roles.service'

export const StuffsDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const {
		register,
		handleSubmit,
		reset,
		control,
		setValue,
		formState: { errors }
	} = useForm<any>()
	const [openModal, setOpenModal] = React.useState(false)
	const [image, setImage] = useState<any>()
	const [fileImage, setFileImage] = useState<any>()
	const [phone, setPhone] = useState<string>()
	const [reasonText, setReasonText] = React.useState('')
	const stuffsMutation = useMutation({
		mutationFn: payload => createEmployee(payload),
		onSuccess: () => {
			reset()
			navigate('/stuffs')
			toast.success('Сотрудник успешно добавлен!')
		}
	})

	const stuffsUpdateMutation = useMutation({
		mutationFn: ({ employee_id, body }: any) => updateEmployee({ employee_id, body }),
		onSuccess: () => {
			toast.success('Изменения успешно сохранены!')
			navigate('/stuffs')
		}
	})

	const { data: roles } = useQuery(['getRolesList'], {
		queryFn: () => getRolesList()
	})

	const { data: staffInfo } = useQuery(['getRolesList', id], {
		queryFn: () => getEmployeeInfo(id),
		enabled:
			!!id &&
			(location.search.indexOf('edit') > 0 ||
				location.search.indexOf('restore') > 0 ||
				location.search.indexOf('view') > 0)
	})
	const deleteStaffMutate = useMutation({
		mutationFn: ({ employee_id, reason }: any) => deleteEmployee({ employee_id, reason }),
		onSuccess: () => {
			toast.success('Сотрудник успешно удален!')
			setReasonText('')
			navigate('/stuffs')
		}
	})
	const restoreStaffMutate = useMutation({
		mutationFn: (employee_id: any) => restoreEmployee(employee_id),
		onSuccess: () => {
			toast.success('Сотрудник снова принят на работу!')
			navigate('/stuffs')
		}
	})
	const onChangeReason = (e: any) => {
		setReasonText(e.target.value)
	}
	const onSubmit = (formValues: any) => {
		const fd: any = new FormData()
		const PhoneMask = phone?.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')
		const numberPhone = Number(PhoneMask)
		if (fileImage || image) {
			if (fileImage) fd.append('avatar', fileImage)
			fd.append('first_name', formValues.first_name)
			fd.append('last_name', formValues.last_name)
			fd.append('phone', numberPhone)
			fd.append('email', formValues.email)
			fd.append('iin', formValues.iin)
			fd.append('role_id', formValues.role_id)
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions
			location.search.indexOf('edit') > 0
				? stuffsUpdateMutation.mutate({ employee_id: id, body: fd })
				: stuffsMutation.mutate(fd)
		} else {
			toast.error('Сотрудник нужен фото!')
		}
	}

	const handleStaffDelete = () => {
		if (reasonText) {
			deleteStaffMutate.mutate({ employee_id: id, reason: reasonText })
		} else {
			toast.error('Нужнa причина!')
		}
	}
	const handleRestoreStaff = () => {
		restoreStaffMutate.mutate(id)
	}

	const onAvatarUpload = (file: any) => {
		setFileImage(file)
		setImage(URL.createObjectURL(file))
	}
	const handleOpenModal = () => setOpenModal(true)
	const handleCloseModal = () => setOpenModal(false)

	const onChangePhone = useCallback((e: any) => {
		setPhone(e.target.value)
	}, [])

	useEffect(() => {
		if (staffInfo) {
			setImage(staffInfo.data.avatar_url)
			setValue('first_name', staffInfo.data.first_name)
			setValue('last_name', staffInfo.data.last_name)
			setValue('birth_date', staffInfo.data.birth_date)
			setValue('role_id', staffInfo.data.role.id)
			setValue('email', staffInfo.data.email)
			setValue('phone', staffInfo.data.phone)
			setPhone(`${staffInfo.data.phone}`)
			setValue('iin', staffInfo.data.iin)
			setValue('gender', staffInfo.data.gender)
		}
	}, [setValue, staffInfo])
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={handleOpenModal}>
						Уволить
					</Button>
				)}
				{location.search.indexOf('restore') > 0 && (
					<Button variant='outlined' onClick={handleRestoreStaff}>
						<span style={{ color: '#24D321' }}>Отозвать сотрудника</span>
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={824}>
					<Typography variant='headline_1'>
						{location.search.indexOf('edit') > 0 ||
						location.search.indexOf('restore') > 0 ||
						location.search.indexOf('view') > 0
							? 'Данные сотрудника'
							: 'Добавление сотрудника'}
					</Typography>
					{roles?.data && (
						<Box display='flex'>
							<Typography variant='body1' color='grey' mr={2}>
								ID: <strong>{roles.data.id}</strong>
							</Typography>
							<Typography variant='body1' color='grey'>
								Дата добавления: <strong>{moment(roles.data.created_at).format('DD MMM YYYY')}</strong>
							</Typography>
						</Box>
					)}
					<Box display='flex' mt={6}>
						<EditAvatar
							src={image}
							onUpload={onAvatarUpload}
							disabled={location.search.indexOf('restore') > 0 || location.search.indexOf('view') > 0}
						/>
						<form
							onSubmit={handleSubmit(onSubmit)}
							noValidate
							style={{ width: '100%', marginLeft: '40px' }}
						>
							<Box mb={2}>
								<Typography variant='title_1'>Персональные данные</Typography>
								<TextField
									label='Имя'
									placeholder=''
									required
									fullWidth
									type='string'
									disabled={location.search.indexOf('view') > 0}
									error={!!errors.first_name}
									{...register('first_name')}
								/>
								<TextField
									label='Фамилия'
									placeholder=''
									required
									fullWidth
									type='string'
									disabled={location.search.indexOf('view') > 0}
									error={!!errors.last_name}
									{...register('last_name')}
								/>
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<TextField
											label='ИИН'
											placeholder=''
											required
											fullWidth
											type='number'
											disabled={location.search.indexOf('view') > 0}
											error={!!errors.iin}
											{...register('iin')}
										/>
									</Grid>
									<Grid item xs={6}>
										{roles?.data && (
											<Controller
												control={control}
												name='role_id'
												defaultValue=''
												render={({ field: { ...field } }) => (
													<FormControl required fullWidth>
														<InputLabel id='demo-simple-select-label'>Должность</InputLabel>
														<Select
															{...field}
															labelId='demo-simple-select-label'
															id='demo-simple-select'
															disabled={location.search.indexOf('view') > 0}
														>
															{roles?.data?.map((role: any) => {
																return (
																	<MenuItem value={Number(role.id)} key={role.id}>
																		{role.name}
																	</MenuItem>
																)
															})}
														</Select>
													</FormControl>
												)}
											/>
										)}
									</Grid>
								</Grid>
								<Typography variant='title_1'>Контакты</Typography>

								<Grid container spacing={1}>
									<Grid item xs={6}>
										<InputMask
											mask='9 (999) 999-99-99'
											maskChar=''
											maskPlaceholder=''
											onChange={onChangePhone}
											disabled={location.search.indexOf('view') > 0}
											value={phone}
											alwaysShowMask={false}
										>
											<TextField
												fullWidth
												label='Введите номер'
												type='text'
												InputProps={{
													startAdornment: (
														<InputAdornment position='start'>
															<PhoneIphone />
														</InputAdornment>
													)
												}}
											/>
										</InputMask>
									</Grid>
									<Grid item xs={6} mb={6}>
										<TextField
											label='Почта'
											placeholder=''
											required
											fullWidth
											disabled={location.search.indexOf('view') > 0}
											type='email'
											{...register('email', {
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message: 'Неправильная почта'
												}
											})}
											helperText={!!errors.email && 'Неправильная почта'}
											error={!!errors.email}
											{...register('email')}
										/>
									</Grid>
								</Grid>
								{location.search.indexOf('restore') < 0 && location.search.indexOf('view') < 0 && (
									<Button
										variant='contained'
										type='submit'
										color='success'
										style={{ width: '252px' }}
									>
										{stuffsMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
									</Button>
								)}
							</Box>
						</form>
					</Box>
				</Box>
			</Box>
			<Dialog
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
					<Box mt={2}>
						<TextField
							label='Причина удаления'
							placeholder=''
							fullWidth
							multiline
							rows={3}
							type='text'
							value={reasonText}
							onChange={onChangeReason}
							name='reason'
						/>
					</Box>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button onClick={handleCloseModal} variant='text' color='success' style={{ marginBottom: '16px' }}>
						Нет, оставить
					</Button>
					<Button onClick={handleStaffDelete} autoFocus variant='text' disabled={!reasonText} color='error'>
						Да, уволить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
