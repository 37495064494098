import { Box, Button, Divider, Typography } from '@mui/material'
import { ArrowBack } from 'core/icons'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { ManageClosetsHistoryCardComponent } from './manage-closets-history-card.component'
import { ManageClosetsHistoryFilterComponent } from './manage-closets-history-filter.component'
import { historyList } from './mock'

export const ManageClosetsHistoryPage = () => {
	const navigate = useNavigate()

	const [search, setSearch] = React.useState('')
	const [point, setPoint] = React.useState('1')
	const [status, setStatus] = React.useState('all')

	return (
		<div>
			<Box p={2} display='flex' alignItems='center' gap={2}>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} />
					<Typography variant='footnote_2'>Назад</Typography>
				</Button>
				<Box flexGrow={1}>
					<Typography variant='title_2'>История открытий шкафов</Typography>
				</Box>
			</Box>
			<Divider />
			<ManageClosetsHistoryFilterComponent
				search={search}
				point={point}
				status={status}
				setSearch={setSearch}
				setPoint={setPoint}
				setStatus={setStatus}
			/>
			<Divider />
			<Box sx={{ maxWidth: 496, mx: 'auto' }}>
				{historyList.map(historyItem => (
					<Box display='flex' flexDirection='column' mt={5}>
						<Typography variant='title_1'>{historyItem.day}</Typography>
						{historyItem.orders.map(order => (
							<ManageClosetsHistoryCardComponent {...order} key={order.number} />
						))}
					</Box>
				))}
			</Box>
		</div>
	)
}
