import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	Switch,
	TextField,
	Typography
} from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import { ArrowBack } from 'core/icons'
import moment from 'moment'
import { clientsPermissions, managePermissions } from 'pages/RolesDetail/enum'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import { createRoles, deleteRoles, getRoleById, updateRoles } from 'services/roles/roles.service'
import { useBoolean } from 'usehooks-ts'

export const RolesDetailPage = () => {
	const { refetchProfile } = useApp()
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm<any>()

	const modalOpen = useBoolean(false)
	const [permissionArray, setPerimissionArray] = useState<any>()
	const rolesMutation = useMutation({
		mutationFn: payload => createRoles(payload),
		onSuccess: () => {
			reset()
			navigate('/accesses_roles')
			toast.success('Successfully added')
		}
	})

	const roleUpdateMutation = useMutation({
		mutationFn: ({ role_id, body }: any) => updateRoles({ role_id, body }),
		onSuccess: () => {
			toast.success('Successfully updated')
			refetchProfile()
			navigate('/accesses_roles')
		}
	})

	const { data: role } = useQuery(['getRoleById', id, location], {
		queryFn: () => getRoleById(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})

	const deletePointerMutate = useMutation({
		mutationFn: (rolesId: any) => deleteRoles(rolesId),
		onSuccess: () => {
			toast.success('Successfully deleted')
			navigate('/accesses_roles')
		}
	})

	const handleServiceDelete = () => {
		deletePointerMutate.mutate(id)
	}
	const onSubmit = (formValues: any) => {
		const data = {
			...formValues,
			permissions: permissionArray
		}
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		location.search.indexOf('edit') > 0
			? roleUpdateMutation.mutate({ role_id: id, body: data })
			: rolesMutation.mutate(data)
	}
	const handleChangePermission = (event: any) => {
		const arrayForKeys: any = []
		const permissionsMap = new Map()
		permissionArray?.forEach((per: any) => permissionsMap.set(per, true))
		if (event.target.checked) {
			permissionsMap.set(event.target.name, event.target.checked)
			permissionsMap.forEach((value, key) => {
				arrayForKeys.push(key)
			})
		} else {
			permissionsMap.delete(event.target.name)
			permissionsMap.forEach((value, key) => {
				arrayForKeys.push(key)
			})
		}
		setPerimissionArray(arrayForKeys)
	}

	const checkHasPermission = useCallback(
		(key: any) => {
			if (permissionArray) {
				return permissionArray.filter((a: any) => a === key).length > 0
			}
			return undefined
		},
		[permissionArray]
	)

	useEffect(() => {
		if (role) {
			const permissionsMap = new Map()
			const arrayForKeys: any = []
			setValue('name', role.data.name)
			setValue('description', role.data.description)
			role?.data?.permissions.forEach((per: any) => permissionsMap.set(per, true))
			permissionsMap.forEach((value, key) => {
				arrayForKeys.push(key)
			})
			setPerimissionArray(arrayForKeys)
		}
	}, [setValue, role])
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={modalOpen.setTrue}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
				<Box display='flex' flexDirection='column' alignItems='center'>
					<Box mt={6} width={520} mb={6}>
						<Typography variant='headline_1'>
							{location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0
								? 'Данные роли'
								: 'Добавление роли'}
						</Typography>
						{role?.data && (
							<Box display='flex'>
								<Typography variant='body1' color='grey'>
									Дата добавления:{' '}
									<strong>{moment(role.data.created_at).format('DD MMM YYYY')}</strong>
								</Typography>
							</Box>
						)}
						<Box
							mb={2}
							mt={4}
							width={520}
							sx={{
								background: '#F7F7F7',
								borderRadius: '12px',
								padding: '24px'
							}}
						>
							<Typography
								variant='title_1'
								color='secondary.black'
								component='div'
								style={{ marginBottom: '24px' }}
							>
								Заполните поля
							</Typography>
							<TextField
								label='Введите наименование должности'
								placeholder=''
								fullWidth
								disabled={location.search.indexOf('view') > 0}
								type='text'
								error={!!errors.name}
								{...register('name')}
							/>
							<TextField
								label='Описание должности'
								placeholder=''
								disabled={location.search.indexOf('view') > 0}
								required
								fullWidth
								type='text'
								error={!!errors.description}
								{...register('description')}
							/>
						</Box>
					</Box>
					<Grid container spacing={2} ml={0}>
						<Grid item xs={1}>
							<Typography variant='body_1' color='primary.black'>
								№
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant='body_1' color='primary.black'>
								Разделы
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant='body_1' color='primary.black'>
								Просмотр
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography variant='body_1' color='primary.black'>
								Редактирование
							</Typography>
						</Grid>
					</Grid>
					<Divider flexItem />
					<Box display='flex' justifyContent='start' width='100%' p={3}>
						<Typography variant='title_1' color='primary.black'>
							Справочники
						</Typography>
					</Box>
					{clientsPermissions.map(cpm => (
						<React.Fragment key={cpm.name}>
							<Grid container spacing={2} alignItems='center' ml={0}>
								<Grid item xs={1}>
									<Typography variant='footnote_2' color='secondary.black'>
										{cpm.id}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant='footnote_2' color='secondary.black'>
										{cpm.name}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<FormControlLabel
										value='start'
										control={
											<Switch
												color='primary'
												disabled={location.search.indexOf('view') > 0}
												name={cpm.view}
												checked={checkHasPermission(cpm.view)}
												onChange={handleChangePermission}
											/>
										}
										sx={{ marginLeft: 0 }}
										label={
											<Typography variant='footnote_2' color='secondary.black'>
												{permissionArray?.includes(cpm.view) ? 'Включено' : 'Выключено'}
											</Typography>
										}
										labelPlacement='start'
									/>
								</Grid>
								<Grid item xs={3}>
									<FormControlLabel
										value='start'
										control={
											<Switch
												color='primary'
												disabled={location.search.indexOf('view') > 0}
												name={cpm.edit}
												checked={checkHasPermission(cpm.edit)}
												onChange={handleChangePermission}
											/>
										}
										sx={{ marginLeft: 0 }}
										label={
											<Typography variant='footnote_2' color='secondary.black'>
												{permissionArray?.includes(cpm.edit) ? 'Включено' : 'Выключено'}
											</Typography>
										}
										labelPlacement='start'
									/>
								</Grid>
							</Grid>
							<Divider sx={{ margin: '8px 0' }} flexItem />
						</React.Fragment>
					))}
					<Box display='flex' justifyContent='start' width='100%' p={3}>
						<Typography variant='title_1' color='primary.black'>
							Управления
						</Typography>
					</Box>
					{managePermissions.map(cpm => (
						<React.Fragment key={cpm.name}>
							<Grid container spacing={2} alignItems='center' ml={0}>
								<Grid item xs={1}>
									<Typography variant='footnote_2' color='secondary.black'>
										{cpm.id}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant='footnote_2' color='secondary.black'>
										{cpm.name}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<FormControlLabel
										value='start'
										control={
											<Switch
												color='primary'
												disabled={location.search.indexOf('view') > 0}
												name={cpm.view}
												checked={checkHasPermission(cpm.view)}
												onChange={handleChangePermission}
											/>
										}
										sx={{ marginLeft: 0 }}
										label={
											<Typography variant='footnote_2' color='secondary.black'>
												{permissionArray?.includes(cpm.view) ? 'Включено' : 'Выключено'}
											</Typography>
										}
										labelPlacement='start'
									/>
								</Grid>
								{cpm.edit && (
									<Grid item xs={3}>
										<FormControlLabel
											value='start'
											control={
												<Switch
													color='primary'
													disabled={location.search.indexOf('view') > 0}
													name={cpm.edit}
													checked={checkHasPermission(cpm.edit)}
													onChange={handleChangePermission}
												/>
											}
											sx={{ marginLeft: 0 }}
											label={
												<Typography variant='footnote_2' color='secondary.black'>
													{permissionArray?.includes(cpm.edit) ? 'Включено' : 'Выключено'}
												</Typography>
											}
											labelPlacement='start'
										/>
									</Grid>
								)}
							</Grid>
							<Divider sx={{ margin: '8px 0' }} flexItem />
						</React.Fragment>
					))}
					{location.search.indexOf('view') < 0 && (
						<Box width={252} mt={4}>
							<Button variant='contained' type='submit' fullWidth color='success'>
								{rolesMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
							</Button>
						</Box>
					)}
				</Box>
			</form>

			<Dialog
				open={modalOpen.value}
				onClose={modalOpen.setFalse}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={modalOpen.setFalse}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleServiceDelete} autoFocus variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
