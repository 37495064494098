import { Box, Typography } from '@mui/material'
import { DoubleArrowDown, DoubleArrowUp } from 'core/icons'
import React from 'react'

export const EntryCardComponent = ({ cardItem }: any) => {
	return (
		<Box
			display='flex'
			flexDirection='column'
			p={2}
			gap={1}
			sx={{
				marginX: 2,
				marginBottom: 1,
				border: '1px solid rgba(214, 214, 214, 0.48)',
				background: '#FFFFFF',
				borderRadius: 1.5
			}}
		>
			<Box display='flex' alignItems='center'>
				<img
					style={{
						background: '#D9D9D9',
						borderRadius: '50%',
						width: 40,
						height: 40,
						objectFit: 'cover'
					}}
					src={cardItem.customer.avatar_url}
					alt='avatar'
				/>
				<Box display='flex' flexDirection='column' ml={2}>
					<Typography variant='body_2'>
						{cardItem.customer.first_name} {cardItem.customer.last_name}
					</Typography>
					<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
						{cardItem.ticket_type}
					</Typography>
				</Box>
			</Box>
			{cardItem.children_count !== 0 && cardItem.children_count && (
				<Box display='flex' alignItems='center' py={1}>
					<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
						Дети:
					</Typography>
					<Box display='flex' ml={2} gap={0.5}>
						{cardItem?.guests
							?.slice(0, 3)
							.filter((quest: any) => quest.is_child)
							.map((childrenItem: { avatar_url: string }) => (
								<img
									style={{
										background: '#D9D9D9',
										borderRadius: '50%',
										width: 24,
										height: 24,
										objectFit: 'cover'
									}}
									src={childrenItem.avatar_url}
									alt='avatar'
								/>
							))}
						{cardItem.children_count.length > 3 && (
							<Box
								display='flex'
								alignItems='center'
								justifyContent='center'
								sx={{
									width: 24,
									height: 24,
									borderRadius: 3,
									background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #D9D9D9'
								}}
							>
								<Typography variant='footnote_2' sx={{ color: 'primary.white' }}>
									+{cardItem.children_count.length - 3}
								</Typography>
							</Box>
						)}
					</Box>
				</Box>
			)}
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Box display='flex' flexDirection='column' gap={1}>
					<Box display='flex' gap={1}>
						<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
							Билет№:
						</Typography>
						<Typography variant='footnote_1'>{cardItem.ticket_number}</Typography>
					</Box>
					<Box display='flex' gap={1}>
						<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
							Время:
						</Typography>
						<Typography variant='footnote_1'>{cardItem.time}</Typography>
					</Box>
				</Box>
				<Box>{cardItem.type === 'in' ? <DoubleArrowUp /> : <DoubleArrowDown />}</Box>
			</Box>
		</Box>
	)
}
