import {
	Box,
	Button,
	Divider,
	Menu,
	MenuItem,
	Modal,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import React from 'react'
import QRCode from 'react-qr-code'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Dots, Edit, Settings, View } from '../../core/icons'
import { getTowelsList } from '../../services/towels/towel.service'
import { parseLockers } from '../Lockers/parse'

export const TowelsPage = () => {
	const [qrUrl, setQrUrl] = React.useState('')
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 556,
		height: 588,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4
	}
	const navigate = useNavigate()
	const { profile } = useApp()
	const [openModal, setOpenModal] = React.useState(false)

	const handleOpenModal = (type: string) => () => {
		setQrUrl(JSON.stringify({ type }))
		setOpenModal(true)
	}
	const handleCloseModal = () => setOpenModal(false)
	const { data: towels } = useQuery(['getTowelsList'], {
		queryFn: () => getTowelsList()
	})

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleEditTowel = (ids: number) => () => {
		navigate(`/towel/${ids}?edit`)
	}
	const handleViewTowel = (ids: number) => () => {
		navigate(`/towel/${ids}?view`)
	}

	const onClickDownload = () => {
		const svg = document.getElementById('QRCode-towels')
		// @ts-ignore
		const svgData = new XMLSerializer().serializeToString(svg)
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		const img = new Image()
		img.onload = () => {
			canvas.width = img.width * 2.5
			canvas.height = img.height * 2.5
			// @ts-ignore
			ctx.fillStyle = '#FFFFFF'
			// @ts-ignore
			ctx.fillRect(0, 0, 640, 640)
			// @ts-ignore
			ctx.drawImage(img, 70, 70, 500, 500)
			const pngFile = canvas.toDataURL('image/png')
			const downloadLink = document.createElement('a')
			downloadLink.download = 'QRCode-towels'
			downloadLink.href = `${pngFile}`
			downloadLink.click()
		}
		img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
	}

	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Полотенце</Typography>
			</Box>
			<Divider />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell align='left'>ID</TableCell>
							<TableCell align='left'>Наименование</TableCell>
							<TableCell align='right'>Цена</TableCell>
							<TableCell align='right'>QR для выдачи</TableCell>
							<TableCell align='right'>QR для сдачи</TableCell>
							<TableCell align='right'>
								<Settings />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{towels?.data &&
							parseLockers(towels.data).map((row: any) => (
								<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component='th' scope='row'>
										{row.id}
									</TableCell>
									<TableCell align='left'>{row.id}</TableCell>
									<TableCell align='left'>{row.name}</TableCell>
									<TableCell align='right'>{row.price} ⍑</TableCell>
									<TableCell align='right'>
										<Button onClick={handleOpenModal('receive_towel')}>Открыть QR</Button>
									</TableCell>
									<TableCell align='right'>
										<Button onClick={handleOpenModal('return_towel')}>Открыть QR</Button>
									</TableCell>
									<TableCell align='right'>
										<Button
											id='basic-button'
											aria-controls={open ? 'basic-menu' : undefined}
											aria-haspopup='true'
											aria-expanded={open ? 'true' : undefined}
											onClick={handleClick}
										>
											<Dots />
										</Button>
										<Menu
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button'
											}}
										>
											<MenuItem onClick={handleViewTowel(row.id)}>
												<View
													style={{
														color: '#349EFF',
														marginRight: '8px'
													}}
												/>
												Просмотр
											</MenuItem>
											{profile?.user?.role.permissions.includes('update_towel') && (
												<MenuItem onClick={handleEditTowel(row.id)}>
													<Edit
														style={{
															marginRight: '8px'
														}}
													/>
													Редактировать
												</MenuItem>
											)}
										</Menu>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					{qrUrl && (
						<Box p={3}>
							<QRCode
								id='QRCode-towels'
								size={256}
								style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
								value={qrUrl}
								viewBox='0 0 256 256'
							/>
						</Box>
					)}
					<Divider />
					<Box sx={{ p: 2 }}>
						<Button onClick={onClickDownload}>Печать</Button>
					</Box>
				</Box>
			</Modal>
		</div>
	)
}
