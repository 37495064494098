import {
	Box,
	Button,
	Divider,
	Menu,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import { ArrowBack, Dots, Edit, Settings, View } from 'core/icons'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getPrinters } from 'services/printer/printer.service'

/* eslint-disable react/react-in-jsx-scope */
export const CheckPrint = () => {
	const navigate = useNavigate()
	const { profile } = useApp()

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const [currentId, setCurrentId] = useState<any>()

	const { data: printers } = useQuery(['getPrinters'], {
		queryFn: () => getPrinters()
	})

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentId(event.currentTarget.id)
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleEditPrinter = () => {
		navigate(`/printer/${currentId}?edit`)
	}

	const handleViewPrinter = () => {
		navigate(`/printer/${currentId}?view`)
	}

	const onPrinterAdd = () => {
		navigate(`/printer/-1`)
	}

	return (
		<>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Box display='flex' alignItems='center'>
					<Button
						color='info'
						variant='text'
						size='small'
						onClick={() => navigate(-1)}
						sx={{ border: 0, borderRadius: '50%', marginRight: '16px' }}
					>
						<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
					</Button>
					<Typography variant='title_2'>Печать чеков</Typography>
				</Box>
				{profile?.user?.role.permissions.includes('update_product') && (
					<Button variant='contained' onClick={onPrinterAdd}>
						Добавить
					</Button>
				)}
			</Box>
			<Divider />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell align='left'>Наименование</TableCell>
							<TableCell align='left'>IP адрес принтера</TableCell>
							<TableCell align='right'>
								<Settings />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{printers?.data &&
							printers.data.map((row: any) => (
								<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component='th' scope='row'>
										{row.id}
									</TableCell>
									<TableCell align='left'>{row.name}</TableCell>
									<TableCell align='left'>{row.host}</TableCell>
									<TableCell align='right'>
										<Button
											id={row.id}
											aria-controls={open ? 'basic-menu' : undefined}
											aria-haspopup='true'
											aria-expanded={open ? 'true' : undefined}
											onClick={handleClick}
										>
											<Dots />
										</Button>
										<Menu
											id={`basic-menu-${row.id}`}
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button'
											}}
										>
											<MenuItem onClick={handleViewPrinter} key={`menu-item-${row.id}`}>
												<View
													style={{
														color: '#349EFF',
														marginRight: '8px'
													}}
												/>
												Просмотр карточки принтера
											</MenuItem>
											{profile?.user?.role.permissions.includes('update_printer') && (
												<MenuItem onClick={handleEditPrinter} key={`menu-item-${row.id}`}>
													<Edit
														style={{
															marginRight: '8px'
														}}
													/>
													Редактировать
												</MenuItem>
											)}
										</Menu>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}
