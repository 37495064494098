import Echo from 'laravel-echo'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { getCrossings } from 'services/crossings/crossings.service'

import { EntryCardComponent } from './entry-card.component'

export const EntryExitCards = ({ tourniquetId }: any) => {
	const [crossings, setCrossings] = React.useState([])
	useQuery([`getCrossings${tourniquetId}`, tourniquetId], {
		queryFn: () =>
			getCrossings({
				per_page: 9999,
				page: 1,
				tourniquet_id: tourniquetId
			}),
		onSuccess: res => {
			setCrossings(res.data)
		}
	})

	useEffect(() => {
		const ACCESS_TOKEN = localStorage.getItem('ACCESS_TOKEN')
		// @ts-ignore
		const echo = new Echo({
			broadcaster: 'pusher',
			cluster: 'mt1',
			key: 'app_key',
			wsHost: 'zerbulakfamily.kz',
			wsPort: '6001',
			forceTLS: false, // Change to true if using HTTPS
			disableStats: false, // Optional: Disable client stat tracking
			authEndpoint: 'https://api.zerbulakfamily.kz/back_office/broadcasting/auth', // Laravel broadcasting authentication endpoint
			auth: {
				headers: {
					Authorization: `Bearer ${ACCESS_TOKEN}`
				}
			}
		})
		echo.private(`crossings`).listen('.crossing.created', (e: any) => {
			let changed = false
			if (tourniquetId === e?.crossing?.tourniquet_id) {
				setCrossings((current: any) => {
					if (current.length > 0) {
						const newMap = current.map((obj: any) => {
							if (obj.id === e?.crossing?.id && obj.tourniquet_id === e?.crossing?.tourniquet_id) {
								changed = true

								return { ...e?.crossing }
							}
							return obj
						})
						if (!changed) {
							changed = false
							const crosArray = [{ ...e.crossing }] as any
							return crosArray.concat(newMap)
						}
					}
					return [{ ...e.crossing }]
				})
			}
		})
	}, [tourniquetId])
	return (
		<div>
			{crossings?.map((cardItem: any) => (
				<EntryCardComponent cardItem={cardItem} key={cardItem.number} />
			))}
		</div>
	)
}
