import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Link,
	Menu,
	MenuItem,
	Pagination,
	Paper,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	Typography
} from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { BASE_API } from 'core/axios.config'
import { useApp } from 'core/contexts/profile.context'
import { dateFormatter } from 'core/helpers/date-formatter'
import { Document } from 'core/icons'
import moment from 'moment'
import { StatusColor, StatusText } from 'pages/HistoryPayment/enum'
import { parseLockers } from 'pages/Lockers/parse'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { closeSession, getBckOperations, getWalletOperations } from 'services/payment/payment.service'
import { downloadFile } from 'services/report/report.service'
import { useDebounce } from 'usehooks-ts'

import { AnalyticsDialog } from './analytics-dialog'

export const HistoryPaymentPage = () => {
	const navigate = useNavigate()
	const { profile } = useApp()
	const [page, setPage] = React.useState(1)
	const [search, setSearch] = React.useState('')
	const [openCloseModal, setOpenCloseModal] = React.useState(false)
	const [tab, setTab] = React.useState('wallet')
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const [radioValue, setRadioValue] = React.useState('all')

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRadioValue((event.target as HTMLInputElement).value)
	}

	const [openProductsModal, setOpenProductsModal] = React.useState(false)
	const [openStaffModal, setOpenStaffModal] = React.useState(false)

	const handleProductsOpenModal = () => setOpenProductsModal(true)
	const handleProductsCloseModal = () => setOpenProductsModal(false)
	const handleStaffOpenModal = () => setOpenStaffModal(true)
	const handleStaffCloseModal = () => setOpenStaffModal(false)

	const [startDate, setStartDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'))
	const [endDate, setEndDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'))

	const onChangeStartDate = (e: any) => {
		setStartDate(e.target.value)
	}
	const onChangeEndDate = (e: any) => {
		setEndDate(e.target.value)
	}

	const debouncedSearch = useDebounce(search, 500)
	const { data: operations } = useQuery(['getWalletOperations', page, debouncedSearch], {
		queryFn: () =>
			getWalletOperations({
				page,
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})
	const { data: bck } = useQuery(['getBckOperations', page, debouncedSearch], {
		queryFn: () =>
			getBckOperations({
				page,
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})
	const closeSessionMutate = useMutation({
		mutationFn: (data: any) => closeSession(data),
		onSuccess: () => {
			toast.success('Сессии успешно закрыты!')
			setOpenCloseModal(false)
		}
	})

	const handleSessionOpenModal = () => setOpenCloseModal(true)
	const handleSessionCloseModal = () => setOpenCloseModal(false)
	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setTab(newValue)
		setPage(1)
	}
	const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}
	const onClickCloseSession = () => {
		closeSessionMutate.mutate({})
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
		setPage(1)
	}

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		handleProductsCloseModal()
		handleStaffCloseModal()
		setAnchorEl(null)
		setStartDate(moment(new Date()).format('YYYY-MM-DD'))
		setEndDate(moment(new Date()).format('YYYY-MM-DD'))
	}

	const handleSubmitReportProducts = () => {
		let dates = ''
		if (radioValue === 'range') {
			dates = `?start_date=${startDate}&end_date=${endDate}`
		}
		const url = `${BASE_API}/back_office/reports/order${dates}`
		downloadFile(url, 'productsReport')
		handleClose()
	}

	const handleSubmitReportStaff = () => {
		let dates = ''
		if (radioValue === 'range') {
			dates = `?start_date=${startDate}&end_date=${endDate}`
		}
		const url = `${BASE_API}/back_office/reports/employee${dates}`
		downloadFile(url, 'employeesReport')
		handleClose()
	}

	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>История платежей</Typography>
				<Box display='flex' justifyContent='space-between'>
					<Button
						color='info'
						variant='text'
						size='small'
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
						sx={{ border: 0, borderRadius: '50%', marginRight: 5 }}
					>
						<Document style={{ marginRight: '12px' }} />
						Скачать отчет
					</Button>
					<Menu
						id='basic-menu'
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'basic-button'
						}}
					>
						<MenuItem onClick={handleProductsOpenModal} key='menu-item-1'>
							По продажам продуктов
						</MenuItem>
						<MenuItem onClick={handleStaffOpenModal} key='menu-item-2'>
							По продажам официантов
						</MenuItem>
					</Menu>

					<Button
						onClick={handleSessionOpenModal}
						variant='outlined'
						color='error'
						sx={{
							marginRight: '8px'
						}}
					>
						Закрыть сессии касс
					</Button>
					{profile?.user?.role.permissions.includes('wallet_deposit') && (
						<Button onClick={() => navigate(`/payment-history/payment-page`)} variant='contained'>
							Внести платеж
						</Button>
					)}
				</Box>
			</Box>
			<Divider />
			<Box p={2} display='flex' justifyContent='center' alignItems='center' gap={2}>
				<Tabs value={tab} onChange={handleChangeTab} aria-label='wrapped label tabs example'>
					<Tab value='wallet' label='Кошельки' wrapped />
					<Tab value='bck' label='БЦК транзакции' />
				</Tabs>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='end' p={1}>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell align='left'>ID операций</TableCell>
							<TableCell align='left'>Дата и время</TableCell>
							<TableCell align='left'>Операция</TableCell>
							<TableCell align='left'>Тип операций</TableCell>
							<TableCell align='left'>Сумма</TableCell>
							<TableCell align='left'>Остаток</TableCell>
							<TableCell align='left'>Владелец кошелька</TableCell>
							<TableCell align='left'>Метод пополнение</TableCell>
							<TableCell align='left'>№ заказа</TableCell>
							<TableCell align='left'>Статус</TableCell>
							<TableCell align='left'>Проведена ли в WEBKASSA</TableCell>
						</TableRow>
					</TableHead>
					{tab === 'wallet' ? (
						<TableBody>
							{operations?.data &&
								parseLockers(operations.data).map((row: any) => (
									<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<TableCell component='th' scope='row'>
											{row.id}
										</TableCell>
										<TableCell align='left'>{row.id}</TableCell>
										<TableCell align='left'>{dateFormatter(new Date(row.created_at))}</TableCell>
										<TableCell
											align='left'
											sx={{
												color: row.type === 'Пополнение' ? '#24D321' : '#f59b52'
											}}
										>
											<strong>{row.type}</strong>
										</TableCell>
										<TableCell align='left'>{row.purchase_type}</TableCell>
										<TableCell align='left'>
											<strong>{row.amount}</strong>
										</TableCell>
										<TableCell align='left'>
											<strong>{row.amount_after}</strong>
										</TableCell>
										<TableCell align='left'>
											<Link href={`/payment-history/${row.id}`} underline='none' color='inherit'>
												<strong>{row.customer_full_name}</strong>
											</Link>
										</TableCell>
										<TableCell align='left'>{row.provider}</TableCell>
										<TableCell align='left'>{row.order_id}</TableCell>
										<TableCell
											align='center'
											sx={{
												color: StatusColor[row.status as keyof typeof StatusColor]
											}}
										>
											<strong>{StatusText[row.status as keyof typeof StatusText]}</strong>
										</TableCell>
										<TableCell
											align='center'
											sx={{
												color: row.receipt_sent === true ? '#24D321' : '#f55252'
											}}
										>
											<strong>{row.receipt_sent === true ? 'Да' : 'Нет'}</strong>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					) : (
						<TableBody>
							{bck?.data &&
								parseLockers(bck.data).map((bckRow: any) => (
									<TableRow
										key={bckRow.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component='th' scope='row'>
											{bckRow.id}
										</TableCell>
										<TableCell align='left'>{bckRow.id}</TableCell>
										<TableCell align='left'>{dateFormatter(new Date(bckRow.created_at))}</TableCell>
										<TableCell
											align='left'
											sx={{
												color: bckRow.type === 'Списание' ? '#f59b52' : '#24D321'
											}}
										>
											<strong>{bckRow.type}</strong>
										</TableCell>
										<TableCell align='left'>{bckRow.purchase_type}</TableCell>
										<TableCell align='left'>
											<strong>{bckRow.amount}</strong>
										</TableCell>
										<TableCell align='left'>
											<strong>{bckRow.amount_after === null ? '-' : bckRow.amount_after}</strong>
										</TableCell>
										<TableCell align='left'>
											<Link
												href={`/payment-history/${bckRow.id}`}
												underline='none'
												color='inherit'
											>
												<strong>{bckRow.customer_full_name}</strong>
											</Link>
										</TableCell>
										<TableCell align='left'>BCC</TableCell>
										<TableCell align='left'>{bckRow.order_id}</TableCell>
										<TableCell
											align='left'
											sx={{
												color: StatusColor[bckRow.status as keyof typeof StatusColor]
											}}
										>
											<strong>{StatusText[bckRow.status as keyof typeof StatusText]}</strong>
										</TableCell>
										<TableCell
											align='center'
											sx={{
												color: bckRow.receipt_sent === true ? '#24D321' : '#f55252'
											}}
										>
											<strong>{bckRow.receipt_sent === true ? 'Да' : 'Нет'}</strong>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					)}
				</Table>
			</TableContainer>
			{operations?.last_page && (
				<Box display='flex' justifyContent='center'>
					<Pagination
						sx={{ marginTop: '16px' }}
						count={operations.last_page}
						page={page}
						onChange={handleChangePage}
					/>
				</Box>
			)}
			<Dialog
				open={openCloseModal}
				onClose={handleSessionCloseModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Подтвердите ваше действие о закрытии сессии касс
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<Button onClick={handleSessionCloseModal} variant='outlined'>
						<span
							style={{
								color: 'red',
								paddingLeft: '40px',
								paddingRight: '40px'
							}}
						>
							Отменить
						</span>
					</Button>
					<Button onClick={onClickCloseSession} variant='contained' color='info'>
						<span
							style={{
								color: 'white',
								paddingLeft: '55px',
								paddingRight: '55px'
							}}
						>
							Да
						</span>
					</Button>
				</DialogActions>
			</Dialog>
			<AnalyticsDialog
				openModal={openProductsModal}
				handleCloseModal={handleProductsCloseModal}
				value={radioValue}
				handleChange={handleChange}
				title='Скачивание отчета по продажам продуктов'
				startDate={startDate}
				onChangeStartDate={onChangeStartDate}
				endDate={endDate}
				onChangeEndDate={onChangeEndDate}
				handleSubmit={handleSubmitReportProducts}
			/>
			<AnalyticsDialog
				openModal={openStaffModal}
				handleCloseModal={handleStaffCloseModal}
				value={radioValue}
				handleChange={handleChange}
				title='Скачивание отчета по продажам официантов'
				startDate={startDate}
				onChangeStartDate={onChangeStartDate}
				endDate={endDate}
				onChangeEndDate={onChangeEndDate}
				handleSubmit={handleSubmitReportStaff}
			/>
		</div>
	)
}
