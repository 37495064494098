import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	Menu,
	MenuItem,
	Modal,
	Typography
} from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { useApp } from 'core/contexts/profile.context'
import { Cancel, Dots, Edit, View } from 'core/icons'
import React, { useState } from 'react'
import QRCode from 'react-qr-code'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteOtherService, getOtherServices } from 'services/otherServices/other.service'
import { useDebounce } from 'usehooks-ts'

export const ServicesPage = () => {
	const navigate = useNavigate()
	const { profile } = useApp()
	const [qrUrl, setQrUrl] = React.useState('')
	const [openModal, setOpenModal] = React.useState(false)
	const [search, setSearch] = React.useState('')
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const [currentId, setCurrentId] = useState<any>()
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)

	const debouncedSearch = useDebounce(search, 500)
	const { data: services, refetch } = useQuery(['getOtherServices', debouncedSearch], {
		queryFn: () =>
			getOtherServices({
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})
	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 556,
		height: 588,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4
	}
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentId(event.currentTarget.id)
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const deleteServiceMutate = useMutation({
		mutationFn: (service_id: any) => deleteOtherService(service_id),
		onSuccess: () => {
			toast.success('Successfully deleted')
			setOpenDeleteModal(false)
			setAnchorEl(null)
			refetch()
		}
	})

	const onClickDownload = () => {
		const svg = document.getElementById('QRCode-services')
		// @ts-ignore
		const svgData = new XMLSerializer().serializeToString(svg)
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		const img = new Image()
		img.onload = () => {
			canvas.width = img.width * 2.5
			canvas.height = img.height * 2.5
			// @ts-ignore
			ctx.fillStyle = '#FFFFFF'
			// @ts-ignore
			ctx.fillRect(0, 0, 640, 640)
			// @ts-ignore
			ctx.drawImage(img, 70, 70, 500, 500)
			const pngFile = canvas.toDataURL('image/png')
			const downloadLink = document.createElement('a')
			downloadLink.download = 'QRCode-services'
			downloadLink.href = `${pngFile}`
			downloadLink.click()
		}
		img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
	}

	const handleDeleteOpenModal = () => setOpenDeleteModal(true)
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)

	const handleCloseModal = () => setOpenModal(false)
	const handleEditService = () => {
		navigate(`/other_services/${currentId}?edit`)
	}
	const handleViewService = () => {
		navigate(`/other_services/${currentId}?view`)
	}
	const handleOpenModal = (data: any) => () => {
		setQrUrl(JSON.stringify({ type: 'service', id: data.id }))
		setOpenModal(true)
	}
	const handleAddService = () => {
		navigate('/other_services/add')
	}
	const handleServiceDelete = () => {
		deleteServiceMutate.mutate(currentId)
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Прочие услуги</Typography>
				{profile?.user?.role.permissions.includes('update_service') && (
					<Button onClick={handleAddService} variant='contained'>
						Добавить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center' p={1}>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<Box sx={{ padding: '48px 96px' }}>
				<Grid container spacing={2}>
					{services?.data.map((service: any) => (
						<Grid item xs={3}>
							<Card
								sx={{
									maxWidth: 280,
									minHeight: 282,
									height: '100%',
									background: '#F7F7F7',
									borderRadius: '12px',
									position: 'relative',
									display: 'flex',
									flexDirection: 'column'
								}}
							>
								<CardMedia
									sx={{
										height: 160,
										width: 280,
										border: '1px solid #F7F7F7',
										borderRadius: '12px'
									}}
									image={service.img_url}
									title={service.name}
								/>
								<Box
									position='absolute'
									right={8}
									top={8}
									display='flex'
									justifyContent='center'
									alignContent='center'
									sx={{
										width: 32,
										height: 32,
										background: 'white',
										borderRadius: '12px'
									}}
								>
									<Button
										id={service.id}
										aria-controls={open ? 'basic-menu' : undefined}
										aria-haspopup='true'
										aria-expanded={open ? 'true' : undefined}
										onClick={handleClick}
									>
										<Dots />
									</Button>
								</Box>
								<Menu
									id={`basic-menu-${service.id}`}
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'basic-button'
									}}
								>
									<MenuItem onClick={handleViewService} key={`menu-item-${service.id}`}>
										<View
											style={{
												color: '#349EFF',
												marginRight: '8px'
											}}
										/>
										Просмотр услуги
									</MenuItem>
									{profile?.user?.role.permissions.includes('update_service') && (
										<MenuItem onClick={handleEditService} key={`menu-item-${service.id}`}>
											<Edit
												style={{
													marginRight: '8px'
												}}
											/>
											Редактировать
										</MenuItem>
									)}
									{profile?.user?.role.permissions.includes('update_service') && (
										<MenuItem onClick={handleDeleteOpenModal}>
											<Cancel
												style={{
													marginRight: '8px'
												}}
											/>
											Удалить
										</MenuItem>
									)}
								</Menu>
								<CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
									<Typography gutterBottom variant='caption_2' component='div'>
										Рейтинг: <span style={{ color: '#24D321' }}>{service.rating}</span>
									</Typography>
									<Typography variant='body_2' component='div' color='text.secondary'>
										{service.name}
									</Typography>
									<Typography variant='body_2' color='text.black'>
										{service.price} ₸
									</Typography>
									<Box display='flex' justifyContent='center' mt='auto'>
										<Button sx={{ mt: 1 }} onClick={handleOpenModal(service)}>
											Открыть QR
										</Button>
									</Box>
								</CardContent>
							</Card>
							<Dialog
								open={openDeleteModal}
								onClose={handleClose}
								aria-labelledby='alert-dialog-title'
								aria-describedby='alert-dialog-description'
							>
								<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
								<DialogContent>
									<DialogContentText id='alert-dialog-description'>
										Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без
										сохранении
									</DialogContentText>
								</DialogContent>
								<DialogActions
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'end'
									}}
								>
									<Button
										onClick={handleDeleteCloseModal}
										variant='text'
										color='success'
										style={{ marginBottom: '16px' }}
									>
										Нет, оставить
									</Button>
									<Button onClick={handleServiceDelete} autoFocus variant='text' color='error'>
										Да, удалить
									</Button>
								</DialogActions>
							</Dialog>
						</Grid>
					))}
				</Grid>
				<Modal
					open={openModal}
					onClose={handleCloseModal}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
				>
					<Box sx={style}>
						{qrUrl && (
							<Box p={3}>
								<QRCode
									id='QRCode-services'
									size={256}
									style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
									value={qrUrl}
									viewBox='0 0 256 256'
								/>
							</Box>
						)}
						<Divider />
						<Box sx={{ p: 2 }}>
							<Button onClick={onClickDownload}>Печать</Button>
						</Box>
					</Box>
				</Modal>
			</Box>
		</div>
	)
}
