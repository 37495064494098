export enum OrderTypes {
	queue = '#FF9A3D',
	in_process = '#7D7D7D',
	ready = '#24D321',
	default = '#349EFF'
}

export enum OrderStatusText {
	queue = 'В очереди',
	new_order = 'Новый заказ',
	closed = 'Доставлено',
	in_process = 'Готовится',
	declined = 'Не доставлено!',
	ready = 'Не доставлено!',
	payment_error = 'Ошибка в оплате',
	cancelled = 'Отменена'
}

export enum OrderStatusColor {
	cancelled = '#ED4141',
	queue = '#FF9A3D',
	new_order = '#24D321',
	closed = '#24D321',
	ready = '#ED4141',
	declined = '#ED4141',
	in_process = '#7D7D7D',
	payment_error = '#ED4141'
}
