/* eslint-disable react/no-unescaped-entities */
import { Box, Typography } from '@mui/material'
import React from 'react'

export const AboutApp = () => {
	return (
		<Box py={6} px={8}>
			<section>
				<Typography variant='headline_1' mb={2}>
					О приложении
				</Typography>
				<Box display='flex' flexDirection='column'>
					<Typography variant='body_2' mb={2}>
						Добро пожаловать в мобильное приложение аквапарка &quot;ZERBULAQ&quot;
					</Typography>
					<Typography variant='body_2' mb={2}>
						О приложении:
					</Typography>
					<Typography variant='body_2' mb={2}>
						Мобильное приложение "ZERBULAQ" - это ваш надежный путеводитель в мире веселья и приключений
						аквапарка, расположенного в городе Тараз, Казахстан. Наше приложение разработано для обеспечения
						максимально комфортного и удобного опыта для всех наших гостей.
					</Typography>
					<Typography variant='body_2'>Основные возможности приложения:</Typography>
					<ul>
						<li>
							<Typography variant='body_2'>
								Быстрый доступ к информации: Получайте актуальную информацию о расписании работы, ценах
								на билеты, акциях и специальных предложениях прямо на своем мобильном устройстве. Будьте
								в курсе всех новостей и событий, происходящих в аквапарке.
							</Typography>
						</li>
						<li>
							<Typography variant='body_2'>
								Покупка билетов онлайн: Забудьте о долгих очередях и ожиданиях! С помощью нашего
								приложения вы можете приобрести билеты на аквапарк в удобное для вас время. Просто
								выберите желаемую дату и количество билетов, оплатите онлайн и получите электронные
								билеты прямо на свое устройство.
							</Typography>
						</li>
						<li>
							<Typography variant='body_2'>
								Карта аквапарка: Ознакомьтесь с подробной картой аквапарка, чтобы легко ориентироваться
								и найти все интересующие вас аттракционы, бассейны, кафе и другие объекты. Планируйте
								свой день заранее и не упускайте ни одной возможности для веселья.
							</Typography>
						</li>
						<li>
							<Typography variant='body_2'>
								Расписание шоу и мероприятий: Узнайте о всех запланированных шоу, концертах и
								развлекательных мероприятиях, которые будут проходить в аквапарке. Не упустите
								возможность насладиться захватывающими представлениями и познакомиться с артистами.
							</Typography>
						</li>
						<li>
							<Typography variant='body_2'>
								Удобное управление: Мы создали простой и интуитивно понятный интерфейс, который
								позволяет вам легко и быстро находить нужную информацию, делать заказы и получать
								уведомления о важных обновлениях и акциях.
							</Typography>
						</li>
					</ul>
					<Typography variant='body_2' mb={2}>
						Мы стремимся предоставить вам незабываемый опыт посещения аквапарка "ZERBULAQ" и наше мобильное
						приложение является вашим надежным помощником в этом. Если у вас возникли вопросы или
						предложения, пожалуйста, свяжитесь с нами через раздел "Обратная связь" в приложении.
					</Typography>
					<Typography variant='body_2'>Приятного времяпрепровождения в аквапарке "ZERBULAQ"!</Typography>
				</Box>
			</section>
		</Box>
	)
}
