import { Box, Button, Divider, Typography } from '@mui/material'
import { ArrowBack } from 'core/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const QrDetailPage = () => {
	const navigate = useNavigate()
	return (
		<Box>
			<Box p={2} display='flex' alignItems='center'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%', marginRight: '16px' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				<Typography variant='title_2'>Данные заказа</Typography>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={520}>
					<Typography variant='headline_1'>Заказ №23443</Typography>
					<Box display='flex'>
						<Typography style={{ marginRight: '24px' }}>Статус: Оплачено</Typography>
						<Typography>Дата добавления: 12 сен 2023</Typography>
					</Box>
					<Typography>Кассир: Камила Карсенбаева</Typography>
					<Typography style={{ marginBottom: '56px' }}>Откуда заказ: Мобильное приложение</Typography>
					<Box display='flex' justifyContent='space-between' style={{ marginBottom: '56px' }}>
						<img src='/assets/img/Frame_55.png' alt='frame' style={{ width: 64, height: 64 }} />
						<Box display='flex' flexDirection='column'>
							<Typography>Coca Cola (0,5 л) без сахара</Typography>
							<Typography variant='headline_3'>1x</Typography>
						</Box>

						<Typography variant='headline_3'>450 ₸</Typography>
					</Box>
					<Divider />
					<Box display='flex' justifyContent='space-between'>
						<Typography variant='headline_3'>Итого</Typography>
						<Typography variant='headline_3'>450 ₸</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
