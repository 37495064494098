import { axiosBaseInstance } from 'core/axios.config'

export const deleteTowel = async (towel_id: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/towels/${towel_id}`)
	return v.data
}

export const getTowelsList = async () => {
	const { data } = await axiosBaseInstance.get(`/back_office/towels/`)
	return data
}

export const getTowelsListId = async (towel_id: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/towels/${towel_id}`)
	return data
}

export const updateTowels = async ({ towel_id, body }: any) => {
	const { data } = await axiosBaseInstance.put(`back_office/towels/${towel_id}/`, body)
	return data
}
export const getFilePath = async (path: any) => {
	const { data } = await axiosBaseInstance.post(`/back_office/files/download/${path}/`)
	return data
}
