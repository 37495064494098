import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	Typography
} from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import { dateFormatter } from 'core/helpers/date-formatter'
import { ArrowBack } from 'core/icons'
import moment from 'moment'
import { ManageTapchanStatus, ManageTapchanType } from 'pages/TapchansManageDetail/enum'
import React, { useEffect } from 'react'
import QRCode from 'react-qr-code'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getTapchanId, removeOwnerTapchan } from 'services/tapchan/tapchan.service'
import { useBoolean } from 'usehooks-ts'

export const TapchansManageDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { profile } = useApp()
	const { id } = useParams()
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)

	const modalOpen = useBoolean(false)
	const [qrUrl, setQrUrl] = React.useState('')

	const { data: tapchan } = useQuery(['getTapchanId', id], {
		queryFn: () => getTapchanId(id),
		enabled: !!id
	})

	const handleDeleteOpenModal = () => {
		setOpenDeleteModal(true)
	}
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)
	const removeOwnerMutation = useMutation({
		mutationFn: (tapchan_id: any) => removeOwnerTapchan(tapchan_id),
		onSuccess: () => {
			toast.success('Владелец удален успешно!')
			setOpenDeleteModal(false)
			navigate('/management_tapchan')
		}
	})
	const handleRemoveOwner = () => {
		removeOwnerMutation.mutate(id)
	}
	const onClickDownload = () => {
		const svg = document.getElementById('QRCode-tapchans-detail')
		// @ts-ignore
		const svgData = new XMLSerializer().serializeToString(svg)
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		const img = new Image()
		img.onload = () => {
			canvas.width = img.width * 2.5
			canvas.height = img.height * 2.5
			// @ts-ignore
			ctx.fillStyle = '#FFFFFF'
			// @ts-ignore
			ctx.fillRect(0, 0, 640, 640)
			// @ts-ignore
			ctx.drawImage(img, 70, 70, 500, 500)
			const pngFile = canvas.toDataURL('image/png')
			const downloadLink = document.createElement('a')
			downloadLink.download = 'QRCode-tapchans-detail'
			downloadLink.href = `${pngFile}`
			downloadLink.click()
		}
		img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
	}

	const handleAddPage = (ids: any) => () => {
		navigate(`/management_tapchan/${ids}/add`)
	}

	useEffect(() => {
		if (tapchan) {
			setQrUrl(
				JSON.stringify({
					type: 'tapchan',
					id: tapchan?.data.id
				})
			)
		}
	}, [tapchan])
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={modalOpen.setTrue}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={874}>
					<Typography variant='headline_1'>Данные топчана</Typography>
					{tapchan?.data && (
						<Box display='flex'>
							<Typography variant='body1' color='grey' mr={2}>
								ID: <strong>{tapchan.data.id}</strong>
							</Typography>
							<Typography variant='body1' color='grey'>
								Дата добавления:{' '}
								<strong>{moment(tapchan.data.created_at).format('DD MMM YYYY')}</strong>
							</Typography>
						</Box>
					)}
					<Box display='flex' justifyContent='space-between' mt={6}>
						<Box
							display='flex'
							flexDirection='column'
							justifyContent='center'
							alignContent='center'
							mt={6}
							sx={{
								width: 264,
								height: 264,
								borderColor: 'red',
								borderRadius: '12px',
								marginRight: '12px'
							}}
						>
							{qrUrl && (
								<Box p={3}>
									<QRCode
										id='QRCode-tapchans-detail'
										size={254}
										style={{
											height: 'auto',
											maxWidth: '254px',
											width: '254px'
										}}
										value={qrUrl}
										viewBox='0 0 254 254'
									/>
								</Box>
							)}
							<Button onClick={onClickDownload}>Печать</Button>
						</Box>
						<Box>
							{tapchan?.data && (
								<Grid container rowSpacing={3} my={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Номер тапчана
											</Typography>
											<Typography>{tapchan?.data.number}</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Вид тапчана
											</Typography>
											<Typography>
												{
													ManageTapchanType[
														tapchan?.data.type as keyof typeof ManageTapchanType
													]
												}
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Статус
											</Typography>
											<Typography>
												{
													ManageTapchanStatus[
														tapchan?.data.status as keyof typeof ManageTapchanStatus
													]
												}
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Дата и время брони
											</Typography>
											<Typography>
												{tapchan?.data?.status === 'free'
													? '-'
													: dateFormatter(new Date(tapchan?.data?.created_at))}
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Владелец
											</Typography>
											<Typography>
												{tapchan?.data?.last_rent?.customer?.first_name
													? tapchan?.data?.last_rent?.customer?.first_name
													: '-'}
											</Typography>
										</Box>
									</Grid>
								</Grid>
							)}
							{tapchan?.data && (
								<Box>
									{tapchan?.data?.status === 'free' ? (
										<Button
											onClick={handleAddPage(id)}
											variant='outlined'
											disabled={
												!profile?.user?.role.permissions.includes('update_manage_tapchan')
											}
											sx={{ width: '252px' }}
										>
											<span style={{ color: '#24D321' }}>Добавить владельца</span>
										</Button>
									) : (
										<Button
											onClick={handleDeleteOpenModal}
											variant='outlined'
											disabled={
												!profile?.user?.role.permissions.includes('update_manage_tapchan')
											}
											sx={{ width: '252px' }}
											color='error'
										>
											<span>Убрать владельца</span>
										</Button>
									)}
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
			<Dialog
				open={openDeleteModal}
				onClose={handleDeleteCloseModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем убрать владельца, убедитесь в том что это необходимо
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={handleDeleteCloseModal}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleRemoveOwner} autoFocus variant='text' color='error'>
						Да, убрать
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
