import { axiosBaseInstance } from 'core/axios.config'

export const getTapchan = async (params: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/tapchans/`, {
		params
	})
	return data
}

export const getTapchanHistory = async (params: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/tapchans-rent-history/`, {
		params
	})
	return data
}

export const getTapchanManage = async (params: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/tapchans-history/`, {
		params
	})
	return data
}

export const addTapchan = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/tapchans/`, data)
	return v.data
}

export const deleteTapchan = async (tapchan_id: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/tapchans/${tapchan_id}/`)
	return v.data
}

export const getTapchanId = async (tapchan_id: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/tapchans/${tapchan_id}`)
	return data
}

export const updateTapchan = async ({ tapchan_id, body }: any) => {
	const { data } = await axiosBaseInstance.post(`back_office/tapchans/${tapchan_id}/`, body)
	return data
}

export const removeOwnerTapchan = async (tapchan_id: any) => {
	const { data } = await axiosBaseInstance.post(`back_office/tapchans/${tapchan_id}/remove-owner`)
	return data
}

export const addOwnerTapchan = async ({ tapchan_id, body }: any) => {
	const { data } = await axiosBaseInstance.post(`back_office/tapchans/${tapchan_id}/add-owner`, body)
	return data
}
