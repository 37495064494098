import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	TextField,
	Typography
} from '@mui/material'
import UploadIcon from 'components/uploadIcon/upload-icon'
import { ArrowBack } from 'core/icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import { addNewRule, deleteRule, getRulesId, updateRule } from 'services/rules/rules.service'
import { useBoolean } from 'usehooks-ts'

export const RulesDetailPage = () => {
	const navigate = useNavigate()
	const [image, setImage] = useState<any>()
	const [fileImage, setFileImage] = useState<any>()
	const location = useLocation()
	const { id } = useParams()
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm<any>()

	const modalOpen = useBoolean(false)

	const ruleMutation = useMutation({
		mutationFn: payload => addNewRule(payload),
		onSuccess: () => {
			reset()
			navigate('/rules')
			toast.success('Правила успешно добавлен!')
		}
	})

	const rulesUpdateMutation = useMutation({
		mutationFn: ({ basic_rule_id, body }: any) => updateRule({ basic_rule_id, body }),
		onSuccess: () => {
			navigate('/rules')
			toast.success('Изменения успешно сохранены!')
		}
	})

	const { data: rules } = useQuery(['getRulesId', id, location], {
		queryFn: () => getRulesId(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})
	const deleteRuleMutate = useMutation({
		mutationFn: (basic_rule_id: any) => deleteRule(basic_rule_id),
		onSuccess: () => {
			toast.success('Правила успешно удалена!')
			navigate('/rules')
		}
	})

	const onIconUpload = (file: any) => {
		setFileImage(file)
		setImage(URL.createObjectURL(file))
	}
	const onDeleteIcon = () => {
		setFileImage(null)
		setImage(null)
	}

	const onSubmit = (data: any) => {
		const fd: any = new FormData()
		if (fileImage) fd.append('icon', fileImage)
		fd.append('name', data.name)
		fd.append('description', data.description)
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		location.search.indexOf('edit') > 0
			? rulesUpdateMutation.mutate({ basic_rule_id: id, body: fd })
			: ruleMutation.mutate(fd)
	}
	const handleTourniquetDelete = () => {
		deleteRuleMutate.mutate(id)
	}

	useEffect(() => {
		if (rules?.data) {
			setValue('name', rules.data.name)
			setValue('description', rules.data.description)
			setImage(rules.data.icon_url)
		}
	}, [setValue, rules])
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={modalOpen.setTrue}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={520}>
					<Typography variant='headline_1'>
						{location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0
							? 'Данные инструктажа'
							: 'Добавление инструктажа'}
					</Typography>
					{rules?.data && (
						<Box display='flex'>
							<Typography variant='body1' color='grey' mr={2}>
								ID: <strong>{rules.data.id}</strong>
							</Typography>
							<Typography variant='body1' color='grey'>
								Дата добавления: <strong>{moment(rules.data.created_at).format('DD MMM YYYY')}</strong>
							</Typography>
						</Box>
					)}
					<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
						<Box mb={2} mt={4}>
							<TextField
								label='Наименование'
								placeholder=''
								required
								fullWidth
								type='text'
								disabled={location.search.indexOf('view') > 0}
								error={!!errors.name}
								{...register('name')}
							/>
							<TextField
								label='Описание'
								placeholder=''
								required
								fullWidth
								type='text'
								disabled={location.search.indexOf('view') > 0}
								error={!!errors.description}
								{...register('description')}
							/>
						</Box>
						<UploadIcon
							src={image}
							disabled={location.search.indexOf('view') > 0}
							onUpload={onIconUpload}
							onDeleteIcon={onDeleteIcon}
						/>

						{location.search.indexOf('view') < 0 && (
							<Button variant='contained' type='submit' color='success' style={{ width: '252px' }}>
								{ruleMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
							</Button>
						)}
					</form>
				</Box>
			</Box>
			<Dialog
				open={modalOpen.value}
				onClose={modalOpen.setFalse}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={modalOpen.setFalse}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleTourniquetDelete} autoFocus variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
