import { axiosBaseInstance } from 'core/axios.config'

export const getAllProducts = async (params: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/category-products`, {
		params
	})
	return data
}

export const getProducts = async (params: any) => {
	console.log(params)
	const { data } = await axiosBaseInstance.get(`/back_office/products/all/${params.id}`, {
		params
	})
	return data
}

export const addNewProducts = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/products/`, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return v.data
}

export const getProductsInfo = async (id: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/products/${id}`)
	return data
}
export const getProductsHistoryInfo = async (product_id: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/products/${product_id}/change-history`)
	return data
}

export const updateProducts = async ({ product_id, body }: any) => {
	const v = await axiosBaseInstance.post(`/back_office/products/${product_id}`, body)
	return v.data
}

export const deleteProducts = async (product_id: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/products/${product_id}`)
	return v.data
}
