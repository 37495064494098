import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	Menu,
	MenuItem,
	Typography
} from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import { Cancel, Dots, Edit, View } from 'core/icons'
import { splitAmount } from 'core/utils'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteTicket, getTickets } from 'services/tickets/tickets.service'

export const TicketsComponent = () => {
	const navigate = useNavigate()
	const { profile } = useApp()
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
	const [currentId, setCurrentId] = useState<any>()
	const { data: tickets, refetch } = useQuery(['getTickets'], {
		queryFn: () => getTickets()
	})

	const deleteTicketMutate = useMutation({
		mutationFn: (ticket_id: any) => deleteTicket(ticket_id),
		onSuccess: () => {
			toast.success('Successfully deleted')
			setOpenDeleteModal(false)
			setAnchorEl(null)
			refetch()
		}
	})
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleDeleteOpenModal = () => setOpenDeleteModal(true)
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentId(event.currentTarget.id)
		setAnchorEl(event.currentTarget)
	}
	const handleEditTicker = () => {
		navigate(`/tickets/${currentId}?edit`)
	}
	const handleViewTicket = () => {
		navigate(`/tickets/${currentId}?view`)
	}
	const handleDeleteTicket = () => {
		deleteTicketMutate.mutate(currentId)
	}
	return (
		<Box display='flex' justifyContent='center' mt={5}>
			<Box width='816px' display='flex' flexDirection='column' alignItems='center'>
				<Box width='100%' mb={4}>
					<Typography variant='headline_1' mb={1}>
						Все добавленные билеты
					</Typography>
					<Typography variant='body_2' color='secondary.gray'>
						Общее количество:{' '}
						<Typography variant='body_1' color='primary.black'>
							{tickets?.data?.length}
						</Typography>
					</Typography>
				</Box>
				<Grid container spacing={2}>
					{tickets?.data?.map((ticket: any) => (
						<Grid item xs={6} key={ticket.id}>
							<Box
								sx={{
									background: ticket.color || '#E2F1FF',
									borderRadius: '12px',
									padding: '4px 4px 4px 40px'
								}}
							>
								<Box
									sx={{
										background: '#FFFFFF',
										borderRadius: '0px 10px 10px 0px',
										padding: '12px 16px'
									}}
									display='flex'
									justifyContent='space-between'
								>
									<Box width={278}>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Typography
													variant='caption_1_regular'
													color='secondary.black'
													component='div'
												>
													Тип билета:
												</Typography>
												<Typography variant='footnote_1'>{ticket.type?.name}</Typography>
											</Grid>
										</Grid>
										<Grid container spacing={2} my={2}>
											<Grid item xs={6}>
												<Typography
													variant='caption_1_regular'
													color='secondary.black'
													component='div'
												>
													Тариф:
												</Typography>
												<Typography variant='footnote_1'>{ticket.tariff?.name}</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography
													variant='caption_1_regular'
													color='secondary.black'
													component='div'
												>
													Категория:
												</Typography>
												<Typography variant='footnote_1'>{ticket.category?.name}</Typography>
											</Grid>
										</Grid>

										<Typography variant='caption_1_regular' color='secondary.black' component='div'>
											Цена:
										</Typography>
										<Typography variant='headline_2' color='primary'>
											{splitAmount(ticket.price)} ₸
										</Typography>
									</Box>
									<Box>
										<Button
											id={ticket.id}
											aria-controls={open ? 'basic-menu' : undefined}
											aria-haspopup='true'
											aria-expanded={open ? 'true' : undefined}
											onClick={handleClick}
										>
											<Dots />
										</Button>
										<Menu
											id={`basic-menu-${ticket.id}`}
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button'
											}}
										>
											<MenuItem onClick={handleViewTicket} key={`menu-item-${ticket.id}`}>
												<View
													style={{
														color: '#349EFF',
														marginRight: '8px'
													}}
												/>
												Просмотр билета
											</MenuItem>
											<Divider />
											{profile?.user?.role.permissions.includes('update_ticket') && (
												<MenuItem onClick={handleEditTicker} key={`menu-item-${ticket.id}`}>
													<Edit
														style={{
															marginRight: '8px'
														}}
													/>
													Редактировать
												</MenuItem>
											)}
											<Divider />
											{profile?.user?.role.permissions.includes('update_ticket') && (
												<MenuItem onClick={handleDeleteTicket}>
													<Cancel
														style={{
															marginRight: '8px'
														}}
													/>
													Удалить
												</MenuItem>
											)}
										</Menu>
									</Box>
								</Box>
							</Box>
						</Grid>
					))}
				</Grid>
			</Box>

			<Dialog
				open={openDeleteModal}
				onClose={handleDeleteOpenModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={handleDeleteCloseModal}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleDeleteTicket} autoFocus variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
