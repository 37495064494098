import 'moment/locale/ru'

import { Box, Button, Divider, Typography } from '@mui/material'
import { ArrowBack } from 'core/icons'
import moment from 'moment/moment'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { getTowelDetailRequest } from 'services/towelManagement/towelManage.service'

import { TowelDetailStatusText } from './enum'

export const TowelsClientDetailPage = () => {
	const navigate = useNavigate()
	const { id } = useParams()

	const { data: towelRequest } = useQuery(['getTowelReturnRequest', id], {
		queryFn: () => getTowelDetailRequest(id)
	})
	return (
		<Box>
			<Box p={2}>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				{towelRequest && (
					<Box mt={6} width={824}>
						<Typography variant='headline_1'>Данные владельца</Typography>
						<Box display='flex' mb={1}>
							<Typography variant='body_2' color='primary.gray' mr={1}>
								Дата добавления клиента:
							</Typography>
							<Typography variant='body_2'>
								{moment(towelRequest.created_at).locale('ru').format('ll')}
							</Typography>
						</Box>
						<Box display='flex' mt={6}>
							<img
								alt='lol'
								style={{
									width: '264px',
									height: '264px',
									flexShrink: 0,
									objectFit: 'cover',
									border: '1px solid #D6D6D6',
									borderRadius: 12
								}}
								src={towelRequest?.customer?.avatar_url}
							/>
							<Box style={{ width: '100%', marginLeft: '40px' }}>
								<Box mb={2}>
									<Box display='flex' flexDirection='column' mb={3}>
										<Typography variant='caption_1_regular' color='primary.gray' mr={1}>
											Имя и фамилия клиента
										</Typography>
										<Typography variant='body_2'>
											{towelRequest?.customer?.first_name} {towelRequest?.customer?.last_name}
										</Typography>
									</Box>
									<Box display='flex' gap={2}>
										<Box display='flex' flexDirection='column' mb={3} mr={6}>
											<Typography variant='caption_1_regular' color='primary.gray' mr={1}>
												Статус
											</Typography>
											<Typography variant='body_2'>
												{
													TowelDetailStatusText[
														towelRequest.status as keyof typeof TowelDetailStatusText
													]
												}
											</Typography>
										</Box>
										<Box display='flex' flexDirection='column'>
											<Typography variant='caption_1_regular' color='primary.gray' mr={1}>
												Дата и время брони
											</Typography>
											<Typography variant='body_2'>
												{moment(towelRequest.receive_time).locale('ru').format('lll')}
											</Typography>
										</Box>
									</Box>
									<Box display='flex' flexDirection='column' mb={3}>
										<Typography variant='caption_1_regular' color='primary.gray' mr={1}>
											Количество полотенц
										</Typography>
										<Typography variant='body_2'>{towelRequest.quantity}x</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	)
}
