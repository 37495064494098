import { axiosBaseInstance } from 'core/axios.config'

export const getBasicRules = async () => {
	const { data } = await axiosBaseInstance.get(`/back_office/basic_rules/`)
	return data
}

export const addNewRule = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/basic_rules/`, data)
	return v.data
}

export const getRulesId = async (basic_rule_id: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/basic_rules/${basic_rule_id}`)
	return data
}

export const deleteRule = async (basic_rule_id: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/basic_rules/${basic_rule_id}/`)
	return v.data
}

export const updateRule = async ({ basic_rule_id, body }: any) => {
	const v = await axiosBaseInstance.post(`back_office/basic_rules/${basic_rule_id}/`, body)
	return v.data
}
