import {
	Box,
	Button,
	Divider,
	Link,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { useApp } from 'core/contexts/profile.context'
import { dateFormatter } from 'core/helpers/date-formatter'
import { StatusColor, StatusText } from 'pages/HistoryPayment/enum'
import { parseLockers } from 'pages/Lockers/parse'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getWalletOperations } from 'services/payment/payment.service'
import { useDebounce } from 'usehooks-ts'

export const ReturnCashPage = () => {
	const navigate = useNavigate()
	const { profile } = useApp()
	const [page, setPage] = React.useState(1)
	const [search, setSearch] = React.useState('')

	const debouncedSearch = useDebounce(search, 500)
	const { data: operations } = useQuery(['getWalletOperations', page, debouncedSearch], {
		queryFn: () =>
			getWalletOperations({
				page,
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})
	const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
		setPage(1)
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Возврат денег</Typography>
				{profile?.user?.role.permissions.includes('wallet_deposit') && (
					<Button onClick={() => navigate(`/return-cash/return`)} variant='contained'>
						Совершить возврат
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='end' p={1}>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell align='left'>ID операций</TableCell>
							<TableCell align='left'>Дата и время</TableCell>
							<TableCell align='left'>Операция</TableCell>
							<TableCell align='left'>Тип операций</TableCell>
							<TableCell align='left'>Сумма</TableCell>
							<TableCell align='left'>Остаток</TableCell>
							<TableCell align='left'>Владелец кошелька</TableCell>
							<TableCell align='left'>Метод пополнение</TableCell>
							<TableCell align='left'>№ заказа</TableCell>
							<TableCell align='left'>Статус</TableCell>
							<TableCell align='left'>Проведена ли в WEBKASSA</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{operations?.data &&
							parseLockers(operations.data).map((row: any) => (
								<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component='th' scope='row'>
										{row.id}
									</TableCell>
									<TableCell align='left'>{row.id}</TableCell>
									<TableCell align='left'>{dateFormatter(new Date(row.created_at))}</TableCell>
									<TableCell
										align='left'
										sx={{
											color: row.type === 'Пополнение' ? '#24D321' : '#f59b52'
										}}
									>
										<strong>{row.type}</strong>
									</TableCell>
									<TableCell align='left'>{row.purchase_type}</TableCell>
									<TableCell align='left'>
										<strong>{row.amount}</strong>
									</TableCell>
									<TableCell align='left'>
										<strong>{row.amount_after}</strong>
									</TableCell>
									<TableCell align='left'>
										<Link href={`/payment-history/${row.id}`} underline='none' color='inherit'>
											<strong>{row.customer_full_name}</strong>
										</Link>
									</TableCell>
									<TableCell align='left'>{row.provider}</TableCell>
									<TableCell align='left'>{row.order_id}</TableCell>
									<TableCell
										align='center'
										sx={{
											color: StatusColor[row.status as keyof typeof StatusColor]
										}}
									>
										<strong>{StatusText[row.status as keyof typeof StatusText]}</strong>
									</TableCell>
									<TableCell
										align='center'
										sx={{
											color: row.receipt_sent === true ? '#24D321' : '#f55252'
										}}
									>
										<strong>{row.receipt_sent === true ? 'Да' : 'Нет'}</strong>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{operations?.last_page && (
				<Box display='flex' justifyContent='center'>
					<Pagination
						sx={{ marginTop: '16px' }}
						count={operations.last_page}
						page={page}
						onChange={handleChangePage}
					/>
				</Box>
			)}
		</div>
	)
}
