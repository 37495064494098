import { Box, Button, Divider, Typography } from '@mui/material'
import { AccessTime, CheckCircle, ListAlt } from 'core/icons'
import Echo from 'laravel-echo'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getOrders } from 'services/orders/orders.service'
import { useDebounce } from 'usehooks-ts'

import { OrderCardComponent } from './order-card.component'
import { OrdersFilterComponent } from './orders-filter.component'
import { OrdersFilterByProducts } from './orders-filter-by-products.component'
// import { ProductsListComponent } from "pages/Orders/products-list.component"

export const OrdersTestPage = () => {
	const navigate = useNavigate()
	const [search, setSearch] = React.useState('')
	const [point, setPoint] = React.useState()
	const [pointColor, setPointColor] = React.useState('#FFFFFF')
	const [orders, setOrders] = React.useState([])
	const [initOrders, setInitOrders] = React.useState([])
	const [filterOrdersByProducts, setFilterOrdersbyProducts] = React.useState<any>([])

	const debouncedSearch = useDebounce(search, 500)
	useQuery(['getOrders', point, debouncedSearch], {
		queryFn: () =>
			getOrders({
				id: point,
				params: {
					search: debouncedSearch.length > 0 ? debouncedSearch : null
				}
			}),
		onSuccess: (res: any) => {
			setOrders(res.data)
			setInitOrders(res.data)
		},
		enabled: !!point
	})
	useEffect(() => {
		const ACCESS_TOKEN = localStorage.getItem('ACCESS_TOKEN')
		// @ts-ignore
		const echo = new Echo({
			broadcaster: 'pusher',
			cluster: 'mt1',
			key: 'app_key',
			wsHost: 'zerbulakfamily.kz',
			wsPort: '6001',
			forceTLS: false, // Change to true if using HTTPS
			disableStats: false, // Optional: Disable client stat tracking
			authEndpoint: 'https://api.zerbulakfamily.kz/back_office/broadcasting/auth', // Laravel broadcasting authentication endpoint
			auth: {
				headers: {
					Authorization: `Bearer ${ACCESS_TOKEN}`
				}
			}
		})
		echo.private('orders').notification((notification: any) => {
			setOrders((current: any) => {
				return current.map((obj: any) => {
					if (obj.id === notification?.order?.id) {
						return { ...notification?.order }
					}
					return obj
				})
			})
			setInitOrders((current: any) => {
				return current.map((obj: any) => {
					if (obj.id === notification?.order?.id) {
						return { ...notification?.order }
					}
					return obj
				})
			})
		})
	}, [])

	useEffect(() => {
		if (filterOrdersByProducts.length) {
			setOrders(
				orders.filter((order: any) => {
					return order.products.some((productInfo: any) => {
						return filterOrdersByProducts.includes(productInfo.product.name)
					})
				})
			)
		} else {
			setOrders(initOrders)
		}
	}, [filterOrdersByProducts, initOrders, setFilterOrdersbyProducts])
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Заказы</Typography>
				<Button onClick={() => navigate('/orders/history')} variant='outlined'>
					<span style={{ color: '#349EFF' }}>История</span>
				</Button>
			</Box>
			<Divider />
			<OrdersFilterComponent
				search={search}
				point={point}
				setSearch={setSearch}
				setPoint={setPoint}
				setPointColor={setPointColor}
			/>
			<OrdersFilterByProducts setFilterOrdersbyProducts={setFilterOrdersbyProducts} />
			<Divider />
			<Box display='flex' sx={{ borderBottom: '0.5px solid #D7D7D7' }}>
				<Box
					display='flex'
					flexDirection='column'
					sx={{
						width: 'calc(100%/3)',
						minWidth: '276px',
						borderRight: '0.5px solid #D7D7D7',
						background: pointColor
					}}
				>
					<Box
						display='flex'
						alignItems='center'
						gap={2}
						py={2.75}
						px={2}
						sx={{
							borderBottom: '0.5px solid #D7D7D7',
							cursor: 'pointer',
							background: '#ffffff'
						}}
					>
						<ListAlt style={{ fill: '#FF9A3D' }} />
						<Box
							display='flex'
							flexDirection='column'
							justifyContent='center'
							flexGrow={1}
							sx={{ minHeight: 46 }}
						>
							<Typography variant='body_1'>
								В очереди - {orders?.filter((ord: any) => ord.status === 'queue').length}
							</Typography>
						</Box>
					</Box>
					{!!orders?.length && (
						<Box display='flex' flexDirection='column' mt={1}>
							{orders
								?.filter((ord: any) => ord.status === 'queue')
								.map((order: any) => (
									<OrderCardComponent
										key={order.id}
										orderId={order.id}
										orderType={order.status}
										number={order.id}
										status={order.status}
										products={order.products}
										orderFrom={order.type}
										workers={order.employees}
										time={order.created_at}
									/>
								))}
						</Box>
					)}
				</Box>
				<Box
					display='flex'
					flexDirection='column'
					sx={{
						width: 'calc(100%/3)',
						minHeight: '100vh',
						minWidth: '276px',
						borderRight: '0.5px solid #D7D7D7',
						background: pointColor
					}}
				>
					<Box
						display='flex'
						alignItems='center'
						gap={2}
						py={2.75}
						px={2}
						sx={{
							borderBottom: '0.5px solid #D7D7D7',
							cursor: 'pointer',
							background: '#ffffff'
						}}
					>
						<AccessTime style={{ fill: '#7D7D7D' }} />
						<Box
							display='flex'
							flexDirection='column'
							justifyContent='center'
							flexGrow={1}
							sx={{ minHeight: 46 }}
						>
							<Typography variant='body_1'>
								В процессе - {orders?.filter((ord: any) => ord.status === 'in_process').length}
							</Typography>
						</Box>
					</Box>
					{!!orders?.length && (
						<Box display='flex' flexDirection='column' mt={1}>
							{orders
								?.filter((ord: any) => ord.status === 'in_process')
								.map((order: any) => (
									<OrderCardComponent
										key={order.id}
										orderId={order.id}
										orderType={order.status}
										number={order.id}
										status={order.status}
										products={order.products}
										workers={order.employees}
										orderFrom={order.type}
										time={order.created_at}
									/>
								))}
						</Box>
					)}
				</Box>
				<Box
					display='flex'
					flexDirection='column'
					sx={{
						width: 'calc(100%/3)',
						minWidth: '276px',
						borderRight: '0.5px solid #D7D7D7',
						background: pointColor
					}}
				>
					<Box
						display='flex'
						alignItems='center'
						gap={2}
						py={2.75}
						px={2}
						sx={{
							borderBottom: '0.5px solid #D7D7D7',
							cursor: 'pointer',
							background: '#ffffff'
						}}
					>
						<CheckCircle style={{ fill: '#24D321' }} />
						<Box
							display='flex'
							flexDirection='column'
							justifyContent='center'
							flexGrow={1}
							sx={{ minHeight: 46 }}
						>
							<Typography variant='body_1'>
								Готово -{' '}
								{orders?.filter((ord: any) => ord.status === 'ready' || ord.status === 'closed').length}
							</Typography>
						</Box>
					</Box>
					{!!orders?.length && (
						<Box display='flex' flexDirection='column' mt={1}>
							{orders
								?.filter((ord: any) => ord.status === 'ready')
								.map((order: any) => (
									<OrderCardComponent
										key={order.id}
										orderId={order.id}
										orderType={order.status}
										number={order.id}
										status={order.status}
										products={order.products}
										orderFrom={order.type}
										workers={order.employees}
										time={order.created_at}
									/>
								))}
						</Box>
					)}
				</Box>
			</Box>
		</div>
	)
}
