import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputAdornment,
	Radio,
	RadioGroup,
	TextField,
	Typography
} from '@mui/material'
import { ArrowBack } from 'core/icons'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import QRCode from 'react-qr-code'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addTapchan, deleteTapchan, getTapchanId, updateTapchan } from 'services/tapchan/tapchan.service'
import { useBoolean } from 'usehooks-ts'

export const TapchansDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const {
		register,
		handleSubmit,
		control,
		reset,
		setValue,
		formState: { errors }
	} = useForm<any>()

	const modalOpen = useBoolean(false)
	const [qrUrl, setQrUrl] = React.useState('')

	const tapchanMutation = useMutation({
		mutationFn: payload => addTapchan(payload),
		onSuccess: () => {
			toast.success('Топчан успешно добавлен!')
			reset()
			navigate('/tapchans')
		}
	})

	const tapchanUpdateMutation = useMutation({
		mutationFn: ({ tapchan_id, body }: any) => updateTapchan({ tapchan_id, body }),
		onSuccess: () => {
			toast.success('Изменения успешно сохранены!')
			navigate('/tapchans')
		}
	})

	const { data: tapchan } = useQuery(['getTapchanId', id, location], {
		queryFn: () => getTapchanId(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})
	const deleteTapchanMutate = useMutation({
		mutationFn: (tapchan_id: any) => deleteTapchan(tapchan_id),
		onSuccess: () => {
			toast.success('Топчан успешно удален!')
			navigate('/tapchans')
		}
	})

	const onSubmit = (data: any) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		location.search.indexOf('edit') > 0
			? tapchanUpdateMutation.mutate({ tapchan_id: id, body: data })
			: tapchanMutation.mutate(data)
	}

	const handleTapchanDelete = () => {
		deleteTapchanMutate.mutate(id)
	}
	const onClickDownload = () => {
		const svg = document.getElementById('QRCode-tapchans-detail')
		// @ts-ignore
		const svgData = new XMLSerializer().serializeToString(svg)
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		const img = new Image()
		img.onload = () => {
			canvas.width = img.width * 2.5
			canvas.height = img.height * 2.5
			// @ts-ignore
			ctx.fillStyle = '#FFFFFF'
			// @ts-ignore
			ctx.fillRect(0, 0, 640, 640)
			// @ts-ignore
			ctx.drawImage(img, 70, 70, 500, 500)
			const pngFile = canvas.toDataURL('image/png')
			const downloadLink = document.createElement('a')
			downloadLink.download = 'QRCode-tapchans-detail'
			downloadLink.href = `${pngFile}`
			downloadLink.click()
		}
		img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
	}

	useEffect(() => {
		if (tapchan) {
			setQrUrl(JSON.stringify({ type: 'tapchan', id: tapchan.data.id }))
			setValue('type', tapchan.data.type)
			setValue('price', tapchan.data.price)
			setValue('number', tapchan.data.number)
		}
	}, [setValue, tapchan])
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={modalOpen.setTrue}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box
					mt={6}
					width={location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0 ? 824 : 520}
				>
					<Typography variant='headline_1'>
						{location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0
							? 'Данные топчана'
							: 'Добавление топчана'}
					</Typography>
					{tapchan?.data && (
						<Box display='flex'>
							<Typography variant='body1' color='grey' mr={2}>
								ID: <strong>{tapchan.data.id}</strong>
							</Typography>
							<Typography variant='body1' color='grey'>
								Дата добавления:{' '}
								<strong>{moment(tapchan.data.created_at).format('DD MMM YYYY')}</strong>
							</Typography>
						</Box>
					)}
					<Box display='flex' mt={6}>
						{(location.search.indexOf('edit') > 0 || location.search.indexOf('view')) > 0 && (
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								mt={6}
								sx={{
									width: 264,
									height: 264,
									borderColor: 'red',
									borderRadius: '12px'
								}}
							>
								{qrUrl && (
									<Box p={3}>
										<QRCode
											id='QRCode-tapchans-detail'
											size={254}
											style={{
												height: 'auto',
												maxWidth: '254px',
												width: '254px'
											}}
											value={qrUrl}
											viewBox='0 0 254 254'
										/>
									</Box>
								)}
								<Button onClick={onClickDownload}>Печать</Button>
							</Box>
						)}
						<form
							onSubmit={handleSubmit(onSubmit)}
							noValidate
							style={{ width: '100%', marginLeft: '40px' }}
						>
							<Controller
								control={control}
								name='type'
								defaultValue=''
								render={({ field: { ref, onBlur, name, ...field } }) => (
									<FormControl fullWidth disabled={location.search.indexOf('view') > 0}>
										<FormLabel id='demo-radio-buttons-group-label' style={{ margin: '16px 0' }}>
											<Typography variant='title_1' color='black'>
												Вид топчана
											</Typography>
										</FormLabel>
										<RadioGroup
											aria-labelledby='demo-radio-buttons-group-label'
											defaultValue='ordinary'
											{...field}
										>
											<FormControlLabel value='ordinary' control={<Radio />} label='Обычный' />
											<Divider />
											<FormControlLabel value='vip' control={<Radio />} label='VIP' />
										</RadioGroup>
									</FormControl>
								)}
							/>
							<Box mb={2} mt={4}>
								<TextField
									label='Номер'
									placeholder=''
									required
									fullWidth
									disabled={location.search.indexOf('view') > 0}
									type='number'
									error={!!errors.number}
									{...register('number')}
									onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
								/>
							</Box>
							<Box mb={2}>
								<TextField
									label='Цена'
									placeholder=''
									required
									fullWidth
									disabled={location.search.indexOf('view') > 0}
									type='number'
									InputProps={{
										startAdornment: <InputAdornment position='start'>₸</InputAdornment>
									}}
									error={!!errors.price}
									{...register('price')}
								/>
							</Box>

							{location.search.indexOf('view') < 0 && (
								<Button variant='contained' type='submit' sx={{ width: '252px' }} color='success'>
									{tapchanMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
								</Button>
							)}
						</form>
					</Box>
				</Box>
			</Box>

			<Dialog
				open={modalOpen.value}
				onClose={modalOpen.setFalse}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={modalOpen.setFalse}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleTapchanDelete} autoFocus variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
