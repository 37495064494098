import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import * as React from 'react'

export const OrdersEditCardComponent = ({ name, rating, price, img_path }: any) => {
	return (
		<Grid item xs={6}>
			<Card
				sx={{
					width: 212,
					background: '#F7F7F7',
					borderRadius: '12px',
					position: 'relative'
				}}
			>
				<CardMedia
					sx={{
						height: 160,
						width: 212,
						border: '1px solid #F7F7F7',
						borderRadius: '12px',
						backgroundSize: 'cover',
						backgroundPosition: 'top'
					}}
					image={img_path}
					title={name}
				/>
				<CardContent sx={{ p: '8px 12px !important' }}>
					<Typography gutterBottom variant='caption_2' component='div'>
						Рейтинг: <span style={{ color: '#24D321' }}>{rating}</span>
					</Typography>
					<Typography gutterBottom variant='footnote_2' component='div' color='text.secondary'>
						{name}
					</Typography>
					<Typography variant='body_2'>{price} ₸</Typography>
					<Button variant='text' size='small' fullWidth sx={{ mt: 0.5 }}>
						Добавить
					</Button>
				</CardContent>
			</Card>
		</Grid>
	)
}
