import { Box, Button, Divider, Grid, Tab, Tabs, Typography } from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { ArrowBack } from 'core/icons'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { addedProducts, editProducts } from './mock'
import { OrdersEditCardComponent } from './orders-edit-card.component'
import { OrdersEditProductComponent } from './orders-edit-product.component'

export const OrdersEditPage = () => {
	const navigate = useNavigate()

	const [searchTab, setSearchTab] = useSearchParams({ tab: '1' })
	const [search, setSearch] = React.useState('')
	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setSearchTab({ tab: newValue })
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
	}
	const onClickSave = () => {
		toast.success('Изменения внесены успешно!')
		navigate('/orders')
	}

	return (
		<Box display='flex' gap={2}>
			<Box
				sx={{
					width: 536,
					border: '1px solid rgba(214, 214, 214, 0.48)',
					borderRadius: 1.5
				}}
			>
				<Box px={2} py={2.5} display='flex' alignItems='center' gap={2}>
					<Button
						color='info'
						variant='text'
						size='small'
						onClick={() => navigate(-1)}
						sx={{ border: 0, borderRadius: '50%' }}
					>
						<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} />
						<Typography variant='footnote_2'>Назад</Typography>
					</Button>
				</Box>
				<Divider />
				<Box p={2} display='flex' justifyContent='center' alignItems='center' gap={2}>
					<Tabs
						value={searchTab.get('tab')}
						onChange={handleChangeTab}
						aria-label='wrapped label tabs example'
					>
						<Tab value='1' label='Напитки' wrapped />
						<Tab value='2' label='Закуски' />
						<Tab value='3' label='Супы' />
						<Tab value='4' label='Горячее' />
					</Tabs>
				</Box>
				<Divider />
				<Box display='flex' flexDirection='column' py={3} px={6} gap={3}>
					<SearchInput search={search} onChangeSearch={onChangeSearch} />
					<Box>
						<Grid container spacing={2}>
							{editProducts.map((product: any) => (
								<OrdersEditCardComponent key={`edit-card-${product.id}`} {...product} />
							))}
						</Grid>
					</Box>
				</Box>
			</Box>
			<Box
				display='flex'
				flexDirection='column'
				sx={{
					width: 536,
					border: '1px solid rgba(214, 214, 214, 0.48)',
					borderRadius: 1.5
				}}
			>
				<Box px={2} py={3} display='flex' alignItems='center' gap={2}>
					<Typography variant='title_2'>Добавленные товары</Typography>
				</Box>
				<Divider />
				<Box px={4} py={2} display='flex' flexDirection='column'>
					{addedProducts.map(product => (
						<OrdersEditProductComponent key={`added-product-${product.id}`} {...product} />
					))}
				</Box>
				<Divider sx={{ mt: 'auto' }} />
				<Box px={6} py={3} display='flex' flexDirection='column' alignItems='center' gap={2}>
					<Box display='flex' justifyContent='space-between' width='100%'>
						<Typography variant='title_2'>Итого</Typography>
						<Typography variant='title_2'>1 800 ₸</Typography>
					</Box>
					<Button variant='contained' color='success' sx={{ mt: 3, height: 50, px: 8 }} onClick={onClickSave}>
						Сохранить
					</Button>
				</Box>
			</Box>
		</Box>
	)
}
