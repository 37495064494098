import { Box, Button, Chip, ListSubheader, Menu, MenuItem, Typography } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useApp } from 'core/contexts/profile.context'
import { ArrowDropDown, KeyboardBackspace, ListIcon } from 'core/icons'
import React from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

import { Styled } from './sidebar.styles'
import { MenuList } from './sidebar.types'

type Props = {
	primaryMenuList: MenuList[]
}

const Sidebar = ({ primaryMenuList }: Props) => {
	const { profile } = useApp()
	const location = useLocation()
	const navigate = useNavigate()
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const hide = useBoolean(false)
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const onClickProfile = () => {
		navigate('/profile')
	}

	const onLogout = () => {
		localStorage.removeItem('ACCESS_TOKEN')
		navigate('/login')
	}
	return (
		<Box width={hide.value ? '90px' : '304px'} sx={{ margin: '16px 16px 8px' }}>
			<Button variant='text' size='small' onClick={hide.value ? hide.setFalse : hide.setTrue}>
				<ListIcon />
			</Button>
			{!hide.value && (
				<Styled.Root>
					<Box display='flex' justifyContent='space-between'>
						<Box
							gap={1}
							display='flex'
							sx={{ cursor: 'pointer' }}
							flexDirection='column'
							onClick={onClickProfile}
						>
							<Typography variant='title_2'>
								{`${profile?.user.first_name} ${profile?.user.last_name}`}
							</Typography>
							<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
								{profile?.user.email}
							</Typography>
						</Box>
						<Button onClick={handleClick}>
							<ArrowDropDown />
						</Button>
						<Menu
							id='basic-menu'
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button'
							}}
						>
							<MenuItem onClick={onLogout}>
								<KeyboardBackspace style={{ marginRight: '16px' }} />
								Выйти
							</MenuItem>
						</Menu>
					</Box>
				</Styled.Root>
			)}
			<Styled.Root sx={{ marginTop: '16px' }}>
				<Box display='flex' flexDirection='column' flex='1'>
					<Box flex='1'>
						<nav>
							{primaryMenuList.map(({ id, subheader, list }) => (
								<Box mb={1} key={id}>
									<List
										sx={{
											padding: '0'
										}}
										subheader={
											!hide.value &&
											subheader && (
												<ListSubheader
													sx={{
														padding: '0',
														marginBottom: '16px',
														fontWeight: '700',
														fontsize: '16px',
														lineHeight: '20px',
														letterSpacing: '0.6px',
														color: '#181818'
													}}
												>
													{subheader}
												</ListSubheader>
											)
										}
									>
										{list.map(
											({ id: listId, text, url, icon, secondaryText, show }) =>
												show && (
													<ListItem
														key={listId}
														sx={{
															padding: '0'
														}}
														secondaryAction={
															secondaryText && <Chip label={secondaryText} size='small' />
														}
													>
														<ListItemButton
															component={NavLink}
															to={url}
															selected={location.pathname === url}
															sx={{
																height: '52px',
																'&.Mui-selected': {
																	background: '#E2F1FF',
																	svg: {
																		fill: '#349EFF'
																	}
																}
															}}
														>
															<ListItemIcon>{icon}</ListItemIcon>
															{!hide.value && (
																<ListItemText
																	primary={text}
																	primaryTypographyProps={{
																		variant: 'body_2',
																		color:
																			location.pathname === url
																				? '#181818'
																				: '#575757'
																	}}
																/>
															)}
														</ListItemButton>
													</ListItem>
												)
										)}
									</List>
								</Box>
							))}
						</nav>
					</Box>
				</Box>
			</Styled.Root>
		</Box>
	)
}

export default Sidebar
