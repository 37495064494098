import {
	Box,
	Button,
	CircularProgress,
	Divider,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import { SosAdd } from 'core/icons'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import QRCode from 'react-qr-code'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getPoints } from 'services/pointer/pointer.service'
import { addNewSos, cancelSos } from 'services/sos/sos.service'

export const SosPage = () => {
	const navigate = useNavigate()
	const { profile } = useApp()
	const [qrImg, setQrImg] = useState<any>()
	const [sosId, setSosId] = useState<any>()
	const {
		register,
		control,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm<any>()
	const sosMutation = useMutation({
		mutationFn: payload => addNewSos(payload),
		onSuccess: res => {
			setQrImg(JSON.stringify({ type: 'sos', id: res.data.id }))
			setSosId(res.data.id)
			toast.success('Qr!')
		}
	})

	const deleteSosMutate = useMutation({
		mutationFn: (sos_id: any) => cancelSos(sos_id),
		onSuccess: () => {
			reset()
			setQrImg(null)
			toast.success('Successfully canceled')
		}
	})

	const { data: points } = useQuery(['getPoints'], {
		queryFn: () => getPoints()
	})

	const onSubmit = (data: any) => {
		sosMutation.mutate(data)
	}

	const onCancel = () => {
		deleteSosMutate.mutate(sosId)
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>SOS</Typography>
				<Button onClick={() => navigate('/sos/history')} variant='outlined' color='info'>
					<span style={{ color: '#349EFF' }}>История</span>
				</Button>
			</Box>
			<Divider />
			<Box display='flex' mt={1} gap={2}>
				<Box
					sx={{
						width: '50%',
						minHeight: '100vh',
						border: '1px solid rgba(214, 214, 214, 0.48)',
						borderRadius: 1.5
					}}
				>
					<Box
						sx={{
							padding: '24px 16px'
						}}
					>
						<Typography variant='title_2'>Данные для оплаты</Typography>
					</Box>
					<Divider />
					<Box
						sx={{
							padding: '24px 56px'
						}}
					>
						<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
							{points?.data && (
								<Controller
									control={control}
									name='issue_point_id'
									defaultValue=''
									render={({ field: { ...field } }) => (
										<FormControl required fullWidth>
											<InputLabel id='demo-simple-select-label'>Точка выдачи</InputLabel>
											<Select
												{...field}
												labelId='demo-simple-select-label'
												id='demo-simple-select'
											>
												{points?.data?.map((point: any) => {
													return (
														<MenuItem value={Number(point.id)} key={point.id}>
															{point.address}
														</MenuItem>
													)
												})}
											</Select>
										</FormControl>
									)}
								/>
							)}
							<Box mb={2}>
								<TextField
									label='Описание (Необзяательно)'
									placeholder=''
									required
									fullWidth
									type='text'
									error={!!errors.comment}
									{...register('comment')}
								/>
							</Box>
							<Box mb={2}>
								<TextField
									label='Сумма платежа'
									placeholder=''
									required
									fullWidth
									type='number'
									error={!!errors.price}
									{...register('price')}
								/>
							</Box>
							{profile?.user?.role.permissions.includes('update_sos') && (
								<Box display='flex' justifyContent='center' alignItems='center'>
									<Button
										variant='contained'
										type='submit'
										sx={{
											width: '252px'
										}}
									>
										{sosMutation.isLoading ? <CircularProgress size={24} /> : 'Получить qr'}
									</Button>
								</Box>
							)}
						</form>
					</Box>
				</Box>
				<Box
					sx={{
						width: '50%',
						minHeight: '100vh',
						border: '1px solid rgba(214, 214, 214, 0.48)',
						borderRadius: 1.5
					}}
				>
					<Box
						sx={{
							padding: '24px 16px'
						}}
					>
						<Typography variant='title_2'>QR для оплаты</Typography>
					</Box>
					<Divider />
					{qrImg ? (
						<Box
							sx={{
								width: '500px',
								height: '500px'
							}}
							display='flex'
							flexDirection='column'
							justifyContent='center'
							alignItems='center'
							p={5}
						>
							<QRCode
								size={50}
								style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
								value={qrImg}
								viewBox='0 0 100 100'
							/>
							<Box display='flex' justifyContent='center' alignContent='center' mt={5} mb={3}>
								<Typography variant='footnote_1'>Покажите данный QR для его сканирования</Typography>
							</Box>
							<Box display='flex' justifyContent='center' alignContent='center'>
								<Button
									onClick={onCancel}
									sx={{
										width: '252px'
									}}
									variant='outlined'
									color='error'
								>
									Отмена
								</Button>
							</Box>
						</Box>
					) : (
						<Box
							sx={{
								padding: '24px 56px'
							}}
						>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								alignItems='center'
								sx={{
									padding: '200px 56px'
								}}
							>
								<SosAdd />
								<Typography variant='title_3' mt={3} mb={1}>
									Заполните поля что слева
								</Typography>
								<Typography variant='footnote_1' color='primary.gray'>
									Чтобы создать QR для срочной оплаты, необходимо описать платеж
								</Typography>
							</Box>
						</Box>
					)}
				</Box>
			</Box>
		</div>
	)
}
