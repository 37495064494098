import { Box, Button, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material'
import { ArrowBack, Lock } from 'core/icons'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { backOfficeUpdatePassword } from 'services/authorization/authorization.service'

export const RestorePasswordComponent = ({ phone, setStep, otp }: any) => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<any>()
	const registerMutation = useMutation({
		mutationFn: payload => backOfficeUpdatePassword(payload),
		onSuccess: () => {
			setStep('login')
		}
	})

	const onSubmit = (data: any) => {
		const PhoneMask = phone.replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ', '')
		// @ts-ignore
		registerMutation.mutate({
			phone: PhoneMask,
			password: data.password,
			password_confirmation: data.password,
			otp
		})
	}
	return (
		<Box width='100%'>
			<Button
				color='info'
				variant='text'
				size='small'
				onClick={() => setStep('login')}
				sx={{ border: 0, borderRadius: '50%' }}
			>
				<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
			</Button>
			<Typography
				variant='headline_1'
				mb={1}
				sx={{
					width: '100%'
				}}
			>
				Придумайте пароль
			</Typography>
			<Typography
				variant='footnote_1'
				mb={4}
				sx={{
					width: '100%'
				}}
			>
				С помощью ввода пароля, осуществляется вход в систему
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
				<Box mb={2}>
					<TextField
						label='Ваш пароль'
						placeholder=''
						required
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<Lock />
								</InputAdornment>
							)
						}}
						type='password'
						error={!!errors.password}
						{...register('password')}
					/>
				</Box>
				<Box mb={2}>
					<TextField
						label='Повторите пароль'
						placeholder=''
						required
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<Lock />
								</InputAdornment>
							)
						}}
						type='password'
						error={!!errors.password_confirmation}
						{...register('password_confirmation')}
					/>
				</Box>
				<Button variant='contained' type='submit' fullWidth disabled={registerMutation.isLoading}>
					{registerMutation.isLoading ? <CircularProgress /> : 'Подтвердить'}
				</Button>
			</form>
		</Box>
	)
}
