import { axiosBaseInstance } from 'core/axios.config'

export const getTourniquets = async (params?: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/tourniquets/`, {
		params
	})
	return data
}

export const addTourniquets = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/tourniquets/`, data)
	return v.data
}

export const getTourniquetsId = async (tourniquet_id: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/tourniquets/${tourniquet_id}`)
	return data
}

export const activateTourniquet = async (tourniquet_id: any) => {
	const v = await axiosBaseInstance.post(`/back_office/tourniquets/${tourniquet_id}/activate`, tourniquet_id)
	return v.data
}

export const deleteTourniquet = async ({ tourniquet_id, reason }: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/tourniquets/${tourniquet_id}/`, {
		data: {
			reason
		}
	})
	return v.data
}

export const blockTourniquet = async (tourniquet_uid: string) => {
	const { data } = await axiosBaseInstance.post(`/back_office/tourniquets/${tourniquet_uid}/block`, tourniquet_uid)
	return data
}

export const updateTourniquet = async ({ tourniquet_id, body }: any) => {
	const { data } = await axiosBaseInstance.put(`back_office/tourniquets/${tourniquet_id}/`, body)
	return data
}
