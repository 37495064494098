import { axiosBaseInstance } from 'core/axios.config'

export const getEmployeesList = async (params?: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/employees/`, {
		params
	})
	return data
}

export const createEmployee = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/employees`, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return v.data
}

export const deleteEmployee = async ({ employee_id, reason }: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/employees/${employee_id}/`, {
		data: {
			reason
		}
	})
	return v.data
}

export const getEmployeeInfo = async (id: any) => {
	const { data } = await axiosBaseInstance.get(`back_office/employees/${id}/`)
	return data
}

export const getEmployeeChangeHistory = async (employee_id: any) => {
	const { data } = await axiosBaseInstance.get(`back_office/employees/${employee_id}/change-history`)
	return data
}

export const updateEmployee = async ({ employee_id, body }: any) => {
	const { data } = await axiosBaseInstance.post(`back_office/employees/${employee_id}/`, body, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return data
}
export const restoreEmployee = async (employee_id: number) => {
	const { data } = await axiosBaseInstance.put(`back_office/employees/${employee_id}/restore/`)
	return data
}
