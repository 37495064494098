import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	TextField,
	Typography
} from '@mui/material'
import { ArrowBack } from 'core/icons'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import {
	addTourniquets,
	deleteTourniquet,
	getTourniquetsId,
	updateTourniquet
} from 'services/tourniquet/tourniquet.service'
import { useBoolean } from 'usehooks-ts'

export const TourniquetsDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const [reasonText, setReasonText] = React.useState('')
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm<any>()

	const modalOpen = useBoolean(false)

	const tourniquetMutation = useMutation({
		mutationFn: payload => addTourniquets(payload),
		onSuccess: () => {
			reset()
			navigate('/tourniquets')
			toast.success('Турникет успешно добавлен!')
		}
	})

	const tourniquetUpdateMutation = useMutation({
		mutationFn: ({ tourniquet_id, body }: any) => updateTourniquet({ tourniquet_id, body }),
		onSuccess: () => {
			navigate('/tourniquets')
			toast.success('Изменения успешно сохранены!')
		}
	})

	const { data: tourniquet } = useQuery(['getTourniquetId', id, location], {
		queryFn: () => getTourniquetsId(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})
	const deleteTourniquetMutate = useMutation({
		mutationFn: ({ tourniquet_id, reason }: any) => deleteTourniquet({ tourniquet_id, reason }),
		onSuccess: () => {
			toast.success('Турникет успешно удален!')
			navigate('/tourniquets')
		}
	})

	const onSubmit = (data: any) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		location.search.indexOf('edit') > 0
			? tourniquetUpdateMutation.mutate({ tourniquet_id: id, body: data })
			: tourniquetMutation.mutate(data)
	}
	const handleTourniquetDelete = () => {
		if (reasonText) {
			deleteTourniquetMutate.mutate({
				tourniquet_id: id,
				reason: reasonText
			})
		} else {
			toast.error('Нужнa причина!')
		}
	}

	const onChangeReason = (e: any) => {
		setReasonText(e.target.value)
	}

	useEffect(() => {
		if (tourniquet) {
			setValue('name', tourniquet.data.name)
			setValue('external_id', tourniquet.data.external_id)
		}
	}, [setValue, tourniquet])
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={modalOpen.setTrue}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={520}>
					<Typography variant='headline_1'>
						{location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0
							? 'Данные турникета'
							: 'Добавление турникета'}
					</Typography>
					{tourniquet?.data && (
						<Box display='flex'>
							<Typography variant='body1' color='grey' mr={2}>
								ID: <strong>{tourniquet.data.id}</strong>
							</Typography>
							<Typography variant='body1' color='grey'>
								Дата добавления:{' '}
								<strong>{moment(tourniquet.data.created_at).format('DD MMM YYYY')}</strong>
							</Typography>
						</Box>
					)}
					<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
						<Box mb={2} mt={4}>
							<TextField
								label='Наименование'
								placeholder=''
								required
								fullWidth
								type='text'
								disabled={location.search.indexOf('view') > 0}
								error={!!errors.name}
								{...register('name')}
							/>
							<TextField
								label='ID замка'
								placeholder=''
								required
								disabled={location.search.indexOf('view') > 0}
								fullWidth
								type='number'
								error={!!errors.external_id}
								{...register('external_id')}
							/>
						</Box>

						{location.search.indexOf('view') < 0 && (
							<Button variant='contained' type='submit' color='success' style={{ width: '252px' }}>
								{tourniquetMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
							</Button>
						)}
					</form>
				</Box>
			</Box>
			<Dialog
				open={modalOpen.value}
				onClose={modalOpen.setFalse}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
					<Box mt={2}>
						<TextField
							label='Причина удаления'
							placeholder=''
							fullWidth
							multiline
							rows={3}
							type='text'
							value={reasonText}
							onChange={onChangeReason}
							name='reason'
						/>
					</Box>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={modalOpen.setFalse}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button
						onClick={handleTourniquetDelete}
						autoFocus
						variant='text'
						disabled={!reasonText}
						color='error'
					>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
