import { Box, Button, Divider, Typography } from '@mui/material'
import { dateFormatter } from 'core/helpers/date-formatter'
import { ArrowBack } from 'core/icons'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { getEmployeeChangeHistory } from 'services/employees/employees.service'

export const StuffChangeHistoryComponent = () => {
	const navigate = useNavigate()
	const { id } = useParams()

	const { data: histories } = useQuery(['getEmployeeChangeHistory', id], {
		queryFn: () => getEmployeeChangeHistory(id)
	})

	return (
		<div>
			<Box p={2} display='flex' alignItems='center'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%', marginRight: '16px' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				<Typography variant='title_2'>История изменений</Typography>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={500}>
					{histories?.data.map((history: any) => (
						<Box display='flex' flexDirection='column' key={history.id} mb={2}>
							<Typography variant='caption_1_regular' color='primary.gray' mb={1}>
								{dateFormatter(new Date(history.created_at))} - Изменения внес:{' '}
								{`${history.author.first_name} ${history.author.last_name}`}.{' '}
							</Typography>
							{JSON.parse(history.body).map((change: any) => (
								<Box display='flex' flexDirection='column' key={change.old_value}>
									<Typography variant='body_2' mb={1}>
										{change.old_value} <span style={{ color: '#24D321' }}>→</span>{' '}
										{change.new_value}
									</Typography>
									<Typography variant='footnote_2' color='primary.gray' mb={1}>
										Поле: <span style={{ color: '#181818' }}>Имя</span>
									</Typography>
								</Box>
							))}
							<Divider />
						</Box>
					))}
				</Box>
			</Box>
		</div>
	)
}
