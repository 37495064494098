import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Link,
	Pagination,
	Paper,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	Typography
} from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { useApp } from 'core/contexts/profile.context'
import { dateFormatter } from 'core/helpers/date-formatter'
import { TopchansManageStatusColor, TopchansManageStatusText, TopchansManageTypeText } from 'pages/TapchansManage/enum'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDebounce } from 'usehooks-ts'

import { getTapchanManage, removeOwnerTapchan } from '../../services/tapchan/tapchan.service'
import { parseLockers } from '../Lockers/parse'

export const TapchansManagePage = () => {
	const navigate = useNavigate()
	const [tab, setTab] = React.useState('all')
	const [page, setPage] = React.useState(1)
	const [currentId, setCurrentId] = useState<any>()
	const [search, setSearch] = React.useState('')

	const { profile } = useApp()
	const debouncedSearch = useDebounce(search, 500)
	const { data: tapchansManage, refetch } = useQuery(['getTapchanManage', tab, page, debouncedSearch], {
		queryFn: () =>
			getTapchanManage({
				page,
				tab,
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)

	const handleDeleteOpenModal = (curId: any) => () => {
		setCurrentId(curId)
		setOpenDeleteModal(true)
	}
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)
	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setTab(newValue)
	}

	const removeOwnerMutation = useMutation({
		mutationFn: (tapchan_id: any) => removeOwnerTapchan(tapchan_id),
		onSuccess: () => {
			toast.success('Владелец удален успешно!')
			setOpenDeleteModal(false)
			navigate('/management_tapchan')
			refetch()
		}
	})

	const onClickAddManage = (id: any) => () => {
		navigate(`/management_tapchan/${id}/add`)
	}

	const handleTapchanRemove = () => {
		removeOwnerMutation.mutate(currentId)
	}

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}

	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
		setPage(1)
		setTab('all')
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Управление топчанами</Typography>
				<Button onClick={() => navigate('/management_tapchan/history')} variant='outlined'>
					<span style={{ color: '#349EFF' }}>История</span>
				</Button>
			</Box>
			<Divider />
			<Box sx={{ width: '100%' }} mb={2} py={4} display='flex' justifyContent='center'>
				<Tabs value={tab} onChange={handleChangeTab} aria-label='wrapped label tabs example'>
					<Tab value='all' label='Списком' wrapped />
					<Tab value='ordinary' label='На карте' />
				</Tabs>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='end' p={1}>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell align='left'>ID</TableCell>
							<TableCell align='left'>Вид топчана</TableCell>
							<TableCell align='left'>Статус</TableCell>
							<TableCell align='center'>Дата и время брони</TableCell>
							<TableCell align='center'>Владелец</TableCell>
							<TableCell align='right'>Управление</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tapchansManage?.data &&
							parseLockers(tapchansManage.data).map((row: any) => (
								<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component='th' scope='row'>
										{row.id}
									</TableCell>
									<TableCell align='left'>{row.number}</TableCell>
									<TableCell align='left'>
										{TopchansManageTypeText[row.type as keyof typeof TopchansManageTypeText]}
									</TableCell>
									<TableCell
										align='left'
										sx={{
											color: TopchansManageStatusColor[
												row.status as keyof typeof TopchansManageStatusColor
											]
										}}
									>
										<Link href={`/management_tapchan/${row.id}`} underline='none' color='inherit'>
											{
												TopchansManageStatusText[
													row.status as keyof typeof TopchansManageStatusText
												]
											}
										</Link>
									</TableCell>
									<TableCell align='center'>
										{row.status === 'free' ? '-' : dateFormatter(new Date(row.created_at))}
									</TableCell>
									<TableCell align='center'>
										{row.last_rent?.customer?.last_name ? row.last_rent?.customer?.last_name : '-'}
									</TableCell>
									<TableCell align='right' id={row.id}>
										{row.status === 'free' ? (
											<Button
												variant='text'
												color='success'
												disabled={
													!profile?.user?.role.permissions.includes('update_manage_tapchan')
												}
												onClick={onClickAddManage(row.id)}
											>
												Добавить
											</Button>
										) : (
											<Button
												id={row.id}
												variant='text'
												disabled={
													!profile?.user?.role.permissions.includes('update_manage_tapchan')
												}
												color='error'
												onClick={handleDeleteOpenModal(row.id)}
											>
												Убрать
											</Button>
										)}
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{tapchansManage?.last_page && (
				<Box display='flex' justifyContent='center'>
					<Pagination
						sx={{ marginTop: '16px' }}
						count={tapchansManage.last_page}
						page={page}
						onChange={handleChange}
					/>
				</Box>
			)}
			<Dialog
				open={openDeleteModal}
				onClose={handleDeleteCloseModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем убрать владельца, убедитесь в том что это необходимо
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={handleDeleteCloseModal}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleTapchanRemove} autoFocus variant='text' color='error'>
						Да, убрать
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
