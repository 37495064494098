import { axiosBaseInstance } from 'core/axios.config'

export const getTicketsTariffs = async () => {
	const { data } = await axiosBaseInstance.get(`/back_office/ticket/tariffs`)
	return data
}

export const addTicketsTariffs = async (body: any) => {
	const { data } = await axiosBaseInstance.post(`/back_office/ticket/tariffs`, body)
	return data
}

export const getTicketTariffsId = async (tariff_id: any) => {
	const v = await axiosBaseInstance.get(`/back_office/ticket/tariffs/${tariff_id}/`)
	return v.data
}

export const deleteTicketTariffs = async (tariff_id: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/ticket/tariffs/${tariff_id}/`)
	return v.data
}

export const updateTicketsTariffs = async ({ tariff_id, body }: any) => {
	const { data } = await axiosBaseInstance.put(`/back_office/ticket/tariffs/${tariff_id}/`, body)
	return data
}
