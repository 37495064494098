import { Box, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

export const VisitorsCardComponent = ({ visitor }: any) => {
	console.log(visitor)
	return (
		<Box
			display='flex'
			flexDirection='column'
			py={1.5}
			sx={{
				width: 329
			}}
		>
			<Box display='flex' alignItems='center' gap={1}>
				<img
					style={{
						background: '#D9D9D9',
						width: 74,
						height: 74,
						objectFit: 'cover',
						borderRadius: '50%'
					}}
					src={visitor.avatar_url}
					alt='avatar'
				/>
				<Box display='flex' flexDirection='column' ml={2} gap={0.25}>
					<Typography variant='body_2'>
						{visitor.first_name} {visitor.last_name}
					</Typography>
					<Box display='flex' alignItems='center' gap={0.5}>
						<Typography variant='body_2' sx={{ color: 'primary.gray' }}>
							Тип билета:
						</Typography>
						<Typography variant='footnote_2'>{visitor.ticket_type}</Typography>
					</Box>
					<Typography variant='body_2' sx={{ color: 'primary.gray' }}>
						{moment(visitor.birth_date).format('DD.MM.YYYY')} -{' '}
						{visitor.gender === 'male' ? 'Мужчина' : 'Женщина'}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}
