import { axiosBaseInstance } from 'core/axios.config'

export const updateProfile = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/profile`, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return v.data
}

export const getProfile = async () => {
	const { data } = await axiosBaseInstance.get(`/back_office/profile`)
	return data
}
