import { axiosBaseInstance } from 'core/axios.config'

export const getRolesList = async () => {
	const { data } = await axiosBaseInstance.get(`/back_office/roles`)
	return data
}
export const getRoleById = async (id: any) => {
	const { data } = await axiosBaseInstance.get(`/back_office/roles/${id}`)
	return data
}

export const createRoles = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/roles/`, data)
	return v.data
}

export const deleteRoles = async (role_id: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/roles/${role_id}/`)
	return v.data
}

export const updateRoles = async ({ role_id, body }: any) => {
	const { data } = await axiosBaseInstance.put(`back_office/roles/${role_id}`, body)
	return data
}
