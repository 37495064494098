import {
	Box,
	Button,
	Chip,
	FormControl,
	ListSubheader,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Theme,
	Typography,
	useTheme
} from '@mui/material'
// import SearchInput from "components/search/SearchInput"
import { FilterList } from 'core/icons'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { getAllProducts } from 'services/product/product.service'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
}

function getStyles(name: string, personName: readonly string[], theme: Theme) {
	return {
		fontWeight:
			personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
	}
}

export const OrdersFilterByProducts = ({ setFilterOrdersbyProducts }: any) => {
	const theme = useTheme()
	// eslint-disable-next-line no-unused-vars
	// const [page, setPage] = React.useState(1)
	const [personName, setPersonName] = useState<string[]>([])

	const { data: products } = useQuery(['getAllProducts', 1], {
		queryFn: () => getAllProducts({ page: 1 })
	})

	const handleChange = (event: SelectChangeEvent<typeof personName>) => {
		const {
			target: { value }
		} = event
		// console.log(event)
		setPersonName(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		)
	}

	const cleanFilters = () => {
		setPersonName([])
		setFilterOrdersbyProducts([])
	}

	const applyFilters = () => {
		setFilterOrdersbyProducts(personName)
	}

	const renderSelectGroup = (order: any) => {
		const items = order.products.map((product: any) => {
			return (
				<MenuItem key={product.id} value={product.name} style={getStyles(product.name, personName, theme)}>
					{product.name}
				</MenuItem>
			)
		})
		return [<ListSubheader>{order.name}</ListSubheader>, items]
	}

	return (
		<Box display='flex' justifyContent='space-between' alignItems='center' py={1} px={2}>
			<Box display='flex' justifyContent='space-between' alignItems='center' gap={3}>
				<FilterList />
				<Box display='flex' alignItems='center'>
					<Typography variant='title_3' color='primary.gray'>
						Продукты:
					</Typography>
					{true && (
						<FormControl sx={{ m: 1, width: 500 }}>
							<Select
								fullWidth
								labelId='demo-multiple-chip-label'
								id='demo-multiple-chip'
								multiple
								value={personName}
								onChange={handleChange}
								input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
								renderValue={selected => (
									<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
										{selected.map(value => (
											<Chip key={value} label={value} />
										))}
									</Box>
								)}
								MenuProps={MenuProps}
							>
								{products?.data.map((category: any) => {
									return renderSelectGroup(category)
								})}
							</Select>
						</FormControl>
					)}
					<Button
						variant='outlined'
						size='small'
						sx={{
							height: 50,
							mr: 1
						}}
						color='primary'
						onClick={applyFilters}
					>
						<span style={{ color: '#349EFF' }}>Применить</span>
					</Button>
					<Button
						variant='outlined'
						size='small'
						sx={{
							height: 50
						}}
						color='error'
						onClick={cleanFilters}
					>
						<span>Очистить</span>
					</Button>
				</Box>
			</Box>
		</Box>
	)
}
