import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import { dateFormatterTime } from 'core/helpers/date-formatter'
import { Cancel, ChevronRight, Dots } from 'core/icons'
import React from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { cancelOrder, updateOrders } from 'services/orders/orders.service'

import { OrderStatusColor, OrderStatusText, OrderTypes } from './enum'
import { OrdersDeleteComponent } from './orders-delete.component'
import { ProductsListComponent } from './products-list.component'

export const OrderCardComponent = ({ orderType, number, status, products, workers, time, orderFrom, orderId }: any) => {
	const navigate = useNavigate()

	const [anchorDotsEl, setAnchorDotsEl] = React.useState<null | HTMLElement>(null)

	const { profile } = useApp()
	const openDots = Boolean(anchorDotsEl)
	const [isVisibleProducts, setIsVisibleProducts] = React.useState(false)
	const [showRemoveModal, setShowRemoveModal] = React.useState(false)

	const upDateOrderMutation = useMutation({
		mutationFn: (payload: any) => updateOrders(payload),
		onSuccess: () => {
			toast.success('Заказ успешно изменен!')
		}
	})

	const cancelOrderMutation = useMutation({
		mutationFn: (payload: any) => cancelOrder(payload),
		onSuccess: () => {
			navigate(`/orders`)
			toast.success('Заказ успешно удален!')
		}
	})

	const showProducts = React.useMemo(
		() => !(orderType === 'done' && !isVisibleProducts),
		[orderType, isVisibleProducts]
	)

	const handleClickDots = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorDotsEl(event.currentTarget)
	}
	const handleCloseDots = () => {
		setAnchorDotsEl(null)
	}
	const handleClickShowProducts = () => {
		setIsVisibleProducts(!isVisibleProducts)
	}
	const handleRemove = () => {
		setShowRemoveModal(true)
	}
	const onClickUpdateOrder = () => {
		upDateOrderMutation.mutate(orderId)
	}
	const onDeleteOrder = () => {
		cancelOrderMutation.mutate(orderId)
	}
	return (
		<Box
			display='flex'
			flexDirection='column'
			gap={2.5}
			p={1.5}
			mb={1.5}
			sx={{
				width: 276,
				maxWidth: 312,
				marginX: 'auto',
				border: `1px solid ${status === 'declined' ? 'rgba(237, 65, 65, 1)' : 'rgba(214, 214, 214, 0.48)'}`,
				borderRadius: 1.5,
				background: '#FFFFFF'
			}}
		>
			<Box display='flex' alignItems='center' gap={1}>
				<span
					style={{
						display: 'block',
						width: '4px',
						height: '24px',
						background: OrderTypes[orderType as keyof typeof OrderTypes] ?? OrderTypes.default,
						borderRadius: '2px'
					}}
				/>
				<Typography variant='title_2' flexGrow={1}>
					Заказ №{number}
				</Typography>
				{products?.length > 0 && orderType === 'done' && (
					<ChevronRight
						onClick={handleClickShowProducts}
						style={{
							transform: showProducts ? 'rotate(-90deg)' : 'rotate(90deg)',
							color: showProducts ? '#349EFF' : '#7D7D7D',
							cursor: 'pointer'
						}}
					/>
				)}
			</Box>
			{status && (
				<Box display='flex' gap={0.5}>
					<Typography variant='caption_1_regular' color='primary.gray'>
						Статус:
					</Typography>
					<Typography
						variant='caption_1'
						sx={{
							color: OrderStatusColor[status as keyof typeof OrderStatusColor]
						}}
					>
						{OrderStatusText[status as keyof typeof OrderStatusText]}
					</Typography>
				</Box>
			)}
			{products?.length > 0 && showProducts && (
				<ProductsListComponent products={products} orderType={orderType} />
			)}
			<Box display='flex' flexDirection='column' gap={1}>
				{time && (
					<Box display='flex' gap={0.5}>
						<Typography variant='caption_1_regular' color='primary.gray'>
							Время создания:
						</Typography>
						<Typography variant='caption_1'>{dateFormatterTime(new Date(time))}</Typography>
					</Box>
				)}
				{orderFrom && (
					<Box display='flex' gap={0.5}>
						<Typography variant='caption_1_regular' color='primary.gray'>
							Откуда заказ:
						</Typography>
						<Typography variant='caption_1'>{orderFrom === 'desktop_type' ? 'Admin' : 'Mobile'}</Typography>
					</Box>
				)}
				<Box display='flex' flexDirection='column'>
					{workers?.map((worker: any) => (
						<Box key={worker.id} display='flex' gap={0.5}>
							<Typography variant='caption_1_regular' color='primary.gray'>
								{worker.role.name}:
							</Typography>
							<Typography variant='caption_1'>
								{worker.first_name} {worker.last_name}
							</Typography>
						</Box>
					))}
				</Box>
			</Box>
			{(orderType === 'queue' || orderType === 'in_process') && (
				<Box display='flex' gap={1}>
					{profile?.user?.role.permissions.includes('update_order') && (
						<Button
							variant='outlined'
							size='small'
							fullWidth
							onClick={onClickUpdateOrder}
							sx={{
								color: 'primary.main',
								border: '1px solid #D6D6D6',
								borderRadius: 1,
								height: 40
							}}
						>
							{orderType === 'queue' ? 'Принять' : 'Готово к выдачи'}
						</Button>
					)}
					{profile?.user?.role.permissions.includes('update_order') && (
						<Button
							id={`dots-btn-${number}`}
							aria-controls={openDots ? `basic-menu-${number}` : undefined}
							aria-haspopup='true'
							aria-expanded={openDots ? 'true' : undefined}
							onClick={handleClickDots}
							sx={{
								border: '1px solid #D6D6D6',
								borderRadius: 1,
								minWidth: 40
							}}
						>
							<Dots style={{ fill: '#7D7D7D' }} />
						</Button>
					)}
				</Box>
			)}
			<Menu
				id={`basic-menu-${number}`}
				anchorEl={anchorDotsEl}
				open={openDots}
				onClose={handleCloseDots}
				MenuListProps={{
					'aria-labelledby': `dots-btn-${number}`
				}}
			>
				<MenuItem key={`menu-item-${number}`} onClick={handleRemove}>
					<Cancel
						style={{
							marginRight: '8px'
						}}
					/>
					Удалить
				</MenuItem>
			</Menu>
			<OrdersDeleteComponent
				showModal={showRemoveModal}
				onDeleteOrder={onDeleteOrder}
				setShowModal={setShowRemoveModal}
			/>
		</Box>
	)
}
