import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from '@mui/material'
import EditAvatar from 'components/avatar/EditAvatar'
import { ArrowBack } from 'core/icons'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import { getCategoryList } from 'services/food/food.service'
import { addNewProducts, deleteProducts, getProductsInfo, updateProducts } from 'services/product/product.service'

export const FoodDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const {
		register,
		handleSubmit,
		reset,
		control,
		setValue,
		formState: { errors }
	} = useForm<any>()
	const [image, setImage] = useState<any>()
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
	const [fileImage, setFileImage] = useState<any>()

	const { data: product } = useQuery(['getProductsInfo', id, location], {
		queryFn: () => getProductsInfo(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})

	const addNewProductsMutation = useMutation({
		mutationFn: payload => addNewProducts(payload),
		onSuccess: response => {
			toast.success('Successfully added')
			navigate(`/food_and_drinks/${response.data.category.id}/product`)
			reset()
		}
	})
	const updateProductsMutation = useMutation([product], {
		mutationFn: ({ product_id, body }: any) => updateProducts({ product_id, body }),
		onSuccess: response => {
			reset()
			navigate(`/food_and_drinks/${response.data.category.id}/product`)
			toast.success('Изменения успешно сохранены!')
		}
	})

	const deleteFoodMutate = useMutation(['deleteProducts', product], {
		mutationFn: (service_id: any) => deleteProducts(service_id),
		onSuccess: () => {
			navigate(`/food_and_drinks/${product.data.category.id}/product`)
			toast.success('Successfully deleted')
			setOpenDeleteModal(false)
		}
	})

	const { data: categories } = useQuery(['getCategoryList'], {
		queryFn: () =>
			getCategoryList({
				search: null
			})
	})

	const handleDeleteOpenModal = () => setOpenDeleteModal(true)
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)

	const handleDeleteFood = () => {
		deleteFoodMutate.mutate(id)
	}

	const onSubmit = (body: any) => {
		const fd: any = new FormData()
		if (fileImage) fd.append('avatar', fileImage)
		fd.append('name', body.name)
		fd.append('status', body.status)
		fd.append('measure', body.measure)
		if (body.description) fd.append('description', body.description)
		fd.append('category_id', body.category_id)
		fd.append('rating', body.rating)
		fd.append('price', body.price)
		fd.append('characteristic', body.characteristic)

		let isVisible = 0
		if (body.is_visible === 'true') {
			isVisible = 1
		} else if (typeof body.is_visible === 'boolean' && body.is_visible) {
			isVisible = 1
		}
		fd.append('is_visible', isVisible)
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		location.search.indexOf('edit') > 0
			? updateProductsMutation.mutate({ product_id: id, body: fd })
			: addNewProductsMutation.mutate(fd)
	}
	const onAvatarUpload = (file: any) => {
		setFileImage(file)
		setImage(URL.createObjectURL(file))
	}

	useEffect(() => {
		if (product) {
			setImage(product.data.img_path)
			setValue('name', product.data.name)
			setValue('status', product.data.status)
			setValue('measure', product.data.measure)
			setValue('description', product.data.description)
			setValue('category_id', product.data.category.id)
			setValue('rating', product.data.rating)
			setValue('price', product.data.price)
			setValue('characteristic', product.data.characteristic)
			setValue('is_visible', product.data.is_visible)
		}
	}, [setValue, product])
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={handleDeleteOpenModal}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={824}>
					<Typography variant='headline_1'>Данные продукта</Typography>
					<Box display='flex' mt={6}>
						<EditAvatar
							src={image}
							onUpload={onAvatarUpload}
							disabled={location.search.indexOf('view') > 0}
						/>
						<form
							onSubmit={handleSubmit(onSubmit)}
							noValidate
							style={{ width: '100%', marginLeft: '40px' }}
						>
							<Box mb={2}>
								<TextField
									label='Название продукта'
									placeholder='Название продукта'
									required
									fullWidth
									disabled={location.search.indexOf('view') > 0}
									type='text'
									error={!!errors.name}
									{...register('name')}
								/>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Controller
											control={control}
											name='category_id'
											defaultValue=''
											render={({ field: { ref, onBlur, name, ...field } }) => (
												<FormControl required fullWidth>
													<InputLabel id='demo-simple-select-label'>Категория</InputLabel>
													<Select
														disabled={location.search.indexOf('view') > 0}
														labelId='demo-simple-select-label'
														id='demo-simple-select'
														{...field}
													>
														{categories?.data.map((category: any) => (
															<MenuItem value={category.id}>{category.name}</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Controller
											control={control}
											name='status'
											defaultValue=''
											render={({ field: { ref, onBlur, name, ...field } }) => (
												<FormControl required fullWidth>
													<InputLabel id='demo-simple-select-label'>
														Статус готовки
													</InputLabel>
													<Select
														disabled={location.search.indexOf('view') > 0}
														labelId='demo-simple-select-label'
														id='demo-simple-select'
														{...field}
													>
														<MenuItem value='ready_product'>Готовая продукция</MenuItem>
														<MenuItem value='cook_product'>Не готовая продукция</MenuItem>
													</Select>
												</FormControl>
											)}
										/>
									</Grid>
								</Grid>
								<Controller
									control={control}
									name='is_visible'
									defaultValue=''
									render={({ field: { ref, onBlur, name, ...field } }) => (
										<FormControl required fullWidth>
											<InputLabel id='demo-simple-select-label'>
												Доступность показа клиентам
											</InputLabel>
											<Select
												disabled={location.search.indexOf('view') > 0}
												labelId='demo-simple-select-label'
												id='demo-simple-select'
												{...field}
											>
												<MenuItem value='true'>Доступно</MenuItem>
												<MenuItem value='false'>Не доступно</MenuItem>
											</Select>
										</FormControl>
									)}
								/>

								<Grid container spacing={2}>
									<Grid item xs={4}>
										<TextField
											label='Объем'
											placeholder=''
											required
											fullWidth
											disabled={location.search.indexOf('view') > 0}
											type='number'
											error={!!errors.characteristic}
											{...register('characteristic')}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											label='Ед. изм.'
											placeholder=''
											required
											fullWidth
											type='text'
											disabled={location.search.indexOf('view') > 0}
											error={!!errors.measure}
											{...register('measure')}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											label='Рейтинг'
											placeholder=''
											required
											fullWidth
											disabled={location.search.indexOf('view') > 0}
											type='number'
											helperText='Введите 1 до 5'
											error={!!errors.rating}
											{...register('rating')}
										/>
									</Grid>
								</Grid>

								<TextField
									label='Описание (Необзяательно)'
									placeholder=''
									fullWidth
									multiline
									rows={2}
									disabled={location.search.indexOf('view') > 0}
									type='text'
									error={!!errors.description}
									{...register('description')}
								/>
								<TextField
									label='Цена'
									placeholder=''
									required
									fullWidth
									type='price'
									disabled={location.search.indexOf('view') > 0}
									InputProps={{
										startAdornment: <InputAdornment position='start'>₸</InputAdornment>
									}}
									error={!!errors.price}
									{...register('price')}
								/>
							</Box>
							{location.search.indexOf('view') < 0 && (
								<Button
									variant='contained'
									type='submit'
									color='success'
									sx={{ paddingLeft: 12, paddingRight: 12 }}
								>
									{addNewProductsMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
								</Button>
							)}
						</form>
					</Box>
				</Box>
			</Box>
			<Dialog
				open={openDeleteModal}
				onClose={handleDeleteCloseModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={handleDeleteCloseModal}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button autoFocus variant='text' color='error' onClick={handleDeleteFood}>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
