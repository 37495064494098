import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Menu,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material'
import { useApp } from 'core/contexts/profile.context'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteRule, getBasicRules } from 'services/rules/rules.service'

import { Cancel, Dots, Edit, Settings, View } from '../../core/icons'
import { parseLockers } from '../Lockers/parse'

export const RulesPage = () => {
	const { profile } = useApp()
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const navigate = useNavigate()
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
	const [currentId, setCurrentId] = useState<any>()

	const { data: rules, refetch } = useQuery(['getBasicRules'], {
		queryFn: () => getBasicRules()
	})

	const handleDeleteOpenModal = () => setOpenDeleteModal(true)
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentId(event.currentTarget.id)
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const deleteRuleMutate = useMutation({
		mutationFn: (basic_rule_id: any) => deleteRule(basic_rule_id),
		onSuccess: () => {
			toast.success('Successfully deleted')
			setOpenDeleteModal(false)
			setAnchorEl(null)
			refetch()
		}
	})
	const handleEditTourniquet = () => {
		navigate(`/rules/${currentId}?edit`)
	}
	const handleViewTourniquet = () => {
		navigate(`/rules/${currentId}?view`)
	}

	const handleTourniquetDelete = () => {
		deleteRuleMutate.mutate(currentId)
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Инструктаж правил</Typography>
				{profile?.user?.role.permissions.includes('update_basic_rule') && (
					<Button onClick={() => navigate('/rules/add')} variant='contained'>
						Добавить
					</Button>
				)}
			</Box>
			<Divider />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell align='left'>Наименование базового правила</TableCell>
							<TableCell align='left'>Описание</TableCell>
							<TableCell align='right'>
								<Settings />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rules?.data &&
							parseLockers(rules.data).map((row: any) => (
								<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component='th' scope='row'>
										{row.id}
									</TableCell>
									<TableCell align='left'>{row.name}</TableCell>
									<TableCell align='left'>{row.description}</TableCell>
									<TableCell align='right'>
										<Button
											id={row.id}
											aria-controls={open ? 'basic-menu' : undefined}
											aria-haspopup='true'
											aria-expanded={open ? 'true' : undefined}
											onClick={handleClick}
										>
											<Dots />
										</Button>
										<Menu
											id={`basic-menu-${row.id}`}
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button'
											}}
										>
											<MenuItem onClick={handleViewTourniquet} key={`menu-item-${row.id}`}>
												<View
													style={{
														color: '#349EFF',
														marginRight: '8px'
													}}
												/>
												Просмотр карточки инструктажа
											</MenuItem>
											{profile?.user?.role.permissions.includes('update_basic_rule') && (
												<MenuItem onClick={handleEditTourniquet} key={`menu-item-${row.id}`}>
													<Edit
														style={{
															marginRight: '8px'
														}}
													/>
													Редактировать
												</MenuItem>
											)}
											{profile?.user?.role.permissions.includes('update_basic_rule') && (
												<MenuItem onClick={handleDeleteOpenModal}>
													<Cancel
														style={{
															marginRight: '8px'
														}}
													/>
													Удалить
												</MenuItem>
											)}
										</Menu>
									</TableCell>
									<Dialog
										open={openDeleteModal}
										onClose={handleClose}
										aria-labelledby='alert-dialog-title'
										aria-describedby='alert-dialog-description'
									>
										<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
										<DialogContent>
											<DialogContentText id='alert-dialog-description'>
												Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и
												без сохранении
											</DialogContentText>
										</DialogContent>
										<DialogActions
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'end'
											}}
										>
											<Button
												onClick={handleDeleteCloseModal}
												variant='text'
												color='success'
												style={{ marginBottom: '16px' }}
											>
												Нет, оставить
											</Button>
											<Button
												onClick={handleTourniquetDelete}
												autoFocus
												variant='text'
												color='error'
											>
												Да, удалить
											</Button>
										</DialogActions>
									</Dialog>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
