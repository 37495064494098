import {
	Autocomplete,
	Box,
	Button,
	Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography
} from '@mui/material'
import { ArrowBack } from 'core/icons'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addClosetsOwner, getCustomerActiveClosets } from 'services/closets/closets.service'
import { getEmployeesList } from 'services/employees/employees.service'
import { getCustomerGuests } from 'services/guests/guests.service'

import { ManageClosestCategory, ManageClosestState, ManageClosestStatus } from './enum'
import { parseClients } from './utils'

export const ManageClosestDetailPage = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const [searchParams] = useSearchParams()
	const gender = searchParams.get('gender')
	const page = searchParams.get('page')
	const { handleSubmit, control, watch } = useForm<any>()
	const { data: closet } = useQuery(['getCustomerActiveClosetsId', id], {
		queryFn: () => getCustomerActiveClosets(id),
		enabled: !!id
	})
	const watchType = watch('type')
	const addOwnerMutation = useMutation({
		mutationFn: ({ closet_id, body }: any) => addClosetsOwner({ closet_id, body }),
		onSuccess: () => {
			toast.success('Владелец выбран успешно!')
			navigate(`/management_closet?gender=${gender}&page=${page}`)
		}
	})
	const { data: clients } = useQuery(['getCustomerGuests'], {
		queryFn: () =>
			getCustomerGuests({
				per_page: 1000000,
				having_tickets: 1
			})
	})

	const { data: stuffs } = useQuery(['getEmployeesList'], {
		queryFn: () => getEmployeesList()
	})

	const onSubmit = (data: any) => {
		const userId = watchType === 'employee' ? data?.stuffs_user_id.id : data?.clients_user_id.id
		addOwnerMutation.mutate({
			closet_id: id,
			body: {
				user_type: data?.type,
				user_id: userId
			}
		})
	}
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(`/management_closet?gender=${gender}&page=${page}`)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
			</Box>
			<Divider />
			<Box display='flex' mt={2} gap={2}>
				<Box
					width={274}
					sx={{
						border: '1px solid rgba(214, 214, 214, 0.48)',
						borderRadius: 1.5
					}}
				>
					<Box px={2} py={3}>
						<Typography variant='title_2'>Сведения о шкафе</Typography>
					</Box>
					<Divider />
					<Box display='flex' flexDirection='column' alignItems='center' px={2} py={6}>
						<Box
							display='flex'
							alignItems='center'
							justifyContent='center'
							mb={2}
							sx={{
								width: 130,
								height: 130,
								background: '#349EFF',
								borderRadius: 2.5
							}}
						>
							<Typography variant='headline_1' color='primary.white'>
								{closet?.data.number}
							</Typography>
						</Box>
						<Box display='flex' flexDirection='column' alignItems='center' p={2}>
							<Typography variant='caption_1_regular' color='primary.gray'>
								Номер шкафчика
							</Typography>
							<Typography variant='body_1'>{closet?.data.number}</Typography>
						</Box>
						<Box display='flex' flexDirection='column' alignItems='center' p={2}>
							<Typography variant='caption_1_regular' color='primary.gray'>
								Статус
							</Typography>
							{closet?.data.status && (
								<Typography variant='body_1'>
									{ManageClosestStatus[closet?.data.status as keyof typeof ManageClosestStatus]}
								</Typography>
							)}
						</Box>
						<Box display='flex' flexDirection='column' alignItems='center' p={2}>
							<Typography variant='caption_1_regular' color='primary.gray'>
								Состояние
							</Typography>
							{closet?.data.state && (
								<Typography variant='body_1'>
									{ManageClosestState[closet?.data.state as keyof typeof ManageClosestState]}
								</Typography>
							)}
						</Box>
						<Box display='flex' flexDirection='column' alignItems='center' p={2}>
							<Typography variant='caption_1_regular' color='primary.gray'>
								Относится к раздевалке
							</Typography>
							{closet?.data.category && (
								<Typography variant='body_1'>
									{ManageClosestCategory[closet?.data.category as keyof typeof ManageClosestCategory]}
								</Typography>
							)}
						</Box>
					</Box>
				</Box>
				<Box
					width={798}
					sx={{
						border: '1px solid rgba(214, 214, 214, 0.48)',
						borderRadius: 1.5
					}}
				>
					<Box px={2} py={3}>
						<Typography variant='title_2'>Добавление владельца</Typography>
					</Box>
					<Divider />
					<Box display='flex' p={3}>
						<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
							<Controller
								control={control}
								name='type'
								defaultValue=''
								render={({ field: { ref, onBlur, name, ...field } }) => (
									<FormControl fullWidth disabled={location.search.indexOf('view') > 0}>
										<FormLabel id='demo-radio-buttons-group-label' style={{ margin: '16px 0' }}>
											<Typography variant='title_3' color='black'>
												Выберите тип владельца
											</Typography>
										</FormLabel>
										<RadioGroup
											aria-labelledby='demo-radio-buttons-group-label'
											defaultValue='ordinary'
											{...field}
											style={{ margin: '16px 0' }}
										>
											<FormControlLabel value='customer' control={<Radio />} label='Клиент' />
											<Divider />
											<FormControlLabel value='employee' control={<Radio />} label='Сотрудник' />
										</RadioGroup>
									</FormControl>
								)}
							/>
							{watchType === 'customer' && clients?.data && (
								<Box mb={2}>
									<Controller
										control={control}
										defaultValue=''
										name='clients_user_id'
										render={({ field: { ...field } }) => (
											<Autocomplete
												id='clients-select'
												options={parseClients(clients?.data)}
												sx={{ width: 300 }}
												getOptionLabel={option => (option ? option.label : '')}
												renderInput={params => <TextField {...params} label='Клиенты' />}
												{...field}
												onChange={(event, item) => {
													field.onChange(item)
												}}
											/>
										)}
									/>
								</Box>
							)}
							{watchType === 'employee' && stuffs?.data && (
								<Box mb={2}>
									<Controller
										control={control}
										defaultValue=''
										name='stuffs_user_id'
										render={({ field: { ...field } }) => (
											<Autocomplete
												id='stuffs-select'
												disablePortal
												options={parseClients(stuffs?.data)}
												getOptionLabel={option => (option ? option.label : '')}
												sx={{ width: 300 }}
												renderInput={params => <TextField {...params} label='Сотрудники' />}
												{...field}
												onChange={(event, item) => {
													field.onChange(item)
												}}
											/>
										)}
									/>
								</Box>
							)}
							<Button
								variant='contained'
								type='submit'
								size='medium'
								sx={{ width: '252px' }}
								color='success'
							>
								Сделать владельцем
							</Button>
						</form>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
