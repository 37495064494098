import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	Link,
	Menu,
	MenuItem,
	TextField,
	Typography
} from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { useApp } from 'core/contexts/profile.context'
import { Cancel, Dots, Edit, View } from 'core/icons'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteCategory, getCategoryList } from 'services/food/food.service'
import { useDebounce } from 'usehooks-ts'

export const FoodsCategoryPage = () => {
	const navigate = useNavigate()
	const { profile } = useApp()
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
	const [currentId, setCurrentId] = useState<any>()
	const [reasonText, setReasonText] = React.useState('')
	const [search, setSearch] = React.useState('')
	const [anchorDotsEl, setAnchorDotsEl] = React.useState<null | HTMLElement>(null)
	const openDots = Boolean(anchorDotsEl)

	const debouncedSearch = useDebounce(search, 500)
	const { data: categories, refetch } = useQuery(['getCategoryList', debouncedSearch], {
		queryFn: () =>
			getCategoryList({
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})

	const deleteCategoryMutate = useMutation(['deleteFoodCategory'], {
		mutationFn: ({ category_id, reason }: any) => deleteCategory({ category_id, reason }),
		onSuccess: () => {
			refetch()
			setAnchorDotsEl(null)
			setReasonText('')
			toast.success('Successfully deleted')
			setOpenDeleteModal(false)
		}
	})
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const onChangeReason = (e: any) => {
		setReasonText(e.target.value)
	}

	const handleClickDots = (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentId(event.currentTarget.id)
		setAnchorDotsEl(event.currentTarget)
	}
	const handleCloseDots = () => {
		setAnchorDotsEl(null)
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
	}

	const handleDeleteOpenModal = () => setOpenDeleteModal(true)
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)

	const handleAddCategory = () => {
		navigate('/food_and_drinks/category/add')
	}
	const handleAddFood = () => {
		navigate('/food_and_drinks/product/add')
	}

	const handleEditFoodCategory = () => {
		navigate(`/food_and_drinks/category/${currentId}?edit`)
	}

	const handleViewFoodCategory = () => {
		navigate(`/food_and_drinks/category/${currentId}?view`)
	}

	const handleDeleteCategory = () => {
		if (reasonText) {
			deleteCategoryMutate.mutate({
				category_id: currentId,
				reason: reasonText
			})
		} else {
			toast.error('Нужнa причина!')
		}
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Еда и напитки</Typography>
				{profile?.user?.role.permissions.includes('update_product_category') && (
					<Button onClick={handleClick} variant='contained'>
						Добавить
					</Button>
				)}
				<Menu
					id='basic-menu'
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button'
					}}
				>
					<MenuItem onClick={handleAddCategory}>Новую категорию</MenuItem>
					<MenuItem onClick={handleAddFood}>Новый товар</MenuItem>
				</Menu>
			</Box>
			<Divider />
			<Box p={2} display='flex' justifyContent='end'>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<Box sx={{ padding: '48px 96px' }}>
				<Grid container spacing={2}>
					{categories?.data.map((category: any) => (
						<Grid item xs={3}>
							<Card
								sx={{
									maxWidth: 280,
									background: '#F7F7F7',
									borderRadius: '12px',
									position: 'relative',
									zIndex: '5'
								}}
							>
								<Link href={`food_and_drinks/${category.id}/product`} underline='none'>
									<CardMedia
										sx={{
											height: 160,
											width: 280,
											border: '1px solid #F7F7F7',
											borderRadius: '12px'
										}}
										image={category.img_path}
										title={category.name}
									/>
								</Link>
								<Box
									position='absolute'
									right={8}
									top={8}
									display='flex'
									justifyContent='center'
									alignContent='center'
									sx={{
										width: 32,
										height: 32,
										border: '1px solid #D6D6D6',
										background: 'white',
										borderRadius: '12px'
									}}
								>
									<Button
										id={category.id}
										aria-controls={openDots ? `basic-menu-${category.id}` : undefined}
										aria-haspopup='true'
										aria-expanded={openDots ? 'true' : undefined}
										onClick={handleClickDots}
									>
										<Dots />
									</Button>
								</Box>
								<Menu
									id={`basic-menu-${category.id}`}
									anchorEl={anchorDotsEl}
									open={openDots}
									onClose={handleCloseDots}
									MenuListProps={{
										'aria-labelledby': category.id
									}}
								>
									<MenuItem key={`menu-item-${category.id}`} onClick={handleViewFoodCategory}>
										<View
											style={{
												color: '#349EFF',
												marginRight: '8px'
											}}
										/>
										Просмотр категории
									</MenuItem>
									{profile?.user?.role.permissions.includes('update_product_category') && (
										<MenuItem key={`menu-item-${category.id}`} onClick={handleEditFoodCategory}>
											<Edit
												style={{
													marginRight: '8px'
												}}
											/>
											Редактировать
										</MenuItem>
									)}
									{profile?.user?.role.permissions.includes('update_product_category') && (
										<MenuItem onClick={handleDeleteOpenModal}>
											<Cancel
												style={{
													marginRight: '8px'
												}}
											/>
											Удалить
										</MenuItem>
									)}
								</Menu>

								<Link href={`food_and_drinks/${category.id}/product`} underline='none' color='inherit'>
									<CardContent>
										<Typography gutterBottom variant='body_1' component='div'>
											{category.name}
										</Typography>
										<Typography variant='footnote_2' color='text.secondary'>
											{category.products_count} товаров
										</Typography>
									</CardContent>
								</Link>
							</Card>
						</Grid>
					))}
				</Grid>
			</Box>
			<Dialog
				open={openDeleteModal}
				onClose={handleDeleteCloseModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
					<Box mt={2}>
						<TextField
							label='Причина удаления'
							placeholder=''
							fullWidth
							multiline
							rows={3}
							type='text'
							value={reasonText}
							onChange={onChangeReason}
							name='reason'
						/>
					</Box>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={handleDeleteCloseModal}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button
						autoFocus
						variant='text'
						disabled={!reasonText}
						color='error'
						onClick={handleDeleteCategory}
					>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
