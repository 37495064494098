import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	TextField,
	Typography
} from '@mui/material'
import { Add, ArrowBack, Remove } from 'core/icons'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify'
import {
	addTicketsTariffs,
	deleteTicketTariffs,
	getTicketTariffsId,
	updateTicketsTariffs
} from 'services/ticketTariff/tickets-tariff.service'
import { useBoolean } from 'usehooks-ts'

export const TicketsTariffDetailPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm<any>()
	const modalOpen = useBoolean(false)

	const [count, setCount] = useState(0)

	const ticketsTariffMutation = useMutation({
		mutationFn: payload => addTicketsTariffs(payload),
		onSuccess: () => {
			reset()
			navigate('/tickets')
			toast.success('Successfully added')
		}
	})
	const tariffUpdateMutation = useMutation({
		mutationFn: ({ tariff_id, body }: any) => updateTicketsTariffs({ tariff_id, body }),
		onSuccess: () => {
			toast.success('Изменения успешно сохранены!')
			navigate('/tickets?tab=3')
		}
	})
	const deleteTariffMutate = useMutation({
		mutationFn: (tariff_id: any) => deleteTicketTariffs(tariff_id),
		onSuccess: () => {
			toast.success('Тариф успешно удален!')
			navigate('/tickets?tab=3')
		}
	})
	const { data: tariff } = useQuery(['getTapchanId', id, location], {
		queryFn: () => getTicketTariffsId(id),
		enabled: !!id && (location.search.indexOf('edit') > 0 || location.search.indexOf('view') > 0)
	})
	const IncNum = () => {
		setCount(count + 1)
	}
	const DecNum = () => {
		if (count > 0) setCount(count - 1)
	}

	const onSubmit = (formValues: any) => {
		const data = {
			...formValues,
			hours: count
		}
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		location.search.indexOf('edit') > 0
			? tariffUpdateMutation.mutate({ tariff_id: id, body: data })
			: ticketsTariffMutation.mutate(data)
	}

	const handleTariffDelete = () => {
		deleteTariffMutate.mutate(id)
	}

	useEffect(() => {
		if (tariff?.data) {
			setCount(tariff.data.hours)
			setValue('name', tariff.data.name)
		}
	}, [setValue, tariff])

	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				{location.search.indexOf('edit') > 0 && (
					<Button variant='outlined' color='error' onClick={modalOpen.setTrue}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={520}>
					<Typography variant='headline_1'>Данные тарифа</Typography>
					<form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '100%' }}>
						<Box mb={3} mt={4}>
							<TextField
								label='Наименование'
								placeholder=''
								required
								disabled={location.search.indexOf('view') > 0}
								fullWidth
								error={!!errors.name}
								{...register('name')}
							/>
						</Box>
						<Typography variant='title_1' color='#575757'>
							Укажите время
						</Typography>
						<Box display='flex' justifyContent='space-between' mt={4} mb={4}>
							<Typography variant='body_2'>Часы в тарифе</Typography>
							<Box>
								<Button
									onClick={DecNum}
									disabled={location.search.indexOf('view') > 0}
									sx={{
										minWidth: '34px',
										minHeight: '34px',
										background: '#F7F7F7',
										padding: 5,
										borderRadius: '12px',
										color: '#575757',
										marginRight: '12px'
									}}
								>
									<Remove />
								</Button>
								{count}
								<Button
									onClick={IncNum}
									disabled={location.search.indexOf('view') > 0}
									sx={{
										minWidth: '34px',
										minHeight: '34px',
										background: '#E2F1FF',
										padding: 5,
										borderRadius: '12px',
										marginLeft: '12px'
									}}
								>
									<Add />
								</Button>
							</Box>
						</Box>
						{location.search.indexOf('view') < 0 && (
							<Button variant='contained' type='submit' fullWidth color='success'>
								{ticketsTariffMutation.isLoading ? <CircularProgress size={24} /> : 'Сохранить'}
							</Button>
						)}
					</form>
				</Box>
			</Box>

			<Dialog
				open={modalOpen.value}
				onClose={modalOpen.setFalse}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={modalOpen.setFalse}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleTariffDelete} autoFocus variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
