import { Box, Button, Divider, Pagination, styled, Typography } from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { dateFormatter } from 'core/helpers/date-formatter'
import { ArrowBack, ChevronRight } from 'core/icons'
import Cookies from 'js-cookie'
import { EnterExitHistoryStatusColor, EnterExitHistoryStatusText } from 'pages/EnterExit/enum'
import { parseLockers } from 'pages/Lockers/parse'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getCrossingsList } from 'services/crossings/crossings.service'
import { useDebounce } from 'usehooks-ts'

const ImgBlock = styled('div')(() => ({
	img: {
		height: '40px',
		width: '40px',
		borderRadius: '40px',
		objectFit: 'cover'
	}
}))

export const EnterExitHistoryPage = () => {
	const navigate = useNavigate()
	const [page, setPage] = React.useState(1)
	const [search, setSearch] = React.useState('')

	const debouncedSearch = useDebounce(search, 500)
	const { data: history } = useQuery(['getCrossingsList', page, debouncedSearch], {
		queryFn: () =>
			getCrossingsList({
				per_page: 10,
				page,
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})
	const handleChangeCrossings = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
		setPage(1)
	}

	const handleCardClick = (data: any) => () => {
		Cookies.set('enter-exit-history-client', JSON.stringify(data))
		navigate('/management_enter_exit/history/client')
	}

	return (
		<div>
			<Box p={2} display='flex' alignItems='center'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%', marginRight: '16px' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
				<Typography variant='title_2'>История</Typography>
			</Box>
			<Divider />
			<Divider />
			<Box p={2} display='flex' justifyContent='end'>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
				{history?.data &&
					Object.keys(history?.data).map(key => (
						<Box mt={6} width={500} key={`enter-exit-history-${key}`}>
							<Typography variant='headline_1' color='primary.black' mb={4}>
								{key}
							</Typography>
							{history?.data[key] &&
								parseLockers(history?.data[key]).map((data: any) => (
									<Box
										display='flex'
										justifyContent='space-between'
										alignItems='center'
										mb={2}
										key={`enter-exit-history-item-${data.id}`}
										sx={{ borderBottom: '1px solid #D7D7D7' }}
									>
										<Box display='flex' justifyContent='start' alignItems='center' pb={2} gap={2}>
											<Box>
												<ImgBlock>
													<img src={data?.customer?.avatar_url} alt='frame' />
												</ImgBlock>
											</Box>
											<Box display='flex' flexDirection='column'>
												<Box display='flex'>
													<Typography
														variant='caption_1_regular'
														sx={{
															color: EnterExitHistoryStatusColor[
																data.type as keyof typeof EnterExitHistoryStatusColor
															]
														}}
														mr={1}
													>
														{
															EnterExitHistoryStatusText[
																data.type as keyof typeof EnterExitHistoryStatusText
															]
														}
													</Typography>
													<Typography variant='caption_1_regular' color='primary.gray'>
														- {dateFormatter(new Date(data.created_at))}
													</Typography>
												</Box>
												{data.customer && (
													<Typography variant='body_2'>
														{data.customer?.first_name} {data.customer?.last_name}
													</Typography>
												)}
												{!data.show_has_children_warning && (
													<Typography variant='caption_1_regular' color='primary.gray'>
														Без детей
													</Typography>
												)}
												{!!data.guests?.length && (
													<Box display='flex' alignItems='center' mt={1}>
														<Typography variant='footnote_1' color='primary.gray' mr={1}>
															Другие посетители:
														</Typography>
														<Box display='flex' ml={2} gap={0.5}>
															{data?.guests
																.slice(0, 3)
																.map((childrenItem: { avatar_url: string }) => (
																	<img
																		style={{
																			background: '#D9D9D9',
																			borderRadius: '50%',
																			objectFit: 'cover',
																			width: 24,
																			height: 24
																		}}
																		src={childrenItem.avatar_url}
																		alt='avatar'
																	/>
																))}
															{data?.guests.length > 3 && (
																<Box
																	display='flex'
																	alignItems='center'
																	justifyContent='center'
																	sx={{
																		width: 24,
																		height: 24,
																		borderRadius: '50%',
																		background:
																			'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #D9D9D9'
																	}}
																>
																	<Typography
																		variant='body_2'
																		sx={{ color: 'primary.white' }}
																	>
																		+{data.guests.length - 3}
																	</Typography>
																</Box>
															)}
														</Box>
													</Box>
												)}
											</Box>
										</Box>
										<Box>
											<ChevronRight
												style={{ fill: '#7D7D7D', cursor: 'pointer' }}
												onClick={handleCardClick(data)}
											/>
										</Box>
									</Box>
								))}
						</Box>
					))}
				{history?.last_page && (
					<Box display='flex' justifyContent='center'>
						<Pagination
							sx={{ marginTop: '16px' }}
							count={history.last_page}
							page={page}
							onChange={handleChangeCrossings}
						/>
					</Box>
				)}
			</Box>
		</div>
	)
}
