const mainColors = {
	Brand: {
		default: '#349EFF',
		secondary: '#BFE0FF'
	},
	White: {
		default: '#FFFFFF',
		neutral: '#F6F6F6'
	},
	Black: {
		default: '#181818',
		secondary: '#7C7C7C'
	},
	Gray: {
		default: '#7D7D7D',
		secondary: '#D6D6D6'
	},
	Red: {
		default: '#ED4141',
		secondary: '#FFAFAF'
	},
	Success: {
		default: '#24D321',
		secondary: '#24D321'
	},
	Orange: {
		default: '#FF9A3D',
		secondary: '#F4BC87'
	},
	Pink: {
		default: '#FC6BFF',
		secondary: '#FEDBFF'
	},
	Background: {
		'background-1': '#F7F7F7',
		'background-2': '#FFFFFF'
	}
}

export default mainColors
