export const historyList = [
	{
		day: 'Сегодня',
		orders: [
			{
				date: '12.10.23',
				time: '19:46',
				number: '8932389',
				owner: 'Алпамыс Актилеков'
			},
			{
				date: '12.10.23',
				time: '19:46',
				number: '8932390',
				owner: 'Свободный шкаф'
			}
		]
	},
	{
		day: 'Вчера',
		orders: [
			{
				date: '12.10.23',
				time: '19:46',
				number: '8932391',
				owner: 'Алпамыс Актилеков'
			},
			{
				date: '12.10.23',
				time: '19:46',
				number: '8932392',
				owner: 'Алпамыс Актилеков'
			},
			{
				date: '12.10.23',
				time: '19:46',
				number: '8932393',
				owner: 'Алпамыс Актилеков'
			}
		]
	}
]
