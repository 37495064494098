import { Box, Button, Divider, Typography } from '@mui/material'
import { ArrowBack } from 'core/icons'
import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { OrdersDeleteComponent } from '../Orders/orders-delete.component'
import { detailOrders } from './mock'
import { OrdersDetailCardComponent } from './orders-detail-card.component'

export const OrdersDetailPage = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const [searchParams] = useSearchParams()
	const isHistoryDetail = Boolean(searchParams.get('is_history'))

	const [showRemoveModal, setShowRemoveModal] = React.useState(false)

	const onEditClick = () => {
		navigate(`/orders/${id}/edit`)
	}

	const onRemoveClick = () => {
		setShowRemoveModal(true)
	}

	return (
		<Box>
			<Box p={2} display='flex' alignItems='center' gap={2}>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} />
					<Typography variant='footnote_2'>Назад</Typography>
				</Button>
				<Box flexGrow={1}>
					{isHistoryDetail ? (
						<Typography variant='title_2'>Заказ №{id}</Typography>
					) : (
						<Typography variant='title_2'>Данные заказа</Typography>
					)}
					{isHistoryDetail && (
						<Typography variant='title_2' color='success.main'>
							{' '}
							- Передан
						</Typography>
					)}
				</Box>
				{!isHistoryDetail && (
					<Button variant='outlined' sx={{ color: 'primary.main' }} onClick={onEditClick}>
						изменить
					</Button>
				)}
				{!isHistoryDetail && (
					<Button variant='outlined' color='error' onClick={onRemoveClick}>
						Удалить
					</Button>
				)}
			</Box>
			<Divider />
			<Box
				display='flex'
				flexDirection='column'
				justifyContent='center'
				mt={5}
				sx={{ maxWidth: 520, mx: 'auto' }}
				gap={5}
			>
				<Box display='flex' flexDirection='column' gap={0.5}>
					<Typography variant='headline_1'>Заказ №283281</Typography>
					<Box display='flex' gap={2}>
						<Box display='flex' gap={1}>
							<Typography variant='body_2' color='primary.gray'>
								Статус:
							</Typography>
							<Typography variant='body_1' color='primary.orange'>
								В очереди
							</Typography>
						</Box>
						<Box display='flex' gap={1}>
							<Typography variant='body_2' color='primary.gray'>
								Дата добавления:
							</Typography>
							<Typography variant='body_1'>12 сен 2023</Typography>
						</Box>
					</Box>
					<Box display='flex' gap={1}>
						<Typography variant='body_2' color='primary.gray'>
							Кассир:
						</Typography>
						<Typography variant='body_1'>Камила Карсенбаева</Typography>
					</Box>
					<Box display='flex' gap={1}>
						<Typography variant='body_2' color='primary.gray'>
							Откуда заказ:
						</Typography>
						<Typography variant='body_1'>Мобильное приложение</Typography>
					</Box>
				</Box>
				<Box display='flex' flexDirection='column' mx={2}>
					{detailOrders.map(order => (
						<OrdersDetailCardComponent key={`order-detail-${order.id}`} {...order} />
					))}
				</Box>
				<Box display='flex' justifyContent='space-between' mx={2}>
					<Typography variant='title_2'>Итого</Typography>
					<Typography variant='title_2'>1800 ₸</Typography>
				</Box>
			</Box>
			<OrdersDeleteComponent showModal={showRemoveModal} setShowModal={setShowRemoveModal} />
		</Box>
	)
}
