import { InputAdornment, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Search } from 'core/icons'
import React from 'react'

const CssTextField = styled(TextField)({
	'& .MuiOutlinedInput-root': {
		background: '#F6F6F6',
		'& fieldset': {
			borderColor: 'transparent'
		},
		'&:hover fieldset': {
			borderColor: 'transparent!important'
		},
		'&.Mui-focused fieldset': {
			borderColor: 'transparent'
		}
	}
})

function SearchInput(props: any) {
	const { search, onChangeSearch } = props

	return (
		<CssTextField
			id='input-with-icon-textfield'
			value={search}
			onChange={onChangeSearch}
			placeholder='Поиск'
			sx={{
				border: 'none',
				margin: 0
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position='start'>
						<Search />
					</InputAdornment>
				)
			}}
		/>
	)
}

export default SearchInput
