import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { dateFormatter } from 'core/helpers/date-formatter'
import { ArrowBack, CheckCircle } from 'core/icons'
import {
	OperationDetailStatusText,
	OperationStatusText,
	StatusBackgroundColor,
	StatusColor,
	StatusText
} from 'pages/HistoryPayment/enum'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { customerWalletOperationsId } from 'services/payment/payment.service'

export const ReturnCashDetailPage = () => {
	const navigate = useNavigate()
	const { id } = useParams()

	const { data: paymentHistory } = useQuery(['customerWalletOperationsId', id], {
		queryFn: () => customerWalletOperationsId(id),
		enabled: !!id
	})
	return (
		<Box>
			<Box p={2} display='flex' justifyContent='space-between'>
				<Button
					color='info'
					variant='text'
					size='small'
					onClick={() => navigate(-1)}
					sx={{ border: 0, borderRadius: '50%' }}
				>
					<ArrowBack style={{ fill: '#349EFF', marginRight: '12px' }} /> Назад
				</Button>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='center'>
				<Box mt={6} width={574}>
					<Typography variant='headline_1'>Данные платежа</Typography>
					{paymentHistory?.data && (
						<Box display='flex'>
							<Typography variant='body1' color='grey'>
								Дата операции:{' '}
								<strong>{dateFormatter(new Date(paymentHistory.data.created_at))}</strong>
							</Typography>
						</Box>
					)}
					{paymentHistory?.data && (
						<Box
							display='flex'
							sx={{
								borderRadius: '12px',
								backgroundColor:
									StatusBackgroundColor[
										paymentHistory?.data?.status as keyof typeof StatusBackgroundColor
									]
							}}
							p={2}
							mt={2}
						>
							<Box display='column' justifyContent='center' alignContent='center' mr={2}>
								<CheckCircle
									style={{
										fill: StatusColor[paymentHistory?.data?.status as keyof typeof StatusColor]
									}}
								/>
							</Box>
							<Box display='column'>
								<Typography mb={1} variant='footnote_1' color='gray'>
									Статус
								</Typography>
								<Typography>
									<strong>
										{StatusText[paymentHistory?.data?.status as keyof typeof StatusText]}
									</strong>
								</Typography>
							</Box>
						</Box>
					)}
					<Box display='flex' justifyContent='space-between' mt={2}>
						<Box>
							{paymentHistory?.data && (
								<Grid container rowSpacing={3} my={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												ID
											</Typography>
											<Typography variant='body1'>
												<strong>{paymentHistory?.data.id}</strong>
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Тип операции
											</Typography>
											<Typography variant='body1'>
												<strong>
													{
														OperationDetailStatusText[
															paymentHistory.data
																?.type as keyof typeof OperationDetailStatusText
														]
													}
												</strong>
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Provider_order_id
											</Typography>
											<Typography variant='body1'>
												<strong>{paymentHistory?.data?.provider_order_id}</strong>
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Проведена ли в кассе?
											</Typography>
											<Typography variant='body1'>
												<strong>{paymentHistory?.data?.provider_order_id}</strong>
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Статус
											</Typography>
											<Typography variant='body1'>
												<strong>
													{
														OperationStatusText[
															paymentHistory.data
																?.type as keyof typeof OperationStatusText
														]
													}
												</strong>
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Метод пополнение
											</Typography>
											<Typography variant='body1'>
												<strong>
													{paymentHistory?.data?.provider === 'cashier_web'
														? paymentHistory?.data?.operation_author.first_name
														: 'Kassa24'}
												</strong>
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												ID кошелька клиента
											</Typography>
											<Typography variant='body1'>
												<strong>{paymentHistory?.data?.wallet?.id}</strong>
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												ID заказа
											</Typography>
											<Typography variant='body1'>
												<strong>
													{paymentHistory?.data?.last_rent?.customer?.first_name
														? paymentHistory?.data?.last_rent?.customer?.first_name
														: '-'}
												</strong>
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Владелец кошелька
											</Typography>
											<Typography variant='body1'>
												<strong>
													{paymentHistory?.data?.wallet?.customer?.first_name}{' '}
													{paymentHistory?.data?.wallet?.customer?.last_name}
												</strong>
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Сумма
											</Typography>
											<Typography variant='body1'>
												<strong>{paymentHistory?.data?.amount}</strong>
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Сумма до операции
											</Typography>
											<Typography variant='body1'>
												<strong>{paymentHistory?.data?.actual_wallet_amount_before}</strong>
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display='flex' flexDirection='column'>
											<Typography variant='footnote_2' sx={{ color: 'primary.gray' }}>
												Остаток
											</Typography>
											<Typography variant='body1'>
												<strong>{paymentHistory?.data?.wallet?.amount}</strong>
											</Typography>
										</Box>
									</Grid>
								</Grid>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
