import { Box, Button, Divider, Tab, Tabs, Typography } from '@mui/material'
import { TowelsMainComponent } from 'pages/TowelsManage/towels-main.component'
import { TowelsTenantListComponent } from 'pages/TowelsManage/towels-tenant-list.component'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const TowelsManagePage = () => {
	const navigate = useNavigate()
	const [tab, setTab] = React.useState('1')
	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setTab(newValue)
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Управление полотенцами</Typography>
				<Button onClick={() => navigate('/management_towel/history')} variant='outlined'>
					<span style={{ color: '#349EFF' }}>История</span>
				</Button>
			</Box>
			<Divider />
			<Box sx={{ width: '100%' }} mb={2} py={4} display='flex' justifyContent='center'>
				<Tabs value={tab} onChange={handleChangeTab} aria-label='wrapped label tabs example'>
					<Tab value='1' label='Основное управление' wrapped />
					<Tab value='2' label='Список арендаторов ' />
				</Tabs>
			</Box>
			<Divider />
			{tab === '1' && <TowelsMainComponent />}
			{tab === '2' && <TowelsTenantListComponent />}
		</div>
	)
}
