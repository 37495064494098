import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { FilterList } from 'core/icons'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { getPoints, getPresets, setPresets } from 'services/pointer/pointer.service'

export const OrdersFilterComponent = ({ search, point, setSearch, setPoint, setPointColor }: any) => {
	const { data: pointers } = useQuery(['getPoints'], {
		queryFn: () => getPoints()
	})
	const { data: presets } = useQuery(['getPresets'], {
		queryFn: () => getPresets()
	})
	const setPresetsMutate = useMutation({
		mutationFn: (point_id: any) => setPresets(point_id)
	})
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
	}

	const handlePointChange = (event: SelectChangeEvent) => {
		const pointId = event.target.value
		setPoint(pointId)
		setPresetsMutate.mutate(pointId)
		const currentPoint = pointers?.data.find((n: any) => n.id.toString() === pointId.toString())
		if (currentPoint) setPointColor(currentPoint.color?.length > 0 ? currentPoint.color : '#FFFFFF')
	}

	useEffect(() => {
		if (pointers?.data) {
			const presetPointId = presets?.data?.issue_point_id
			if (presetPointId) {
				setPoint(presetPointId)
				const currentPoint = pointers?.data.find((n: any) => n.id.toString() === presetPointId.toString())
				if (currentPoint) {
					setPointColor(currentPoint.color?.length > 0 ? currentPoint.color : '#FFFFFF')
				}
			} else {
				setPoint(pointers?.data[0].id)
				setPointColor(pointers?.data[0].color?.length > 0 ? pointers?.data[0].color : '#FFFFFF')
			}
		}
	}, [pointers, presets?.data?.issue_point_id, setPoint, setPointColor])

	return (
		<Box display='flex' justifyContent='space-between' alignItems='center' py={1} px={2}>
			<Box display='flex' justifyContent='space-between' alignItems='center' gap={3}>
				<FilterList />
				<Box display='flex' alignItems='center'>
					<Typography variant='title_3' color='primary.gray'>
						Точка:
					</Typography>
					{point && (
						<FormControl>
							<Select
								value={point}
								defaultValue={String(point)}
								onChange={handlePointChange}
								displayEmpty
								id='my-point-select'
								sx={{
									'& #my-point-select': {
										fontSize: '16px',
										fontWeight: 700
									},
									height: 24,
									'.MuiOutlinedInput-notchedOutline': {
										border: 'none'
									},
									'.MuiSelect-iconOutlined': {
										marginTop: -0.5
									}
								}}
							>
								{pointers?.data?.map((pointProps: any) => (
									<MenuItem value={pointProps.id}>{pointProps.address}</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</Box>
			</Box>
			<SearchInput search={search} onChangeSearch={onChangeSearch} />
		</Box>
	)
}
