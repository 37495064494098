import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Menu,
	MenuItem,
	Pagination,
	Paper,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	TextField,
	Typography
} from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { useApp } from 'core/contexts/profile.context'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDebounce } from 'usehooks-ts'

import { AccessTime, Cancel, Dots, Edit, Restored, Settings, View } from '../../core/icons'
import { deleteEmployee, getEmployeesList, restoreEmployee } from '../../services/employees/employees.service'
import { parseLockers } from '../Lockers/parse'

export const StuffsPage = () => {
	const navigate = useNavigate()
	const { profile } = useApp()
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const [tab, setTab] = React.useState('0')
	const [page, setPage] = React.useState(1)
	const [search, setSearch] = React.useState('')
	const [reasonText, setReasonText] = React.useState('')

	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
	const [currentId, setCurrentId] = useState<any>()
	const handleDeleteOpenModal = () => setOpenDeleteModal(true)
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)

	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setTab(newValue)
		setPage(1)
	}

	const debouncedSearch = useDebounce(search, 500)
	const { data: stuffs, refetch } = useQuery(['getEmployeesList', tab, page, debouncedSearch], {
		queryFn: () =>
			getEmployeesList({
				page,
				archived: tab,
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})
	const restoreStaffMutate = useMutation({
		mutationFn: (employee_id: any) => restoreEmployee(employee_id),
		onSuccess: () => {
			toast.success('Successfully restored')
			setAnchorEl(null)
			refetch()
		}
	})
	const deleteStaffMutate = useMutation({
		mutationFn: ({ employee_id, reason }: any) => deleteEmployee({ employee_id, reason }),
		onSuccess: () => {
			toast.success('Successfully deleted')
			setOpenDeleteModal(false)
			setAnchorEl(null)
			setReasonText('')
			refetch()
		}
	})
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentId(event.currentTarget.id)
		setAnchorEl(event.currentTarget)
	}
	const onChangeReason = (e: any) => {
		setReasonText(e.target.value)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleEditStuff = () => {
		if (tab === '1') {
			navigate(`/stuffs/${currentId}?restore`)
		} else {
			navigate(`/stuffs/${currentId}?edit`)
		}
	}
	const handleViewStaff = () => {
		navigate(`/stuffs/${currentId}?view`)
	}
	const handleHistoryStuff = () => {
		navigate(`/stuffs/${currentId}/history`)
	}
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}
	const handleStaffRestore = () => {
		restoreStaffMutate.mutate(currentId)
	}
	const handleStaffDelete = () => {
		if (reasonText) {
			deleteStaffMutate.mutate({ employee_id: currentId, reason: reasonText })
		} else {
			toast.error('Нужнa причина!')
		}
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
		setPage(1)
		setTab('0')
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Сотрудники</Typography>
				{profile?.user?.role.permissions.includes('update_employee') && (
					<Button onClick={() => navigate('/stuffs/add')} variant='contained'>
						Добавить
					</Button>
				)}
			</Box>
			<Divider />
			<Box sx={{ width: '100%' }} mb={2} py={4} display='flex' justifyContent='center'>
				<Tabs value={tab} onChange={handleChangeTab} aria-label='wrapped label tabs example'>
					<Tab value='0' label='Текущий список' wrapped />
					<Tab value='1' label='Архив из уволенных' />
				</Tabs>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='end' p={1}>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell align='left'>Имя</TableCell>
							<TableCell align='left'>Фамилия</TableCell>
							<TableCell align='left'>Роль</TableCell>
							<TableCell align='left'>Телефон</TableCell>
							<TableCell align='left'>Почта</TableCell>
							<TableCell align='left'>ИИН</TableCell>
							<TableCell align='right'>
								<Settings />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{stuffs?.data &&
							parseLockers(stuffs.data).map((row: any) => (
								<TableRow
									key={row.id}
									sx={{
										'&:last-child td, &:last-child th': { border: 0 }
									}}
								>
									<TableCell component='th' scope='row'>
										{row.id}
									</TableCell>
									<TableCell align='left'>{row.first_name}</TableCell>
									<TableCell align='left'>{row.last_name}</TableCell>
									<TableCell align='left'>{row?.role?.name}</TableCell>
									<TableCell align='left'>{row.phone}</TableCell>
									<TableCell
										align='left'
										style={{
											maxWidth: 172,
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis'
										}}
									>
										{row.email}
									</TableCell>
									<TableCell
										align='left'
										style={{
											maxWidth: 172,
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis'
										}}
									>
										{row.iin}
									</TableCell>
									<TableCell align='right'>
										<Button
											id={row.id}
											aria-controls={open ? 'basic-menu' : undefined}
											aria-haspopup='true'
											aria-expanded={open ? 'true' : undefined}
											onClick={handleClick}
										>
											<Dots />
										</Button>
										<Menu
											id={`basic-menu-${row.id}`}
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button'
											}}
										>
											<MenuItem onClick={handleViewStaff} key={`menu-item-${row.id}`}>
												<View
													style={{
														color: '#349EFF',
														marginRight: '8px'
													}}
												/>
												Просмотр карточки сотрудника
											</MenuItem>
											{profile?.user?.role.permissions.includes('update_employee') && (
												<MenuItem onClick={handleEditStuff} key={`menu-item-${row.id}`}>
													<Edit
														style={{
															marginRight: '8px'
														}}
													/>
													Редактировать
												</MenuItem>
											)}
											<Divider />
											{tab === '0' && (
												<>
													<MenuItem onClick={handleHistoryStuff}>
														<AccessTime
															style={{
																marginRight: '8px'
															}}
														/>
														История изменений
													</MenuItem>
													<Divider />
												</>
											)}
											{tab === '0' &&
											profile?.user?.role.permissions.includes('update_employee') ? (
												<MenuItem onClick={handleDeleteOpenModal}>
													<Cancel
														style={{
															marginRight: '8px'
														}}
													/>
													Уволить
												</MenuItem>
											) : (
												<MenuItem onClick={handleStaffRestore}>
													<Restored
														style={{
															marginRight: '8px'
														}}
													/>
													Отозвать
												</MenuItem>
											)}
										</Menu>
									</TableCell>
									<Dialog
										open={openDeleteModal}
										onClose={handleDeleteCloseModal}
										aria-labelledby='alert-dialog-title'
										aria-describedby='alert-dialog-description'
									>
										<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
										<DialogContent>
											<DialogContentText id='alert-dialog-description'>
												Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и
												без сохранении
											</DialogContentText>
											<Box mt={2}>
												<TextField
													label='Причина удаления'
													placeholder=''
													fullWidth
													multiline
													rows={3}
													type='text'
													value={reasonText}
													onChange={onChangeReason}
													name='reason'
												/>
											</Box>
										</DialogContent>
										<DialogActions
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'end'
											}}
										>
											<Button
												onClick={handleDeleteCloseModal}
												variant='text'
												color='success'
												style={{ marginBottom: '16px' }}
											>
												Нет, оставить
											</Button>
											<Button
												onClick={handleStaffDelete}
												autoFocus
												disabled={!reasonText}
												variant='text'
												color='error'
											>
												Да, уволить
											</Button>
										</DialogActions>
									</Dialog>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{stuffs?.last_page && (
				<Box display='flex' justifyContent='center'>
					<Pagination
						sx={{ marginTop: '16px' }}
						count={stuffs.last_page}
						page={page}
						onChange={handleChange}
					/>
				</Box>
			)}
		</div>
	)
}
