import { axiosBaseInstance } from 'core/axios.config'

export const getTicketsCategories = async (is_parent?: any) => {
	const { data } = await axiosBaseInstance.get(
		`/back_office/ticket/categories${is_parent ? `?only_parent=${1}` : ''}`
	)
	return data
}

export const addTicketsCategory = async (data: any) => {
	const v = await axiosBaseInstance.post(`/back_office/ticket/categories`, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return v.data
}

export const getTicketCategoryId = async (category_id: any) => {
	const v = await axiosBaseInstance.get(`/back_office/ticket/categories/${category_id}`)
	return v.data
}

export const deleteTicketCategory = async (category_id: any) => {
	const v = await axiosBaseInstance.delete(`/back_office/ticket/categories/${category_id}/`)
	return v.data
}

export const updateTicketsCategory = async ({ category_id, body }: any) => {
	const { data } = await axiosBaseInstance.post(`/back_office/ticket/categories/${category_id}/`, body, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return data
}
