import { axiosBaseInstance, axiosBaseInstanceToken } from 'core/axios.config'

export const backOfficeRegister = async (data: any) => {
	const v = await axiosBaseInstance.post(`back_office/register`, data)

	return v.data
}
export const backOfficeLogin = async (data: any) => {
	const v = await axiosBaseInstance.post(`back_office/login`, data)

	return v.data
}

export const backOfficeOtp = async (data: any) => {
	const v = await axiosBaseInstance.post(`back_office/otp`, data)

	return v.data
}
export const backOfficeOtpWithoutToken = async (data: any) => {
	const v = await axiosBaseInstanceToken.post(`back_office/otp`, data)

	return v.data
}
export const backOfficeResetPassword = async (data: any) => {
	const v = await axiosBaseInstance.post(`back_office/reset-password`, data)

	return v.data
}
export const backOfficeUpdatePassword = async (data: any) => {
	const v = await axiosBaseInstance.post(`back_office/update-password`, data)

	return v.data
}
export const backOfficeOtpCheck = async (data: any) => {
	const v = await axiosBaseInstance.post(`back_office/otp/check`, data)

	return v.data
}
