import { Box, Button, Divider, Typography } from '@mui/material'
import { ChevronRight } from 'core/icons'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { getCrossings } from 'services/crossings/crossings.service'

import { EntryDetailCardComponent } from './entry-detail-card.component'

export const EnterExitDetailPage = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const { data: crossings } = useQuery([`getCrossings${id}`, id], {
		enabled: !!id,
		queryFn: () =>
			getCrossings({
				per_page: 12,
				page: 1,
				tourniquet_id: id
			})
	})
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Управление входа/выхода</Typography>
				<Box display='flex'>
					<Button
						onClick={() => navigate('/management_enter_exit/history')}
						variant='outlined'
						sx={{ marginRight: '8px' }}
					>
						<span style={{ color: '#349EFF' }}>История посещения</span>
					</Button>
					<Button onClick={() => navigate('/management_enter_exit/activate&deactivate')} variant='contained'>
						<span>(де-) Активировать</span>
					</Button>
				</Box>
			</Box>
			<Divider />
			<Box
				display='flex'
				justifyContent='center'
				p={2.75}
				gap={2}
				sx={{
					borderBottom: '1px solid rgba(214, 214, 214, 0.48)',
					borderRadius: 1.5
				}}
			>
				<Typography variant='body_1'>Турникет №{id}</Typography>
				<ChevronRight
					style={{
						fill: '#349EFF',
						transform: 'rotate(180deg)',
						cursor: 'pointer'
					}}
					onClick={() => navigate('/management_enter_exit')}
				/>
			</Box>
			<Box display='flex' flexDirection='column' alignItems='center' my={2} gap={2}>
				{crossings?.data?.map((cardItem: any, index: number) => (
					<EntryDetailCardComponent isActive={index === 0} cardItem={cardItem} key={cardItem.number} />
				))}
			</Box>
		</div>
	)
}
