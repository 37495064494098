import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Menu,
	MenuItem,
	Modal,
	Pagination,
	Paper,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	Typography
} from '@mui/material'
import SearchInput from 'components/search/SearchInput'
import { useApp } from 'core/contexts/profile.context'
import { Cancel, Dots, Edit, Settings, View } from 'core/icons'
import { TopchansTypeText } from 'pages/Tapchans/enum'
import React, { useState } from 'react'
import QRCode from 'react-qr-code'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDebounce } from 'usehooks-ts'

import { deleteTapchan, getTapchan } from '../../services/tapchan/tapchan.service'
import { parseLockers } from '../Lockers/parse'

export const TapchansPage = () => {
	const { profile } = useApp()
	const navigate = useNavigate()
	const [tab, setTab] = React.useState('all')
	const [page, setPage] = React.useState(1)
	const [search, setSearch] = React.useState('')
	const [qrUrl, setQrUrl] = React.useState('')
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const [currentId, setCurrentId] = useState<any>()

	const debouncedSearch = useDebounce(search, 500)
	const { data: tapchans, refetch } = useQuery(['getTapchan', tab, page, debouncedSearch], {
		queryFn: () =>
			getTapchan({
				page,
				type: tab === 'all' ? null : tab,
				search: debouncedSearch.length > 0 ? debouncedSearch : null
			})
	})
	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 556,
		height: 588,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4
	}
	const [openModal, setOpenModal] = React.useState(false)
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
	const handleOpenModal = (qrData: any) => () => {
		setQrUrl(JSON.stringify({ type: 'tapchan', id: qrData.id }))
		setOpenModal(true)
	}
	const handleCloseModal = () => setOpenModal(false)

	const handleDeleteOpenModal = () => setOpenDeleteModal(true)
	const handleDeleteCloseModal = () => setOpenDeleteModal(false)
	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setTab(newValue)
		setPage(1)
	}
	const deleteTapchanMutate = useMutation({
		mutationFn: (tapchan_id: any) => deleteTapchan(tapchan_id),
		onSuccess: () => {
			toast.success('Successfully deleted')
			setOpenDeleteModal(false)
			setAnchorEl(null)
			refetch()
		}
	})
	const onClickDownload = () => {
		const svg = document.getElementById('QRCode-tapchans')
		// @ts-ignore
		const svgData = new XMLSerializer().serializeToString(svg)
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		const img = new Image()
		img.onload = () => {
			canvas.width = img.width * 2.5
			canvas.height = img.height * 2.5
			// @ts-ignore
			ctx.fillStyle = '#FFFFFF'
			// @ts-ignore
			ctx.fillRect(0, 0, 640, 640)
			// @ts-ignore
			ctx.drawImage(img, 70, 70, 500, 500)
			const pngFile = canvas.toDataURL('image/png')
			const downloadLink = document.createElement('a')
			downloadLink.download = 'QRCode-tapchans'
			downloadLink.href = `${pngFile}`
			downloadLink.click()
		}
		img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
	}
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentId(event.currentTarget.id)
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleEditTapchan = () => {
		navigate(`/tapchans/${currentId}?edit`)
	}
	const handleViewTopchan = () => {
		navigate(`/tapchans/${currentId}?view`)
	}
	const handleTapchanDelete = () => {
		deleteTapchanMutate.mutate(currentId)
	}
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}
	const onChangeSearch = (e: any) => {
		setSearch(e.target.value)
		setPage(1)
		setTab('all')
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' p={2}>
				<Typography variant='title_2'>Топчаны</Typography>
				{profile?.user?.role.permissions.includes('update_tapchan') && (
					<Button onClick={() => navigate('/tapchans/add')} variant='contained'>
						Добавить
					</Button>
				)}
			</Box>
			<Divider />
			<Box sx={{ width: '100%' }} p={2} display='flex' justifyContent='space-between'>
				<Tabs value={tab} onChange={handleChangeTab} aria-label='wrapped label tabs example'>
					<Tab value='all' label='Все' wrapped />
					<Tab value='ordinary' label='Обычные' />
					<Tab value='vip' label='VIP' />
				</Tabs>
				<SearchInput search={search} onChangeSearch={onChangeSearch} />
			</Box>
			<Divider />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>№</TableCell>
							<TableCell align='left'>Номер топчана</TableCell>
							<TableCell align='left'>Вид топчана</TableCell>
							<TableCell align='right'>Цена</TableCell>
							<TableCell align='right'>Статичный QR</TableCell>
							<TableCell align='right'>
								<Settings />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tapchans?.data &&
							parseLockers(tapchans.data).map((row: any) => (
								<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component='th' scope='row'>
										{row.id}
									</TableCell>
									<TableCell align='left'>{row.number}</TableCell>
									<TableCell align='left'>
										{TopchansTypeText[row.type as keyof typeof TopchansTypeText]}
									</TableCell>
									<TableCell align='right'>{row.price}</TableCell>
									<TableCell align='right'>
										<Button onClick={handleOpenModal(row)}>Открыть QR</Button>
									</TableCell>
									<TableCell align='right'>
										<Button
											id={row.id}
											aria-controls={open ? 'basic-menu' : undefined}
											aria-haspopup='true'
											aria-expanded={open ? 'true' : undefined}
											onClick={handleClick}
										>
											<Dots />
										</Button>
										<Menu
											id={`basic-menu-${row.id}`}
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button'
											}}
										>
											<MenuItem onClick={handleViewTopchan} key={`menu-item-${row.id}`}>
												<View
													style={{
														color: '#349EFF',
														marginRight: '8px'
													}}
												/>
												Просмотр карточки топчана
											</MenuItem>
											{profile?.user?.role.permissions.includes('update_tapchan') && (
												<MenuItem onClick={handleEditTapchan} key={`menu-item-${row.id}`}>
													<Edit
														style={{
															marginRight: '8px'
														}}
													/>
													Редактировать
												</MenuItem>
											)}
											{profile?.user?.role.permissions.includes('update_tapchan') && (
												<MenuItem onClick={handleDeleteOpenModal}>
													<Cancel
														style={{
															marginRight: '8px'
														}}
													/>
													Удалить
												</MenuItem>
											)}
										</Menu>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{tapchans?.last_page && (
				<Box display='flex' justifyContent='center'>
					<Pagination
						sx={{ marginTop: '16px' }}
						count={tapchans.last_page}
						page={page}
						onChange={handleChange}
					/>
				</Box>
			)}
			<Dialog
				open={openDeleteModal}
				onClose={handleDeleteCloseModal}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Вы уверены?</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Прежде чем удалить, убедитесь в том что данные удалятся безвозвратно и без сохранении
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end'
					}}
				>
					<Button
						onClick={handleDeleteCloseModal}
						variant='text'
						color='success'
						style={{ marginBottom: '16px' }}
					>
						Нет, оставить
					</Button>
					<Button onClick={handleTapchanDelete} autoFocus variant='text' color='error'>
						Да, удалить
					</Button>
				</DialogActions>
			</Dialog>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					{qrUrl && (
						<Box p={3}>
							<QRCode
								id='QRCode-tapchans'
								size={256}
								style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
								value={qrUrl}
								viewBox='0 0 256 256'
							/>
						</Box>
					)}
					<Divider />
					<Box sx={{ p: 2 }}>
						<Button onClick={onClickDownload}>Печать</Button>
					</Box>
				</Box>
			</Modal>
		</div>
	)
}
